<section class="main-pageNotFound">
    <div class="container">
        <div class="main-wrapper">
            <h1>OOPS !</h1>
            <h3>Admin not done the payment.Please connect with your admin</h3>
            <!-- <h3>Look like you're lost</h3>
            <p>the page you are looking for is not available!</p> -->
            <!-- <a [href]="sendURL">
                <button>Go to Home</button>
            </a> -->
        </div>
    </div>
</section>