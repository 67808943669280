import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProgramsM3uResolver implements Resolve<any> {
  userDetails: any;
  m3uType: any;
  mediaItemId: any;
  channelName: any;
  episodeNo: any;
  seasonNo: any;
  showSeriesName: any;
  constructor(
    private _Meta: Meta,
    private _Title: Title,
    private _HomeService: HomeService,
    private _SessionService: SessionService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.m3uType = route.queryParams.channelType
    this.mediaItemId = route.queryParams.mediaItemId
    this.channelName = route.queryParams.channelName
    this.episodeNo = route.queryParams.episodeNo
    this.seasonNo = route.queryParams.seasonNo
    this.userDetails = this._SessionService.getSession('loggedInUser');
    return this._HomeService
      .getM3uUrl(this.channelName,this.m3uType,this.episodeNo,this.seasonNo,this.mediaItemId)
      .toPromise()
      .then((data) => data)
      .catch(err => '')
  }
}
