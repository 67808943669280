import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChannelComponent } from './components/channel/channel.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { BillingComponent } from './components/dashboard/billing/billing.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MenuComponent } from './components/dashboard/menu/menu.component';
import { ProfileComponent } from './components/dashboard/profile/profile.component';
import { SecurityComponent } from './components/dashboard/security/security.component';
import { EbookDetailComponent } from './components/ebook-detail/ebook-detail.component';
import { EbookDetailResolver } from './components/ebook-detail/ebook-detail.resolver';
import { EbookComponent } from './components/ebook/ebook.component';
import { GivingComponent } from './components/giving/giving.component';
import { HomeComponent } from './components/home/home.component';
import { JoinComponent } from './components/join/join.component';
import { MobileAppComponent } from './components/mobile-app/mobile-app.component';
import { NetworkComponent } from './components/network/network.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ProgramsResolver } from './components/programs/programs.resolver';
import { SigninPageComponent } from './components/signin-page/signin-page.component';
import { SignupPageComponent } from './components/signup-page/signup-page.component';
import { WatchdailyComponent } from './components/watchdaily/watchdaily.component';
import { WatchDailyResolver } from './components/watchdaily/watchdaily.resolver';
import { AuthGuard } from './guards/auth.guard';
import { EbookGuard } from './guards/ebook.guard';
import { GivingGuard } from './guards/giving.guard';
import { HomeGuard } from './guards/home.guard';
import { JoinGuard } from './guards/join.guard';
import { MobileAppGuard } from './guards/mobile-app.guard';
import { VodGuard } from './guards/vod.guard';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SitecantreachComponent } from './sitecantreach/sitecantreach.component';
import { IframeLiveComponent } from './components/iframe-live/iframe-live.component';
import { LiveGuard } from './guards/live.guard';
import { IframePlayerComponent } from './components/iframe-player/iframe-player.component';
import { IframePlayerResolver } from './components/iframe-player/iframe-player.resolver';
import { ProgramsM3uComponent } from './components/programs-m3u/programs-m3u.component';
import { M3uDetailPageComponent } from './components/m3u-detail-page/m3u-detail-page.component';
import { M3uDetailPageResolver } from './components/m3u-detail-page/m3u-detail-page.resolver';
import { ProgramsM3uResolver } from './components/programs-m3u/programs-m3u.resolver';
import { ChannelGuard } from './guards/channel.guard';
import { NetworkGuard } from './guards/network.guard';
import { EbookResolver } from './components/ebook/ebook.resolver';
import { HomeResolver } from './components/home/home.resolver';
import { PaymentnotdonepageComponent } from './paymentnotdonepage/paymentnotdonepage.component';

const routes: Routes = [
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'play', component: ProgramsM3uComponent, resolve: { data: ProgramsM3uResolver } },
  { path: 'main', component: HomeComponent, canActivate: [HomeGuard], resolve: { data: HomeResolver } },
  { path: 'channel', component: ChannelComponent, canActivate: [ChannelGuard] },
  { path: 'network', component: NetworkComponent, canActivate: [NetworkGuard] },
  { path: 'm3u-detail', component: M3uDetailPageComponent, resolve: { data: M3uDetailPageResolver } },
  { path: 'giving', component: GivingComponent, canActivate: [GivingGuard] },
  {
    path: 'mobileApp',
    component: MobileAppComponent,
    canActivate: [MobileAppGuard],
  },
  {
    path: 'vod',
    component: WatchdailyComponent,
    resolve: { data: WatchDailyResolver },
    canActivate: [VodGuard],
  },
  { path: 'signin', component: SigninPageComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'site-error', component: SitecantreachComponent },
  { path: 'join', component: JoinComponent, canActivate: [JoinGuard] },
  // { path: 'program', component: ProgramsComponent},
  {
    path: 'video/:id',
    component: ProgramsComponent,
    resolve: { data: ProgramsResolver },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'menu',
        component: MenuComponent,
        children: [
          { path: '', redirectTo: 'profile', pathMatch: 'full' },
          { path: 'profile', component: ProfileComponent },
          { path: 'security', component: SecurityComponent },
          { path: 'billing', component: BillingComponent },
        ],
      },
      //   {
      //     path: 'security',
      //     component: SecurityComponent,
      // },
    ],
  },
  {
    path: 'ebook-list',
    canActivate: [EbookGuard],
    component: EbookComponent,
    resolve: { data: EbookResolver }
  },
  {
    path: 'ebook/:id',
    canActivate: [EbookGuard],
    component: EbookDetailComponent,
    resolve: { data: EbookDetailResolver },
  },
  {
    path: 'frame',
    canActivate: [LiveGuard],
    component: IframeLiveComponent,
  },
  {
    path: 'liveVideo/:id',
    component: IframePlayerComponent,
    resolve: { data: IframePlayerResolver },
  },
  {
    path: 'payment-not-done',
    component: PaymentnotdonepageComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PagenotfoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
