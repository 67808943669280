import {
  ChangeDetectorRef,
  Component,
  PLATFORM_ID,
  Inject,
  ApplicationRef,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from './services/home/home.service';
import { LoginService } from './services/login/login.service';
import { SessionService } from './services/shared/session.service';
import { SharedService } from './services/shared/shared.service';
import { filter, take } from 'rxjs/operators';
import { EventReplayer } from 'preboot';
import { isPlatformBrowser } from '@angular/common';
import { SocketService } from './services/socket/socket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentPageComponent } from './components/payment-page/payment-page.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  showLoader = false;
  loaderSubscription: Subscription;
  gettoken: any;
  userDetails: any;
  isHeader: boolean;
  isAbsolutePosition: boolean;
  isRelativePosition: boolean;
  isMainPage: boolean;
  isGiving: boolean;
  showView:boolean;
  private socket: any;
  constructor(
    public sharedService: SharedService,
    public loginService: LoginService,
    public router: Router,
    private cdref: ChangeDetectorRef,
    private homeService: HomeService,
    private _SessionService: SessionService,
    @Inject(PLATFORM_ID) private platformId: string,
    private appRef: ApplicationRef,
    private replayer: EventReplayer,
    private socketService: SocketService,
    private _NgbModal: NgbModal
  ) {
    this.loaderSubscription = new Subscription();
    this.isHeader = false;
    this.isAbsolutePosition = false;
    this.isRelativePosition = true;
    this.isMainPage = false;
    this.isGiving = false;
    this.showView = false
    this.showLoader = true;
    this.router.events.subscribe((events: any) => {
      this.routerInterceptor(events);
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  routerInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      this.sharedService.updateLoader(true);
    } else if (event instanceof NavigationCancel) {
      this.sharedService.updateLoader(false);
    } else if (event instanceof NavigationError) {
      this.sharedService.updateLoader(false);
    } else if (event instanceof NavigationEnd) {
      if (event.urlAfterRedirects === '/main' && event.url === '/') {
        this.userDetails = this._SessionService.getSession('loggedInUser');
        if (this.userDetails) {
          this.router.navigate(['/vod']);
        }
      }
      if (
        event.urlAfterRedirects.includes('vod') ||
        event.urlAfterRedirects.includes('ebook') ||
        event.urlAfterRedirects.includes('video') ||
        event.urlAfterRedirects.includes('contact-us') ||
        event.urlAfterRedirects.includes('main') ||
        event.urlAfterRedirects.includes('channel') ||
        event.urlAfterRedirects.includes('network') ||
        event.urlAfterRedirects.includes('play') ||
        event.urlAfterRedirects.includes('mobileApp') ||
        event.urlAfterRedirects.includes('giving') ||
        event.urlAfterRedirects.includes('frame')
      ) {
        if (
          event.urlAfterRedirects.includes('vod') ||
          event.urlAfterRedirects.includes('ebook-list') ||
          event.urlAfterRedirects.includes('contact-us') ||
          event.urlAfterRedirects.includes('main') ||
          event.urlAfterRedirects.includes('channel') ||
          event.urlAfterRedirects.includes('network') ||
          event.urlAfterRedirects.includes('play') ||
          event.urlAfterRedirects.includes('mobileApp') ||
          event.urlAfterRedirects.includes('giving') ||
          event.urlAfterRedirects.includes('frame')
        ) {
          this.isGiving = event.urlAfterRedirects.includes('giving');
          if (
            event.urlAfterRedirects.includes('ebook-list') ||
            event.urlAfterRedirects.includes('vod')
          ) {
            if (window.innerWidth < 600) {
              this.isRelativePosition = true;
            } else {
              this.isRelativePosition = false;
            }
          } else {
            this.isRelativePosition = true;
          }
          this.isAbsolutePosition = true;
        } else {
          this.isAbsolutePosition = false;
          this.isRelativePosition = false;
        }
        this.isHeader = true;
        if(event.urlAfterRedirects.includes('m3u-detail') || event.urlAfterRedirects.includes('profile') || event.urlAfterRedirects.includes('signup')){
          this.isHeader = false;
        }
      } else {
        this.isAbsolutePosition = false;
        this.isHeader = false;
      }
      this.sharedService.updateLoader(false);
    }
  }

  ngOnInit() {
    this.loaderSubscription = this.sharedService.loaderState$.subscribe(
      (state: any) => {
        this.showLoader = state;
      }
      );
      this.router.initialNavigation();
      if (isPlatformBrowser(this.platformId)) {
        this.appRef.isStable
        .pipe(
          filter((stable) => stable),
          take(1)
          )
          .subscribe(() => {
            this.replayer.replayAll();
          });
        }
    this.pageCustomization();
    // if(this.router.url !== '/login' && this.router.url !== '/' && this.router.url !== '/reset-password' && this.router.url !== '/signup' ){
    //   this.tokenValidate();
    // }
    // this.userDetails = this._SessionService.getSession('loggedInUser');
    // if (this.userDetails) {
    //   this.router.navigate(['/vod']);
    // }
    //  else {
    //   this.router.navigate(['/main']);
    // }
  }

  sendDataToServer() {
    const data = {
      message: 'Hello, server!'
    };
    this.socketService.emit('viewer', data);
  }
  
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.replayer.replayAll();
      setTimeout(() => {
        this.sendDataToServer();
      }, 1000);
    }
  }
  ngOnDestroy() {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  pageCustomization() {
    this.sharedService.updateLoader(true);
    this.homeService.pageCustomizationData().subscribe(
      (response: any) => {
        if(!response.data.paymentDone){
          this._NgbModal.dismissAll();
            this._NgbModal.open(PaymentPageComponent, {
              backdrop: false,
              centered: true,
              size: 'lg',
              ariaLabelledBy: 'Payment Page'
            });
        }
        else{
          this.showView = true
          this.showLoader = false
          this.homeService.sendData(response);
          this.sharedService.updateLoader(false);
        }
      },
      (error: any) => {
        let errMsg = error.error.message;
        if (errMsg.includes('Organization not found with subdomain')) {
          this.router.navigate(['/site-error']);
        }
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }
}
