<div class="main-security">
    <div class="container">
        <div class="profile-form form-wrapper">
            <form #signInForm="ngForm">
                <div class="d-flex" style="flex-direction: column;">
                        <div class="form-group">
                            <label>Current Password</label>
                            <input type="password" name="email" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>New Password</label>
                            <input type="password" name="password" class="form-control">
                        </div>
                        <div class="save-btn mt-10">
                            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}" class="main-btn" type="submit" >Update Password</button>
                        </div>
                    </div>
                </form>
        </div>
    </div>
</div>