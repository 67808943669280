import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-m3u-detail-page',
  templateUrl: './m3u-detail-page.component.html',
  styleUrls: ['./m3u-detail-page.component.scss']
})
export class M3uDetailPageComponent implements OnInit {
  appBGColor = 'background-color: rgb(6, 12, 53);'
  loginImg: any;
  scrHeight: any;
  scrWidth: any;
  leftGradiant: any;
  bottomGradiant: any;
  m3uType: any;
  orderObj: any;
  sharedData: any;
  mediaItemId: any
  logo: any;
  isFav = false
  m3uData: any;
  isLoggedIn: any;
  itemDetail: any;
  showMore = false
  duration: any;
  seasonData: any;
  seasons: any;
  isFavourite: any;
  channelName: any;
  selectedM3uCategory: any;
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(
    private elementRef: ElementRef,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private messageService: MessageService,
    private _sessionService: SessionService
    ) {
    this.getScreenSize()
   }

  ngOnInit(): void {
    let loginData: any;
    loginData = this._sessionService.getSession('loggedInUser');
    // loginData = localStorage.getItem('loggedInUser')
    this.isLoggedIn = loginData ? true : false;
    this.m3uType = this.route.snapshot.queryParams.channelType
    this.mediaItemId = this.route.snapshot.queryParams.mediaItemId
    this.isFavourite = this.route.snapshot.queryParams.isFav
    this.channelName = this.route.snapshot.queryParams.channelName
    this.selectedM3uCategory = this.route.snapshot.queryParams.selectedM3uCategory
    // const storedData = localStorage.getItem('m3uData');
    // this.sharedData = JSON.parse(storedData?storedData:'')
    // this.orderObj = JSON.parse(this.sharedData.allData)
    this.isFav = this.isFavourite === "true" ? true : false
    this.getSubscribe()
    // this.getM3uDetail()
    this.route.data.subscribe(data => {
      const response = data.data;
      this.getResolverData(response);
    });
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        // this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        // this.blurOpacity = response.data.webAppSettingDTO
        //   ? response.data.webAppSettingDTO.shadowsFade / 10
        //   : '0.5';
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
        //   this.appBGColor;
          this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId
        ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId
        : '';
        // this.addAlpha(this.appBGColor, this.blurOpacity);
        // this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.loginImg = this.orderObj ? this.orderObj.tvgLogo : response.data.mainBannerDTO.backgroundImageId ? `${appConfig.imageURL}` + response.data.mainBannerDTO.backgroundImageId + '?height=630&width=1120' : '';
        // this.loginImg = response.data.mainBannerDTO.backgroundImageId
        //   ? `${appConfig.imageURL}` +
        //     response.data.mainBannerDTO.backgroundImageId +
        //     '?height=630&width=1120'
        //   : '';
        // this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
        //   `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        // );
        // this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
        //   `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        // );
      }
    });
  }

  gotToView(){
    localStorage.removeItem('m3uData')
    this.router.navigate(['network'], {queryParams: {channelType: this.m3uType,selectedM3uCategory: this.selectedM3uCategory}})
    // query params for filter
    // {queryParams: {filterData: JSON.stringify({selectedM3uCategoryName: this.dataFromParams.selectedM3uCategoryName,inputSearch:this.dataFromParams.searchInputData,showViewAll: this.dataFromParams.showViewAll,selectedChannelId : this.dataFromParams.selectedChannelId,viewAllSelected: this.dataFromParams.viewAllSelected})}}
  }
  goToItemView(data: any,i:any){
    // if(this.currentVedioIsPaid || this.loginRequired){
    //   this.paymentModal = true
    // }
    // else{
      const episodeNo = i+1
      const seasonNo = this.seasons ? this.seasons.match(/\d+/g) : '';
      // const storedData = localStorage.getItem('m3uData');
      // this.sharedData = JSON.parse(storedData?storedData:'')
      // this.m3uData = JSON.parse(this.sharedData.allData)
      // const senddata = {allData: JSON.stringify(this.m3uData)}
      // localStorage.setItem('m3uData',JSON.stringify(senddata));
      if(this.m3uType === 'M3U_MOVIES'){
        this.router.navigate(['/play'],{queryParams: {channelType: this.m3uType, mediaItemId: this.mediaItemId,channelName:this.itemDetail.tvgName,isFav: this.isFav,selectedM3uCategory: this.selectedM3uCategory}})
      }
      else{
        this.router.navigate(['/play'],{queryParams: {channelType: this.m3uType, mediaItemId: this.mediaItemId,channelName:this.itemDetail.tvgName,showSeriesName:data.tvgName,episodeNo:episodeNo,seasonNo: seasonNo,isFav: this.isFav,selectedM3uCategory: this.selectedM3uCategory}})
      }
      // , {queryParams: {allData: JSON.stringify(data),URL: data.channelUri}}
      // query params for filter 
      // filterData: JSON.stringify({selectedM3uCategoryName: this.selectedM3uCategoryName,inputSearch:this.searchInputData,showViewAll: this.showViewAll,selectedChannelId : this.selectedChannelId,viewAllSelected: this.viewAllSelected})
    // }
  }

  addToFav(){
    this.sharedService.updateLoader(true);
    const dataToSend = {
        channelName: this.channelName,
        channelType: this.m3uType,
        mediaItemId: this.mediaItemId
    }
    this.homeService.addToFavM3u(dataToSend).subscribe((response)=>{
      this.isFav = true
      // const storedData = localStorage.getItem('m3uData');
      // this.sharedData = JSON.parse(storedData?storedData:'')
      // this.m3uData = JSON.parse(this.sharedData.allData)
      // // this.m3uData = localStorage.getItem('m3uData') ? JSON.parse(this.m3uData) : '';
      // const senddata = {allData: JSON.stringify(this.m3uData)}
      // localStorage.setItem('m3uData',JSON.stringify(senddata));
      // this.m3uData.isFavourite = "true"
      this.sharedService.updateLoader(false);
    },(error)=>{
        this.sharedService.updateLoader(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
      });
    })
  }

  removeFromFav(){
    this.sharedService.updateLoader(true);
    this.homeService.removeFromFavM3u(this.orderObj.channelName,this.m3uType,this.mediaItemId).subscribe((response)=>{
      this.isFav = false
      // const storedData = localStorage.getItem('m3uData');
      // this.sharedData = JSON.parse(storedData?storedData:'')
      // this.m3uData = JSON.parse(this.sharedData.allData)
      // // this.m3uData = localStorage.getItem('m3uData') ? JSON.parse(this.m3uData) : '';
      // this.m3uData.isFavourite = "false"
      // const senddata = {allData: JSON.stringify(this.m3uData)}
      // localStorage.setItem('m3uData',JSON.stringify(senddata));
      this.sharedService.updateLoader(false);
    },(error)=>{
        this.sharedService.updateLoader(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
      });
    })
  }

  convertTime(min: any){
    const totalMinutes = min;
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const seconds = 0;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const formattedResult = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`; 
    return formattedResult
  }
  getResolverData(response: any) {
    if(this.m3uType === 'M3U_MOVIES'){
      this.itemDetail = response.data;
    }
    else{
      this.itemDetail = response.data.seriesDetails;
      this.seasonData = response.data.seasonData;
      this.seasons = this.itemDetail.seasons ? this.itemDetail.seasons[0]: null
      this.seasonData.forEach((element:any) => {
        if(element.duration){
          element.showDuration = this.convertTime(element.duration)
        }
      });
    }
    if(this.itemDetail){
      this.duration = this.convertTime(this.itemDetail.duration)
    }
  }
  getDetailOfSeason(event:any,data:any){
    let seasonNumber: any;
    seasonNumber = this.getSeasonNumber(data)
    let seriesId:any = this.itemDetail.tvgId
    let seriesName: any = this.itemDetail.tvgName
    this.sharedService.updateLoader(true);
    this.homeService.getDetailOfSeason(seasonNumber,seriesId,seriesName).subscribe((response)=>{
      this.seasonData = []
      this.seasonData = response.data
      this.seasonData.forEach((element:any) => {
        if(element.duration){
          element.showDuration = this.convertTime(element.duration)
        }
      });
      this.sharedService.updateLoader(false);
    })
  }

  getSeasonNumber(data:any){
    const numberPattern = /\d+/;
    const match = data.match(numberPattern);
    return  match[0];
  }
}
