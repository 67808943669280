<div class="main-signinPage">
  <div class="col-12" style="position: relative;">
    <div class="d-flex">
      <div class="col-5 left-panel" [ngStyle]="{'padding-top': showForgot ? '11%' : ''}">
        <div class="left-content">
          <div class="container form-wrapper">
            <form #signInForm="ngForm">
              <div class="d-flex" style="flex-direction: column;margin-bottom: 20px;">
                <div class="logo mb-20">
                  <a routerLink="/main">
                    <img [src]="logo" alt="">
                  </a>
                </div>
                <div class="form-group">
                  <!-- <label>Email</label> -->
                  <input type="text" name="email" placeholder="Email" class="form-control"
                    [(ngModel)]="login.username" />
                </div>
                <div class="form-group" *ngIf="!showForgot">
                  <!-- <label>Password</label> -->
                  <span class="app-password">
                    <input [type]="passwordType ? 'text' : 'password'" name="password" class="form-control" onkeydown="if(['Space'].includes(arguments[0].code)){return false;}"
                      placeholder="Password" [(ngModel)]="login.password" />
                    <span class="password-icon">
                      <i *ngIf="passwordType" [class.view]="passwordType" (click)="passwordType = !passwordType"
                        class="fa fa-eye" aria-hidden="true"></i>
                      <i *ngIf="!passwordType" [class.view]="passwordType" (click)="passwordType = !passwordType"
                        class="fa fa-eye-slash" aria-hidden="true"></i>
                    </span>
                  </span>
                </div>

                <div class="btn-wrapper d-flex justify-content-between align-items-center">

                  <div class="save-btn">
                    <button [ngStyle]="{
                            'background-color': primaryColor
                              ? primaryColor
                              : '#006aff'
                          }" *ngIf="!showForgot" class="main-btn" type="submit"
                      [ngClass]="{ disable: !login.username || !login.password }" (click)="loginUser()">
                      Log in
                    </button>
                    <button [ngStyle]="{
                            'background-color': primaryColor
                              ? primaryColor
                              : '#006aff'
                          }" *ngIf="showForgot" class="main-btn" type="submit" [ngClass]="{ disable: !login.username }"
                      (click)="forgotPasswordLink()">
                      Send Instructions
                    </button>
                  </div>
                  <div class="forgot-btn">
                    <span *ngIf="!showForgot" [ngStyle]="{'color': primaryColor ? primaryColor : '#006aff'}"
                      (click)="showForgot = true">Forgot Password?</span>
                    <span *ngIf="showForgot" [ngStyle]="{'color': primaryColor ? primaryColor : '#006aff'}"
                      (click)="showForgot = false">Never mind</span>
                  </div>
                </div>
              </div>
            </form>

            <div class="session-actions d-flex items-center justify-center">
              <div class="w-100">New here? <span><a [ngStyle]="{'color': primaryColor ? primaryColor : ''}"
                    (click)="gotoSignup()">Sign up</a></span></div>
            </div>

          </div>
        </div>
      </div>

      <div class="right-side col-7" [style.background-color]="appBGColor">
        <!-- <div class="right-side-wrapper">

        </div> -->
        <!-- <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div> -->
        <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
        <!-- <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div> -->
      </div>
    </div>
    <!-- <div class="col-7">
    
        </div> -->
    <div class="main-banner" *ngIf="scrWidth > 800">
      <div class="banner-img-wrapper">
        <div class="left-banner-color" [style.background-color]="appBGColor"></div>
        <img class="main-bg-image" [src]="loginImg" onload="style.opacity = 1" style="opacity: 0;object-fit: cover;">
      </div>
      <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
      <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
      <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
    </div>
  </div>
</div>