<section class="main-giving">
    <section class="top-header">
        <section class="main-banner-wrapper">
            <div class="banner-img-wrapper">
                <div class="left-banner-color" [style.background-color]="appBGColor"></div>
                <img class="main-bg-image" [src]="pageCustomeData.mainBannerDTO.mainHomeBgImage"
                    onload="style.opacity = 1" style="opacity: 0;object-fit: cover;">
            </div>
            <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
            <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
            <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
        </section>

        <div class="formContainer">
            <div class="profile-form form-wrapper">
                <form #signInForm="ngForm">
                    <div class="donation-input-web"
                        [style.marginRight]="DonationAmount.length>0 ? (DonationAmount.length*50 + 'px'): '65px'">
                        <div class="sign lightText">$</div>
                        <div class="input-field">
                            <!-- <label for="donationamount">{{DonationAmount}}</label> -->
                            <input type="text light" id="amount"
                                [style.width]="DonationAmount.length>0 ? (DonationAmount.length*65 + 'px'): '65px'"
                                (keypress)="isNumberOnly($event)" name="donationamount" class="input"
                                [(ngModel)]="DonationAmount" placeholder="0">
                        </div>
                    </div>
                    <p *ngIf="!showaddedCard" class="form-heading-web">Your Information</p>
                    <div class="d-flex" style="flex-direction: column;">
                        <div *ngIf="!showaddedCard">
                            <div class="d-flex justify-content-between">
                                <div class="form-group w-49">
                                    <input type="text" name="firstName" #firstName="ngModel" required
                                        placeholder="First Name" [(ngModel)]="userFirstName" class="form-control">
                                    <small class="text-danger" *ngIf="paymentFormSubmitted">
                                        <span class="text-danger" *ngIf="firstName.errors?.required">Please
                                            enter first name.</span>
                                    </small>
                                </div>
                                <div class="form-group w-49">
                                    <input type="text" name="lastName" #lastName="ngModel" required
                                        placeholder="Last Name" [(ngModel)]="userLastName" class="form-control">
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="form-group w-49">
                                    <input type="text" name="email" [readonly]="isLoggedIn" #email="ngModel" required
                                        placeholder="Email" [(ngModel)]="userEmail" class="form-control"
                                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
                                    <small class="text-danger" *ngIf="email.errors && paymentFormSubmitted">
                                        <span class="text-danger" *ngIf="email.errors?.required"> Please
                                            enter email.</span>
                                        <span class="text-danger" *ngIf="email.errors?.pattern">Please enter
                                            valid email pattern.</span>
                                    </small>
                                </div>
                                <div class="form-group w-49">
                                    <input type="text" name="number" (keypress)="isNumberOnly($event)" #number="ngModel"
                                        required placeholder="Mobile" [(ngModel)]="userMobile" class="form-control">
                                    <small class="text-danger" *ngIf="paymentFormSubmitted">
                                        <span class="text-danger" *ngIf="number.errors?.required">Please
                                            enter mobile.</span>
                                    </small>
                                </div>
                            </div>
                        </div>


                        <div class="form-input-control">
                            <ng-container *ngIf="!showaddedCard">

                                <div class="form-group " *ngIf="showCard">
                                    <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions">
                                    </ngx-stripe-card>
                                </div>

                                <div class="form-group-wrapper">

                                    <div class="form-group w-49">
                                        <input type="text" class="form-control" placeholder="Address line 1"
                                            [(ngModel)]="paymentForm.addressLine1" [ngModelOptions]="{standalone: true}"
                                            name="addressLine1" #addressLine1="ngModel" required maxlength="255"
                                            pattern="^[a-zA-Z0-9\s,.'-/]{3,}$">
                                        <small class="text-danger" *ngIf="paymentFormSubmitted">
                                            <span class="text-danger" *ngIf="addressLine1.errors?.required">Please
                                                enter address.</span>
                                            <span class="text-danger" *ngIf="addressLine1.errors?.pattern">Please
                                                enter
                                                valid address.</span>
                                        </small>
                                    </div>
                                    <div class="form-group w-49">
                                        <input type="text" class="form-control" placeholder="Address line 2"
                                            [(ngModel)]="paymentForm.addressLine2" [ngModelOptions]="{standalone: true}"
                                            name="addressLine2" #addressLine2="ngModel" maxlength="255">
                                    </div>
                                </div>
                                <div class="form-group-wrapper">
                                    <div class="form-group w-49">
                                        <ng-container *ngIf="countryList.length;else countryInput">
                                            <span class="app-dropdown d-block m-0">
                                                <select class="form-select" placeholder="Select Country"
                                                    [(ngModel)]="paymentForm.country" name="country" required
                                                    #country="ngModel" (change)="updateStateList($event)">
                                                    <option selected disabled value="">Select Country</option>
                                                    <option *ngFor="let ct of countryList" value="{{ct.code2}}">
                                                        {{ct.name}}
                                                    </option>
                                                </select>
                                                <small class="text-danger"
                                                    *ngIf="(paymentFormSubmitted && !paymentForm.country)">Please
                                                    enter country.</small>
                                            </span>
                                        </ng-container>
                                        <ng-template #countryInput>
                                            <input type="text" class="form-control" placeholder="Country" maxlength="25"
                                                [(ngModel)]="paymentForm.country" [ngModelOptions]="{standalone: true}"
                                                name="country" #country="ngModel" required>
                                            <small class="text-danger" *ngIf="paymentFormSubmitted">Please
                                                enter country.</small>
                                        </ng-template>
                                    </div>
                                    <div class="form-group w-49">
                                        <ng-container *ngIf="stateList.length;else stateInput">
                                            <span class="app-dropdown d-block m-0">
                                                <select class="form-select" placeholder="Select State"
                                                    [(ngModel)]="paymentForm.state" name="state" required
                                                    #state="ngModel">
                                                    <option selected disabled value="">Select State</option>
                                                    <option *ngFor="let ct of stateList" value="{{ct.name}}">
                                                        {{ct.name}}
                                                    </option>
                                                </select>
                                                <small class="text-danger"
                                                    *ngIf="paymentFormSubmitted && !paymentForm.state">Please
                                                    enter state.</small>
                                            </span>
                                        </ng-container>
                                        <ng-template #stateInput>
                                            <input type="text" class="form-control" placeholder="State" maxlength="25"
                                                [(ngModel)]="paymentForm.state" [ngModelOptions]="{standalone: true}"
                                                name="state" #state="ngModel" required>
                                            <small class="text-danger" *ngIf="paymentFormSubmitted">Please
                                                enter state.</small>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="form-group-wrapper">
                                    <div class="form-group w-49">
                                        <ng-container *ngIf="cityList.length;else cityInput">
                                            <span class="app-dropdown d-block m-0">
                                                <select class="form-select" placeholder="Select City"
                                                    [(ngModel)]="paymentForm.city" name="city" required #city="ngModel">
                                                    <option selected disabled value="">Select City</option>
                                                    <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                                        {{ct.cityName}}
                                                    </option>
                                                </select>
                                                <small class="text-danger" *ngIf="paymentFormSubmitted">Please
                                                    enter city.</small>
                                            </span>
                                        </ng-container>
                                        <ng-template #cityInput>
                                            <input type="text" class="form-control" placeholder="City" maxlength="25"
                                                [(ngModel)]="paymentForm.city" [ngModelOptions]="{standalone: true}"
                                                name="city" #city="ngModel" pattern="^[A-Za-z\s]*$" required>
                                            <small class="text-danger" *ngIf="paymentFormSubmitted">
                                                <span class="text-danger" *ngIf="city.errors?.required">Please
                                                    enter
                                                    city.</span>
                                                <span class="text-danger" *ngIf="city.errors?.pattern">Please enter
                                                    valid city name.</span>
                                            </small>
                                        </ng-template>
                                    </div>
                                    <div class="form-group w-49">
                                        <input type="text" class="form-control" placeholder="Zipcode" maxlength="12" minlength="3"
                                            [(ngModel)]="paymentForm.zipcode" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                            
                                            [ngModelOptions]="{standalone: true}" name="zip" #zip="ngModel" required
                                            pattern="[0-9.]+">
                                        <small class="text-danger"
                                            *ngIf="zip.errors && (paymentFormSubmitted)">
                                            <span class="text-danger" *ngIf="zip.errors?.required">Please enter
                                                zipcode.</span>
                                            <span class="text-danger" *ngIf="zip.errors?.pattern">Please enter
                                                valid zipcode.</span>
                                        </small>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="form-group mt-5" *ngIf="showaddedCard">
                            <div class="cardInfo">
                                <div class="d-flex align-items-center">
                                    <div class=" d-flex cardImg-wrapper justify-content-center align-items-center">
                                        <img *ngIf="cardBrand === 'MasterCard'"
                                            src="assets/images/cards/Mastercard-Debit-card-(1).png" alt="">
                                        <img *ngIf="cardBrand === 'Visa'" src="assets/images/cards/Visa_debit.png"
                                            alt="">
                                        <img *ngIf="cardBrand === 'UnionPay'"
                                            src="assets/images/cards/Unionpay card.png" alt="">
                                        <img *ngIf="cardBrand === 'JCB'" src="assets/images/cards/JCB debit.png" alt="">
                                        <img *ngIf="cardBrand === 'American Express'"
                                            src="assets/images/cards/American Express.png" alt="">
                                        <img *ngIf="cardBrand === 'Discover'"
                                            src="assets/images/cards/Discover card.png" alt="">
                                        <img *ngIf="cardBrand === 'Diners Club'"
                                            src="assets/images/cards/Diners Club card.png" alt="">
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                                        <div class="d-flex flex-column px-3">
                                            <span class="para-text">{{cardBrand}}</span>
                                            <span class="para-text">●●●● ●●●● ●●●● {{cardLast4Digit}}</span>
                                        </div>
                                        <div>
                                            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                                                *ngIf="showaddedCard" (click)="showaddedCard=false" class="main-btn"
                                                type="submit">Replace Card</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="save-btn mt-10">
                            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                                [ngClass]="{disabled: !DonationAmount || disableButton}"
                                [disabled]="!DonationAmount || disableButton" class="main-btn"
                                style="margin-bottom: 10px;" *ngIf="!showaddedCard" (click)="createToken()"
                                type="submit">Donate Now</button>
                            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                                [ngClass]="{disabled: !DonationAmount || disableButton}"
                                [disabled]="!DonationAmount || disableButton" class="main-btn"
                                style="margin-bottom: 10px;" *ngIf="showaddedCard" (click)="donateNow()"
                                type="submit">Donate Now</button>
                            <!-- <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}" *ngIf="!showaddedCard"  class="main-btn"
                            type="submit" >Replace Card</button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Mobile view-------------- -->
        <div class="content-wrap mobileView">
            <div class="content">

                <div class="main-mobile">
                    <img class="platform-image" src="assets/images/Mobile 3.svg" alt="">

                    <div class="mobile-background light">
                        <div class="main-container">
                            <section class="header" [ngStyle]="{'background-color': appBGColor ? appBGColor : ''}">

                                <div class="tab-name" *ngIf="!getUserInfo">{{givingPageName}}</div>
                                <!-- <div class="tab-name" *ngIf="getUserInfo">CARD FORM</div> -->
                            </section>

                            <section class="main-body light">
                                <div class="main-donation">
                                    <div class="upper" style="margin-bottom: 20px;">
                                        <!-- <div class="donation-title lightText" *ngIf="!getUserInfo">
                                                        Org Title
                                                    </div> -->
                                        <!-- <div class="donation-input-web" [style.marginRight]="DonationAmount.length>0 ? (DonationAmount.length*60 + 'px'): '80px'">
                                                        <div class="sign lightText" style="color: #6d6c6c !important;">$</div>
                                                        <div class="input-field">
                                                            <input type="text light" style="color: #6d6c6c !important;" id="amount" [style.width]="DonationAmount.length>0 ? (DonationAmount.length*65 + 'px'): '65px'" (keypress)="isNumberOnly($event)" name="donationamount" class="input" [(ngModel)]="DonationAmount" placeholder="0">
                                                        </div>
                                                    </div> -->
                                        <p style="font-size: 18px;color: #656565;">How much would you like to donate?
                                        </p>
                                        <p-inputNumber [(ngModel)]="DonationAmount" min="0" max="9999" maxlength="9"
                                            step="1" inputId="currency-us" mode="currency" currency="USD"
                                            locale="en-US">
                                        </p-inputNumber>
                                        <!-- <input type="text" style="text-align: center;font-size: 70px;border-radius: 8px;border: 1px solid #656565;width: 90%;color: #656565;" placeholder="$0"> -->
                                        <div class="formData">
                                            <p *ngIf="!showaddedCard" class="form-heading" style="font-size: 14px;">Your
                                                Information</p>
                                            <div class="form-wrapper">
                                                <div *ngIf="!showaddedCard">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="form-group w-49">
                                                            <input type="text" name="firstName" #firstName="ngModel"
                                                                required placeholder="First Name"
                                                                [(ngModel)]="userFirstName" class="form-control">
                                                            <small class="text-danger" *ngIf="paymentFormSubmitted">
                                                                <span class="text-danger"
                                                                    *ngIf="firstName.errors?.required">Please
                                                                    enter first name.</span>
                                                            </small>
                                                        </div>
                                                        <div class="form-group w-49">
                                                            <input type="text" name="lastName" #lastName="ngModel"
                                                                required placeholder="Last Name"
                                                                [(ngModel)]="userLastName" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-between mt-2">
                                                        <div class="form-group w-49">
                                                            <input type="text" name="email" [readonly]="isLoggedIn"
                                                                #email="ngModel" required placeholder="Email"
                                                                [(ngModel)]="userEmail" class="form-control"
                                                                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                                            <small class="text-danger" *ngIf="paymentFormSubmitted">
                                                                <span class="text-danger"
                                                                    *ngIf="email.errors?.required"> Please
                                                                    enter email.</span>
                                                                <span class="text-danger"
                                                                    *ngIf="email.errors?.pattern">Please enter
                                                                    valid email pattern.</span>
                                                            </small>
                                                        </div>
                                                        <div class="form-group w-49">
                                                            <input type="text" name="number"
                                                                (keypress)="isNumberOnly($event)" #number="ngModel"
                                                                required placeholder="Mobile" [(ngModel)]="userMobile"
                                                                class="form-control">
                                                            <small class="text-danger" *ngIf="paymentFormSubmitted">
                                                                <span class="text-danger"
                                                                    *ngIf="number.errors?.required">Please
                                                                    enter mobile.</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-input-control mt-2" *ngIf="!showaddedCard">
                                                    <ng-container>

                                                        <div class="form-group " *ngIf="showCard2">
                                                            <ngx-stripe-card [options]="cardOptionsMobile"
                                                                [elementsOptions]="elementsOptions"></ngx-stripe-card>
                                                        </div>

                                                        <div class="form-group-wrapper mt-2">

                                                            <div class="form-group w-49">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Address line 1"
                                                                    [(ngModel)]="paymentForm.addressLine1"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    name="addressLine1" #addressLine1="ngModel" required
                                                                    maxlength="255" pattern="^[a-zA-Z0-9\s,.'-]{3,}$">
                                                                <small class="text-danger" *ngIf="paymentFormSubmitted">
                                                                    <span class="text-danger"
                                                                        *ngIf="addressLine1.errors?.required">Please
                                                                        enter address.</span>
                                                                    <span class="text-danger"
                                                                        *ngIf="addressLine1.errors?.pattern">Please
                                                                        enter
                                                                        valid address.</span>
                                                                </small>
                                                            </div>
                                                            <div class="form-group w-49">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Address line 2"
                                                                    [(ngModel)]="paymentForm.addressLine2"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    name="addressLine2" #addressLine2="ngModel"
                                                                    maxlength="255">
                                                            </div>
                                                        </div>
                                                        <div class="form-group-wrapper mt-2">
                                                            <div class="form-group w-49">
                                                                <ng-container
                                                                    *ngIf="countryList.length;else countryInput">
                                                                    <span class="app-dropdown d-block m-0">
                                                                        <select class="form-select"
                                                                            placeholder="Select Country"
                                                                            [(ngModel)]="paymentForm.country"
                                                                            name="country" required #country="ngModel"
                                                                            (change)="updateStateList($event)">
                                                                            <option selected disabled value="">Select
                                                                                Country</option>
                                                                            <option *ngFor="let ct of countryList"
                                                                                value="{{ct.code2}}">
                                                                                {{ct.name}}
                                                                            </option>
                                                                        </select>
                                                                        <small class="text-danger"
                                                                            *ngIf="(paymentFormSubmitted && country.errors?.required)">Please
                                                                            enter country.</small>
                                                                    </span>
                                                                </ng-container>
                                                                <ng-template #countryInput>
                                                                    <input type="text" class="form-control"
                                                                        placeholder="Country" maxlength="25"
                                                                        [(ngModel)]="paymentForm.country"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        name="country" #country="ngModel" required>
                                                                    <small class="text-danger"
                                                                        *ngIf="paymentFormSubmitted && country.errors?.required">Please
                                                                        enter country.</small>
                                                                </ng-template>
                                                            </div>
                                                            <div class="form-group w-49">
                                                                <ng-container *ngIf="stateList.length;else stateInput">
                                                                    <span class="app-dropdown d-block m-0">
                                                                        <select class="form-select"
                                                                            placeholder="Select State"
                                                                            [(ngModel)]="paymentForm.state" name="state"
                                                                            required #state="ngModel">
                                                                            <option selected disabled value="">Select
                                                                                State</option>
                                                                            <option *ngFor="let ct of stateList"
                                                                                value="{{ct.name}}">
                                                                                {{ct.name}}
                                                                            </option>
                                                                        </select>
                                                                        <small class="text-danger"
                                                                            *ngIf="(paymentFormSubmitted && state.errors?.required)">Please
                                                                            enter state.</small>
                                                                    </span>
                                                                </ng-container>
                                                                <ng-template #stateInput>
                                                                    <input type="text" class="form-control"
                                                                        placeholder="State" maxlength="25"
                                                                        [(ngModel)]="paymentForm.state"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        name="state" #state="ngModel" required>
                                                                    <small class="text-danger"
                                                                        *ngIf="paymentFormSubmitted && state.errors?.required">Please
                                                                        enter state.</small>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                        <div class="form-group-wrapper mt-2">
                                                            <div class="form-group w-49">
                                                                <ng-container *ngIf="cityList.length;else cityInput">
                                                                    <span class="app-dropdown d-block m-0">
                                                                        <select class="form-select"
                                                                            placeholder="Select City"
                                                                            [(ngModel)]="paymentForm.city" name="city"
                                                                            required #city="ngModel">
                                                                            <option selected disabled value="">Select
                                                                                City</option>
                                                                            <option *ngFor="let ct of cityList"
                                                                                value="{{ct.cityName}}">
                                                                                {{ct.cityName}}
                                                                            </option>
                                                                        </select>
                                                                        <small class="text-danger"
                                                                            *ngIf="paymentFormSubmitted">Please
                                                                            enter city.</small>
                                                                    </span>
                                                                </ng-container>
                                                                <ng-template #cityInput>
                                                                    <input type="text" class="form-control"
                                                                        placeholder="City" maxlength="25"
                                                                        [(ngModel)]="paymentForm.city"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        name="city" #city="ngModel"
                                                                        pattern="^[A-Za-z\s]*$" required>
                                                                    <small class="text-danger"
                                                                        *ngIf="paymentFormSubmitted">
                                                                        <span class="text-danger"
                                                                            *ngIf="city.errors?.required">Please
                                                                            enter
                                                                            city.</span>
                                                                        <span class="text-danger"
                                                                            *ngIf="city.errors?.pattern">Please enter
                                                                            valid city name.</span>
                                                                    </small>
                                                                </ng-template>
                                                            </div>
                                                            <div class="form-group w-49">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Zipcode" maxlength="12" minlength="3"
                                                                    [(ngModel)]="paymentForm.zipcode" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                                    [ngModelOptions]="{standalone: true}" name="zip"
                                                                    #zip="ngModel" required
                                                                    pattern="[0-9.]+">
                                                                <small class="text-danger"
                                                                    *ngIf="zip.errors && (paymentFormSubmitted || zip.touched)">
                                                                    <span class="text-danger"
                                                                        *ngIf="zip.errors?.required">Please enter
                                                                        zipcode.</span>
                                                                    <span class="text-danger"
                                                                        *ngIf="zip.errors?.pattern">Please enter
                                                                        valid zipcode.</span>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group mt-5" *ngIf="showaddedCard">
                                        <div class="cardInfo">
                                            <div class="d-flex align-items-center flex-column">
                                                <div
                                                    class=" d-flex cardImg-wrapper justify-content-center align-items-center">
                                                    <img *ngIf="cardBrand === 'MasterCard'"
                                                        src="assets/images/cards/Mastercard-Debit-card-(1).png" alt="">
                                                    <img *ngIf="cardBrand === 'Visa'"
                                                        src="assets/images/cards/Visa_debit.png" alt="">
                                                    <img *ngIf="cardBrand === 'UnionPay'"
                                                        src="assets/images/cards/Unionpay card.png" alt="">
                                                    <img *ngIf="cardBrand === 'JCB'"
                                                        src="assets/images/cards/JCB debit.png" alt="">
                                                    <img *ngIf="cardBrand === 'American Express'"
                                                        src="assets/images/cards/American Express.png" alt="">
                                                    <img *ngIf="cardBrand === 'Discover'"
                                                        src="assets/images/cards/Discover card.png" alt="">
                                                    <img *ngIf="cardBrand === 'Diners Club'"
                                                        src="assets/images/cards/Diners Club card.png" alt="">
                                                </div>
                                                <!-- <div class="d-flex justify-content-between align-items-center" style="width: 100%;"> -->
                                                <div class="d-flex flex-column py-4">
                                                    <span class="para-text">{{cardBrand}}</span>
                                                    <span class="para-text">●●●● ●●●● ●●●● {{cardLast4Digit}}</span>
                                                </div>
                                                <div style="width: 100%;">
                                                    <button style="width: 100%;border-radius: 50px;"
                                                        [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                                                        *ngIf="showaddedCard" (click)="showaddedCard=false"
                                                        class="main-btn" type="submit">Replace Card</button>
                                                </div>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom">
                                        <!-- <button class="next-btn" (click)="getUserInfo = true" *ngIf="!getUserInfo" [ngStyle]="{'background-color': appBtnPrimaryColor}">Next</button> -->
                                        <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                                            [ngClass]="{disabled: !DonationAmount || disableButton}"
                                            [disabled]="!DonationAmount || disableButton" class="next-btn"
                                            *ngIf="!showaddedCard" (click)="createToken()" type="submit">Donate
                                            Now</button>
                                        <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                                            [ngClass]="{disabled: !DonationAmount || disableButton}"
                                            [disabled]="!DonationAmount || disableButton" class="next-btn"
                                            *ngIf="showaddedCard" (click)="donateNow()" type="submit">Donate
                                            Now</button>
                                    </div>

                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>


<p-dialog [(visible)]="showPopup" [modal]="true" [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo">
        <div class="tick-icon">
            <i class="fa fa-check" aria-hidden="true"></i>
        </div>
        <h1>Thank you !</h1>
        <p>You will receive your donation receipt via email</p>
    </div>
</p-dialog>