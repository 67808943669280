import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DomSanitizer,
  Title,
  Meta,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { emptyPlayListDataForCustom, PageCustomiseDataModel } from 'src/app/Models/common.model';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
})
export class ChannelComponent implements OnInit {
  pageCustomeData: PageCustomiseDataModel;
  primaryColor: any;
  appBGColor: any;
  bottomGradiant: any;
  leftGradiant: any;
  blurOpacity: any;
  changeToRGB: any;
  appBtnPrimaryColor: any;
  headerLinkColor: any;
  headerLogo: any;
  mainPageName: any;
  vodPageName: any;
  contactName: any;
  showHideEbook: boolean;
  ebookPage: string;
  logo: any;
  loginImg: any;
  isLoggedIn: boolean;
  logoSize: any;
  favIcon: any;
  logoSizeRoundValue: number;
  domain: string;

  playlistData: any[] = [];
  isM3u8 = true;
  poster: string;
  autoPlayChange: boolean;
  currentVideoResolution: string;
  allResolutionsName: string[];
  allVideoResolutionList: any[];
  videoJsPlayerUrl: string;
  videoJsPlayerType: string;
  currentMediaItemValues: any;
  pauseTime: number;
  channelData: any[] = [];
  allDayDefault: any[] = [];
  categoryData: any[] = [];
  streamId: string;
  videoUrl: SafeResourceUrl;
  showIframe = false;
  selectedChannelId: any
  showLogo = false;
  currentVedioIsPaid: any;
  loginUserDetail: any;
  paymentDone: any;
  userId: any
  subscriptionPlanID: any
  isSubscriptionAvailable: boolean;
  isUserPlanPaymentDone: boolean;
  userPlanId: any;
  isOneTimePurchase: any;
  loginRequired: any;
  itemPrice: any
  PlanID: any;
  orderObj: any;
  dataFromParams: any
  accessType: any
  videoIsStopped: boolean = false
  channelName: string;
  channelDesc: string;
  searchInputData: any;
  checkStatusinterval: any;
  logoHeight: any;
  m3uType = 'M3U_CHANNEL'
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;
  @ViewChild('scrollthree') scrollthree: ElementRef;

  scrHeight: any;
  scrWidth: any;
  currentDate = new Date();
  currentDay: any;
  sendDateData = {
    slotInterval: 30,
    openTime: '00:00:00',
    closeTime: '23:59:59',
    date: moment(this.currentDate).format('YYYY-MM-DD'),
  };
  currentSelectedChannelLogo: any;
  logoOnRight: any;
  itemDescription: any;
  logoOnPlayer: any;
  playlistType: any;
  videoOnLoop: any;
  currentSelectedChannelName: any;
  currentSelectedChannelDesc: any;
  currentPlayingItemName: any;
  currentStartItemTime: any;
  currentEndItemTime: any;
  checkCurrentTimeInterval: any;
  currentItemCurrentTime: any;
  currentItemRemainingTime: any;
  selectedChannel: any;
  selectedChannelFromAdmin: any
  tillCurrectTimeSec: any
  sendDate: any
  isManual = false
  showSpinner = false
  showErrorMessage = false
  showm3uContainer = false
  showViewAll = false
  showNotFound = false
  viewAllSelected = false
  selectedM3uCategory: any
  selectedM3uCategoryNameFromDropdown = true
  allDataFromCategory: any[] = []
  selectedM3uCategoryName: any
  viewAllm3uData: any[] = []
  m3uData: any[] = []
  allM3uData: any[] = []
  m3uCategory: any[] = []
  page = 1
  size = 50
  pageForChannelM3u = 1
  rowCount: number = 50;
  seriesPages: number
  seriesRows: number
  totalElements: number
  paymentModal = false
  interval: any
  @HostListener('window:resize', ['$event'])
  data: any
  channelDataItem: any;
  isM3u8Loaded = false;
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private messageService: MessageService,
    public homeService: HomeService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private ngxFavicon: AngularFaviconService,
    private loginService: LoginService,
    private _sessionService: SessionService,
    private _Title: Title,
    private _Meta: Meta,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.pageCustomeData = new PageCustomiseDataModel();
    this.getScreenSize();
    this.showHideEbook = false;
    this.currentVedioIsPaid = false
    this.isSubscriptionAvailable = false
    this.isUserPlanPaymentDone = false
    this.ebookPage = '';
    this.logoSizeRoundValue = 0;
    this.domain = '';
    this.currentVideoResolution = '720';
    this.tillCurrectTimeSec = 0;
    this.allResolutionsName = ['720', '360', 'original', '1080'];
    this.allVideoResolutionList = [];
    this.generateCalendar(this.sendDateData)
  }

  ngOnInit(): void {
    this.getLogoDimention()
    // this.route.queryParamMap
    //   .subscribe((params) => {
    //     let filterData = params.get('filterData')
    //     this.dataFromParams = JSON.parse(filterData?filterData:'')
    //     this.selectedM3uCategoryName = this.dataFromParams.selectedM3uCategoryName ? this.dataFromParams.selectedM3uCategoryName : null
    //     this.showViewAll = this.dataFromParams.showViewAll 
    //     this.viewAllSelected = this.dataFromParams.viewAllSelected ? this.dataFromParams.viewAllSelected : false
    //     // below code need to add in another method
    //     if(this.selectedM3uCategoryName){
    //       this.selectedChannelId = this.dataFromParams.selectedChannelId
    //       this.searchM3uData(this.selectedChannelId,this.searchInputData,this.selectedM3uCategoryName)
    //     }
    // });
    // setInterval(() => {
    //   this.currentDate = new Date()
    //   this.currentDay = moment(this.currentDate).format('dddd').substring(0,3)

    //   let sendDateTime = new Date()
    //   let todayDateTime = moment(sendDateTime).format('YYYY-MM-DD') + ' ' + '00:00:00'
    //   let currentDateTime = moment(sendDateTime).format('YYYY-MM-DD HH:mm:ss')
    //   var getDiff = moment(currentDateTime).diff(moment(todayDateTime))
    //   // let secondsDiff =  currentDateTime.diff(todayDateTime)
    //   let getDiffinSec = getDiff / 1000;
    //   // console.log(secondsDiff,'<=============secondsDiff');

    //   this.tillCurrectTimeSec = getDiffinSec
    // }, 1000);
    this.orderObj = this.route.snapshot.params.id;
    let loginData: any;
    loginData = this._sessionService.getSession('loggedInUser');
    this.loginUserDetail = loginData;
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this.route.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.paymentDone = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
      : '';
    this.userId = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.id
      : null;
    this.subscriptionPlanID = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
      : '';
    // loginData = localStorage.getItem('loggedInUser')
    this.isLoggedIn = loginData ? true : false;
    this.autoPlayChange = true
    if (this.isLoggedIn) {
      this.userPlanDetails(this.userId);
    }
    this.sendDate = moment(this.currentDate).format('YYYY-MM-DD')
    this.getSubscribe()
    this.generateVideoUrl('')
    // if(this.isLoggedIn){
    //   this.getChannelData(this.sendDate)
    // }
    // else{
    //   this.getChannelDataWithout(this.sendDate)
    // }
    // setTimeout(() => {
    //   const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    //   const scrollTo = this.tillCurrectTimeSec ? (((this.tillCurrectTimeSec * 0.16666667 - 300)) + 'px') : '0'
    //   scrollOne.scrollLeft = parseInt(scrollTo)
    // }, 1000);

    setInterval(() => {
      this.currentDate = new Date()
      this.currentDay = moment(this.currentDate).format('dddd').substring(0, 3)
      let sendDateTime = new Date()
      let todayDateTime = moment(sendDateTime).format('YYYY-MM-DD') + ' ' + '00:00:00'
      let currentDateTime = moment(sendDateTime).format('YYYY-MM-DD HH:mm:ss')
      var getDiff = moment(currentDateTime).diff(moment(todayDateTime))
      let getDiffinSec = getDiff / 1000;
      this.tillCurrectTimeSec = getDiffinSec
    }, 1000);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // if (this.currentTimeSubscribe) {
    //   this.currentTimeSubscribe.unsubscribe();
    // }
    if (this.checkCurrentTimeInterval) {
      clearInterval(this.checkCurrentTimeInterval);
    }
    if (this.checkStatusinterval) {
      clearInterval(this.checkStatusinterval)
    }
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  updateScroll() {
    const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;
    const scrollthree = this.scrollthree.nativeElement as HTMLElement;
    // do logic and set
    scrollTwo.scrollLeft = scrollOne.scrollLeft;
    scrollthree.scrollLeft = scrollOne.scrollLeft;
  }

  modifyVideoUrl(): string {
    if (this.allVideoResolutionList.length) {
      const flavourList = this.allVideoResolutionList.filter(
        (value: any) => value.size === this.currentVideoResolution && value.src
      );
      return flavourList.length ? flavourList[0].src : '';
    }
    return '';
  }
  resolutionChange(event: any) {
    // if (event) {
    //   this.currentVideoResolution = '360';
    //   this.videoJsPlayerUrl = this.modifyVideoUrl()
    //     ? this.modifyVideoUrl()
    //     : this.currentMediaItemValues.videoDTO
    //     ? this.currentMediaItemValues.videoDTO.path
    //     : this.currentMediaItemValues.videoUrl
    //     ? this.currentMediaItemValues.videoUrl
    //     : 'https://cdn.plyr.io/static/blank.mp4';
    //   this.currentVideoResolution = '720';
    // }
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.pageCustomeData = new PageCustomiseDataModel(response.data);
        this.selectedChannelId = response.data.pageCustomizationDTO.selectedChannel
        this.getSchedulingChannelInfo(
          this.sendDate,
          response.data.pageCustomizationDTO.selectedChannel,
          this.isLoggedIn
        );
        this.getChannelItemById(
          response.data.pageCustomizationDTO.selectedChannel,
          this.isLoggedIn
        );
        this.mainPageName = response.data.pageCustomizationDTO.homePage;
        this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
        this.contactName = response.data.pageCustomizationDTO.contactUs;
        this.showHideEbook = response.data.pageCustomizationDTO.showHideEbook;
        this.ebookPage = response.data.pageCustomizationDTO.ebookPage;
        this.selectedChannelFromAdmin =
          response.data.pageCustomizationDTO.selectedChannel;
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.contactUs}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
              ? `${response.data.pageCustomizationDTO.orgName}-`
              : ''
            }${response.data.pageCustomizationDTO.contactUs}`,
        });
        if (this.router.url === '/vod') {
          this.router.navigate(['/vod'], {
            queryParams: { vod: this.vodPageName },
          });
        }
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          '#080B2A';
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = 'linear-gradient(to bottom left, #080b2b, #010204)'
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId
          ? `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`
          : '';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        this._Meta.updateTag({
          property: 'og:image',
          content: this.logo,
        });
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.logoSizeRoundValue = Math.round(this.logoSize / 10) * 10;

        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );
        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';

        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.loginImg = response.data.mainBannerDTO.backgroundImageId
          ? `${appConfig.imageURL}` +
          response.data.mainBannerDTO.backgroundImageId +
          '?height=630&width=1120'
          : '';
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
      }
    });
  }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  getSchedulingChannelInfo(date: any, itemId: number, isLogin: boolean) {
    this.homeService.getSchedulingChannelInfo(date, itemId, isLogin, this.isM3u8).subscribe((response: any) => {
      this.playlistData = response.data.playlist ? response.data.playlist : []
      if (!this.playlistData || this.playlistData.length === 0) {
        this.playlistData = emptyPlayListDataForCustom
      }
    });
  }

  getBroadcastingStatusForChannel(streamId: any) {
    this.sharedService.updateLoader(false);
    this.homeService.getBroadcastingStatusForChannel(streamId, this.isLoggedIn).subscribe((response) => {
      this.sharedService.updateLoader(false);
      console.log(response)
      if (response.data === 'broadcasting') {
        this.videoIsStopped = false
      }
      else {
        this.videoIsStopped = true
      }
    })
    this.sharedService.updateLoader(false);
  }

  getChannelDataWithout(date: any): void {
    this.homeService.getChannelListWithout(date).subscribe((response: any) => {
      this.channelData = response.data
      // this.videoJsPlayerType = 'application/x-mpegURL'
      if (this.channelData.length > 0) {
        this.channelData.forEach((el: any, i: any) => {
          if (el.Playlist.length > 0) {
            el.Playlist.forEach((element: any, index: any) => {
              if (element.id === this.selectedChannelFromAdmin) {
                element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                element['isSelected'] = false
                setTimeout(() => {
                  this.selectChannel(element)
                }, 1000);
              }
            });
            this.channelData[0].Playlist[0]['isSelected'] = true
            // setTimeout(() => {
            //   if(i === 0){
            //     if(this.channelData[i].Playlist[i]){
            //       this.selectChannel(this.channelData[0].Playlist[0])
            //     }
            //   }
            //   // this.streamId = this.channelData[0].Playlist[0].streamId
            // }, 1000);
            // this.videoJsPlayerType = ''
            // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
            // this.videoJsPlayerUrl = this.channelData[0].Playlist[0].playlistUrl
            // this.currentSelectedChannelLogo = this.channelData[0].Playlist[0].logoPath
            // this.logoOnRight = this.channelData[0].Playlist[0].logoOnRight
            // this.logoOnPlayer = this.channelData[0].Playlist[0].logoOnPlayer
            // this.playlistType = this.channelData[0].Playlist[0].playlistType
            // this.videoOnLoop = this.channelData[0].Playlist[0].videoOnLoop
            // this.currentSelectedChannelName = this.channelData[0].Playlist[0].title
            // this.currentSelectedChannelDesc = this.channelData[0].Playlist[0].description
          }
        })
        this.categoryData = this.channelData.map((el) => ({
          categoryName: el.Category
        }))
        // let newCategoryData = [...new Set(this.categoryData)];
        // console.log(newCategoryData);

      }
    });
  }

  getChannelItemById(id: number, isLogin: boolean) {
    this.showErrorMessage = false
    this.showm3uContainer = false
    this.homeService
      .getChannelItemById(id, isLogin, 10)
      .subscribe((response: any) => {
        this.channelDataItem = response.data
        this.itemPrice = response.data.price ? response.data.price : '';
        this.channelName = response.data.title ? response.data.title : ''
        this.channelDesc = response.data ? response.data.description : ''
        this.isOneTimePurchase = response.data.isOneTimePurchase
        

        setTimeout(() => {
          if (this.scrollOne) {
            const scrollOne = this.scrollOne.nativeElement as HTMLElement;
            const scrollTo = this.tillCurrectTimeSec ? (((this.tillCurrectTimeSec * 0.13888889 - 385)) + 'px') : '0'
            scrollOne.scrollLeft = parseInt(scrollTo)
          }
        }, 1500);
        
        // if(!this.isLoggedIn){
        this.PlanID = this.loginUserDetail
          ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
          : '';
        this.currentVedioIsPaid = true;
        // }
        // else{
        if (response.data.mediaAccessType === 'PAID') {
          this.isSubscriptionAvailable = response.data
            .subscriptionPlanIds.length
            ? true
            : false;
          response.data.subscriptionPlanIds.forEach(
            (element: any) => {
              if (element == this.PlanID && this.paymentDone) {
                this.isSubscriptionAvailable = false
                this.currentVedioIsPaid = false;
              }
              if (element == this.userPlanId && this.isUserPlanPaymentDone) {
                this.isSubscriptionAvailable = false
                this.currentVedioIsPaid = false;
              }
            }
          );
        } else {
          this.currentVedioIsPaid = false;
        }
        if (response.data.mediaAccessType === 'FREE') {
          this.isOneTimePurchase = false;
        }
        if (response.data.mediaAccessType === 'PAID' && response.data.isOneTimePurchasePaymentDone) {
          this.loginRequired = false;
          this.isOneTimePurchase = false;
          this.currentVedioIsPaid = false;
          this.isSubscriptionAvailable = false
          // this.vedioIsNull = false;
        }
        this.accessType =
          response.data.mediaAccessType === 'ACCESSREQUIRED'
            ? true
            : false;
        if (this.accessType && !this.isLoggedIn) {
          this.loginRequired = true;
        } else {
          this.loginRequired = false;
        }
        if (this.interval) {
          clearInterval(this.interval)
        }
        if (response.data.channelInfoDTO.channelType === 'M3U') {
          this.isManual = false
          if (response.data.channelInfoDTO.processURL === 'IN_PROGRESS') {
            this.showSpinner = true
            this.showm3uContainer = false
            this.interval = setInterval(() => {
              this.getChannelItemById(this.selectedChannelId, this.isLoggedIn);
              // this.getMediaSeriesData(this.mediaSeriesId)
            }, 10000);
          }
          else if (response.data.channelInfoDTO.processURL === 'ERROR') {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.showSpinner = false
            this.showm3uContainer = false
            this.showErrorMessage = true
          }
          else {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.showSpinner = false
            this.showm3uContainer = true
            this.selectM3uType(this.m3uType)
            // this.m3uData = response.data.channelInfoDTO.m3uCategoryList
            // this.allM3uData = response.data.channelInfoDTO.m3uCategoryList
            // this.m3uCategory = this.m3uData.map((el)=>({
            //   categoryName : el.Category
            // }))
          }
          // this.m3uData = response.data.channelInfoDTO.m3uCategoryList
          // this.allM3uData = response.data.channelInfoDTO.m3uCategoryList
          // this.m3uCategory = this.m3uData.map((el)=>({
          //   categoryName : el.Category
          // }))
          // this.showSpinner = false
        }
        else {
          this.isManual = true
          this.showLogo = response.data.channelInfoDTO.logoOnPlayer
          setTimeout(() => {
            this.selectChannel(response.data.channelInfoDTO);
          }, 1000);
        }
      });
  }

  userPlanDetails(id: any) {
    this.sharedService.updateLoader(false);
    this.homeService.userPlanDetails(id).subscribe(
      (response: any) => {
        this.userPlanId = response.data.id;
        this.isUserPlanPaymentDone = response.data.isPaymentDone;
      },
      (error) => {
        this.sharedService.updateLoader(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
    this.sharedService.updateLoader(false);
  }

  updateVedioPlay(event?: any) { }
  played(event: any) { }

  generateVideoUrl(videoId: string): void {
    // const url = `https://media.nowcast.cc:5443/LiveApp/play.html?name=${videoId}`;
    const url = `${environment.antMediaServerUrlChannel}/ChannelApp/play.html?name=${videoId}`;
    this.videoUrl = videoId ? this.sanitizer.bypassSecurityTrustResourceUrl(url): '';
  }

  selectChannel(data: any) {
    this.currentSelectedChannelLogo = ''
    this.currentPlayingItemName = ''
    this.currentStartItemTime = ''
    this.currentEndItemTime = ''
    this.itemDescription = ''
    // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'

    this.currentSelectedChannelLogo = data.logoId ? `${appConfig.imageURL}` + data.logoId : null
    this.currentSelectedChannelName = data.title
    this.currentSelectedChannelDesc = data.description
    this.logoOnRight = data.logoOnRight
    this.streamId = data.streamId
    if (this.streamId) {
      // if(!this.checkURL.includes(this.streamId)){
      this.generateVideoUrl(this.streamId)
      // }
      if (data.channelType === 'CUSTOM') {
        this.getBroadcastingStatusForChannel(this.streamId)
        if (this.checkStatusinterval) {
          clearInterval(this.checkStatusinterval)
        }
        this.checkStatusinterval = setInterval(() => {
          this.getBroadcastingStatusForChannel(this.streamId)
        }, 15000)
      }
      else {
        if (this.checkStatusinterval) {
          clearInterval(this.checkStatusinterval)
        }
      }
    }
    // this.generateVideoUrl(this.streamId)
    this.logoOnPlayer = data.logoOnPlayer
    this.playlistType = data.playlistType
    this.videoOnLoop = data.videoOnLoop
    this.videoJsPlayerType = ''
    this.videoJsPlayerUrl = ''
    if (data.channelType === 'M3U8') {
      // if(!data.stop){
      // setTimeout(() => {
        this.videoJsPlayerType = 'application/x-mpegURL'
        this.videoJsPlayerUrl = data.m3u8Url ? data.m3u8Url : ''
        this.currentPlayingItemName = this.channelName
        this.itemDescription = this.channelDesc
      // }, 500);
      // }
      this.isM3u8 = true
    }
    else {
      this.isM3u8 = false
    }
    this.isM3u8Loaded = true;
    if (this.playlistData.length > 0) {
      this.playlistData.forEach(el => {
        let sendDate = new Date()
        this.getCurrentPlayItem(sendDate, el)
      })
    }
    // if(this.channelData.length>0){
    //   this.channelData.forEach((el: any)=>{
    //     if(el.Playlist.length>0){
    //       el.Playlist.forEach((element:any) => {
    //         if(element.id === data.id){
    //           element.isSelected = true
    //           this.selectedChannel = element
    //           let sendDate = new Date()
    //           this.getCurrentPlayItem(sendDate,element)
    //         }
    //         else{
    //           element.isSelected = false
    //         }
    //       });
    //     }
    //   })
    // }
  }

  generateCalendar(x: any) {
    //Format the time
    let start = x.date + ' ' + x.openTime;
    let end = x.date + ' ' + x.closeTime;
    let startTime = moment(start, 'YYYY-MM-DD HH:mm');

    //Format the end time and the next day to it
    let endTime = moment(end, 'YYYY-MM-DD HH:mm').add(0, 'days');

    //Times
    let allTimes: any = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format('YYYY-MM-DD HH:mm'));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    this.allDayDefault = [];
    this.allDayDefault = allTimes.map((el: any, i: any) => ({
      date: x.date,
      end: allTimes[i + 1],
      start: el,
    }));
  }

  getCurrentPlayItem(currentDateTime: any, getData: any) {
    let currectDateTime = moment(currentDateTime).format('YYYY-MM-DD HH:mm:ss')
    if (this.checkCurrentTimeInterval) {
      clearInterval(this.checkCurrentTimeInterval)
    }
    if (this.playlistData.length > 0) {
      this.playlistData.forEach(element => {
        if (element.videoId === getData.videoId) {
          element.start = element.date + ' ' + element.startTime
          element.end = element.date + ' ' + element.endTime
          let time: any
          if (this.playlistType === 'LOOP' && this.videoOnLoop) {
            let selectedTime = moment(currentDateTime).format('HH:mm:ss')
            let selectedDateTime = element.date + ' ' + selectedTime
            time = moment(selectedDateTime, 'YYYY-MM-DD HH:mm:ss')
          }
          else {
            time = moment(currectDateTime, 'YYYY-MM-DD HH:mm:ss')
          }
          let beforeTime = moment(element.start, 'YYYY-MM-DD HH:mm:ss')
          let afterTime = moment(element.end, 'YYYY-MM-DD HH:mm:ss');
          if (time.isBetween(beforeTime, afterTime)) {
            this.currentPlayingItemName = element.name
            this.currentStartItemTime = element.start
            this.currentEndItemTime = element.end
            this.itemDescription = element.itemDescription
            this.checkCurrentTimeInterval = setInterval(() => {
              let sendCurrentDateTime = new Date()
              let sendDateData = moment(sendCurrentDateTime).format('YYYY-MM-DD HH:mm:ss')
              let sendStartTime = element.start
              var getDiff = moment(sendCurrentDateTime).diff(moment(sendStartTime))
              let currDiff = moment.utc(getDiff).format('HH:mm:ss')

              // var getDiff = moment.utc(moment(sendDateData, "HH:mm:ss").diff(moment(sendStartTime, "HH:mm:ss"))).format("HH:mm:ss")
              this.currentItemCurrentTime = currDiff;

              let sendEndTime = element.end
              var getRemDiff = moment(sendEndTime).diff(moment(sendDateData))
              let remainDiff = moment.utc(getRemDiff).format('HH:mm:ss')
              // var getRemDiff = moment.utc(moment(sendEndTime, "HH:mm:ss").diff(moment(sendDateData, "HH:mm:ss"))).format("HH:mm:ss")
              this.currentItemRemainingTime = remainDiff;
              if (this.currentItemRemainingTime == '00:00:00') {
                if (this.selectedChannel) {
                  if (this.checkCurrentTimeInterval) {
                    clearInterval(this.checkCurrentTimeInterval)
                  }
                  this.currentPlayingItemName = ''
                  this.currentStartItemTime = ''
                  this.currentEndItemTime = ''
                  this.currentItemRemainingTime = ''
                  this.currentItemCurrentTime = ''
                  this.itemDescription = ''
                  setTimeout(() => {
                    this.selectChannel(this.selectedChannel)
                  }, 2000);
                  // this.getMediaItemData(this.mediaItemId, 'channelData');
                  // let sendDate = moment(this.currentDate).format('YYYY-MM-DD')
                  // this.getSchedulingChannelInfoWithDate(sendDate,this.mediaItemId)
                }
              }
            }, 1000);
          }



        }
      })
    }

  }

  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.channelName}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }${response.data.channelName}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  getAccess() {
    if (this.isLoggedIn) {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          id: this.PlanID,
          showOffers: true,
          type: 'chanel',
          clickFrom: 'chanel'
        },
      });
    } else {
      this.router.navigate(['/signup'], {
        queryParams: { vedioId: this.selectedChannelId, showOffers: true, type: 'chanel' },
      });
    }
  }
  buyItem() {
    if (this.isLoggedIn) {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          id: this.PlanID,
          buyItem: true,
          ItemPrice: this.itemPrice,
          clickFrom: 'chanel',
          type: 'chanel'
        },
      });
    } else {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          ItemPrice: this.itemPrice,
          buyItem: true,
          type: 'chanel'
        },
      });
    }
  }
  signInRequired() {
    this.router.navigate(['/signup'], {
      queryParams: {
        vedioId: this.selectedChannelId,
        clickFrom: 'programsignInRequire',
        type: 'chanel',
        loginRequired: true
      },
    });
  }
  signInOnMedia() {
    this.router.navigate(['/signin'], {
      queryParams: { vedioId: this.selectedChannelId, type: 'chanel' },
    });
  }

  goBackView() {
    this.getChannelItemById(this.selectedChannelId, this.isLoggedIn)
    this.showSpinner = false
    this.showViewAll = false;
    this.showSpinner = true
    this.viewAllSelected = false
    this.selectedM3uCategoryNameFromDropdown = true
    this.selectedM3uCategoryName = null
  }

  loadMediaItem(event: any) {
    this.page = (event.first / event.rows) + 1;
    this.size = event.rows;
    this.getM3uDataByCatagory(this.selectedM3uCategoryName)
  }

  getM3uDataByCatagory(catagoryName: string) {
    this.m3uData = []
    // this.viewAllm3uData = []
    this.showSpinner = true
    this.viewAllSelected = true
    this.selectedM3uCategoryName = catagoryName
    this.homeService.getM3uDataByCatagory(this.selectedChannelId, catagoryName, this.m3uType, this.isLoggedIn, this.page, this.size).subscribe((response: any) => {
      this.showViewAll = true
      this.showSpinner = false
      this.selectedM3uCategoryNameFromDropdown = false
      // let catagoryName = response.data.Category
      let newData = response.data.Playlist.content
      this.totalElements = response.data.Playlist.totalElements
      // const dataArray: any[] = response.data.Playlist.content;
      // this.viewAllm3uData.push(...dataArray)
      let newM3uData = {
        Category: catagoryName,
        Playlist: newData
      }
      // console.log(newM3uData,'newM3uData')
      this.m3uData.push(newM3uData)
      this.viewAllm3uData.push(newM3uData)
    });
  }

  selectCategory(event: any) {
    if (event.value === null) {
      if (this.searchInputData) {
        this.searchM3uData(this.selectedChannelId, this.searchInputData, null)
      } else {
        this.m3uData = this.allM3uData
      }
      this.selectedM3uCategoryName = null
      this.showViewAll = false
    }
    else {
      this.allDataFromCategory = []
      this.selectedM3uCategoryName = event.value.categoryName
      this.selectedM3uCategoryNameFromDropdown = true
      this.showViewAll = true
      this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
      // this.m3uData = this.allM3uData.filter(el=>{
      //   if(el.Category === event.value.categoryName){
      //     this.selectedM3uCategoryNameFromDropdown = event.value.categoryName
      //     this.selectedM3uCategoryName = event.value.categoryName
      //     return el
      //   }
      // })
      // this.allDataFromCategory = this.m3uData
    }
  }
  goToView(data: any) {
    if (this.currentVedioIsPaid || this.loginRequired) {
      this.paymentModal = true
    }
    else {
      const senddata = { allData: JSON.stringify(data) }
      localStorage.setItem('m3uData', JSON.stringify(senddata));
      this.router.navigate(['/play'])
      // , {queryParams: {allData: JSON.stringify(data),URL: data.channelUri}}
      // query params for filter 
      // filterData: JSON.stringify({selectedM3uCategoryName: this.selectedM3uCategoryName,inputSearch:this.searchInputData,showViewAll: this.showViewAll,selectedChannelId : this.selectedChannelId,viewAllSelected: this.viewAllSelected})
    }
  }
  searchM3uData(id: number, search: any, categoryName: any) {
    this.homeService.searchM3uData(id, search, categoryName, this.m3uType, this.isLoggedIn).subscribe((response: any) => {
      this.m3uData = response.data
      if (!response.data.length || (!response.data[0].Playlist.length && response.data.length === 1)) {
        this.showNotFound = true
      }
      else {
        this.showNotFound = false
      }
    });
  }
  searchData(event: any) {
    if (event.target.value.length > 2) {
      const debounce = setTimeout(() => {
        if (this.selectedM3uCategoryName && event.target.value) {
          this.searchM3uData(this.selectedChannelId, event.target.value, this.selectedM3uCategoryName)
        }
        else if (this.selectedM3uCategoryName) {
          this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
        }
        else {
          this.searchM3uData(this.selectedChannelId, event.target.value, null)
        }
        // this.getMediaItems(1, event.target.value ? (this.totalMediaItems>0 ? this.totalMediaItems : 5) : 5,event.target.value);
      }, 1000);
    }
    else if (event.target.value.length === 0) {
      setTimeout(() => {
        if (this.selectedM3uCategoryName && event.target.value) {
          this.searchM3uData(this.selectedChannelId, event.target.value, this.selectedM3uCategoryName)
        }
        else if (this.selectedM3uCategoryName) {
          // this.m3uData = this.allDataFromCategory
          this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
        }
        else {
          this.m3uData = this.allM3uData
        }
        this.showNotFound = false
      }, 1000);
    }
  }

  handleMissingImage(event: any) {
    event.target.src = 'assets/images/no-image-icon-23494.png';
    (event.target as HTMLImageElement).style.width = "75px";
  }

  selectM3uType(type: any) {
    this.m3uType = type
    this.m3uData = []
    this.showViewAll = false;
    this.showSpinner = true;
    this.selectedM3uCategory = null;
    this.selectedM3uCategoryNameFromDropdown = true;
    this.viewAllSelected = false;
    this.selectedM3uCategoryName = null
    this.pageForChannelM3u = 1
    if (this.m3uType === 'M3U_CHANNEL') {
      this.getM3uData(this.m3uType, this.pageForChannelM3u, 100, false)
    } else {
      this.getM3uData(this.m3uType, '', '', false)
    }
  }

  getM3uData(m3uType: any, page: any, size: any, isOnScroll: boolean) {
    // this.sharedService.updateLoader(true);
    this.homeService.getM3uData(this.selectedChannelId, m3uType, this.sendDate, page, size, 10, this.isLoggedIn).subscribe((response: any) => {
      // console.log(response.data)
      if (isOnScroll) {
        this.m3uData = this.m3uData.concat(response.data.content);
      }
      else {
        this.m3uData = response.data.content
      }
      this.allM3uData = response.data.content
      this.data = this.m3uData
      // this.sharedService.updateLoader(false);
      if (this.m3uData.length) {
        this.showSpinner = false
        this.showNotFound = false
        this.m3uCategory = this.m3uData.map((el) => ({
          categoryName: el.Category
        }))
      }
      else {
        this.showSpinner = false
        this.showNotFound = true
      }
      this.cdr.detectChanges();
    });
    // this.sharedService.updateLoader(true);
  }

  onScrollEnd() {
    this.pageForChannelM3u++
    if (this.m3uType === 'M3U_CHANNEL') {
      this.getM3uData(this.m3uType, this.pageForChannelM3u, 100, true)
    } else {
      this.getM3uData(this.m3uType, '', '', false)
    }
  }
  getLogoDimention() {
    this.homeService.dimensions$.subscribe((dimensions) => {
      this.logoHeight = dimensions.height
    });
  }
}
