<div class="main-channel" *ngIf="showUI" >
    <div class="manual-network-wrapper">
        <section class="main-description-player-wrapper">
            <div class="containers" style="margin: 0 20px;">
                <div class="d-flex align-items-center col-12">
                    <div class="detail-card">
                        <div class="d-flex flex-column channel-detail-wrapper">
                            <img style="opacity: 0;" onload="style.opacity = 1" [src]="currentSelectedChannelLogo ? currentSelectedChannelLogo : null" alt="">
                            <p class="channel-name" *ngIf="currentPlayingItemName">{{currentPlayingItemName}}</p>
                            <p class="timing" *ngIf="currentStartItemTime">{{ currentStartItemTime | date: 'hh:mm a'}} <span *ngIf="currentStartItemTime">-</span> {{currentEndItemTime | date: 'hh:mm a'}}</p>
                            <p class="description">{{itemDescription ? itemDescription : ''}}</p>
                        </div>
                    </div>
                    <!-- currentVedioIsPaid- {{currentVedioIsPaid}} isSubscriptionAvailable- {{isSubscriptionAvailable}} isOneTimePurchase-{{isOneTimePurchase}} loginRequired-{{loginRequired}} loginUserDetail-{{loginUserDetail}} -->
                    <div class="">
                        <div class="video-player-wrapper" style="height: 315px; background-color: #00000096;">
                            <ng-container style="background-color: #000;">
                                <app-video-player style="width: 560px;" class="isPaidType" *ngIf="videoJsPlayerUrl"
                                    [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: videoJsPlayerUrl,type: videoJsPlayerType }]}">
                                </app-video-player>
                                <!-- <div class="disable-div-playPause"></div>
                                <div class="disable-div-progress"></div> -->
                            </ng-container>
                            <div class="paid-info" *ngIf="currentVedioIsPaid">
                                <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                                    [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                                    class="watch-now mb-20">Get Access Now</button>
                                <button *ngIf="isOneTimePurchase && itemPrice"
                                    [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                                    class="watch-now mb-20" style="margin-left: 10px;">Buy Item ${{itemPrice}}</button>
                                <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                                        (click)="signInOnMedia()">sign up
                                    </span>to continue</p>
                            </div>
                            <div class="paid-info" *ngIf="loginRequired">
                                <p class="vedio-name mb-20">Please create a free account to watch the content</p>
                                <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                    (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
                                <p class="vedio-name">or <span style="cursor: pointer;" (click)="signInOnMedia()">sign in </span>to
                                    continue</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="main-catagory">
            <div class="catagory-wrapper">
                <ul>
                    <li  *ngFor="let item of categoryData">{{item.categoryName}}</li>
                </ul>
            </div>
        </section> -->
        <section class="main-playlist-timeline">
            <div>
                <div class="d-flex flex-column">
                    <div class=" d-flex time-wrapper" #scrollTwo>
                        <p class="day-time">{{currentDay}}, {{currentDate | date:'hh:mm a'}}</p>
                        <div class="d-flex all-time" *ngFor="let item of allDayDefault">
                            <p>{{item.start | date: 'hh:mm a'}}</p>
                        </div>
                        <div class="ruler-wrapper" #scrollthree>
                            <span class="ruler-horizontal" [style.backgroundColor]="'#fff'" [ngStyle]="{'width': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 325) + 'px') : '0'}"></span>
                            <span class="triangle-down" [style.borderTop]="'19px solid #fff'" [title]="currentDate | date:'hh:mm:ss a'" [ngStyle]="{'left': tillCurrectTimeSec ? (((tillCurrectTimeSec * 0.13888889) + 310) + 'px') : '0'}"></span>
                        </div>
                    </div>
                    <div class="playlist-wrapper" (scroll)="updateScroll()" #scrollOne>
                        <div class="d-flex">
                            <div>
                                <div class="channelDataSaprate" *ngFor="let item of channelData" >
                                    <div class="channel-wrapper d-flex" *ngFor="let data of item.Playlist" (click)="selectChannel(data)" [ngClass]="{'activeChannel': data.isSelected}" >
                                        <p class="d-flex align-items-center">
                                            <span class="d-flex" style="width: 45%;">
                                                <img [src]="data.tvgLogo"
                                                    style="width:auto;height:55px;margin-left:15px;object-fit: contain;max-width: 90px;margin-right: 15px;" alt="">
                                            </span>
                                            <!-- <span class="d-flex justify-content-center" style="font-size: 18px;margin-right: 10px;min-width: 55px;">
                                                {{data.channelNumber}}
                                            </span> -->
                                            <span class="ch-title" [pTooltip]="data.channelName" style="font-size: 18px;">
                                                {{data.channelName}}
                                            </span>
                                        </p>
                                        <div class="all-playlist-wrapper d-flex" *ngFor="let item of data.playlist">
                                            <div class="playlist-data" [ngStyle]="{'width': item.duration ? ((item.duration * 0.13888889) + 'px') : '0'}" [pTooltip]="item.tvgName"><span>{{item.tvgName}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="no-data" *ngIf="channelData.length === 0">No data exist</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    </div>
</div>
<p-dialog *ngIf="paymentModal" [closable]="false" header="" [(visible)]="paymentModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo1">
        <div class="paid-info" *ngIf="currentVedioIsPaid">
            <button *ngIf="currentVedioIsPaid && isSubscriptionAvailable"
                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="getAccess()"
                class="watch-now mb-20">Get Access Now</button>
            <button *ngIf="isOneTimePurchase"
                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" (click)="buyItem()"
                class="watch-now mb-20" style="margin-left: 10px;">Buy Item ${{itemPrice}}</button>
            <p *ngIf="!loginUserDetail" class="vedio-name">or <span style="cursor: pointer;"
                    (click)="signInOnMedia()">sign up
                </span>to continue</p>
        </div>

        <div class="paid-info" *ngIf="loginRequired">
            <p class="vedio-name mb-20">Please create a free account to watch the content</p>
            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
            <p class="vedio-name">or <span style="cursor: pointer;" (click)="signInOnMedia()">sign in </span>to
                continue</p>
        </div>
    </div>
    <p-footer class="signout-footer">
        <!-- <button type="button" class="btn btn-white" style="margin: 0 10px;" (click)="logOut()">Yes</button> -->
        <button type="button" class="btn btn-white" (click)="paymentModal= false">Cancel</button>
    </p-footer>
</p-dialog>