import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';

@Injectable()
export class WatchDailyResolver implements Resolve<any> {
  constructor(
    private _Title: Title,
    private _HomeService: HomeService,
    private _Meta: Meta
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return true;
  }
}
