import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import * as Plyr from 'plyr';

import { DashjsPlyrDriver } from '../../dashjs-plyr-driver/dashjs-plyr-driver';

import { HlsjsPlyrDriver } from '../../hlsjs-plyr-driver/hlsjs-plyr-driver';
import { SharedService } from 'src/app/services/shared/shared.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login/login.service';
import { AngularFaviconService } from 'angular-favicon';
import { SessionService } from 'src/app/services/shared/session.service';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'app-watchdaily',
  templateUrl: './watchdaily.component.html',
  styleUrls: ['./watchdaily.component.scss'],
  providers: [NgbCarouselConfig],
})
export class WatchdailyComponent implements OnInit {
  products: any[];
  videoList: any;
  mediadata: any;
  showMedia = false;
  showFavMedia = false;
  showcontinueList = false;
  players: Plyr;
  medias: any[] = [];
  favouritiesList: any[] = [];
  favData: any[] = [];
  continueList: any[] = [];
  continueData: any[] = [];
  freeData: any[] = [];
  isLoggedIn: any;
  primaryColor: any;
  appBGColor: any;
  vodPageName: any;
  contactName: any;
  vedioType: any;
  currentVedio: any;
  productss: any;
  showPage = false;
  mediaData: any[] = [];
  allMediaData: any[] = [];
  othersMediaDataList: any[] = [];
  OthermediaData: any[] = [];
  showOtherData: boolean;
  SlideId: any;
  // currentVedio : Plyr.Source[] = [
  //   {src: ''}
  // ]
  options: Plyr.Options = {
    // muted: true,
    volume: 0,
    autoplay: true,
    invertTime: false,
    disableContextMenu: true,
    displayDuration: true,
    settings: ['quality', 'speed', 'loop'],
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'settings',
      'fullscreen',
    ],
    captions: { active: true, update: true, language: 'en' },
    loop: { active: true },
  };

  // dashjsDriver1 = new DashjsPlyrDriver(true);

  // dashjsDriver2 = new DashjsPlyrDriver(false);

  // hlsjsDriver1 = new HlsjsPlyrDriver(false);
  // hlsjsDriver2 = new HlsjsPlyrDriver(false);
  // hlsjsDriver3 = new HlsjsPlyrDriver(false);
  // hlsjsDriver4 = new HlsjsPlyrDriver(false);
  // hlsjsDriver5 = new HlsjsPlyrDriver(false);
  // hlsjsDriver6 = new HlsjsPlyrDriver(false);
  // hlsjsDriver7 = new HlsjsPlyrDriver(false);
  // hlsjsDriver8 = new HlsjsPlyrDriver(false);
  // hlsjsDriver9 = new HlsjsPlyrDriver(false);
  // hlsjsDriver10 = new HlsjsPlyrDriver(false);
  // hlsjsDriver11 = new HlsjsPlyrDriver(false);
  // hlsjsDriver12 = new HlsjsPlyrDriver(false);
  totalVideoPercent: any;
  showMenu = false;
  web: any;
  logoSize: any;
  logoSizeRoundValue: number;
  appBtnPrimaryColor: any;
  headerLinkColor: any;
  headerLogo: any;
  mainPageName: any;
  bottomGradiant: any;
  changeToRGB: any;
  blurOpacity: any;
  favIcon: any;
  scroll: number;
  signoutModal = false;
  scrHeight: any;
  scrWidth: any;
  showPageAfterLoad = false;
  searchInput: any;
  responsiveOptions: any[] = [
    {
      breakpoint: '600px',
      numVisible: 1,
      numScroll: 1
    }
  ]
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  // @ViewChildren(NgbSlide) slides: QueryList<NgbSlide>
  // slideControl: any[] = []
  onSlide(event: any) {
    this.SlideId = event.current;
  }
  showHideEbook: boolean;
  ebookPage: string;
  isDuration: boolean;
  screenSize: string;
  domain: string;
  constructor(
    config: NgbCarouselConfig,
    public homeService: HomeService,
    public router: Router,
    public messageService: MessageService,
    private elementRef: ElementRef,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private ngxFavicon: AngularFaviconService,
    private loginService: LoginService,
    private _Meta: Meta,
    private _Title: Title,
    private _sessionService: SessionService,
    private _ActivatedRoute: ActivatedRoute
  ) {
    Carousel.prototype.onTouchMove = () => { };
    this.getScreenSize();
    if (this.scrWidth < 500) {
      this.scroll = 1;
    } else if (this.scrWidth < 800 && this.scrWidth > 500) {
      this.scroll = 2;
    } else if (this.scrWidth < 1100 && this.scrWidth > 800) {
      this.scroll = 2;
    } else {
      this.scroll = 4;
    }
    this.screenSize = `${Math.round(this.scrWidth / 2)}px`;
    config.interval = 10000;
    config.animation = true;
    config.wrap = true;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.keyboard = true;
    config.pauseOnHover = false;
    this.products = [];
    this.mediaData = [];
    this.othersMediaDataList = [];
    this.continueData = [];
    this.favData = [];
    this.showHideEbook = false;
    this.ebookPage = '';
    this.isDuration = true;
    this.logoSizeRoundValue = 0;
    this.domain = '';
  }

  ngOnInit(): void {
    let loginData: any;
    loginData = this._sessionService.getSession('loggedInUser');
    // loginData = localStorage.getItem('loggedInUser')
    this.isLoggedIn = loginData ? true : false;
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    this.getPageCustomizationByDomain();
    // this.web = `${window.location.protocol}//${window.location.hostname}/`
    this.getSubscribe();
    if (this.isLoggedIn) {
      // this.pageCustomization()
      this.GetMediaItemWith(1, 20, 'banner');
      this.getListOfFavourites();
      this.GetMediaDataWith('');
      setTimeout(() => {
        this.getContinuePlayList();
      }, 1000);
    } else {
      // this.pageCustomization()
      this.GetMediaItemWithout(1, 20, 'banner');
      this.GetMediaDataWithout('');
    }
  }

  logOut() {
    this.loginService.logOutUser().subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logged out successful.',
        });
        setTimeout(() => {
          localStorage.clear();
          this.router.navigate(['/main']);
        }, 100);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.mainPageName = response.data.pageCustomizationDTO.homePage;
        this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
        this.contactName = response.data.pageCustomizationDTO.contactUs;
        this.showHideEbook = response.data.pageCustomizationDTO.showHideEbook;
        this.ebookPage = response.data.pageCustomizationDTO.ebookPage;
        this.isDuration = response.data.webAppSettingDTO.showProgramDuration;
        // if (this.router.url === '/vod') {
        //   this.router.navigate(['/vod'], {
        //     queryParams: { vod: this.vodPageName },
        //   });
        // }
        // webappSetting -----------------
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.watchDailyPage}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
            }${response.data.pageCustomizationDTO.watchDailyPage}`,
        });
        this._Meta.updateTag({
          property: 'og:image',
          content: `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`,
        });
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.logoSizeRoundValue = Math.round(this.logoSize / 10) * 10;
        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 65%,${this.appBGColor})`
        );
        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage =
          '';
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
      }
    });
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.homePage}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
              }${response.data.homePage}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  // test(i: any) {
  //   if (i === 0) {
  //     return this.hlsjsDriver1;
  //   } else if (i === 1) {
  //     return this.hlsjsDriver2;
  //   } else if (i === 2) {
  //     return this.hlsjsDriver3;
  //   } else if (i === 3) {
  //     return this.hlsjsDriver4;
  //   } else if (i === 4) {
  //     return this.hlsjsDriver5;
  //   } else if (i === 5) {
  //     return this.hlsjsDriver6;
  //   } else if (i === 6) {
  //     return this.hlsjsDriver7;
  //   } else if (i === 7) {
  //     return this.hlsjsDriver8;
  //   } else if (i === 8) {
  //     return this.hlsjsDriver9;
  //   } else if (i === 9) {
  //     return this.hlsjsDriver10;
  //   } else if (i === 10) {
  //     return this.hlsjsDriver11;
  //   } else {
  //     return this.hlsjsDriver12;
  //   }
  // }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex =
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error('Invalid RGBA color format.');
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    } else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color);
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  played(event: any) {
    // console.log(event);
  }
  getListOfFavourites() {
    this.homeService.getListOfFavourites().subscribe(
      (response: any) => {
        this.favouritiesList = response.data;
        if (this.favouritiesList.length > 0) {
          this.showFavMedia = true;
        }
        this.favouritiesList.forEach((el) => {
          if (el.mediaItemDTO.wideArtwork.document.id) {
            var width = 480;
            var height = 270;
            let id = el.mediaItemDTO.wideArtwork.document.id;
            el.mediaItemDTO['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.mediaItemDTO.videoDTO) {
            el.mediaItemDTO.videoDTO['vedioTime'] =
              this.sharedService.timeConvert(el.mediaItemDTO.videoDTO.duration);
          } else if (el.mediaItemDTO.videoDuration) {
            el.mediaItemDTO['vedioTime'] = this.sharedService.timeConvert(
              el.mediaItemDTO.videoDuration
            );
          }
        });

        this.favData = this.favouritiesList.map((el) => ({
          mediaImg: el.mediaItemDTO.newwideimg
            ? el.mediaItemDTO.newwideimg
            : '',
          duration: el.mediaItemDTO.videoDTO
            ? el.mediaItemDTO.videoDTO.vedioTime
            : el.mediaItemDTO.vedioTime
              ? el.mediaItemDTO.vedioTime
              : '0:00',
          title: el.mediaItemDTO.title,
          color: el.mediaItemDTO.wideArtwork
            ? el.mediaItemDTO.wideArtwork.document.imageColur
            : '',
          id: el.mediaItemDTO.id,
        }));
      },
      (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  getContinuePlayList() {
    this.homeService.getContinuePlayList().subscribe(
      (response: any) => {
        this.continueList = response.data;
        if (this.continueList.length > 0) {
          this.showcontinueList = true;
        }
        this.continueList.forEach((el) => {
          el['totalVideoPercent'] = (el.pauseTime / el.duration) * 100;
          if (el.mediaItemDTO.wideArtworkId) {
            var width = 480;
            var height = 270;
            let id = el.mediaItemDTO.wideArtworkId;
            el.mediaItemDTO['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.mediaItemDTO.videoDTO) {
            el.mediaItemDTO.videoDTO['vedioTime'] =
              this.sharedService.timeConvert(el.mediaItemDTO.videoDTO.duration);
          } else if (el.mediaItemDTO.videoDuration) {
            el.mediaItemDTO['vedioTime'] = this.sharedService.timeConvert(
              el.mediaItemDTO.videoDuration
            );
          }
        });

        this.continueData = this.continueList.map((el) => ({
          mediaImg: el.mediaItemDTO.newwideimg
            ? el.mediaItemDTO.newwideimg
            : '',
          duration: el.mediaItemDTO.videoDTO
            ? el.mediaItemDTO.videoDTO.vedioTime
            : el.mediaItemDTO.vedioTime
              ? el.mediaItemDTO.vedioTime
              : '0:00',
          title: el.mediaItemDTO.title,
          color: el.mediaItemDTO.wideArtworkImageColor
            ? el.mediaItemDTO.wideArtworkImageColor
            : '',
          id: el.mediaItemDTO.id,
          totalVideoPercent: el.totalVideoPercent,
        }));
      },
      (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  GetMediaItemWith(page: any, size: any, type: any) {
    this.homeService.getMediaItemWith(page, size, type).subscribe(
      (response: any) => {
        this.medias = response.data.content;
        if (this.medias.length > 0) {
          this.showMedia = true;
        }
        this.products = [];
        this.medias.forEach((el) => {
          if (el.bannerArtworkId) {
            var width = 1920;
            var height = 692;
            let id = el.bannerArtworkId;
            el['newbannerimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }

          // if (el.videoDTO) {
          // plyr.player

          let vedioSrc: any;
          // let vedioSrc = el.videoDTO ? el.videoDTO.bannerVideoUrl : el.videoDTO.path ? el.videoDTO.path : el.videoUrl ? el.videoUrl : 'https://cdn.plyr.io/static/blank.mp4';
          if (el.videoDTO) {
            vedioSrc = el.videoDTO.bannerVideoUrl
              ? el.videoDTO.bannerVideoUrl
              : null;

            // else if (el.videoDTO.path) {
            //   vedioSrc = el.videoDTO.path
            // }
          }
          // else if (el.videoUrl) {
          //   vedioSrc = el.videoUrl
          // }
          // else {
          //   vedioSrc = 'https://cdn.plyr.io/static/blank.mp4'
          // }
          var url = vedioSrc;
          var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : '';
          if (urlExt === 'm3u8') {
            el['vedioType'] = true;
            // this.currentVedio[0].src = '';
            // this.currentVedio = [];
            // let newObj = {
            //   src: '',
            // };
            // let newarray: any[] = [];
            // this.currentVedio.push(newObj);
            el['currentVedio'] = vedioSrc;
            el['videoJsPlayerType'] = 'application/x-mpegURL';
            // el.currentVedio[0].src = vedioSrc;
          } else {
            el['vedioType'] = false;
            el['currentVedio'] = vedioSrc;
            el['videoJsPlayerType'] = `video/${urlExt}`;
          }
          if (el.videoDTO) {
            // el['currentVedio'] = el.videoDTO.originalPath;
            el.videoDTO['vedioTime'] = this.sharedService.timeConvert(
              el.videoDTO.duration
            );
          } else if (el.videoDuration) {
            el['vedioTime'] = this.sharedService.timeConvert(el.videoDuration);
          }
        });

        this.freeData = this.medias.filter((el) => {
          if (el.mediaAccessType === 'FREE') {
            return el;
          }
        });

        if (this.freeData.length > 8) {
          this.freeData = this.freeData.slice(0, 9);
        }

        this.products = this.freeData.map((el) => ({
          mediaImg: el.newbannerimg ? el.newbannerimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          subTitle: el.subTitle,
          color: el.bannerArtworkImageColor ? el.bannerArtworkImageColor : '',
          mediaAccessType: el.mediaAccessType,
          id: el.id,
          isVideo: el.isVideo,
          currentVedio: el.currentVedio ? el.currentVedio : null,
          videoJsPlayerType: el.videoJsPlayerType ? el.videoJsPlayerType : '',
          vedioType: el.vedioType ? el.vedioType : false,
        }));
        setTimeout(() => {
          // this.players.autoplay = true // or this.plyr.player.play()
          // this.options.autoplay = true
          this.play();
        }, 1000);
        this.SlideId = this.products.length > 0 ? this.products[0].id : null;
        this.mediadata = this.medias.map((el) => ({
          // mediaImg: el.wideArtwork ? el.wideArtwork.document.newimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
          id: el.id,
        }));
        this.showPage = true;
      },
      (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  GetMediaItemWithout(page: any, size: any, type: any) {
    this.sharedService.updateLoader(true);
    this.homeService.getMediaItemWithout(page, size, type).subscribe(
      (response: any) => {
        this.products = [];
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Logged out successful.' });
        this.medias = response.data.content;
        if (this.medias.length > 0) {
          this.showMedia = true;
        }
        this.medias.forEach((el, i) => {
          // if (el.wideArtwork) {
          //   var width = 480; var height = 270;
          //     let id = el.wideArtwork.document.id;
          //     el.wideArtwork.document['newimg'] = `${appConfig.imageURL}` + id + '?height=' + height + '&width=' + width;
          // }
          if (el.bannerArtworkId) {
            var width = 1920;
            var height = 692;
            let id = el.bannerArtworkId;
            el['newbannerimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          // if (el.videoDTO) {
          // plyr.player

          let vedioSrc: any;
          // let vedioSrc = el.videoDTO ? el.videoDTO.bannerVideoUrl : el.videoDTO.path ? el.videoDTO.path : el.videoUrl ? el.videoUrl : 'https://cdn.plyr.io/static/blank.mp4';
          if (el.videoDTO) {
            vedioSrc = el.videoDTO.bannerVideoUrl
              ? el.videoDTO.bannerVideoUrl
              : null;

            // else if (el.videoDTO.path) {
            //   vedioSrc = el.videoDTO.path
            // }
          }
          // else if (el.videoUrl) {
          //   vedioSrc = el.videoUrl
          // }
          // else {
          //   vedioSrc = 'https://cdn.plyr.io/static/blank.mp4'
          // }
          var url = vedioSrc;
          var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : '';
          if (urlExt === 'm3u8') {
            el['vedioType'] = true;
            // this.currentVedio[0].src = '';
            // this.currentVedio = [];
            // let newObj = {
            //   src: '',
            // };
            // let newarray: any[] = [];
            // this.currentVedio.push(newObj);
            el['currentVedio'] = vedioSrc;
            el['videoJsPlayerType'] = 'application/x-mpegURL';
            // el.currentVedio[0].src = vedioSrc;
          } else {
            el['vedioType'] = false;
            el['currentVedio'] = vedioSrc;
            el['videoJsPlayerType'] = `video/${urlExt}`;
          }
          if (el.videoDTO) {
            // el['currentVedio'] = el.videoDTO.originalPath;
            el.videoDTO['vedioTime'] = this.sharedService.timeConvert(
              el.videoDTO.duration
            );
          }
          // }
          else if (el.videoDuration) {
            el['vedioTime'] = this.sharedService.timeConvert(el.videoDuration);
          }
        });

        this.freeData = this.medias.filter((el) => {
          if (el.mediaAccessType === 'FREE') {
            return el;
          }
        });

        if (this.freeData.length > 8) {
          this.freeData = this.freeData.slice(0, 9);
        }

        this.products = this.freeData.map((el) => ({
          mediaImg: el.newbannerimg ? el.newbannerimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          subTitle: el.subTitle,
          color: el.bannerArtworkImageColor ? el.bannerArtworkImageColor : '',
          mediaAccessType: el.mediaAccessType,
          id: el.id,
          isVideo: el.isVideo,
          currentVedio: el.currentVedio ? el.currentVedio : null,
          videoJsPlayerType: el.videoJsPlayerType ? el.videoJsPlayerType : '',
          vedioType: el.vedioType ? el.vedioType : false,
        }));
        setTimeout(() => {
          // this.players.autoplay = true // or this.plyr.player.play()
          // this.options.autoplay = true
          this.play();
        }, 1000);

        this.SlideId = this.products.length > 0 ? this.products[0].id : null;

        this.mediadata = this.medias.map((el) => ({
          // mediaImg: el.wideArtwork ? el.wideArtwork.document.newimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
          id: el.id,
        }));
        this.showPage = true;
        this.sharedService.updateLoader(false);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  goToView(item: any) {
    this.router.navigate([`video/${item.id}`]);
  }

  watchBtnGoToView(id: number) {
    this.router.navigate([`video/${id}`]);
  }

  // completeVideo(event: Plyr.PlyrEvent) {
  //   this.isVideoPlayed = 0
  // }

  playedFullScreen(event: Plyr.PlyrEvent) {
    // console.log('fullscreen', event);
  }

  stateChange(event: Plyr.PlyrEvent) {
    // console.log('stateChange', event);
  }

  playVideo(): void {
    this.players.play();
  }

  // played(event: Plyr.PlyrEvent) {
  //   console.log('played', event);
  //   setTimeout(() => {
  //     console.log('currntTime',this.players.duration);
  //   }, 1000);
  // }

  // played(event: Plyr.PlyrEvent, index: any) {
  //   // if(data.vedioType === 'true'){
  //   //   this.hlsjsDriver2.load(data.currentVedio[0].src);
  //   // }
  //   this.products.forEach((el: any, i: any) => {
  //     if (el.vedioType === true) {
  //       if (i === 0) {
  //         setTimeout(() => {
  //           this.hlsjsDriver1.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 1) {
  //         setTimeout(() => {
  //           this.hlsjsDriver2.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 2) {
  //         setTimeout(() => {
  //           this.hlsjsDriver3.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 3) {
  //         setTimeout(() => {
  //           this.hlsjsDriver4.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 4) {
  //         setTimeout(() => {
  //           this.hlsjsDriver5.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 5) {
  //         setTimeout(() => {
  //           this.hlsjsDriver6.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 6) {
  //         setTimeout(() => {
  //           this.hlsjsDriver7.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 7) {
  //         setTimeout(() => {
  //           this.hlsjsDriver8.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 8) {
  //         setTimeout(() => {
  //           this.hlsjsDriver9.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 9) {
  //         setTimeout(() => {
  //           this.hlsjsDriver10.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 10) {
  //         setTimeout(() => {
  //           this.hlsjsDriver11.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 11) {
  //         setTimeout(() => {
  //           this.hlsjsDriver12.load(el.currentVedio[0].src);
  //         }, 500);
  //       }

  //       // setTimeout(() => {
  //       //   this.hlsjsDriver2.load(el.currentVedio[0].src);
  //       // }, 1000);
  //     }
  //   });
  //   // console.log(this.currentVedio);

  //   // console.log('played', event);
  //   setTimeout(() => {
  //     // console.log('currntTime',this.players.duration);
  //   }, 1000);
  // }

  play(): void {
    // if(this.vedioType){
    //   this.hlsjsDriver2.load(this.currentVedio[0].src);
    // }
    if (this.players) {
      this.players.play();
      this.players.muted = true;
    }
  }

  languageChanged(driver: HlsjsPlyrDriver, plyr: Plyr) {
    setTimeout(() => (driver.hls.subtitleTrack = plyr.currentTrack), 50);
  }

  searchData(searchInput: any) {
    if (searchInput.length >= 3) {
      if (this.isLoggedIn) {
        this.GetMediaDataWith(searchInput);
      } else {
        this.GetMediaDataWithout(searchInput);
      }
    } else if (searchInput.length === 0) {
      if (this.isLoggedIn) {
        this.GetMediaDataWith('');
      } else {
        this.GetMediaDataWithout('');
      }
    }
  }

  GetMediaDataWithout(search: string) {
    this.sharedService.updateLoader(true);
    this.homeService.getMediaDataWithout(search).subscribe(
      (response: any) => {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Logged out successful.' });

        this.sharedService.updateLoader(true);
        this.allMediaData = response.data.mediaSeriesResponseDTOS;
        this.othersMediaDataList = response.data.otherMediaItemsDTO;
        this.showOtherData =
          response.data.otherMediaItemsDTO.length > 0 ? true : false;
        if (this.allMediaData.length > 0) {
          this.showMedia = true;
        }
        this.allMediaData.forEach((el, i) => {
          el.mediaItemDTO.forEach((element: any, index: any) => {
            element['index'] = index;
            if (element.wideArtworkId) {
              var width = 480;
              var height = 270;
              let id = element.wideArtworkId;
              element['newwideimg'] =
                `${appConfig.imageURL}` +
                id +
                '?height=' +
                height +
                '&width=' +
                width;
              element['newwideimgBanner'] =
                `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              element['color'] = element.wideArtworkImageColor
                ? element.wideArtworkImageColor
                : '';
            }
            if (element.videoDTO) {
              element['duration'] = this.sharedService.timeConvert(
                element.videoDTO.duration
              );
            } else if (element.videoDuration) {
              element['duration'] = this.sharedService.timeConvert(
                element.videoDuration
              );
            }
          });
        });
        this.mediaData = this.allMediaData.map((el, i) => ({
          title: el.title,
          mediaItem: el.mediaItemDTO,
        }));

        this.othersMediaDataList.forEach((element) => {
          if (element.wideArtworkId) {
            var width = 320;
            var height = 180;
            let id = element.wideArtworkId;
            element['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
            element['newwideimgBanner'] =
              `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
            element['color'] = element.wideArtworkImageColor
              ? element.wideArtworkImageColor
              : '';
          }
          if (element.videoDTO) {
            element['duration'] = this.sharedService.timeConvert(
              element.videoDTO.duration
            );
          } else if (element.videoDuration) {
            element['duration'] = this.sharedService.timeConvert(
              element.videoDuration
            );
          }
        });

        // this.OthermediaData = this.othersMediaDataList.map((el, i) => ({
        //   title: el.title,
        //   mediaItem: el.mediaItemDTO,
        // }))
        setTimeout(() => {
          this.showPageAfterLoad = true;
          this.sharedService.updateLoader(false);
        }, 200);
      },
      (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  GetMediaDataWith(search: string) {
    this.sharedService.updateLoader(true);
    this.homeService.getMediaDataWith(search).subscribe(
      (response: any) => {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Logged out successful.' });
        this.sharedService.updateLoader(true);
        this.allMediaData = response.data.mediaSeriesResponseDTOS;
        this.othersMediaDataList = response.data.otherMediaItemsDTO;
        this.showOtherData =
          response.data.otherMediaItemsDTO.length > 0 ? true : false;
        if (this.allMediaData.length > 0) {
          this.showMedia = true;
        }
        this.allMediaData.forEach((el, i) => {
          el.mediaItemDTO.forEach((element: any, index: any) => {
            element['index'] = index;
            if (element.wideArtworkId) {
              var width = 320;
              var height = 180;
              let id = element.wideArtworkId;
              element['newwideimg'] =
                `${appConfig.imageURL}` +
                id +
                '?height=' +
                height +
                '&width=' +
                width;
              element['newwideimgBanner'] =
                `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              element['color'] = element.wideArtworkImageColor
                ? element.wideArtworkImageColor
                : '';
            }
            if (element.videoDTO) {
              element['duration'] = this.sharedService.timeConvert(
                element.videoDTO.duration
              );
            } else if (element.videoDuration) {
              element['duration'] = this.sharedService.timeConvert(
                element.videoDuration
              );
            }
          });
        });
        this.mediaData = this.allMediaData.map((el, i) => ({
          title: el.title,
          mediaItem: el.mediaItemDTO,
        }));

        this.othersMediaDataList.forEach((element) => {
          if (element.wideArtworkId) {
            var width = 320;
            var height = 180;
            let id = element.wideArtworkId;
            element['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
            element['newwideimgBanner'] =
              `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
            element['color'] = element.wideArtworkImageColor
              ? element.wideArtworkImageColor
              : '';
          }
          if (element.videoDTO) {
            element['duration'] = this.sharedService.timeConvert(
              element.videoDTO.duration
            );
          } else if (element.videoDuration) {
            element['duration'] = this.sharedService.timeConvert(
              element.videoDuration
            );
          }
        });
        setTimeout(() => {
          this.showPageAfterLoad = true;
          this.sharedService.updateLoader(false);
        }, 200);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }
}
