import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { ContactModel } from 'src/app/Models/signup.model';
import { SharedService } from 'src/app/services/shared/shared.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';

import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { LoginModel } from 'src/app/Models/login.model';
import { LoginService } from 'src/app/services/login/login.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
})
export class SignupPageComponent implements OnInit {
  primaryColor: any;
  appBGColor: any;
  logo: any;
  favIcon: any;
  showBilling = false;
  showlogin = false;
  showOffers = false;
  signup: ContactModel;
  passwordType = false;
  confirmPasswordType = false;
  passwordTypeLogin = false;
  login: LoginModel;
  planId: any;
  stateList: any[] = [];
  cityDropdown = false;
  cityList: any[] = [];
  subscriptionPlanIds: any;
  subscriptionPlanData: any;
  selectedPlanData: any[] = [];
  countryList: any[] = [];
  userMail: any;
  userName: any;
  userFirstName: any;
  userLastName: any;
  planSelected = false;
  displayBasic: boolean;

  subPlanImg: any;
  subPlanDuration: any;
  subPlanAmount: any;
  subPlanDesc: any;
  loginUserDetail: any;
  planDuration: any;
  planDurationName: any;
  web: any;
  nameLetter: any;
  changeToRGB: any;
  forgotEmail: any;
  userId: any;
  paymentDone: boolean;

  subscriptionPlanList: any[] = [];
  vedioId: any;
  showOfferPage: any;
  currentProgramData: any[] = [];
  currentVedioTitle: any;
  currentVedioDesc: any;
  currentVedioImg: any;
  stripeTokenId: string;
  emailId: any;
  invoiceId: any;
  displayPlan = false;
  subDomainName: any;
  userPlanId: any;
  clickFrom: any;
  cardData: any;
  cardBrand: any;
  cardLast4Digit: any;
  showaddedCard = false;
  unsubscribeModal = false;
  disableContinueBtn = false;
  rightWrapperColor: any;
  isPaymentDone: any;
  showBreadcrumbs = true;
  buyItem: any;
  ItemPrice: any;
  subscriptionPlanIdForVedio: any[] = [];
  userSubscriptionPlanId: any;
  prevSelectedPlanID: any;
  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#6d6d6d',
        color: '#000',
        fontWeight: '500',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#6d6d6d',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  isTermAndCondition: boolean;
  isEbook: boolean;
  isChannel: boolean
  isNetwork: boolean;
  isContinueDisabled: boolean;
  paymentForm: {
    name: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    zipcode: number | null;
  };
  paymentFormSubmitted: boolean;
  isPlanChange: boolean;
  isNoPlanSelected: boolean;
  scrHeight: any;
  scrWidth: any;
  loginImg: any;
  bottomGradiant: any;
  leftGradiant: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  blurOpacity: any;
  redirection: string | null;
  isPlanChangeRequest: boolean;
  m3uType: any;
  loginRequired:boolean;
  isLoggedIn : any;
  domain: string;
  isWhiteLabel: boolean;
  supportUrl: any;
  termsOfUse: any;
  privacyPolicy: any;
  constructor(
    public homeService: HomeService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService,
    private ngxFavicon: AngularFaviconService,
    private elementRef: ElementRef,
    public route: ActivatedRoute,
    private stripeService: StripeService,
    public signService: LoginService,
    private sanitizer: DomSanitizer
  ) {
    this.getScreenSize();
    this.signup = new ContactModel();
    this.login = new LoginModel();
    this.isTermAndCondition = false;
    this.isEbook = false;
    this.isChannel = false
    this.isNetwork = false;
    this.isContinueDisabled = false;
    this.paymentForm = {
      name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zipcode: null,
    };
    this.paymentFormSubmitted = false;
    this.isPlanChange = false;
    this.isNoPlanSelected = false;
    this.redirection = '';
    this.isPlanChangeRequest = false;
  }

  ngOnInit(): void {
    this.loginUserDetail = localStorage.getItem('loggedInUser');
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this.route.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.isLoggedIn = this.loginUserDetail ? true : false;
    this.userMail = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.email
      : '';
    this.userFirstName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.firstName
      : '';
    this.userLastName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.lastName
      : '';
    this.userName = `${this.userFirstName} ${
      this.userLastName ? this.userLastName : ''
    }`;
    this.invoiceId = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.invoiceId
      : '';
    this.paymentDone = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
      : '';
    let firstletterUname = this.userFirstName.charAt(0);
    let lastletterUname = this.userLastName ? this.userLastName.charAt(0) : '';
    let fnameLetter = firstletterUname ? firstletterUname : '';
    let lnameLetter = lastletterUname ? lastletterUname : '';
    this.nameLetter = fnameLetter + lnameLetter;
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
    this.route.queryParamMap.subscribe((params) => {
      this.planId = params.get('id');
      this.planId = parseInt(this.planId);
      this.userPlanId = this.planId;
      this.vedioId = params.get('vedioId');
      this.clickFrom = params.get('clickFrom');
      this.showOfferPage = params.get('showOffers');
      this.buyItem = params.get('buyItem');
      this.ItemPrice = params.get('ItemPrice');
      this.loginRequired = params.get('loginRequired') && params.get('loginRequired') === 'true' ? true : false;
      this.isEbook = params.get('type') && params.get('type') === 'EBOOK' ? true : false;
      this.isChannel = params.get('type') && params.get('type') === 'chanel' ? true : false;
      this.isNetwork = params.get('type') && params.get('type') === 'customNet' ? true : false;
      this.m3uType = params.get('m3uType')
      this.isNoPlanSelected =
        params.get('category') && params.get('category') === 'NOPLAN'
          ? true
          : false;
      this.redirection = params.get('redirect') && params.get('redirect');
    });
    if (this.showOfferPage === 'true') {
      this.showOffers = true;
    }
    if (this.clickFrom === 'programsignInRequire' || this.isNoPlanSelected) {
      this.showBreadcrumbs = false;
    }
    if (this.loginUserDetail) {
      this.userSubscriptionPlanId = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
        : '';
      if (this.clickFrom === 'billing') {
        this.planId = this.userSubscriptionPlanId;
      }
    }
    // this.getAllCountryList();
    if (this.vedioId) {
      if (this.isEbook) {
        if (this.loginUserDetail) {
          this.getEbookMediaItemWithLogin(this.vedioId);
        } else {
          this.getEbookMediaItemWithoutLogin(this.vedioId);
        }
      }
      else if(this.isChannel){
        if (this.loginUserDetail) {
          this.GetMediaItemWithLogin(this.vedioId);
        } else {
          this.GetMediaItemWithoutLogin(this.vedioId);
        }
      }
      else if(this.isNetwork){
        if (this.loginUserDetail) {
          this.GetMediaItemWithLogin(this.vedioId);
        } else {
          this.GetMediaItemWithoutLogin(this.vedioId);
        }
      }
       else {
        if (this.loginUserDetail) {
          this.GetMediaItemWithLogin(this.vedioId);
        } else {
          this.GetMediaItemWithoutLogin(this.vedioId);
        }
      }
    }
    if (this.planId) {
      this.getSubscriptionPlanById(this.planId);
    }
    if (this.loginUserDetail) {
      this.getcardDetails();
    }
    // this.pageCustomization()
    this.getSubscribe();
    setTimeout(() => {
      this.subscriptionPlan();
    }, 800);
    // if (this.vedioId && this.paymentDone) {
    //   this.disableContinueBtn = true;
    // }
    if (this.vedioId) {
      this.validateUserPayment();
    }
    this.getAllCountryList();
  }

  createToken(value: any): void {
    if (!value.valid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please insert correct values.',
      });
      return;
    }
    const name = this.userMail;
    this.paymentFormSubmitted = true;
    if (
      this.paymentForm.name.trim() &&
      this.paymentForm.email.trim() &&
      this.paymentForm.addressLine1.trim() &&
      this.paymentForm.city.trim() &&
      this.paymentForm.country.trim() &&
      this.paymentForm.zipcode &&
      this.paymentForm.state.trim()
    ) {
      this.isContinueDisabled = true;
      this.stripeService
        .createToken(this.card.element, {
          name: this.paymentForm.name.trim(),
          address_line1: this.paymentForm.addressLine1.trim(),
          address_line2: this.paymentForm.addressLine2.trim(),
          address_city: this.paymentForm.city.trim(),
          address_country: this.paymentForm.country.trim(),
          address_state: this.paymentForm.state.trim(),
          address_zip: String(this.paymentForm.zipcode),
          currency: 'usd',
        })
        .subscribe(
          (result) => {
            this.paymentFormSubmitted = false;
            if (result.token) {
              // Use the token
              this.stripeTokenId = result.token.id;
              this.addCardDetails(result);
              this.isContinueDisabled = false;
            } else if (result.error) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: result.error.message,
              });
              console.log(result.error.message);
              this.isContinueDisabled = false;
            }
          },
          (error) => {
            this.isContinueDisabled = false;
          }
        );
    }
  }

  getcardDetails() {
    this.homeService.cardDetailsForGiving(false).subscribe((response: any) => {
      let allCardData = [];
      const sortedCard =
        response.data.cardDetails.cards.length &&
        response.data.cardDetails.cards.sort(
          (currentValue: any, nextValue: any) => {
            const currentDate: any = new Date(currentValue.createdDate);
            const nextDate: any = new Date(nextValue.createdDate);
            return nextDate - currentDate;
          }
        );
      allCardData = sortedCard.length ? sortedCard : [];
      this.cardData = sortedCard.length ? sortedCard[0] : null;
      this.cardBrand = this.cardData ? this.cardData.brand : null;
      this.cardLast4Digit = this.cardData ? this.cardData.last4 : null;
      this.stripeTokenId = this.cardData ? this.cardData.tokenId : null;
      if (this.cardData) {
        this.showaddedCard = true;
      }
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  forgotPasswordLink() {
    const dataToSend: any = {};
    dataToSend['username'] = this.forgotEmail;
    let validateEmail = this.homeService.ValidateEmail(dataToSend.username);
    if (validateEmail) {
      this.signService.forgotPassword(dataToSend).subscribe(
        (response: any) => {
          this.sharedService.updateLoader(false);
          this.displayBasic = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Password reset link successfully send to your mail`,
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.message,
          });
          this.sharedService.updateLoader(false);
        }
      );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid email',
      });
      this.sharedService.updateLoader(false);
    }
    this.sharedService.updateLoader(false);
  }

  addCardDetails(result: any) {
    const data = JSON.parse(JSON.stringify(result));
    let tokenID = data.token.id;
    data['card'] = data.token;
    data['card']['addressCity'] = this.paymentForm.city
      ? this.paymentForm.city
      : null;
    data['card']['addressCountry'] = this.paymentForm.country
      ? this.paymentForm.country
      : null;
    data['card']['addressLine1'] = this.paymentForm.addressLine1
      ? this.paymentForm.addressLine1
      : null;
    data['card']['addressLine1Check'] = data.token.card.address_line1_check
      ? data.token.card.address_line1_check
      : null;
    data['card']['addressLine2'] = this.paymentForm.addressLine2
      ? this.paymentForm.addressLine2
      : null;
    data['card']['addressState'] = this.paymentForm.state
      ? this.paymentForm.state
      : null;
    data['card']['addressZip'] = this.paymentForm.zipcode
      ? this.paymentForm.zipcode
      : null;
    data['card']['addressZipCheck'] = data.token.card.address_zip_check
      ? data.token.card.address_zip_check
      : null;
    data['card']['autoPayment'] = true;
    data['card']['brand'] = data.token.card.brand
      ? data.token.card.brand
      : null;
    data['card']['country'] = this.paymentForm.country
      ? this.paymentForm.country
      : null;
    data['card']['cvcCheck'] = data.token.card.cvc_check
      ? data.token.card.cvc_check
      : null;
    data['card']['dynamicLast4'] = data.token.card.dynamic_last4
      ? data.token.card.dynamic_last4
      : null;
    data['card']['expMonth'] = data.token.card.exp_month
      ? data.token.card.exp_month
      : null;
    data['card']['expYear'] = data.token.card.exp_year
      ? data.token.card.exp_year
      : null;
    data['card']['funding'] = data.token.card.funding
      ? data.token.card.funding
      : null;
    data['card']['id'] = data.token.card.id ? data.token.card.id : null;
    data['card']['last4'] = data.token.card.last4
      ? data.token.card.last4
      : null;
    data['card']['name'] = data.token.card.name ? data.token.card.name : null;
    data['card']['object'] = data.token.card.object
      ? data.token.card.object
      : null;
    data['card']['selectForPayment'] = true;
    data['card']['tokenId'] = tokenID ? tokenID : null;
    data['card']['tokenizationMethod'] = data.token.card.tokenization_method
      ? data.token.card.tokenization_method
      : null;
    delete data['token'];
    delete data['card']['card'];
    delete data['card']['client_ip'];
    delete data['card']['created'];
    delete data['card']['type'];
    delete data['card']['used'];
    delete data['card']['livemode'];

    this.homeService.addCard(data).subscribe(
      (response: any) => {
        this.loginUserDetail = '';
        this.loginUserDetail = localStorage.getItem('loggedInUser');
        this.userId = this.loginUserDetail
          ? JSON.parse(this.loginUserDetail).userDetails.id
          : '';
        if (this.buyItem === 'true') {
          this.makePayment('onetime');
        } else {
          if (this.loginUserDetail) {
            if (!this.vedioId) {
              if (!this.isPlanChangeRequest) {
                this.planId = this.loginUserDetail
                  ? JSON.parse(this.loginUserDetail).userDetails
                      .userSubscriptionPlanId
                  : '';
              }
              this.subscribePlanforUser(this.planId);
            } else if (this.vedioId) {
              this.subscribePlanforUser(this.planId);
            }
          } else {
            this.subscribePlanforUser(this.planId);
          }
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Card added successfully`,
          });
        }
        // if(this.vedioId){
        //  setTimeout(() => {
        //   this.router.navigate(['video'], { queryParams: { id: this.vedioId } });
        //  }, 1000);
        // }
        // else{
        //   this.router.navigate(['/vod']);
        // }
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.',
        });
        this.sharedService.updateLoader(false);
        this.isContinueDisabled = false;
      }
    );
  }

  subscribePlanforUser(planId: any) {
    this.homeService
      .updateUserSubscription(planId)
      .subscribe((response: any) => {
        if (response.body.data.isIOSUser) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'You are not permitted to change the subscription plan.',
          });
          return;
        }
        this.invoiceId = response.body.data && response.body.data.invoiceId;
        this.loginUserDetail = localStorage.getItem('loggedInUser')
          ? JSON.parse(this.loginUserDetail)
          : '';
        this.loginUserDetail.userDetails.userSubscriptionPlanId =
          Number(planId);
        localStorage.setItem(
          'loggedInUser',
          JSON.stringify(this.loginUserDetail)
        );
        if (response.body.data.paymentStatus === 'PAID') {
          this.loginUserDetail = localStorage.getItem('loggedInUser');
          this.loginUserDetail = localStorage.getItem('loggedInUser')
            ? JSON.parse(this.loginUserDetail)
            : '';
          this.loginUserDetail.userDetails.isPaymentDone = true;
          localStorage.setItem(
            'loggedInUser',
            JSON.stringify(this.loginUserDetail)
          );
          this.isContinueDisabled = false;
          if (this.vedioId) {
            setTimeout(() => {
              if (this.isEbook) {
                this.router.navigate([`/ebook/${this.vedioId}`]);
              }
              else if(this.isChannel){
                this.router.navigate([`channel`])
              } 
              else if(this.isNetwork){
                if(this.m3uType){
                  this.router.navigate([`network`], {queryParams: {videoId: this.vedioId,m3uType:this.m3uType}})
                }
                else{
                  this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
                }
              } 
              else {
                this.router.navigate(['video/' + this.vedioId]);
              }
            }, 500);
          } else {
            if (this.isEbook) {
              this.router.navigate([`/ebook-list`]);
            } 
            else if(this.isChannel){
              this.router.navigate([`channel`])
            }
            else if(this.isNetwork){
              if(this.m3uType){
                this.router.navigate([`network`], {queryParams: {videoId: this.vedioId, m3uType:this.m3uType}})
              }
              else{
                this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
              }
            }
            else {
              this.router.navigate(['/vod']);
            }
          }
        } else {
          this.makePayment('');
        }
      });
  }

  pay() {
    if (this.buyItem === 'true') {
      this.makePayment('onetime');
    } else {
      this.makePayment('');
    }
  }

  makePayment(type: any) {
    this.isContinueDisabled = true;
    this.loginUserDetail = localStorage.getItem('loggedInUser');
    let userEmail = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.email
      : '';
    let newdata = {};
    if (type === 'onetime') {
      newdata = {
        card: {
          tokenId: this.cardData ? this.cardData.tokenId : this.stripeTokenId,
        },
        frequency: 'ONETIME',
        email: userEmail,
        amount: this.ItemPrice,
        isGiving: false,
        invoiceId: null,
        subdomain: this.subDomainName ? this.subDomainName : null,
        mediaItemId: this.vedioId,
        isOneTimePayment: true,
      };
    } else {
      newdata = {
        card: { tokenId: this.stripeTokenId },
        email: userEmail,
        amount: Number(this.subPlanAmount),
        isGiving: false,
        invoiceId: this.invoiceId ? this.invoiceId : null,
        subdomain: this.subDomainName ? this.subDomainName : null,
      };
    }

    this.homeService.makePayment(newdata).subscribe(
      (response: any) => {
        if (response.body.data.paymentStatus === 'Payment Failed') {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: response.body.data.reason,
          });
          this.isContinueDisabled = false;
          if (type !== 'onetime') {
            this.loginUserDetail = JSON.parse(this.loginUserDetail);
            this.loginUserDetail.userDetails.isPaymentDone = false;
            localStorage.setItem(
              'loggedInUser',
              JSON.stringify(this.loginUserDetail)
            );
          }
          this.sharedService.updateLoader(false);
        } else if (response.body.data.paymentStatus === 'Payment Succeed') {
          if (type !== 'onetime') {
            this.loginUserDetail = localStorage.getItem('loggedInUser')
              ? JSON.parse(this.loginUserDetail)
              : '';
            this.loginUserDetail.userDetails.isPaymentDone = true;
            localStorage.setItem(
              'loggedInUser',
              JSON.stringify(this.loginUserDetail)
            );
          }
          this.isContinueDisabled = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Payment Done successfully`,
          });
          if (this.vedioId) {
            setTimeout(() => {
              if (this.isEbook) {
                this.router.navigate([`/ebook/${this.vedioId}`]);
              }
              else if(this.isChannel){
                this.router.navigate([`channel`])
              }
              else if(this.isNetwork){
                if(this.m3uType){
                  this.router.navigate([`network`], {queryParams: {videoId: this.vedioId,m3uType:this.m3uType}})
                }
                else{
                  this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
                }
              }
               else {
                this.router.navigate(['video/' + this.vedioId]);
              }
            }, 500);
          } else {
            if (this.isEbook) {
              this.router.navigate([`/ebook-list`]);
            }
            else if(this.isChannel){
              this.router.navigate([`channel`])
            }
            else if(this.isNetwork){
              if(this.m3uType){
                this.router.navigate([`network`], {queryParams: {videoId: this.vedioId, m3uType:this.m3uType}})
              }
              else{
                this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
              }
            }
            else {
              this.router.navigate(['/vod']);
            }
          }
        }
        // this.getCardDetailsList();
        // this.showAddCard = false
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.',
        });
        this.sharedService.updateLoader(false);
        this.isContinueDisabled = false;
      }
    );
  }
  validateUserPayment(): void {
    if (!this.loginUserDetail) {
      return;
    }
    if (this.vedioId) {
      this.sharedService.updateLoader(true);
      this.homeService
        .validateUserPayment(this.vedioId)
        .then((data) => {
          this.sharedService.updateLoader(false);
          if (data && data.data) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `You already paid for this ${
                this.isEbook ? 'Ebook' :  this.isChannel ? 'Channel' : this.isNetwork ? 'Network' :  'video'
              }.`,
            });
            if (this.isEbook) {
              this.router.navigate([`/ebook/${this.vedioId}`]);
            }
            else if(this.isChannel){
              this.router.navigate([`channel`])
            } 
            else if(this.isNetwork){
              if(this.m3uType){
                this.router.navigate([`network`], {queryParams: {videoId: this.vedioId,m3uType:this.m3uType}})
              }
              else{
                this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
              }
            }
            else {
              this.router.navigate([`/video/${this.vedioId}`]);
            }
          }
        })
        .catch((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error during user payment validation.',
          });
          this.sharedService.updateLoader(false);
        });
    }
  }
  getSecretApiKey() {
    if (!this.isPlanChange) {
      this.validateUserPayment();
      this.isPlanChange = false;
    }
    this.homeService.getSecretKey().subscribe((response: any) => {
      if (response.data.stripeApiKey) {
        this.stripeService.setKey(response.data.stripeApiKey);
        setTimeout(() => {
          this.showBilling = true;
        }, 2000);
        this.sharedService.updateLoader(false);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Something went wrong, Please try after sometime or raise a query from contact us page',
        });
        this.sharedService.updateLoader(false);
      }
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
          this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId
          ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId
          : '';
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        if (this.isNoPlanSelected) {
          this.addAlpha(this.appBGColor, this.blurOpacity);
        } else {
          this.addAlpha(this.primaryColor, 0.25);
        }
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
            response.data.pageCustomizationDTO.faviconId
          : '';
        this.loginImg = response.data.mainBannerDTO.backgroundImageId
          ? `${appConfig.imageURL}` +
            response.data.mainBannerDTO.backgroundImageId +
            '?height=630&width=1120'
          : '';
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.ngxFavicon.setFavicon(this.favIcon);
      }
    });
  }

  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this.isWhiteLabel = response.data ? response.data.organizationDTO.isWhiteLabel: false
          this.supportUrl = response.data ? response.data.organizationDTO.supportUrl : null;
          this.termsOfUse = response.data ? response.data.organizationDTO.termsOfUse : null;
          this.privacyPolicy = response.data ? response.data.organizationDTO.privacyPolicy : null;
        }
      });
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {

  //     this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //     this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId : ''
  //     this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //     this.addAlpha(this.primaryColor, 0.25)
  //     this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //     this.ngxFavicon.setFavicon(this.favIcon);
  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);
  
    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }
  
    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;
  
    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    
    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');
  
    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if(color.includes('#')){
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else{
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  getSubscriptionPlanById(id: any) {
    this.homeService.subscriptionPlanById(id).subscribe(
      (response: any) => {
        this.subscriptionPlanData = response.data;
        this.subPlanImg = response.data.documentId
          ? `${appConfig.imageURL}` +
            response.data.documentId +
            '?height=360&width=640'
          : '';
        this.subPlanDuration = response.data.subscriptionPlanDuration
          ? response.data.subscriptionPlanDuration
          : '';
        this.planDuration = response.data.name ? response.data.name : '';
        this.planDurationName = response.data.planDurationName
          ? response.data.planDurationName
          : '';
        this.subPlanAmount = response.data.price ? response.data.price : '';
        this.subPlanDesc = response.data.description
          ? response.data.description
          : '';
        this.subscriptionPlanIds = response.data.id;
        this.rightWrapperColor = response.data.document.imageColur;
        if (this.subPlanDuration === 'DAILY') {
          // this.planDuration = 'Daily'
          this.planDurationName = 'Day';
        } else if (this.subPlanDuration === 'WEEKLY') {
          // this.planDuration = 'Weekly'
          this.planDurationName = 'Week';
        } else if (this.subPlanDuration === 'MONTHLY') {
          // this.planDuration = 'Monthly'
          this.planDurationName = 'Month';
        } else if (this.subPlanDuration === 'YEARLY') {
          // this.planDuration = 'Annual'
          this.planDurationName = 'year';
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  getAllCountryList() {
    this.homeService.getAllCountryList().subscribe(
      (response: any) => {
        this.countryList = response.data.countryList;
        // this.stateList = response.data.countryList[0]
        //   ? response.data.countryList[0].states
        //   : [];
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }
  updateStateList(event: any) {
    const selectedCountryList = this.countryList.filter(
      (value) => value.code2 === event.target.value
    );
    this.stateList = []
    this.paymentForm.state = ''
    if (selectedCountryList.length && selectedCountryList[0].states.length) {
      this.stateList = selectedCountryList[0].states;
    }
  }

  updateCityList(event: any) {
    const selStateObj = this.stateList.filter(
      (el) => el.code === event.target.value
    );
    if (selStateObj.length && selStateObj[0].cities.length) {
      this.cityDropdown = true;
      this.cityList = selStateObj[0].cities;
      this.signup.mailingAddress.city = this.cityList[0].cityName;
    } else {
      this.cityDropdown = false;
      this.signup.mailingAddress.city = '';
      this.cityList = [];
      this.paymentForm.city = '';
    }
  }
  cancelPayment() {
    this.showBilling = false;
    this.paymentFormSubmitted = false;
    this.paymentForm = {
      name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      zipcode: null,
    };
    this.stateList = [];
  }
  createUser() {
    let validateEmail = this.homeService.ValidateEmail(
      this.signup.basicInfo.email
    );
    const regex = /[a-zA-Z]/;
    if (this.loginUserDetail) {
      this.getSecretApiKey();
    } else {
      if (validateEmail) {
        if (this.signup.basicInfo.mobileNumber.toString().length > 4) {
          if (this.signup.password === this.signup.confirmPassword) {
            const data = JSON.parse(JSON.stringify(this.signup));

            const format1 = 'YYYY-MM-DD HH:mm:ss';
            if (
              !regex.test(data.basicInfo.firstName) ||
              (data.basicInfo.lastName && !regex.test(data.basicInfo.lastName))
            ) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please check firstName and lastName',
              });
              this.sharedService.updateLoader(false);

              return;
            }
            if (!this.isTermAndCondition) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please accept terms and conditions.',
              });
              this.sharedService.updateLoader(false);

              return;
            }
            data.sourceType = 'WEBSITE';

            data.subscriptionPlanId = this.subscriptionPlanIds;
            data.deviceType = 'WEBSITE';

            data.basicInfo.fullName = data.basicInfo.firstName
              ? data.basicInfo.firstName
              : '' + data.basicInfo.lastName
              ? data.basicInfo.lastName
              : '';
            let firstletterUname = data.basicInfo.firstName.charAt(0);
            let lastletterUname = data.basicInfo.lastName
              ? data.basicInfo.lastName.charAt(0)
              : '';
            let fnameLetter = firstletterUname ? firstletterUname : '';
            let lnameLetter = lastletterUname ? lastletterUname : '';
            this.nameLetter = fnameLetter + lnameLetter;

            this.homeService
              .createUser({
                ...data,
                isTermAndCondition: this.isTermAndCondition,
              })
              .subscribe(
                (response: any) => {
                  // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Created successfully' });
                  // this.router.navigate(['/contacts-module/contacts']);
                  // this.sharedService.updateLoader(false);
                  this.isTermAndCondition = false;
                  localStorage.setItem(
                    'loggedInUserName',
                    response.body.data.userDetails.username
                  );
                  localStorage.setItem(
                    'loggedInUser',
                    JSON.stringify(response.body.data)
                  );
                  this.loginUserDetail = localStorage.getItem('loggedInUser');
                  this.userMail = this.loginUserDetail
                    ? JSON.parse(this.loginUserDetail).userDetails.email
                    : '';
                  this.userFirstName = this.loginUserDetail
                    ? JSON.parse(this.loginUserDetail).userDetails.firstName
                    : '';
                  this.userLastName = this.loginUserDetail
                    ? JSON.parse(this.loginUserDetail).userDetails.lastName
                    : '';
                  this.userName = `${this.userFirstName} ${
                    this.userLastName ? this.userLastName : ''
                  }`;
                  this.subDomainName = this.loginUserDetail
                    ? JSON.parse(this.loginUserDetail).userDetails.organization
                        .subdomain
                    : null;
                  this.invoiceId = this.loginUserDetail
                    ? JSON.parse(this.loginUserDetail).userDetails.invoiceId
                    : '';
                  if (this.isNoPlanSelected) {
                    this.router.navigate(['/vod']);
                  }
                  if (this.clickFrom === 'programsignInRequire') {
                    if (this.isEbook) {
                      this.router.navigate([`/ebook/${this.vedioId}`]);
                    } 
                    else if(this.isChannel){
                      this.router.navigate([`channel`])
                    } 
                    else if(this.isNetwork){
                      if(this.m3uType){
                        this.router.navigate([`network`], {queryParams: {videoId: this.vedioId,m3uType:this.m3uType}})
                      }
                      else{
                        this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
                      }
                    }
                    else {
                      this.router.navigate(['video/' + this.vedioId]);
                    }
                  } else {
                    if (!this.isNoPlanSelected) {
                      this.getSecretApiKey();
                    }
                  }
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'User created successfully',
                  });
                  this.sharedService.updateLoader(false);
                },
                (error) => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.error
                      ? error.error.message
                      : 'Something went wrong.',
                  });
                  this.sharedService.updateLoader(false);
                }
              );
            this.sharedService.updateLoader(false);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Confirm password is not matched',
            });
            this.sharedService.updateLoader(false);
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Mobile number must be greater than 5',
          });
          this.sharedService.updateLoader(false);
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Email is not valid',
        });
        this.sharedService.updateLoader(false);
      }
    }
  }

  showBasicDialog() {
    this.displayBasic = true;
    this.forgotEmail = null;
  }

  loginUser() {
    // var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // var courseCode = this.login.username;
    // if(this.login.username !== undefined && this.login.username){
    //   if (courseCode.match(regex)) {
    let validateEmail = this.homeService.ValidateEmail(this.login.username);
    if (validateEmail) {
      this.signService.loginUser(this.login).subscribe(
        (response: any) => {
          localStorage.setItem(
            'loggedInUserName',
            response.body.data.userDetails.username
          );
          localStorage.setItem(
            'loggedInUser',
            JSON.stringify(response.body.data)
          );
          this.loginUserDetail = localStorage.getItem('loggedInUser');
          // this.router.navigate(['/vod']);
          // this.showBilling = true;
          this.showlogin = false;
          this.userMail = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.email
            : '';
          this.userFirstName = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.firstName
            : '';
          this.userLastName = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.lastName
            : '';
          this.userName = `${this.userFirstName} ${
            this.userLastName ? this.userLastName : ''
          }`;
          this.paymentDone = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
            : '';
          this.subDomainName = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.organization
                .subdomain
            : null;
          this.invoiceId = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.invoiceId
            : '';
          // if (this.vedioId) {
          //   if (this.isEbook) {
          //     this.router.navigate([`/ebook/${this.vedioId}`]);
          //   } else {
          //     this.router.navigate(['video/' + this.vedioId]);
          //   }
          // } else {
          // if (this.paymentDone) {
          //   this.router.navigate(['/vod']);
          // } else {
          if (!this.vedioId) {
            this.router.navigate(['/vod']);
          } else {
            setTimeout(() => {
              this.getSecretApiKey();
              // this.showBilling = true
            }, 1000);
          }
          // }
          // }
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.message,
          });
          this.sharedService.updateLoader(false);
        }
      );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Enter a Valid Email',
      });
      this.sharedService.updateLoader(false);
    }
  }
  //   else{
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter a Valid Email' });
  //   }
  // }else{
  //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Enter an Email' });
  // }
  // }

  goBackFromForm() {
    if (this.clickFrom === 'programsignInRequire') {
      if (this.isEbook) {
        this.router.navigate([`/ebook/${this.vedioId}`]);
      } 
      else if(this.isChannel){
        this.router.navigate([`channel`])
      } 
      else if(this.isNetwork){
        if(this.m3uType){
          this.router.navigate([`network`], {queryParams: {videoId: this.vedioId,m3uType:this.m3uType}})
        }
        else{
          this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
        }
      }
      else {
        this.router.navigate(['video/' + this.vedioId]);
      }
    } else if (this.buyItem === 'true') {
      if (this.isEbook) {
        this.router.navigate([`/ebook/${this.vedioId}`]);
      } 
      else if(this.isChannel){
        this.router.navigate([`channel`])
      } 
      else if(this.isNetwork){
        if(this.m3uType){
          this.router.navigate([`network`], {queryParams: {videoId: this.vedioId, m3uType:this.m3uType}})
        }
        else{
          this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
        }
      }
      else {
        this.router.navigate(['video/' + this.vedioId]);
      }
    } else if (this.isNoPlanSelected) {
      window.history.back();
    } else {
      this.showBilling = false;
      this.showlogin = false;
      this.showOffers = true;
    }
  }

  subscriptionPlan() {
    this.homeService.subscriptionPlanList().subscribe(
      (response: any) => {
        this.subscriptionPlanList = response.data;
        if (this.loginUserDetail) {
          this.disableContinueBtn = true;
        }
        this.subscriptionPlanList.forEach((el: any) => {
          if (el.documentId) {
            var width = 320;
            var height = 180;
            let id = el.documentId;
            el['planImg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.subscriptionPlanDuration === 'DAILY') {
            // el['planDuration'] = 'Daily'
            el['planDurationName'] = 'day';
          } else if (el.subscriptionPlanDuration === 'WEEKLY') {
            // el['planDuration'] = 'Weekly'
            el['planDurationName'] = 'week';
          } else if (el.subscriptionPlanDuration === 'MONTHLY') {
            // el['planDuration'] = 'Monthly'
            el['planDurationName'] = 'month';
          } else if (el.subscriptionPlanDuration === 'YEARLY') {
            // el['planDuration'] = 'Annual'
            el['planDurationName'] = 'year';
          }
          this.subscriptionPlanList.forEach((el) => {
            if (el.id == this.planId) {
              // console.log(el.id, this.planId);
              el.selectedImage = true;
              this.planSelected = true;
              this.planId = el.id;
              this.prevSelectedPlanID = el.id;
              if (!this.paymentDone) {
                this.disableContinueBtn = false;
              }
            }
          });

          this.subscriptionPlanIdForVedio.forEach((data) => {
            this.subscriptionPlanList.forEach((el) => {
              if (el.id == data) {
                el['disable'] = true;
              }
              if(el.id == this.planId && data == this.planId){
                if (el.id != data) {
                  this.disableContinueBtn = true;
                }
              }
            });
          });

          if (this.clickFrom == 'billing') {
            this.subscriptionPlanList.forEach((el) => {
              el['disable'] = true;
            });
          }
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  selectPlan(item: any) {
    // this.subscriptionPlanList.forEach((el) => el.selectedImage = false);

    if (
      this.loginUserDetail &&
      JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId !==
        item.id
    ) {
      this.disableContinueBtn = false;
    }
    this.subscriptionPlanList.forEach((el, i) => {
      if (el.id === item.id) {
        el.selectedImage = true;
        this.planSelected = true;
        this.planId = el.id;
        this.getSubscriptionPlanById(this.planId);
        this.isPlanChangeRequest = true;
        if (!this.vedioId) {
          this.displayPlan = true;
        }
        if (this.vedioId && this.showOfferPage && this.loginUserDetail) {
          this.displayPlan = true;
        } else if (
          this.vedioId &&
          this.showOfferPage &&
          !this.loginUserDetail
        ) {
          this.subscriptionPlanIds = this.planId;
          this.displayPlan = false;
        }
        // this.selectedPlanData = [];
        // this.selectedPlanData.push(el);
      } else {
        el.selectedImage = false;
      }
    });
  }

  cancelPlan() {
    this.displayPlan = false;
    this.subscriptionPlanList.forEach((el) => {
      el.selectedImage = false;
    });
    this.subscriptionPlanList.forEach((el) => {
      if (el.id === this.prevSelectedPlanID) {
        el.selectedImage = true;
        this.planSelected = true;
        this.planId = el.id;
      }
    });
  }

  changePlan() {
    this.userId = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.id
      : '';
    if (this.showaddedCard) {
      this.homeService.updateUserSubscription(this.planId).subscribe(
        (response: any) => {
          this.displayPlan = false;
          this.showBilling = false;
          this.showlogin = false;
          this.showOffers = false;
          this.isPlanChange = true;
          // this.getSubscriptionPlanById(this.planId)
          if (response.body.data.isIOSUser) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'You are not permitted to change the subscription plan.',
            });
            return;
          }
          this.loginUserDetail = localStorage.getItem('loggedInUser')
            ? JSON.parse(this.loginUserDetail)
            : '';
          this.loginUserDetail.userDetails.userSubscriptionPlanId = Number(
            this.planId
          );
          this.loginUserDetail.userDetails.invoiceId =
            response.body.data.invoiceId;
          this.invoiceId = response.body.data.invoiceId;
          if (response.body.data.paymentStatus === 'PAID') {
            this.loginUserDetail.userDetails.isPaymentDone = true;
          }
          localStorage.setItem(
            'loggedInUser',
            JSON.stringify(this.loginUserDetail)
          );
          if (response.body.data.paymentStatus === 'PAID') {
            this.loginUserDetail.userDetails.isPaymentDone = true;
            this.isPaymentDone = true;
          } else {
            this.isPaymentDone = this.loginUserDetail.userDetails.isPaymentDone;
          }
          if (this.clickFrom === 'billing') {
            this.router.navigate(['/Dashboard/menu/billing']);
          } else if (this.clickFrom === 'program' || this.clickFrom === 'chanel' || this.clickFrom === 'net' || this.clickFrom === 'customNet') {
            if (this.isPaymentDone) {
              if (this.isEbook) {
                this.router.navigate([`/ebook/${this.vedioId}`]);
              } 
              else if(this.isChannel){
                this.router.navigate([`channel`])
              } 
              else if(this.isNetwork){
                if(this.m3uType){
                  this.router.navigate([`network`], {queryParams: {videoId: this.vedioId,m3uType:this.m3uType}})
                }
                else{
                  this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
                }
              }
              else {
                this.router.navigate(['video/' + this.vedioId]);
              }
            } else {
              this.createUser();
            }
          }
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Plan changed successfully',
          });
          if (response.body.data.paymentStatus !== 'PAID') {
            this.makePayment('');
          }
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.message,
          });
          this.sharedService.updateLoader(false);
        }
      );
    } else {
      this.showBilling = false;
      this.showlogin = false;
      this.showOffers = false;
      this.displayPlan = false;
    }
  }

  // chooseOffer(){
  //   this.vedioId = false
  //     this.selectedPlanData.forEach(el=>{
  //       this.subPlanImg = el.documentId ? `${appConfig.imageURL}` + el.documentId + "?height=540&width=960" : ''
  //       this.subPlanDuration = el.subscriptionPlanDuration ? el.subscriptionPlanDuration : '';
  //       this.planDuration = el.planDuration ? el.planDuration : '';
  //       this.planDurationName = el.planDurationName ? el.planDurationName : '';
  //       this.subPlanAmount = el.price ? el.price : '';
  //       this.subPlanDesc = el.description ? el.description : '';
  //     })
  // }

  gotoView() {
    if (this.clickFrom === 'billing') {
      this.router.navigate(['/Dashboard/menu/billing']);
    } else if (this.isEbook) {
      this.router.navigate([`/ebook/${this.vedioId}`]);
    } 
    else if(this.isChannel){
      this.router.navigate([`channel`])
    } 
    else if(this.isNetwork){
      if(this.m3uType){
        this.router.navigate([`network`], {queryParams: {videoId: this.vedioId, m3uType:this.m3uType}})
      }
      else{
        this.router.navigate([`network`], {queryParams: {videoId: this.vedioId}})
      }
    }
    else {
      this.router.navigate(['video/' + this.vedioId]);
    }
  }

  cancelOnCustomerInfo() {
    if (this.clickFrom === 'billing') {
      this.router.navigate(['/Dashboard/menu/billing']);
    } else if (this.isNoPlanSelected && !this.redirection) {
      window.history.back();
    } else if (this.isNoPlanSelected && this.redirection === 'JOIN') {
      this.router.navigate(['/join']);
    } else {
      if(this.isLoggedIn){
        this.router.navigate(['/vod'])
      }
      else{
        this.router.navigate(['/join']);
      }
    }
  }

  GetMediaItemWithLogin(id: any) {
    this.homeService.getMediaDataByIdWith(id).subscribe(
      (response: any) => {
        this.currentProgramData = response.data.currentMediaItem;
        if (response.data.currentMediaItem) {
          if (response.data.currentMediaItem.wideArtworkId) {
            var width = 640;
            var height = 360;
            let id = response.data.currentMediaItem.wideArtworkId;
            response.data.currentMediaItem['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
        }
        this.currentVedioTitle = response.data.currentMediaItem.title;
        this.currentVedioDesc = response.data.currentMediaItem.subTitle;
        this.currentVedioImg = response.data.currentMediaItem.newimg;
        this.subscriptionPlanIdForVedio =
          response.data.currentMediaItem.subscriptionPlanIds;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  GetMediaItemWithoutLogin(id: any) {
    this.homeService.getMediaDataByIdWithout(id).subscribe(
      (response: any) => {
        this.currentProgramData = response.data.currentMediaItem;
        if (response.data.currentMediaItem) {
          if (response.data.currentMediaItem.wideArtworkId) {
            var width = 640;
            var height = 360;
            let id = response.data.currentMediaItem.wideArtworkId;
            response.data.currentMediaItem['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
        }
        this.currentVedioTitle = response.data.currentMediaItem.title;
        this.currentVedioDesc = response.data.currentMediaItem.subTitle;
        this.currentVedioImg = response.data.currentMediaItem.newimg;
        this.subscriptionPlanIdForVedio =
          response.data.currentMediaItem.subscriptionPlanIds;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  getEbookMediaItemWithLogin(id: any) {
    this.homeService.getEbookMediaDataByIdWith(id).subscribe(
      (response: any) => {
        this.currentProgramData = response.data.currentMediaItem;
        if (response.data.currentMediaItem) {
          if (response.data.currentMediaItem.ebookArtworkId) {
            var width = 640;
            var height = 360;
            let id = response.data.currentMediaItem.ebookArtworkId;
            response.data.currentMediaItem['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
        }
        this.currentVedioTitle = response.data.currentMediaItem.title;
        this.currentVedioDesc = response.data.currentMediaItem.subTitle;
        this.currentVedioImg = response.data.currentMediaItem.newimg;
        this.subscriptionPlanIdForVedio =
          response.data.currentMediaItem.subscriptionPlanIds;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }

  getEbookMediaItemWithoutLogin(id: any) {
    this.homeService.getEbookMediaDataByIdWithout(id).subscribe(
      (response: any) => {
        this.currentProgramData = response.data.currentMediaItem;
        if (response.data.currentMediaItem) {
          if (response.data.currentMediaItem.ebookArtworkId) {
            var width = 640;
            var height = 360;
            let id = response.data.currentMediaItem.ebookArtworkId;
            response.data.currentMediaItem['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
        }
        this.currentVedioTitle = response.data.currentMediaItem.title;
        this.currentVedioDesc = response.data.currentMediaItem.subTitle;
        this.currentVedioImg = response.data.currentMediaItem.newimg;
        this.subscriptionPlanIdForVedio =
          response.data.currentMediaItem.subscriptionPlanIds;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this.sharedService.updateLoader(false);
      }
    );
  }
}
