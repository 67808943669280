import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-programs-m3u',
  templateUrl: './programs-m3u.component.html',
  styleUrls: ['./programs-m3u.component.scss']
})
export class ProgramsM3uComponent implements OnInit {
  autoPlayChange = true
  videoJsPlayerUrl: string
  videoJsPlayerType: string
  orderObj: any
  sharedData: any
  dataFromParams: any
  itemName:string
  m3uType: any
  permissionAllowModal = false
  mediaItemId: any;
  channelName: any;
  m3uItemData: any;
  showSeriesName: any;
  isFav: any;
  selectedM3uCategory: any;
  logoHeight: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.getLogoDimention()
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
    this.m3uType = this.route.snapshot.queryParams.channelType
    this.mediaItemId = this.route.snapshot.queryParams.mediaItemId
    this.channelName = this.route.snapshot.queryParams.channelName
    this.showSeriesName = this.route.snapshot.queryParams.showSeriesName
    this.selectedM3uCategory = this.route.snapshot.queryParams.selectedM3uCategory
    this.isFav = this.route.snapshot.queryParams.isFav
    this.route.data.subscribe(data => {
      const response = data.data;
      this.getResolverData(response);
    });
    // const storedData = localStorage.getItem('m3uData');
    // this.sharedData = JSON.parse(storedData?storedData:'')
    // this.orderObj = JSON.parse(this.sharedData.allData)
  }

  addLiveAfterDomain(url: any) {
    var domainPattern = /\/\/([^:/]+(:\d+)?)(\/|$)/;
    var domainMatch = url.match(domainPattern);
    if (domainMatch) {
        var domain = domainMatch[1];
        var modifiedURL = url.replace(domain, domain + '/live');
        return modifiedURL + '.m3u8';
    }
    return url;
  }
  
  gotToView(){
    // let data = this.orderObj
    // const senddata = {allData: JSON.stringify(data)}
    // localStorage.setItem('m3uData', JSON.stringify(senddata));
    this.router.navigate(['/m3u-detail'], {queryParams: {channelType: this.m3uType,mediaItemId: this.mediaItemId,channelName: this.channelName,isFav: this.isFav,selectedM3uCategory: this.selectedM3uCategory}})
    // query params for filter
    // {queryParams: {filterData: JSON.stringify({selectedM3uCategoryName: this.dataFromParams.selectedM3uCategoryName,inputSearch:this.dataFromParams.searchInputData,showViewAll: this.dataFromParams.showViewAll,selectedChannelId : this.dataFromParams.selectedChannelId,viewAllSelected: this.dataFromParams.viewAllSelected})}}
  }

  getResolverData(response:any){
      this.videoJsPlayerUrl = ''
      this.videoJsPlayerType = ''
      this.itemName = ''
      this.m3uItemData = response.data
      if (this.m3uItemData.playingUrl) {
        this.itemName = this.m3uItemData.name
        let size = this.m3uItemData.playingUrl?.split(".").length;
        let ext = this.m3uItemData.playingUrl?.split(".")[size - 1];
        let urlHasExt = false
        if (/(?:\/|^)[^.\/]+$/.test(this.m3uItemData.playingUrl)) {
            // Successful match
            urlHasExt = true
        } else {
          urlHasExt = false
            // Match attempt failed
        }
        if(!urlHasExt){
          if (ext === "m3u8" || this.m3uItemData.playingUrl.includes('.m3u8')) {
            this.videoJsPlayerType = 'application/x-mpegURL';
            this.videoJsPlayerUrl = this.m3uItemData.playingUrl;
          } 
          else {
            this.videoJsPlayerUrl = this.m3uItemData.playingUrl;
            this.videoJsPlayerType = `video/mp4`;
          }
        }
        else{
          let modifiedURL = this.addLiveAfterDomain(this.m3uItemData.playingUrl)
          this.videoJsPlayerType = 'application/x-mpegURL';
          this.videoJsPlayerUrl = modifiedURL;
        }
        this.autoPlayChange = true
      }
    
  }
  getLogoDimention(){
    this.homeService.dimensions$.subscribe((dimensions) => {
      this.logoHeight = dimensions.height
    });
  }
}
