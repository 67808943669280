import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { PageCustomiseDataModel } from 'src/app/Models/common.model';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
})
export class MobileAppComponent implements OnInit {
  pageCustomeData: PageCustomiseDataModel;
  web: any;
  logoSize: any;
  appBtnPrimaryColor: any;
  headerLinkColor: any;
  headerLogo: any;
  mainPageName: any;
  primaryColor: any;
  appBGColor: any;
  vodPageName: any;
  contactName: any;
  showHideEbook: boolean;
  ebookPage: string;
  favIcon: any;
  showMenu = false;
  signoutModal = false;
  isLoggedIn: any;
  mainBGBlur: any;
  leftGradiant: any;
  bottomGradiant: any;
  footerBlur: any;
  blurOpacity: any;
  changeToRGB: any;
  footerblurOpacity: any;
  footerchangeToRGB: any;

  footerDetail: any;
  footerBgImage: any;
  facebookLink: any;
  twitterLink: any;
  instagramLink: any;
  youtubeLink: any;
  showfooter = false;

  storeCards: any[] = [];
  allPlatFormImage: any;
  allPlatFormImagePhone: any;

  scrHeight: any;
  scrWidth: any;
  appicon = 'assets/logos/appstore_Assets/asset3.png';
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  androidImage: string;
  iphoneImage: string;
  amazonTabImage: string;
  androidTVImage: string;
  bannerInfo: {
    shortAppTitle: string;
    appIconId: number;
    fullIconPath: string;
  };
  domain: string;
  isWhiteLabel: boolean;
  constructor(
    public homeService: HomeService,
    public router: Router,
    public messageService: MessageService,
    private elementRef: ElementRef,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private ngxFavicon: AngularFaviconService,
    private _SessionService: SessionService,
    private loginService: LoginService,
    private _Title: Title,
    private _Meta: Meta,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.pageCustomeData = new PageCustomiseDataModel();
    this.storeCards = [
      {
        name: 'Android App On',
        deviceImg: 'assets/device/Android-Mobile-Mockup.png',
        deviceImgType: 'androidMobile',
        bgColor: '#870404',
        bgcardImg: 'assets/logos/appstore_Assets/asset6.png',
        appStoreImg: 'assets/logos/svg/googlepay_get_it_on.svg',
      },
      {
        name: 'Apple App on',
        deviceImg: 'assets/device/iPhone-12.png',
        deviceImgType: 'appleMobile',
        bgColor: '#480548',
        bgcardImg: 'assets/logos/appstore_Assets/asset15.png',
        appStoreImg: 'assets/logos/svg/appstore.svg',
      },
      {
        name: 'Amazon App On',
        deviceImg: 'assets/device/iPad.png',
        deviceImgType: 'tab',
        bgColor: '#032003',
        bgcardImg: 'assets/logos/appstore_Assets/asset10.png',
        appStoreImg: 'assets/logos/svg/amazon_only.svg',
      },
      {
        name: 'Roku Channel',
        deviceImg: 'assets/device/4K-TV-MOCKUP.png',
        deviceImgType: 'tv',
        bgColor: '#480808',
        bgcardImg: 'assets/logos/appstore_Assets/asset21.png',
        appStoreImg: 'assets/logos/svg/roku.svg',
      },
      {
        name: 'Apple TV',
        deviceImg: 'assets/device/4K-TV-MOCKUP.png',
        deviceImgType: 'tv',
        bgColor: '#03566e',
        bgcardImg: 'assets/logos/appstore_Assets/asset18.png',
        appStoreImg: 'assets/logos/svg/appletv.svg',
      },
      {
        name: 'Google TV',
        deviceImg: 'assets/device/4K-TV-MOCKUP.png',
        deviceImgType: 'tv',
        bgColor: '#03566e',
        bgcardImg: 'assets/logos/appstore_Assets/asset6.png',
        appStoreImg: '',
      },
      {
        name: 'Android TV',
        deviceImg: 'assets/device/4K-TV-MOCKUP.png',
        deviceImgType: 'tv',
        bgColor: '#03566e',
        bgcardImg: 'assets/logos/appstore_Assets/asset15.png',
        appStoreImg: '',
      },
    ];
    this.androidImage = '';
    this.iphoneImage = '';
    this.amazonTabImage = '';
    this.androidTVImage = '';
    this.bannerInfo = {
      shortAppTitle: '',
      appIconId: 0,
      fullIconPath: '',
    };
    this.domain = '';
  }

  ngOnInit(): void {
    let loginData: any;
    loginData = this._SessionService.getSession('loggedInUser');
    this.isLoggedIn = loginData ? true : false;
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this._ActivatedRoute.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.getBranding();
    this.getSubscribe();
  }
  getBranding(): void {
    if (this.isLoggedIn) {
      this.homeService.getMobileAppBrandingWith().subscribe((data) => {
        this.bannerInfo = {
          appIconId: data.data.appIconId,
          shortAppTitle: data.data.shortAppTitle,
          fullIconPath: `${appConfig.imageURL}${data.data.appIconId}`,
        };
      });
    } else {
      this.homeService.getMobileAppBrandingWithout().subscribe((data) => {
        this.bannerInfo = {
          appIconId: data.data.appIconId,
          shortAppTitle: data.data.shortAppTitle,
          fullIconPath: `${appConfig.imageURL}${data.data.appIconId}`,
        };
      });
    }
  }
  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.pageCustomeData = new PageCustomiseDataModel(response.data);
        this.mainPageName = response.data.pageCustomizationDTO.homePage;
        this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
        this.contactName = response.data.pageCustomizationDTO.contactUs;
        this.showHideEbook = response.data.pageCustomizationDTO.showHideEbook;
        this.ebookPage = response.data.pageCustomizationDTO.ebookPage;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.appStorePage}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
              ? `${response.data.pageCustomizationDTO.orgName}-`
              : ''
            }${response.data.pageCustomizationDTO.appStorePage}`,
        });
        this._Meta.updateTag({
          property: 'og:image',
          content: `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`,
        });
        this.footerDetail = response.data.footerDTO;
        this.footerBgImage = response.data.footerDTO.backgroundImageId
          ? `${appConfig.imageURL}` +
          response.data.footerDTO.backgroundImageId +
          '?height=630&width=1120'
          : '';
        this.facebookLink = response.data.footerDTO.facebookLink
          ? response.data.footerDTO.facebookLink
          : '';
        this.twitterLink = response.data.footerDTO.twitterLink
          ? response.data.footerDTO.twitterLink
          : '';
        this.instagramLink = response.data.footerDTO.instagramLink
          ? response.data.footerDTO.instagramLink
          : '';
        this.youtubeLink = response.data.footerDTO.youtubeLink
          ? response.data.footerDTO.youtubeLink
          : '';

        if (this.router.url === '/vod') {
          this.router.navigate(['/vod'], {
            queryParams: { vod: this.vodPageName },
          });
        }
        // webappSetting -----------------
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.footerblurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.footerShadowsFade / 10
          : '0.5';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        this.addAlphaFooter(this.appBGColor, this.footerblurOpacity);
        //  radial-gradient(farthest-corner at 55% 45%, rgba(42, 42, 42, 0.1), rgb(42, 42, 42))
        this.mainBGBlur = this.sanitizer.bypassSecurityTrustStyle(
          `radial-gradient(farthest-corner at 55% 40%,${this.changeToRGB},${this.appBGColor})`
        );
        this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to top, ${this.footerchangeToRGB},${this.appBGColor})`
        );
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );
        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.allPlatFormImage = response.data.allPlatFormsDTO
          .devicesScreenImageId
          ? `${appConfig.imageURL}` +
          response.data.allPlatFormsDTO.devicesScreenImageId +
          '?height=360&width=640'
          : '';
        this.allPlatFormImagePhone = response.data.allPlatFormsDTO
          .devicesScreenImageId
          ? `${appConfig.imageURL}` +
          response.data.allPlatFormsDTO.devicesScreenImageId +
          '?height=270&width=480'
          : '';

        this.storeCards = this.storeCards.map((value) => {
          var posterImage = '';
          switch (value.name) {
            case 'Android App On':
              posterImage = response.data.allPlatFormsDTO.androidLinkDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.androidLinkDocumentId}?height=288&width=162`
                : '';
              this.androidImage = posterImage;
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideAndroid
                  ? response.data.allPlatFormsDTO.showHideAndroid
                  : false,
                isLink: response.data.allPlatFormsDTO.androidLink
                  ? true
                  : false,
              };
              break;
            case 'Apple App on':
              posterImage = response.data.allPlatFormsDTO.appleMobileDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.appleMobileDocumentId}?height=288&width=162`
                : '';
              this.iphoneImage = posterImage;
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideIPhone
                  ? response.data.allPlatFormsDTO.showHideIPhone
                  : false,
                isLink: response.data.allPlatFormsDTO.iphoneLink ? true : false,
              };
              break;
            case 'Amazon App On':
              posterImage = response.data.allPlatFormsDTO.amazonDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.amazonDocumentId}?height=240&width=165`
                : '';
              this.amazonTabImage = posterImage;
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideAmazon
                  ? response.data.allPlatFormsDTO.showHideAmazon
                  : false,
                isLink: response.data.allPlatFormsDTO.amazonLink ? true : false,
              };
              break;
            case 'Roku Channel':
              posterImage = response.data.allPlatFormsDTO.rokuTVDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.rokuTVDocumentId}?height=360&width=640`
                : '';
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideRokuTV
                  ? response.data.allPlatFormsDTO.showHideRokuTV
                  : false,
                isLink: response.data.allPlatFormsDTO.rokuTVLink ? true : false,
              };
              break;
            case 'Apple TV':
              posterImage = response.data.allPlatFormsDTO.appleTVDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.appleTVDocumentId}?height=360&width=640`
                : '';
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideAppleTV
                  ? response.data.allPlatFormsDTO.showHideAppleTV
                  : false,
                isLink: response.data.allPlatFormsDTO.appleTVLink
                  ? true
                  : false,
              };
              break;
            case 'Google TV':
              posterImage = response.data.allPlatFormsDTO.googleTvDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.googleTvDocumentId}?height=360&width=640`
                : '';
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideGoogleTv
                  ? response.data.allPlatFormsDTO.showHideGoogleTv
                  : false,
                isLink: response.data.allPlatFormsDTO.googleTvLink
                  ? true
                  : false,
              };
              break;
            case 'Android TV':
              posterImage = response.data.allPlatFormsDTO.androidTVDocumentId
                ? `${appConfig.imageURL}${response.data.allPlatFormsDTO.androidTVDocumentId}?height=360&width=640`
                : '';
              this.androidTVImage = posterImage;
              value = {
                ...value,
                posterImage,
                isShow: response.data.allPlatFormsDTO.showHideAndroidTV
                  ? response.data.allPlatFormsDTO.showHideAndroidTV
                  : false,
                isLink: response.data.allPlatFormsDTO.androidTVLink
                  ? true
                  : false,
              };
              break;
          }
          return value;
        });
      }
      setTimeout(() => {
        this.showfooter = true;
      }, 1000);
    });
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this.isWhiteLabel = response.data.organizationDTO.isWhiteLabel
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.homePage}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }${response.data.homePage}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  addAlphaFooter(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = data + _opacity.toString(16).toUpperCase();
    }
  }

  logOut() {
    this.loginService.logOutUser().subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logged out successful.',
        });
        setTimeout(() => {
          localStorage.clear();
          this.router.navigate(['/main']);
        }, 100);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  goToView(item: any) {
    if (item.name === 'Android App On') {
      this.pageCustomeData.allPlatFormsDTO.androidLink &&
        window.open(this.pageCustomeData.allPlatFormsDTO.androidLink, '_blank');
    } else if (item.name === 'Apple App on') {
      this.pageCustomeData.allPlatFormsDTO.iphoneLink &&
        window.open(this.pageCustomeData.allPlatFormsDTO.iphoneLink, '_blank');
    } else if (item.name === 'Amazon App On') {
      this.pageCustomeData.allPlatFormsDTO.amazonLink &&
        window.open(this.pageCustomeData.allPlatFormsDTO.amazonLink, '_blank');
    } else if (item.name === 'Roku Channel') {
      this.pageCustomeData.allPlatFormsDTO.rokuTVLink &&
        window.open(this.pageCustomeData.allPlatFormsDTO.rokuTVLink, '_blank');
    } else if (item.name === 'Apple TV') {
      this.pageCustomeData.allPlatFormsDTO.appleTVLink &&
        window.open(this.pageCustomeData.allPlatFormsDTO.appleTVLink, '_blank');
    } else if (item.name === 'Google TV') {
      this.pageCustomeData.allPlatFormsDTO.googleTvLink &&
        window.open(
          this.pageCustomeData.allPlatFormsDTO.googleTvLink,
          '_blank'
        );
    } else if (item.name === 'Android TV') {
      this.pageCustomeData.allPlatFormsDTO.androidTVLink &&
        window.open(
          this.pageCustomeData.allPlatFormsDTO.androidTVLink,
          '_blank'
        );
    }
  }
}
