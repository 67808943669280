import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import videojs from 'video.js';

@Component({
  selector: 'app-iframe-player',
  templateUrl: './iframe-player.component.html',
  styleUrls: ['./iframe-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IframePlayerComponent implements OnInit {
  @ViewChild('target', { static: true }) target: ElementRef;
  url: string;
  type: string;
  allVideoResolutionList: any[];
  currentVideoResolution: string;
  player: videojs.Player;
  constructor(private _ActivatedRoute: ActivatedRoute) {
    this.url = '';
    this.type = '';
    this.allVideoResolutionList = [];
    this.currentVideoResolution = '720';
  }
  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe((data) => {
      this.allVideoResolutionList = data.data.data.currentMediaItem.videoDTO
        ? data.data.data.currentMediaItem.videoDTO.flavourList
        : [];
      this.url = data.data.data.currentMediaItem.videoDTO
        ? this.allVideoResolutionList.length && this.modifyVideoUrl()
          ? this.modifyVideoUrl()
          : data.data.data.currentMediaItem.videoDTO.path
        : data.data.data.currentMediaItem.videoUrl
        ? data.data.data.currentMediaItem.videoUrl
        : 'https://cdn.plyr.io/static/blank.mp4';

      if (data.data.data.currentMediaItem.liveStreamDataDTO !== null) {
        if (
          data.data.data.currentMediaItem.liveStreamDataDTO.streamStatus ===
          'LIVE'
        ) {
          this.url = data.data.data.currentMediaItem.liveStreamDataDTO.m3u8Url;
        }
      }
    });

    this.player = videojs(
      this.target.nativeElement,
      {
        fluid: true,
        autoplay: true,
        controls: true,
        sources: [
          {
            src: this.url,
          },
        ],
        controlBar: {
          pictureInPictureToggle: false,
        },
      },
      () => {}
    );
    this.player.aspectRatio('16:9');
    this.player.responsive(true);
  }
  modifyVideoUrl(): string {
    if (this.allVideoResolutionList.length) {
      const flavourList = this.allVideoResolutionList.filter(
        (value: any) => value.size === this.currentVideoResolution && value.src
      );
      return flavourList.length ? flavourList[0].src : '';
    }
    return '';
  }
}
