import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  homeLogo = 'assets/images/logo.png';
  primaryColor: any;
  logo: any;
  appBGColor: any;
  loginImg: any;
  showForgot = false;
  web: any;
  passwordType = false;
  bottomGradiant: any;
  leftGradiant: any;
  blurOpacity: any;
  changeToRGB: any;
  massageTypeList: any[] = [];
  email: any;
  firstName: any;
  lastName: any;
  message: any;
  messageType = '';
  mobileNumber: any;
  isLoggedIn: any;
  logoSize: any;
  appBtnPrimaryColor: any;
  headerLinkColor: any;
  headerLogo: any;
  mainPageName: any;
  vodPageName: any;
  contactName: any;
  showMenu = false;
  showPopup = false;
  favIcon: any;
  imageName = '';
  imageChangedEvent: any = '';
  cropperModal = false;
  croppedImage: any = '';
  attachmentId: any;
  scrHeight: any;
  scrWidth: any;
  loginUserDetail: any;
  userMail: any;
  userFirstName: any;
  userLastName: any;
  phoneNo: any;
  signoutModal = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  showHideEbook: boolean;
  ebookPage: string;
  logoSizeRoundValue: number;
  domain: string;
  isTrailModal = false;
  isInTrial: boolean;
  selectedmessageType: any;
  subscriptionPlanID: any;
  constructor(
    public router: Router,
    private messageService: MessageService,
    public homeService: HomeService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private ngxFavicon: AngularFaviconService,
    private loginService: LoginService,
    private _Title: Title,
    private _Meta: Meta,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.getScreenSize();
    this.showHideEbook = false;
    this.ebookPage = '';
    this.logoSizeRoundValue = 0;
    this.domain = '';
  }

  ngOnInit(): void {
    this.loginUserDetail = localStorage.getItem('loggedInUser');
    this.isLoggedIn = this.loginUserDetail ? true : false;
    this.email = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.email
      : '';
    this.firstName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.firstName
      : '';
    this.lastName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.lastName
      : '';
    this.mobileNumber = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.mobileNumber
      : '';
    this.subscriptionPlanID = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
      : '';
    this.isInTrial = this.loginUserDetail ? JSON.parse(this.loginUserDetail).userDetails.isInTrial : false
    // this.pageCustomization()
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    this._ActivatedRoute.queryParams.subscribe((params) => {
      //this.domain = params.domain;
      this.selectedmessageType = params.from
      this.getPageCustomizationByDomain();
    });
    this.getSubscribe();
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
    this.getMassageTypeList();
    if (this.loginUserDetail) {
      const id = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.id
        : 0;
      id && this.getContactById(id);
    }
  }

  getContactById(id: any) {
    this.homeService.getContactById(id).subscribe((response: any) => {
      this.email = response.data.basicInfo.email;
      this.firstName = response.data.basicInfo.firstName;
      this.lastName = response.data.basicInfo.lastName;
      this.mobileNumber = response.data.basicInfo.mobileNumber;
    });
  }
  logOut() {
    this.loginService.logOutUser().subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logged out successful.',
        });
        setTimeout(() => {
          localStorage.clear();
          this.router.navigate(['/main']);
        }, 100);
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.mainPageName = response.data.pageCustomizationDTO.homePage;
        this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
        this.contactName = response.data.pageCustomizationDTO.contactUs;
        this.showHideEbook = response.data.pageCustomizationDTO.showHideEbook;
        this.ebookPage = response.data.pageCustomizationDTO.ebookPage;
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.contactUs}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
              ? `${response.data.pageCustomizationDTO.orgName}-`
              : ''
            }${response.data.pageCustomizationDTO.contactUs}`,
        });
        if (this.router.url === '/vod') {
          this.router.navigate(['/vod'], {
            queryParams: { vod: this.vodPageName },
          });
        }
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId
          ? `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`
          : '';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        this._Meta.updateTag({
          property: 'og:image',
          content: this.logo,
        });
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.logoSizeRoundValue = Math.round(this.logoSize / 10) * 10;

        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );
        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';

        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.loginImg = response.data.mainBannerDTO.backgroundImageId
          ? `${appConfig.imageURL}` +
          response.data.mainBannerDTO.backgroundImageId +
          '?height=630&width=1120'
          : '';
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
      }
    });
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.contactUs}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }${response.data.contactUs}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {
  //     this.mainPageName = response.data.pageCustomizationDTO.homePage;
  //     this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
  //     this.contactName = response.data.pageCustomizationDTO.contactUs;
  //     if(this.router.url === '/vod'){
  //       this.router.navigate(
  //         ['/vod'],
  //         { queryParams: { vod: this.vodPageName } }
  //       )
  //     }
  //     this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //     this.ngxFavicon.setFavicon(this.favIcon);
  //     this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //     this.blurOpacity = response.data.webAppSettingDTO ? response.data.webAppSettingDTO.shadowsFade / 10  : '0.5'
  //     this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
  //     this.addAlpha(this.appBGColor,this.blurOpacity)
  //     if(this.scrWidth < 500){
  //       this.logoSize = response.data.headerDTO.mobileLogoSize;
  //     }
  //     else if(this.scrWidth > 500 && this.scrWidth < 900){
  //       this.logoSize = response.data.headerDTO.tabletLogoSize;
  //     }
  //     else{
  //       this.logoSize = response.data.headerDTO.webLogoSize;
  //     }
  //     this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(`${response.data.pageCustomizationDTO.primaryColor}`);
  //     this.headerLinkColor = response.data.headerDTO.headerLinksColor;
  //     this.headerLogo = response.data.headerDTO.logoId ? `${appConfig.imageURL}` + response.data.headerDTO.logoId : ''

  //   this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //   this.loginImg = response.data.mainBannerDTO.backgroundImageId ? `${appConfig.imageURL}` + response.data.mainBannerDTO.backgroundImageId + "?height=630&width=1120" : ''
  //   this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`);
  //   this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`);

  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  getMassageTypeList() {
    this.homeService.getMassageTypeList(this.isLoggedIn).subscribe(
      (response: any) => {
        this.massageTypeList = response.data;
        // var mediadata = []
        this.massageTypeList = this.massageTypeList.map((el) => ({
          name: el.toLowerCase(),
          value: el.toUpperCase(),
        }));
        this.massageTypeList = this.massageTypeList.map((el) => ({
          name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
          value: el.value,
        }));
        if (this.selectedmessageType === 'billing') {
          this.massageTypeList.forEach((el) => {
            if (el.value === 'UNSUBSCRIBE') {
              this.messageType = el.value
            }
          })
        }
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }
  submitContactUs() {
    if (this.isLoggedIn) {
      this.submitContactFormWith();
    } else {
      this.submitContactForm();
    }
  }
  submit() {
    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var pattern = new RegExp(
      /^([\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    );
    var validateEmail = null;
    if (!pattern.test(this.email)) {
      validateEmail = false;
    } else {
      validateEmail = true;
    }

    if (validateEmail) {
      var mobilelength = this.mobileNumber.toString().length;
      if (mobilelength > 4 && mobilelength < 16) {
        // if(this.messageType === 'UNSUBSCRIBE'){
        //   if(this.isInTrial){
        //     this.isTrailModal = true
        //   }
        //   else{
        //     this.submitContactUs()
        //   }
        // }
        // else{
        this.submitContactUs()
        // }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please enter a valid phone number',
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid email',
      });
    }
  }

  onFileChange(event: any) {
    var url = event.target.files[0].name;
    var urlExt = url.substr(url.lastIndexOf('.') + 1);
    this.imageName = event.target.files[0].name;
    urlExt = urlExt.toLowerCase();
    if (
      urlExt === 'png' ||
      urlExt === 'jpeg' ||
      urlExt === 'jpg' ||
      urlExt === 'pdf' ||
      urlExt === 'txt' ||
      urlExt === 'doc' ||
      urlExt === 'docx' ||
      urlExt === 'mp4' ||
      urlExt === 'm4v' ||
      urlExt === 'mov' ||
      urlExt === 'wmv' ||
      urlExt === 'flv' ||
      urlExt === 'mkv'
    ) {
      if (event.target.files[0].size > 53000000) {
        setTimeout(() => {
          this.deleteFile();
        }, 600);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Maximum file size allowed is 50 mb',
        });
      } else {
        if (urlExt === 'png' || urlExt === 'jpeg' || urlExt === 'jpg') {
          this.imageChangedEvent = event;
          this.cropperModal = true;
        } else {
          const formData = new FormData();
          formData.append('file', event.target.files[0]);
          this.homeService.uploadFile(formData, 'document',window.location.hostname).subscribe(
            (response: any) => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Document uploaded successfully',
              });
              this.attachmentId = response.body.data.id;
              // this.editMedia.document = response.body.data;
              // this.editMedia.documentId = response.body.data.id;
            },
            (error) => {
              this.imageName = '';
              this.attachmentId = '';
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error.error
                  ? error.error.message
                  : 'Something went wrong.',
              });
            }
          );
        }
      }
    } else {
      this.imageName = '';
      this.attachmentId = '';
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'This format is not supported please upload another',
      });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropImage() {
    const imgData = {
      name: this.imageName,
      isLogo: false,
      imageString: this.croppedImage.split(';base64,')[1],
    };
    this.homeService.uploadBase64WithDomain(imgData,window.location.hostname).subscribe(
      (response: any) => {
        // this.profileImg = `${appConfig.imageURL}` + response.body.data.id;
        this.imageChangedEvent = null;
        this.croppedImage = '';
        this.attachmentId = response.body.data.id;
        this.cropperModal = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Image uploaded successfully',
        });
      },
      (error: any) => {
        this.imageName = '';
        this.attachmentId = '';
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.',
        });
      }
    );
  }

  deleteFile() {
    this.imageName = '';
    this.attachmentId = '';
  }

  submitContactFormWith() {
    const dataToSend = {
      attachmentId: this.attachmentId,
      deviceType: 'WEB',
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      message: this.message,
      messageType: this.messageType,
      mobileNumber: this.mobileNumber,
      sourceType: 'CONTACT_US_WEBSITE',
    };
    this.homeService.postContactUsWithAuth(dataToSend).subscribe(
      (response: any) => {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message send successfully' });
        // this.router.navigate(['/vod']);
        this.showPopup = true;
        this.message = '';
        this.messageType = '';
        this.imageName = '';
        this.attachmentId = '';
        this.selectedmessageType = '';
        this.router.navigate(['contact-us'], { queryParams: {} })
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  submitContactForm() {
    const dataToSend = {
      attachmentId: this.attachmentId,
      deviceType: 'WEB',
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      message: this.message,
      messageType: this.messageType,
      mobileNumber: this.mobileNumber,
      sourceType: 'CONTACT_US_WEBSITE',
    };
    this.homeService.postContactUsWithoutAuth(dataToSend).subscribe(
      (response: any) => {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message send successfully' });
        // this.router.navigate(['/main']);
        this.showPopup = true;
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.message = '';
        this.messageType = '';
        this.mobileNumber = '';
        this.imageName = '';
        this.attachmentId = '';
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }
}
