import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
})
export class JoinComponent implements OnInit {
  logo: any;
  primaryColor: any;
  favIcon: any;
  appBGColor: any;
  allPlans: any[] = [];
  web: any;
  changeToRGB: any;
  subscriptionPlanList: any[] = [];
  textColor: any;
  showbtn = false;
  unsubscribeModal = false;
  skipSubscriptionPlan: boolean;
  constructor(
    public router: Router,
    private messageService: MessageService,
    public homeService: HomeService,
    private ngxFavicon: AngularFaviconService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer
  ) {
    this.allPlans = [
      {
        planImg: 'assets/images/WatchLive.jpeg',
        planName: 'Weekly Access for $99',
        amount: '99',
      },
      {
        planImg: 'assets/images/WatchLive.jpeg',
        planName: 'Monthly Access for $99',
        amount: '199',
      },
      {
        planImg: 'assets/images/WatchLive.jpeg',
        planName: 'Quaterly Access for $99',
        amount: '299',
      },
    ];
    this.skipSubscriptionPlan = false;
  }

  ngOnInit(): void {
    // this.pageCustomization()
    this.getSubscribe();
    this.subscriptionPlan();
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.skipSubscriptionPlan =
          response.data.pageCustomizationDTO.skipSubscriptionPlan &&
          response.data.pageCustomizationDTO.skipSubscriptionPlan;
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
          this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId
          ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId
          : '';
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.addAlpha(this.primaryColor, 0.25);
        // this.textColor = this.sanitizer.bypassSecurityTrustStyle(`${this.changeToRGB})`);
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
            response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        setTimeout(() => {
          this.showbtn = true;
        }, 1000);
      }
    });
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {

  //   this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //   this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId : ''
  //   this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //   this.addAlpha(this.primaryColor,0.25)
  //   // this.textColor = this.sanitizer.bypassSecurityTrustStyle(`${this.changeToRGB})`);
  //   this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //   this.ngxFavicon.setFavicon(this.favIcon);
  //   setTimeout(() => {
  //     this.showbtn = true
  //   }, 1000);
  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);
  
    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }
  
    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;
  
    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    
    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');
  
    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if(color.includes('#')){
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else{
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  subscriptionPlan() {
    this.homeService.subscriptionPlanList().subscribe(
      (response: any) => {
        this.subscriptionPlanList = response.data;
        this.subscriptionPlanList.forEach((el: any) => {
          if (el.documentId) {
            var width = 480;
            var height = 270;
            let id = el.documentId;
            el['planImg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.subscriptionPlanDuration === 'DAILY') {
            el['planDuration'] = 'Daily';
            el['planDurationName'] = 'day';
          } else if (el.subscriptionPlanDuration === 'WEEKLY') {
            el['planDuration'] = 'Weekly';
            el['planDurationName'] = 'week';
          } else if (el.subscriptionPlanDuration === 'MONTHLY') {
            el['planDuration'] = 'Monthly';
            el['planDurationName'] = 'month';
          } else if (el.subscriptionPlanDuration === 'YEARLY') {
            el['planDuration'] = 'Annual';
            el['planDurationName'] = 'year';
          }
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  choosePlan(item: any) {
    this.router.navigate(['/signup'], { queryParams: { id: item.id } });
  }

  navigateWithoutPlan(): void {
    this.router.navigate(['/signup'], {
      queryParams: { category: 'NOPLAN', redirect: 'JOIN' },
    });
  }
}
