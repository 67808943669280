

export class ContactModel {
    
    basicInfo = new BasicInfo();
    mailingAddress = new MailingAddress();
    // paymentHistoryDTO = new PaymentHistory();
    // socialMediaInfo = new SocialMedia();
    // logoId : number;
    deviceType: string;
    subscriptionPlanId!: any;
    password: any;
    confirmPassword: any;
    sourceType: any
    constructor() {
        
        // this.basicInfo = obj ? new BasicInfo(obj.basicInfo) : new BasicInfo();
        // this.mailingAddress = obj ? new MailingAddress(obj.mailingAddress) : new MailingAddress();
        // this.socialMediaInfo = obj ? new SocialMedia(obj.socialMediaInfo) : new SocialMedia();
        // // this.paymentHistoryDTO = obj ? new PaymentHistory(obj.paymentHistoryDTO) : new PaymentHistory();
        // this.logoId = obj && obj.logoId ? obj.logoId : null;
        // this.subscriptionPlanIds = obj && obj.subscriptionPlanIds ? obj.subscriptionPlanIds : [];
        // this.password = obj && obj.password ? obj.password : '';
    }
}

export class BasicInfo {
    email!: string;
    firstName!: string;
    lastName!: string;
    mobileNumber!: string;
    fullName!: string;

    constructor() {
        // this.email = obj ? obj.email : '';
        // this.firstName = obj ? obj.firstName : '';
        // this.lastName = obj ? obj.lastName : '';
        // this.mobileNumber = obj ? obj.mobileNumber : '';
        
     
    }
}

export class MailingAddress {
    addressLine1!: string;
    apartment!: string;
    city!: string;
    postalCode!: string;
    state!: string;
    constructor() {
        // this.addressLine1 = obj ? obj.addressLine1 : '';
        // this.apartment = obj ? obj.apartment : '';
        // this.city = obj ? obj.city : '';
        // this.postalCode = obj ? obj.postalCode : '';
        // this.state = obj ? obj.state : '';
    }
}

export class SocialMedia {
    fb: string;
    insta: string;
    tiktok: string;
    twitter: string;
    constructor(obj?:any) {
        this.fb = obj ? obj.fb : '';
        this.insta = obj ? obj.insta : '';
        this.tiktok = obj ? obj.tiktok : '';
        this.twitter = obj ? obj.twitter : '';
    }
}

export class PaymentHistory {
    amount: number;
    date: any;
    invoice : string;
    status : string;
    constructor(obj?:any){
        this.amount = obj  ? obj.amount : null;
        this.date = obj ? obj.date : null;
        this.invoice = obj ? obj.invoice : null;
        this.status = obj ? obj.status : null;
    }
}

export class ContactSignUp {
    
    basicInfo = new BasicInfo();
    mailingAddress = new MailingAddress();
    socialMediaDTO = new SocialMedia();
    // url : string
    constructor() {
        
        // this.basicInfo = obj ? new BasicInfo(obj.basicInfo) : new BasicInfo();
        // this.mailingAddress = obj ? new MailingAddress(obj.mailingAddress) : new MailingAddress();
        // this.socialMediaDTO = obj ? new SocialMedia(obj.socialMediaDTO) : new SocialMedia();
        // this.url = obj ? obj.url : null
    }
}