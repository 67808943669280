import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ePub from 'epubjs';
import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-ebook-reader',
  templateUrl: './ebook-reader.component.html',
  styleUrls: ['./ebook-reader.component.scss'],
})
export class EbookReaderComponent implements OnInit, OnChanges {
  @ViewChild('ebookReader', { static: true }) ebookReader: ElementRef;
  @Input() eBookUrl: string;
  area: any;
  rendition: any;
  book: any;
  scrWidth: number;
  isSingleView: boolean;
  fontSize: any[];
  defaultFontSize: number;
  disablePlus: boolean;
  disableMinus: boolean;
  currentPage: number;
  constructor(
    private _SharedService: SharedService,
    private _NgbModal: NgbModal,
    private _MessageService: MessageService
  ) {
    this.scrWidth = window.innerWidth;
    this.eBookUrl = '';
    this.isSingleView = false;
    this.fontSize = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
      29, 30,
    ];
    this.defaultFontSize = 16;
    this.disablePlus = false;
    this.disableMinus = false;
    this.currentPage = 0;
  }
  ngOnInit(): void {
    this.area = document.getElementById('area');
    this.loadBooks();
  }

  ngOnChanges(): void {
    if (this.book) {
      this.book.destroy();
    }
    this.loadBooks();
  }
  async loadBooks() {
    this._SharedService.updateLoader(true);
    this.book = ePub(this.eBookUrl);
    this.rendition = this.book.renderTo(this.area, {
      manager: 'continuous',
      flow: 'paginated',
      allowScriptedContent: true,
      width: '100%',
      height: '100%',
    });
    this.rendition.display();
    this._SharedService.updateLoader(false);
  }
  next(): void {
    this.book.package.metadata.direction === 'rtl'
      ? this.rendition.prev()
      : this.rendition.next();
  }
  prev(): void {
    this.book.package.metadata.direction === 'rtl'
      ? this.rendition.next()
      : this.rendition.prev();
  }
  changeFontSize(size: number, type: string): void {
    switch (type) {
      case 'INCREMENT':
        if (this.defaultFontSize < 40) {
          ++this.defaultFontSize;
          this.rendition.themes.fontSize(`${this.defaultFontSize}px`);
          if (this.defaultFontSize === 40) {
            this.disablePlus = true;
          }
          if (this.defaultFontSize === 11) {
            this.disableMinus = false;
          }
        }
        break;
      case 'DECREMENT':
        if (this.defaultFontSize > 10) {
          --this.defaultFontSize;
          this.rendition.themes.fontSize(`${this.defaultFontSize}px`);
          if (this.defaultFontSize === 10) {
            this.disableMinus = true;
          }
          if (this.defaultFontSize === 39) {
            this.disablePlus = false;
          }
        }
        break;
      case 'INPUT':
        if (this.defaultFontSize >= 10 && this.defaultFontSize <= 40) {
          this.rendition.themes.fontSize(`${size}px`);
          this.defaultFontSize = Number(size);
        } else {
          this._MessageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Font size limit from 10 to 40.',
          });
        }
        break;
    }
  }
  changeLayout(event: string): void {
    switch (event) {
      case 'SINGLE':
        if (!this.isSingleView) {
          this.rendition.spread('none', 500);
          this.isSingleView = true;
          this.area.classList.add('single');
        }
        break;
      case 'DOUBLE':
        if (this.isSingleView) {
          this.isSingleView = false;
          this.rendition.spread('auto', 500);
          this.area.classList.remove('single');
        }
        break;
    }
  }

  setting(content: any, event: string) {
    if (event === 'OPEN') {
      this._NgbModal
        .open(content, {
          ariaLabelledBy: 'Settings',
          centered: true,
          size: 'sm',
        })
        .result.then(
          (result) => {
            console.log(result);
          },
          (reason) => this.getDismissReason(reason)
        );
    } else {
      this._NgbModal.dismissAll();
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
