<!-- <app-header></app-header> -->
<div class="main-contact-us">

  <div class="col-12" style="position: relative;">
    <div class="d-flex container">
      <div class="col-5 left-panel" [id]="logoSizeRoundValue" [ngStyle]="{'padding-top': showForgot ? '11%' : ''}">
        <div class="left-content">
          <div class="container form-wrapper">
            <form #signInForm="ngForm">
              <div class="d-flex" style="flex-direction: column">
                <div class="logo mb-20">
                  <!-- <a routerLink="/main">
                          <img [src]="logo" alt="">
                      </a> -->
                  <p class="form-header">Contact Us</p>
                </div>
                <div class="wrapper-class">
                  <div class="form-group">
                    <!-- <label>First Name</label> -->
                    <input type="text" name="firstName" class="form-control" placeholder="First name *"
                      [(ngModel)]="firstName" [disabled]="isLoggedIn" />
                  </div>
                  <div class="form-group">
                    <!-- <label>Last Name</label> -->
                    <input type="text" name="lastName" class="form-control" placeholder="Last name"
                      [(ngModel)]="lastName" [disabled]="isLoggedIn" />
                  </div>
                </div>
                <div class="wrapper-class">

                  <div class="form-group">
                    <!-- <label>Email</label> -->
                    <input type="text" name="email" class="form-control" placeholder="Email *" [(ngModel)]="email"
                      [disabled]="isLoggedIn" />
                  </div>
                  <div class="form-group">
                    <!-- <label>Phone</label> -->
                    <input type="number" name="mobileNumber" class="form-control" placeholder="Phone *" maxlength="15"
                      minlength="5" [(ngModel)]="mobileNumber" [disabled]="isLoggedIn" />
                  </div>
                </div>
                <div class="wrapper-class">

                  <div class="form-group">
                    <!-- <label>Subject</label> -->
                    <select name="messageType" class="form-control" [(ngModel)]="messageType" [disabled]="selectedmessageType === 'billing'">
                      <option selected disabled value="">Choose subject</option>
                      <option *ngFor="let item of massageTypeList" [ngValue]="item.value">{{item.name}}</option>
                    </select>
                  </div>

                  <div class="form-group file-input">
                    <!-- <label>Subject</label> -->
                    <input type="file" *ngIf="!imageName" name="attachment" class="form-control"
                      (change)="onFileChange($event)" placeholder="Upload screenshot" id="">
                    <input type="text" *ngIf="imageName"
                      style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis; padding-right: 30px;"
                      [(ngModel)]="imageName" disabled class="form-control" name="attachment" id="">
                    <span class="delete-icon" *ngIf="imageName">
                      <i (click)="deleteFile()" class="fa fa-trash" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <!-- <label>Message</label> -->
                  <!-- <textarea
                        placeholder="Type your massage in less than 500 words"
                        type="text"
                        name="email"
                        class="form-control"
                        ></textarea> -->
                  <textarea name="message" id="" cols="30" maxlength="500" [(ngModel)]="message"
                    class="form-control textarea" placeholder="Type your message in less than 500 characters *"
                    rows="4"></textarea>
                </div>
                <!-- </div> -->

                <div class="btn-wrapper d-flex justify-content-between align-items-center" style="margin: 10px 5px;">

                  <div class="save-btn">
                    <button [ngStyle]="{
                              'background-color': primaryColor
                                ? primaryColor
                                : '#006aff'
                            }" class="main-btn"
                      [ngClass]="{disable: !email || !firstName || !message || !messageType || !mobileNumber}"
                      (click)="submit()" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="right-side col-7" [style.background-color]="appBGColor">
        <!-- <div class="right-side-wrapper">
            [ngStyle]="{'background-image': 'url(' + loginImg + ')'}" [style.background-color]="appBGColor"
          </div> -->
        <!-- <img [src]="loginImg" alt=""> -->
        <!-- <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div> -->
        <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
        <!-- <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div> -->
      </div>
    </div>
    <!-- <div class="col-7">
      
          </div> -->
    <div class="main-banner" *ngIf="scrWidth > 800">
      <div class="banner-img-wrapper">
        <div class="left-banner-color" [style.background-color]="appBGColor"></div>
        <img class="main-bg-image" [src]="loginImg" onload="style.opacity = 1" style="opacity: 0;object-fit: cover;">
      </div>
      <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
      <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
      <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
    </div>
  </div>
</div>



<p-dialog [(visible)]="showPopup" [modal]="true" [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
  <div class="showInfo">
    <p>Thank you for the feedback, we have registered your response and will address your concern shortly</p>
  </div>
</p-dialog>

<p-dialog *ngIf="cropperModal" class="cropper-modal" [closable]="false" header="Upload Logo" [(visible)]="cropperModal"
  [modal]="true" [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
  <div>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" format="png"
      (imageCropped)="imageCropped($event)">
    </image-cropper>
  </div>
  <p-footer>
    <button type="button" class="btn btn-white" (click)="cropImage()">Crop</button>
    <button type="button" class="btn btn-white"
      (click)="croppedImage = ''; imageChangedEvent = null; cropperModal = false;attachmentId= null;imageName=''">Cancel</button>
  </p-footer>
</p-dialog>

<p-dialog *ngIf="signoutModal" [closable]="false" header="" [(visible)]="signoutModal" [modal]="true"
  [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
  <div class="showInfo1">
    <p>Are you sure you want to sign out ?</p>
  </div>
  <p-footer class="signout-footer">
    <button type="button" class="btn btn-white" style="margin: 0 10px;" (click)="logOut()">Yes</button>
    <button type="button" class="btn btn-white" (click)="signoutModal= false">Cancel</button>
  </p-footer>
</p-dialog>
