import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HomeService } from '../services/home/home.service';
import { SessionService } from '../services/shared/session.service';

@Injectable()
export class NetworkGuard implements CanActivate {
  constructor(
    private _HomeService: HomeService,
    private _Router: Router,
    private _SessionService: SessionService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this._HomeService
      .pageCustomizationData()
      .toPromise()
      .then((response: any) => {
        if (response.data && response.data.pageCustomizationDTO.showHideNetwork) {
          return true;
        } else if (
            response.data &&
            response.data.pageCustomizationDTO.showHideHome &&
            !this._SessionService.getSession('loggedInUser')
          ) {
            this._Router.navigate(['/main']);
            return false;
          } else if (response.data.pageCustomizationDTO.showHideVOD) {
            this._Router.navigate(['/vod'], {
              queryParams: {
                vod: response.data.pageCustomizationDTO.watchDailyPage,
              },
            });
            return false;
        } else if (response.data.pageCustomizationDTO.showHideEbook) {
          this._Router.navigate(['/ebook-list']);
          return false;
        } else if (response.data.pageCustomizationDTO.showHideAppstore) {
          this._Router.navigate(['/mobileApp']);
          return false;
        } else if (response.data.pageCustomizationDTO.showHideGiving) {
          this._Router.navigate(['/giving']);
          return false;
        } else if (response.data.pageCustomizationDTO.isIFrame) {
          this._Router.navigate(['/frame']);
          return false;
        }else if (response.data.pageCustomizationDTO.showHideTvChannel) {
          this._Router.navigate(['/channel']);
          return false;
        } else {
          this._Router.navigate(['/contact-us']);
          return false;
        }
      });
  }
}
