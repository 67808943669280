<section class="main-program-m3u" [ngStyle]="{'padding-top': logoHeight ? ((logoHeight + 60) + 'px') : ''}">
    <div class="container d-flex justify-content-between">
        <div class="vedio-wrapper" >
            <div class="d-flex justify-content-between">
                <div class="backBtn" (click)="gotToView()"><i class="fa fa-chevron-circle-left" aria-hidden="true"></i><span>Back</span></div>
                <p style="margin-top: 15px;margin-bottom: 0;display: flex;justify-content: end;font-size: 16px;color: grey;">Video is not playing? &nbsp;<span style="cursor: pointer;" (click)="permissionAllowModal=true"><i class="fa fa-info-circle" aria-hidden="true"></i></span></p>
            </div>
            <div class="player">
                <ng-container>
                    <app-video-player style="width: 1100px;"
                        [options]="{autoplay: autoPlayChange ? 'play':false, controls: true, sources: [{ src: videoJsPlayerUrl,type:videoJsPlayerType }]}">
                    </app-video-player>
                </ng-container>
            </div>
            <p class="itemName">{{itemName ? itemName : ''}} <span *ngIf="showSeriesName"> ({{showSeriesName ? showSeriesName : ''}})</span></p>
        </div>
    </div>
</section>

<p-dialog *ngIf="permissionAllowModal" [(visible)]="permissionAllowModal" [modal]="true"
    [style]="{width: '45vw'}" [draggable]="false" [resizable]="false">
    <div>
       <h1>To play M3U videos allow permission by following these points.</h1>
       <ul>
            <li>1. Click on lock icon on your URL.</li>
            <li>2. Now click on site settings on coming popup.</li>
            <li>3. Now settings will be open. In permissions search for <b>Insecure content</b> and click on dropdown and select Allow.</li>
            <li>4. Go to our Nowcast website and reload the website</li>
            
       </ul>
    </div>
    <!-- <p-footer>
        <button type="button" class="btn btn-white" (click)="permissionAllowModal=false">Ok</button>
    </p-footer> -->
</p-dialog>