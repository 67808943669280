import { Component, ElementRef, OnInit } from '@angular/core';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  
  appBGColor: any;
  logo: any;
  primaryColor: any;
  favIcon: any;
  constructor(
    public homeService: HomeService,
    private elementRef: ElementRef,
    public messageService: MessageService,
    private ngxFavicon: AngularFaviconService,
  ) { }

  ngOnInit(): void {
    // this.pageCustomization();
    this.getSubscribe()
  }

  getSubscribe(){
    this.homeService.sendPageData$.subscribe((response: any) => {
      if(response.data){
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
          // this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
        this.ngxFavicon.setFavicon(this.favIcon);
    }
    })
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {

  //   this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //   this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     // this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
  //   this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //   this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //   this.ngxFavicon.setFavicon(this.favIcon);
  //   }, (error) => {
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

}
