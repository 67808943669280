<!-- <app-header></app-header> -->
<div class="main-dashboard">
    <div class="d-flex justify-content-between logo-wrapper" style="padding-left: 32px;padding-right: 25px;">
        <a routerLink="/main">
            <img [src]="logo" alt="">
        </a>
        <!-- <button class="main-btn" routerLink="/Watch-daily">back</button> -->
        <div class="close"><i routerLink="/vod" class="fa fa-times" aria-hidden="true"></i></div>
    </div>
    <ul class="menus">
        <li [ngStyle]="{'color': primaryColor ? primaryColor : ''}" routerLink="menu/profile" routerLinkActive="active">
            Profile</li>
        <!-- <li [ngStyle]="{'color': primaryColor ? primaryColor : ''}" routerLink="menu/security" routerLinkActive="active">Security</li> -->
        <li [ngStyle]="{'color': primaryColor ? primaryColor : ''}" routerLink="menu/billing" routerLinkActive="active">
            Billing</li>
    </ul>
    <router-outlet></router-outlet>
</div>