<section class="billing-section">
    <div class="w-100" [ngClass]="{'form-wrapper':planName || isPlanAvailable}">
        <form #billingForm="ngForm">
            <div class="d-flex" style="flex-direction: column;">
                <ng-container *ngIf="planName;else noPlan">
                    <div class="selectedPlanContainer" [ngStyle]="{'background-color': changeToRGB ? changeToRGB : ''}">
                        <div class="d-flex justify-content-between" style="flex-direction: column;">
                            <h3>Current Plan</h3>
                            <p class="text-light">{{planName}}</p>
                            <p>
                                $ {{subscribtionDetails.price}}/{{subscribtionDetails.subscriptionPlanDuration}}</p>
                        </div>
                        <div class="changePlan-btn" (click)="getAccess()">Change Plan</div>
                    </div>
                    <p class="link" (click)="unsubscribePlan()">Unsubscribe plan</p>
                </ng-container>
                <ng-template #noPlan>
                    <div class="card mt-2 mb-5" [ngStyle]="{'background-color': changeToRGB ? changeToRGB : ''}">
                        <div class="card-body">
                            You do not have any plan.
                        </div>
                    </div>
                </ng-template>
                <div class="profile-form" *ngIf="!isPlanAvailable && showPlan ">
                    <h4 class="mb-20">Choose Plan</h4>
                    <form #signInForm="ngForm">
                        <div class="d-flex" style="flex-direction: column;">
                            <div class="plans-container">
                                <div class="plan-block mb-20" *ngFor="let item of subscriptionPlanList;let i = index;">
                                    <label for="selected-image-{{i}}">
                                        <div class="plans cursor-pointer"
                                            [ngStyle]="{'border-color': item.selectedImage === true ? primaryColor : '#fff'}">
                                            <div class="radio-btn">
                                                <input type="radio" id="selected-image-{{i}}" class="cursor-pointer"
                                                    [checked]="item.selectedImage" (change)="selectPlan(item)">
                                            </div>
                                            <div class="plan-content">
                                                <div class="plan-info">
                                                    <div class="plan-title">
                                                        {{item.planDuration}} Access for ${{item.price}} a
                                                        {{item.planDurationName}}
                                                    </div>
                                                    <div class="plan-desc">
                                                        Gain access to the items on a recurring basis
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div class="button-wrapper d-flex justify-content-between" *ngIf="planSelected">
                                <div class="save-btn mt-10 plan-btn">
                                    <button [ngClass]="{disable: !planSelected}" [disabled]="!planSelected"
                                        [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                        class="main-btn" (click)="changePlan()">Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="form-group mt-5" *ngIf="showaddedCard">
                    <div class="cardInfo">
                        <div class="d-flex align-items-center">
                            <div class=" d-flex cardImg-wrapper justify-content-center align-items-center">
                                <img *ngIf="cardBrand === 'MasterCard'" src="assets/images/Mask Group 30.png" alt="">
                                <img *ngIf="cardBrand === 'Visa'" src="assets/images/Visa debit.svg" alt="">
                                <img *ngIf="cardBrand === 'UnionPay'" src="assets/images/Unionpay card.svg" alt="">
                                <img *ngIf="cardBrand === 'JCB'" src="assets/images/JCB debit.svg" alt="">
                                <img *ngIf="cardBrand === 'American Express'" src="assets/images/American Express.svg"
                                    alt="">
                                <img *ngIf="cardBrand === 'Discover'" src="assets/images/Discover card.svg" alt="">
                                <img *ngIf="cardBrand === 'Diners Club'" src="assets/images/Diners Club card.svg"
                                    alt="">
                            </div>
                            <div class="d-flex flex-column px-3">
                                <span class="para-text">{{cardBrand}}</span>
                                <span class="para-text">●●●● ●●●● ●●●● {{cardLast4Digit}}</span>
                            </div>
                        </div>
                        <button *ngIf="showaddedCard" [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}"
                            class="main-btn" (click)="replaceCard()">Replace Card</button>
                    </div>
                </div>
                <ng-container *ngIf="isPlanAvailable && !showaddedCard && showCard">
                    <div class="form-input-control">
                        <div class="form-group ">
                            <label>Payment Method</label>
                            <ngx-stripe-card [options]="cardOptions"
                                [elementsOptions]="elementsOptions"></ngx-stripe-card>
                        </div>
                        <div class="form-group-wrapper">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name on card"
                                    [(ngModel)]="paymentForm.name" [ngModelOptions]="{standalone: true}" name="fullName"
                                    #fullName="ngModel" required maxlength="50" pattern="^[A-Za-z\s]*$">
                                <small class="text-danger"
                                    *ngIf="fullName.errors && (paymentFormSubmitted || fullName.touched)">
                                    <span class="text-danger" *ngIf="fullName.errors?.required">Please
                                        enter
                                        cardholder name.</span>
                                    <span class="text-danger" *ngIf="fullName.errors?.pattern">Please enter
                                        valid name.</span>
                                </small>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Cardholder email"
                                    [(ngModel)]="paymentForm.email" [ngModelOptions]="{standalone: true}" name="email"
                                    #email="ngModel" required maxlength="50"
                                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$">
                                <small class="text-danger"
                                    *ngIf="email.errors && (paymentFormSubmitted || email.touched)">
                                    <span class="text-danger" *ngIf="email.errors?.required"> Please
                                        enter
                                        cardholder email.</span>
                                    <span class="text-danger" *ngIf="email.errors?.pattern">Please enter
                                        valid
                                        email pattern.</span>
                                </small>
                            </div>
                        </div>
                        <div class="form-group-wrapper">

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Address line 1"
                                    [(ngModel)]="paymentForm.addressLine1" [ngModelOptions]="{standalone: true}"
                                    name="addressLine1" #addressLine1="ngModel" required maxlength="255"
                                    pattern="^[a-zA-Z0-9\s,.'-/]{3,}$">
                                <small class="text-danger"
                                    *ngIf="addressLine1.errors && (paymentFormSubmitted || addressLine1.touched)">
                                    <span class="text-danger" *ngIf="addressLine1.errors?.required">Please
                                        enter address.</span>
                                    <span class="text-danger" *ngIf="addressLine1.errors?.pattern">Please
                                        enter
                                        valid address.</span>
                                </small>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Address line 2" maxlength="255"
                                    [(ngModel)]="paymentForm.addressLine2" [ngModelOptions]="{standalone: true}"
                                    name="addressLine2" #addressLine2="ngModel" pattern="^[a-zA-Z0-9\s,.'-/]{3,}$">
                                <small class="text-danger"
                                    *ngIf="addressLine2.errors?.pattern && (paymentFormSubmitted || addressLine2.touched)">Please
                                    enter valid address.</small>
                            </div>
                        </div>
                        <div class="form-group-wrapper">
                            <div class="form-group">
                                <ng-container *ngIf="countryList.length;else countryInput">
                                    <span class="app-dropdown d-block m-0">
                                        <select class="form-select" placeholder="Select Country"
                                            [(ngModel)]="paymentForm.country" name="country" required #country="ngModel"
                                            (change)="updateStateList($event)">
                                            <option selected disabled value="">Select Country</option>
                                            <option *ngFor="let ct of countryList" value="{{ct.code2}}">
                                                {{ct.name}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="country.errors?.required && (paymentFormSubmitted || country.touched)">Please
                                            enter country.</small>
                                    </span>
                                </ng-container>
                                <ng-template #countryInput>
                                    <input type="text" class="form-control" placeholder="Country" maxlength="25"
                                        [(ngModel)]="paymentForm.country" [ngModelOptions]="{standalone: true}"
                                        name="country" #country="ngModel" required>
                                    <small class="text-danger"
                                        *ngIf="country.errors?.required && (paymentFormSubmitted || country.touched)">Please
                                        enter country.</small>
                                </ng-template>
                            </div>
                            <div class="form-group">
                                <ng-container *ngIf="stateList.length;else stateInput">
                                    <span class="app-dropdown d-block m-0">
                                        <select class="form-select" placeholder="Select State"
                                            [(ngModel)]="paymentForm.state" name="state" required #state="ngModel">
                                            <option selected disabled value="">Select State</option>
                                            <option *ngFor="let ct of stateList" value="{{ct.code}}">
                                                {{ct.name}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="state.errors?.required && (paymentFormSubmitted || state.touched)">Please
                                            enter state.</small>
                                    </span>
                                </ng-container>
                                <ng-template #stateInput>
                                    <input type="text" class="form-control" placeholder="State" maxlength="25"
                                        [(ngModel)]="paymentForm.state" [ngModelOptions]="{standalone: true}"
                                        name="state" #state="ngModel" required>
                                    <small class="text-danger"
                                        *ngIf="state.errors?.required && (paymentFormSubmitted || state.touched)">Please
                                        enter state.</small>
                                </ng-template>
                            </div>
                        </div>
                        <div class="form-group-wrapper">
                            <div class="form-group">
                                <ng-container *ngIf="cityList.length;else cityInput">
                                    <span class="app-dropdown d-block m-0">
                                        <select class="form-select" placeholder="Select City"
                                            [(ngModel)]="paymentForm.city" name="city" required #city="ngModel">
                                            <option selected disabled value="">Select City</option>
                                            <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                                {{ct.cityName}}
                                            </option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="city.errors?.required && (paymentFormSubmitted || city.touched)">Please
                                            enter city.</small>
                                    </span>
                                </ng-container>
                                <ng-template #cityInput>
                                    <input type="text" class="form-control" placeholder="City" maxlength="25"
                                        [(ngModel)]="paymentForm.city" [ngModelOptions]="{standalone: true}" name="city"
                                        #city="ngModel" pattern="^[A-Za-z\s]*$" required>
                                    <small class="text-danger"
                                        *ngIf="city.errors && (paymentFormSubmitted || city.touched)">
                                        <span class="text-danger" *ngIf="city.errors?.required">Please
                                            enter
                                            city.</span>
                                        <span class="text-danger" *ngIf="city.errors?.pattern">Please enter
                                            valid city name.</span>
                                    </small>
                                </ng-template>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Zipcode" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    (ngModelChange)="zipValidation($event)" maxlength="12" minlength="3" [(ngModel)]="paymentForm.zipcode"
                                    [ngModelOptions]="{standalone: true}" name="zip" #zip="ngModel" required
                                    pattern="[0-9.]+">
                                <small class="text-danger" *ngIf="zip.errors && (paymentFormSubmitted || zip.touched)">
                                    <span class="text-danger" *ngIf="zip.errors?.required">Please enter
                                        zipcode.</span>
                                    <span class="text-danger" *ngIf="zip.errors?.pattern">Please enter valid
                                        zipcode.</span>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="save-btn mt-10">
                        <button *ngIf="!showaddedCard" [disabled]="paymentFormSubmitting"
                            [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}" class="main-btn"
                            (click)="createToken()">Add
                            Card</button>
                    </div>
                </ng-container>
            </div>
        </form>
    </div>
</section>