<div class="main-homepage" *ngIf="showPage">
    <section class="main-banner-wrapper">
        <div class="banner-img-wrapper">
            <div class="left-banner-color" [style.background-color]="appBGColor"></div>
            <img class="main-bg-image" [src]="pageCustomeData.mainBannerDTO.mainHomeBgImage" onload="style.opacity = 1"
                style="opacity: 0;object-fit: cover;">
        </div>
        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
        <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
        <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
        <div class="container info-wrapper" [style.margin-top.px]="logoSize-50">

            <section class="banner-container paddingx-10">
                <div>
                    <h1 class="banner-title" [ngStyle]="{'font-size.px':bannerFontSize}">
                        {{pageCustomeData.mainBannerDTO.title}}</h1>
                    <h3 class="banner-subtitle">{{pageCustomeData.mainBannerDTO.subTitle}}</h3>
                    <h2 class="banner-description">
                        {{pageCustomeData.mainBannerDTO.description}}
                    </h2>
                    <div class="trail-wrapper">
                        <h3 class="trail-title mb-20">{{pageCustomeData.mainBannerDTO.ctaTitle}}</h3>
                        <div class="join-btn mb-20">
                            <button class="joinin main-btn" (click)="buttonRoute(pageCustomeData.mainBannerDTO.ctaLink)"
                                [style.background-color]="appBtnPrimaryColor">
                                {{pageCustomeData.mainBannerDTO.ctaText}}
                            </button>
                            <div *ngIf="isFreeAccountActive" class="freeAccText" style="text-align: center;" [ngStyle]="{'width': pageCustomeData.mainBannerDTO.ctaText.length > 20 ? (pageCustomeData.mainBannerDTO.ctaText.length * 8.9 + 56) + 'px' : '225px'}">
                                <a (click)="navigateForFreeAccount()" class="cursor-pointer free-account"
                                    [ngStyle]="{'color':headerLinkColor}">Create free
                                    account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>

    <section class="media-container" *ngIf="showOrHideBrowseProgramSection">
        <div class="container paddingx-10">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="feature-catalog-title">{{browseProgramsTitle}}</h2>
                </div>
            </div>
            <div class="justify-content-center">
                <div class="row">
                    <div class="col-sm-6  col-md-4  col-lg-4 media-card" *ngFor="let item of mediadata"
                        (mouseover)="platform(item)">
                        <a class="main_poster" (click)="goToView(item)"
                            [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                            <span class="image-container"
                                [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                    [src]="item.mediaImg ? item.mediaImg : ''" alt="" onload="style.opacity = 1"
                                    style="opacity: 0;border-radius: 12px;" class="images">
                                <div *ngIf="pageCustomeData.webAppSettingDTO.showProgramDuration" class="duration">
                                    {{item.duration}}</div>
                            </span>
                        </a>
                        <a class="card-title title">
                            <span (mouseover)="hover=true" (mouseleave)="hover=false" (click)="goToView(item)"
                                [style.color]="hover===true && platFormTitle === item.title && platFormId === item.id ? appBtnPrimaryColor : '#fff'">
                                {{item.title}}
                            </span>
                        </a>
                        <a class="card-title card-sub-title-margin" *ngIf="item.subTitle">
                            <span (mouseover)="hover=true" (mouseleave)="hover=false" (click)="goToView(item)"
                                [style.color]="hover===true && platFormTitle === item.title && platFormId === item.id ? appBtnPrimaryColor : '#fff'">
                                {{item.subTitle}}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="main-platform" *ngIf="pageCustomeData.allPlatFormsDTO.showHideSection" [ngClass]="{'main-platform-top-margin': showOrHideBrowseProgramSection === false}"
        [ngStyle]="{'margin-top': showOrHideBrowseProgramSection === false ? '100px' : '0' }">
        <div class="container paddingx-10">
            <h2 class="platform-title">{{pageCustomeData.allPlatFormsDTO.title}}</h2>
            <p class="platform-subTitle">
                {{pageCustomeData.allPlatFormsDTO.subTitle}}
            </p>
            <div class="platform-devices">
                <div class="platform-tv">
                    <img class="platform-inner" [src]="allPlatFormImage" onload="style.opacity = 1"
                        style="opacity: 0;object-fit: cover;">

                    <img src="assets/images/4K-TV-MOCKUP.png" alt="">
                </div>
                <div class="platform-ipad">
                    <img class="platform-inner" [src]="allPlatFormImagePhone" onload="style.opacity = 1"
                        style="opacity: 0;object-fit: cover;">

                    <img src="assets/images/iPad 12.9 Mockup.png" alt="">
                </div>
                <div class="platform-macbook">
                    <img class="platform-inner" [src]="allPlatFormImage" onload="style.opacity = 1"
                        style="opacity: 0;object-fit: cover;">

                    <img src="assets/images/Macbook.svg" alt="">
                </div>
                <div class="platform-iphone">
                    <img class="platform-inner" [src]="allPlatFormImagePhone" onload="style.opacity = 1"
                        style="opacity: 0;object-fit: cover;">

                    <img src="assets/images/Phone mockup.png" alt="">
                </div>
                <div class="platform-external-device d-flex">
                    <a class="platform-apple-tv" *ngIf="pageCustomeData.allPlatFormsDTO.showHideAppleTV">
                        <img src="assets/images/Apple TV (1).png" alt="">
                    </a>
                    <a class="platform-amazon" *ngIf="pageCustomeData.allPlatFormsDTO.showHideAmazon">
                        <img src="assets/images/FireTV (1).png" alt="">
                    </a>
                    <a class="platform-roku" *ngIf="pageCustomeData.allPlatFormsDTO.showHideRokuTV">
                        <img src="assets/images/ROKU.png" alt="">
                    </a>
                </div>
            </div>
            <div class="platform-availablity">
                <h3 class="platform-availabilty-title">{{pageCustomeData.allPlatFormsDTO.titleForDevicesLogos}}</h3>
                <div class="platform-availabilty-logos">
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.androidLink"
                        class="platfor-available-img" *ngIf="pageCustomeData.allPlatFormsDTO.showHideAndroid">
                        <img src="assets/images/android (1).png" alt="" class="android">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.iphoneLink" class="platfor-available-img"
                        *ngIf="pageCustomeData.allPlatFormsDTO.showHideIPhone">
                        <img src="assets/images/iPhone (1).png" alt="" class="iphone">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.amazonLink" class="platfor-available-img"
                        *ngIf="pageCustomeData.allPlatFormsDTO.showHideAmazon">
                        <img src="assets/images/firetvlogo (1).png" alt="" class="firetv" style="    height: 47px;
                        margin-top: 3px;">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.appleTVLink"
                        class="platfor-available-img" *ngIf="pageCustomeData.allPlatFormsDTO.showHideAppleTV">
                        <img src="assets/images/Apple TV (3).png" alt="" class="appletv" style="height: 40px;
                        margin-top: -4px;">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.rokuTVLink" class="platfor-available-img"
                        *ngIf="pageCustomeData.allPlatFormsDTO.showHideRokuTV">
                        <img src="assets/images/Roku (1).png" alt="" class="roku" style="height: 30px;
                        margin-top: 2px;">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.androidTVLink"
                        class="platfor-available-img" *ngIf="pageCustomeData.allPlatFormsDTO.showHideAndroidTV">
                        <img src="assets/images/android tv (1).png" class="androidtv" alt="" style="margin-top: 4px;">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.youtubeLink"
                        class="platfor-available-img" *ngIf="pageCustomeData.allPlatFormsDTO.showHideYoutube">
                        <img src="assets/images/youtube.svg" alt="" class="youtube" style="    margin-top: 2px;
                        height: 38px;">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.googleTvLink"
                        class="platfor-available-img" *ngIf="pageCustomeData.allPlatFormsDTO.showHideGoogleTv">
                        <img src="assets/images/google tv.svg" class="googletv" alt="" style="height: 29px;
                        margin-top: 12px; margin-left: 7px;">
                    </a>
                    <a target="_blank" [href]="pageCustomeData.allPlatFormsDTO.cromeCastLink"
                        class="platfor-available-img" *ngIf="pageCustomeData.allPlatFormsDTO.showHideCromeCast">
                        <img src="assets/images/cromecast.png" class="cromecast" alt="" style="height: 45px;
                        margin-top: 5px; margin-left: 7px;">
                    </a>
                </div>
            </div>

            <div class="static-logo"
                [ngStyle]="{'top': !pageCustomeData.bottomCallActionDTO.showHideBottomCallToAction ? '-80px' : '-30px' }">
                <div class="logo">
                    <a routerLink="/main">
                        <img [src]="pageCustomeData.headerDTO.headerLogo" alt="" onload="style.opacity = 1"
                            [ngStyle]="{'width': bottomLogoSize + '%' }" style="opacity: 0;">
                    </a>
                    <!-- <p>{{homeLogo}}</p> -->
                </div>
            </div>
        </div>
    </section>

    <section class="trail-section" *ngIf="pageCustomeData.bottomCallActionDTO.showHideBottomCallToAction">
        <div class="container">
            <h2 class="trail-title">{{pageCustomeData.bottomCallActionDTO.title}}</h2>
            <p class="trail-subtitle">{{pageCustomeData.bottomCallActionDTO.subTitle}}</p>
            <div class="join-btn">
                <button (click)="buttonRoute(pageCustomeData.bottomCallActionDTO.ctaLink)" class="joinin main-btn"
                    [style.background-color]="appBtnPrimaryColor">
                    {{pageCustomeData.bottomCallActionDTO.ctaText}}
                </button>
            </div>
        </div>
    </section>

    <section class="footer">
        <!-- <app-footer></app-footer> -->
        <div class="footer-background" [ngStyle]="{'background-image': 'url(' + footerBgImage + ')'}"></div>
        <div class="footer-shadow" [style.background-image]="footerBlur"></div>
        <div class="container footer-bottom">
            <div class="footer-wrapper col-lg-12 align-items-center">
                <div class="copyright col-lg-5">
                    &copy; {{footerDetail ? footerDetail.copyright : ''}}
                </div>
                <div class="social-wrapper col-lg-5" style="padding-left: 15px;">
                    <div class="social-media">
                        <div class="social-icons">
                            <!-- <img [src]="item.socialImg" (click)="goToSocialLink(item)" alt=""  onload="style.opacity = 1" style="opacity: 0;"> -->
                            <a class="ml-29" [href]="instagramLink" *ngIf="instagramLink" target="_blank"><i
                                    class="fa fa-instagram"></i></a>
                            <a class="ml-29" [href]="facebookLink" *ngIf="facebookLink" target="_blank"><i
                                    class="fa fa-facebook-square"></i></a>
                            <a class="ml-29" [href]="youtubeLink" *ngIf="youtubeLink" target="_blank"><i
                                    class="fa fa-youtube-play"></i></a>
                            <a class="ml-29" [href]="twitterLink" *ngIf="twitterLink" target="_blank"><i
                                    class="fa fa-twitter-square"></i></a>
                        </div>
                    </div>

                </div>
                <div class="poweredBy col-lg-2">
                    <p class="d-flex align-items-center">Powered By
                        <span style="max-width:85px;">
                            <a *ngIf="!isWhiteLabel" href="https://www.nowcast.cc/" target="_blank">
                                <img style="width: 85px;filter: brightness(1.3);"
                                    src="assets/images/Nowcast bottom logo.svg" alt="">
                            </a>
                            <a *ngIf="isWhiteLabel" href="" target="_blank">
                                <img style="width: 85px;filter: brightness(1.3);margin-left: 7px;"
                                    [src]="headerLogo" alt="">
                            </a>
                        </span>
                    </p>
                </div>

            </div>
        </div>
    </section>
</div>