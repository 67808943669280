import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';

@Injectable()
export class IframePlayerResolver implements Resolve<any> {
  constructor(private _HomeService: HomeService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const id = route.params.id;
    return this._HomeService
      .getMediaDataByIdWithout(id)
      .toPromise()
      .then((data) => data);
  }
}
