<div class="main-signup">
    <div class="col-12" style="position: relative;">
        <div class="d-flex container-flex">
            <div class="col-7 left-panel" [style.background-color]="!isNoPlanSelected ? appBGColor : ''"
                [ngStyle]="{'height': loginUserDetail ? '100vh' : showlogin ? '100vh' : '','overflow':isNoPlanSelected?'hidden':''}">
                <div class="left-content"
                    [ngClass]="[!showBilling  && !showlogin && showOffers ? 'inheritStyle' : '', showlogin ? 'signinFormposition' : '']">
                    <div class="logo" [ngClass]="[showlogin ? 'loginPageActive':'']"
                        [ngClass]="{'margin-top-150':paymentFormSubmitted}">
                        <a routerLink="/main">
                            <img [src]="logo" id="mainLogo" alt="">
                        </a>
                    </div>

                    <div class="profile-form form-wrapper" *ngIf="!showBilling  && !showlogin && !showOffers">
                        <div class="breadcrumbs" [ngClass]="{transparent: !showBreadcrumbs}">
                            <div class="step">Choose offer</div>
                            <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div class="step" [ngClass]="{'active': !showBilling}"
                                [ngStyle]="{'color': !showBilling ? primaryColor : ''}">Customer information</div>
                            <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div class="step" [ngClass]="{'active': showBilling}"
                                [ngStyle]="{'color': showBilling ? primaryColor : ''}">Complete order</div>
                        </div>
                        <h4 *ngIf="!loginUserDetail" class="mb-20">Create an account</h4>
                        <h4 *ngIf="loginUserDetail" class="mb-20">Customer information</h4>
                        <form #signInForm="ngForm">
                            <div class="d-flex" style="flex-direction: column;">
                                <div class="signupForm-wrapper" *ngIf="!loginUserDetail">

                                    <!-- <div class="form-group">
                                    <label>Full Name</label>
                                    <input type="text" [(ngModel)]="signup.basicInfo.fullName"
                                     name="fullName" #fullName="ngModel" class="form-control" required>
                                </div> -->
                                    <div class="form-group-wrapper">
                                        <div class="form-group">
                                            <!-- <label>First Name</label> -->
                                            <input type="text" placeholder="First Name *"
                                                [(ngModel)]="signup.basicInfo.firstName" name="fullName"
                                                #fullName="ngModel" class="form-control" required>
                                        </div>
                                        <div class="form-group">
                                            <!-- <label>Last Name</label> -->
                                            <input type="text" placeholder="Last Name"
                                                [(ngModel)]="signup.basicInfo.lastName" name="fullName"
                                                #fullName="ngModel" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-group-wrapper">
                                        <div class="form-group">
                                            <!-- <label>Email</label> -->
                                            <input type="text" placeholder="Email *"
                                                [(ngModel)]="signup.basicInfo.email" name="email" #email="ngModel"
                                                required
                                                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                                                class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <!-- <label>Mobile</label> -->
                                            <input type="number" placeholder="Mobile *" minlength="5" maxlength="15"
                                                [(ngModel)]="signup.basicInfo.mobileNumber" name="mobileNumber"
                                                #mobileNumber="ngModel" required pattern="^[0-9]*$"
                                                class="form-control">
                                        </div>
                                    </div>

                                    <div class="form-group-wrapper">
                                        <div class="form-group">
                                            <!-- <label>Password</label> -->
                                            <span class="app-password">
                                                <input [type]="passwordType ? 'text' : 'password'"
                                                    [(ngModel)]="signup.password" placeholder="Password *" onkeydown="if(['Space'].includes(arguments[0].code)){return false;}"
                                                    name="postalCode" #postalCode="ngModel" required pattern="^[0-9]*$"
                                                    class="form-control" />
                                                <span class="password-icon">
                                                    <i *ngIf="passwordType" [class.view]="passwordType"
                                                        (click)="passwordType = !passwordType" class="fa fa-eye"
                                                        aria-hidden="true"></i>
                                                    <i *ngIf="!passwordType" [class.view]="passwordType"
                                                        (click)="passwordType = !passwordType" class="fa fa-eye-slash"
                                                        aria-hidden="true"></i>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <!-- <label>Password</label> -->
                                            <span class="app-password">
                                                <input [type]="confirmPasswordType ? 'text' : 'password'"
                                                    [(ngModel)]="signup.confirmPassword" onkeydown="if(['Space'].includes(arguments[0].code)){return false;}"
                                                    placeholder="Confirm Password *" name="postalCode"
                                                    #postalCode="ngModel" required pattern="^[0-9]*$"
                                                    class="form-control" />
                                                <span class="password-icon">
                                                    <i *ngIf="confirmPasswordType" [class.view]="confirmPasswordType"
                                                        (click)="confirmPasswordType = !confirmPasswordType"
                                                        class="fa fa-eye" aria-hidden="true"></i>
                                                    <i *ngIf="!confirmPasswordType" [class.view]="confirmPasswordType"
                                                        (click)="confirmPasswordType = !confirmPasswordType"
                                                        class="fa fa-eye-slash" aria-hidden="true"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group-wrapper" style="margin-left: 5px;">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" [value]="true"
                                                [(ngModel)]="isTermAndCondition" [ngModelOptions]="{standalone: true}"
                                                id="termsandcondtion">
                                            <label class="form-check-label" for="termsandcondtion">
                                                By clicking, you agree to our 
                                                    <span *ngIf="!isWhiteLabel">
                                                        <a [ngStyle]="{ 'color': primaryColor ? primaryColor : '#fff'}"
                                                        href="https://www.nowcast.cc/terms-of-conditions" target="_blank"
                                                        style="cursor: pointer;"> Terms of Service</a> and <a
                                                        [ngStyle]="{ 'color': primaryColor ? primaryColor : '#fff'}"
                                                        href="https://www.nowcast.cc/privacy-policy" target="_blank"
                                                        style="cursor: pointer;"> Privacy Policy</a>
                                                    </span>
                                                    <span *ngIf="isWhiteLabel">
                                                        <a [ngStyle]="{ 'color': primaryColor ? primaryColor : '#fff'}"
                                                        [href]="termsOfUse" target="_blank"
                                                        style="cursor: pointer;"> Terms of Service</a> and <a
                                                        [ngStyle]="{ 'color': primaryColor ? primaryColor : '#fff'}"
                                                        [href]="privacyPolicy" target="_blank"
                                                        style="cursor: pointer;"> Privacy Policy</a>
                                                    </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="signupCard" *ngIf="loginUserDetail">
                                    <div class="usercard">
                                        <div class="img">{{nameLetter}}
                                        </div>
                                        <div class="userInfo">
                                            <div class="userName">
                                                {{userName}}
                                            </div>
                                            <div class="userMail">
                                                {{userMail}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button-wrapper d-flex">
                                    <div class="cancel-btn mt-10">
                                        <button (click)="cancelOnCustomerInfo()" class="main-btn"
                                            *ngIf="!vedioId">Cancel</button>
                                        <button *ngIf="vedioId" (click)="goBackFromForm()"
                                            class="main-btn">Cancel</button>
                                    </div>
                                    <div class="save-btn mt-10">
                                        <button *ngIf="!loginUserDetail"
                                            [ngClass]="{disable: !signup.basicInfo.firstName || !signup.basicInfo.email || !signup.basicInfo.mobileNumber || !signup.password || !signup.confirmPassword || !isTermAndCondition}"
                                            [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                            class="main-btn" type="submit" (click)="createUser()">Continue</button>
                                        <button *ngIf="loginUserDetail"
                                            [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                            class="main-btn" type="submit" (click)="createUser()">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="session-actions d-flex items-center justify-center"
                            *ngIf="!loginUserDetail && !showlogin && !loginRequired">
                            <div class="w-100">Already have an account? <span><a
                                        [ngStyle]="{'color': primaryColor ? primaryColor : ''}"
                                        (click)="showlogin =true">Sign in</a></span></div>
                        </div>
                    </div>

                    <div class="profile-form form-wrapper" *ngIf="!showBilling  && !showlogin && showOffers">
                        <div class="breadcrumbs">
                            <div class="step" [ngClass]="{'active': showOffers}"
                                [ngStyle]="{'color': showOffers ? primaryColor : ''}">Choose offer</div>
                            <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div class="step">Customer information</div>
                            <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div class="step">Complete order</div>
                        </div>
                        <h4 class="mb-20">Choose offer</h4>
                        <form #signInForm="ngForm">
                            <div class="d-flex" style="flex-direction: column;">
                                <div class="plans-container">
                                    <div class="plan-block mb-20"
                                        *ngFor="let item of subscriptionPlanList;let i = index;">
                                        <label for="selected-image-{{i}}" [ngClass]="{disable: !item.disable}">
                                            <div class="plans" [ngClass]="{disable: !item.disable}"
                                                [ngStyle]="{'border-color': item.selectedImage === true ? primaryColor : '#fff'}">
                                                <div class="radio-btn">
                                                    <input type="radio" id="selected-image-{{i}}"
                                                        [checked]="item.selectedImage" (change)="selectPlan(item)">
                                                </div>
                                                <!-- <div class="plan-img"> 
                                            <img [src]="item.planImg" alt="">
                                        </div> -->
                                                <div class="plan-content">
                                                    <div class="plan-info">
                                                        <div class="plan-title">
                                                            <p>{{item.name}}</p>
                                                        </div>
                                                        <div class="plan-desc">
                                                            <!-- ${{item.price}}/{{item.planDurationName}} -->
                                                            Gain access to the items on a recurring basis
                                                        </div>
                                                    </div>
                                                    <div class="plan-info">
                                                        <div class="plan-title">
                                                            <span>${{item.price}}/{{item.planDurationName}}</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="plan-action">
                                                <a *ngIf="showbtn" [ngStyle]="{'background-color': changeToRGB ? changeToRGB : '' , 'color': primaryColor ? primaryColor : '#fff'}" (click)="choosePlan(item)" class="choose-plan">Choose Plan</a>
                                            </div> -->
                                                </div>

                                            </div>
                                        </label>
                                    </div>

                                    <div class="unsubscribe-wrapper" *ngIf="loginUserDetail">
                                        <p [ngStyle]="{'color': primaryColor ? primaryColor : '#006aff'}"
                                            (click)="unsubscribeModal=true">Unsubscribe Plan</p>
                                    </div>
                                </div>

                                <div class="button-wrapper d-flex justify-content-between">
                                    <div class="cancel-btn mt-10">
                                        <button (click)="gotoView()" class="main-btn">Cancel</button>
                                    </div>
                                    <div class="save-btn mt-10">
                                        <button
                                            [ngClass]="{disable: !planSelected || disableContinueBtn || clickFrom === 'billing'}"
                                            [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                            class="main-btn"
                                            (click)="showBilling = false;showlogin=false;showOffers=false">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="profile-form form-wrapper" *ngIf="showBilling  && !showlogin && !showOffers">
                        <div class="breadcrumbs">
                            <div class="step">Choose offer</div>
                            <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div class="step" [ngClass]="{'active': !showBilling}"
                                [ngStyle]="{'color': !showBilling ? primaryColor : ''}">Customer information</div>
                            <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                            <div class="step" [ngClass]="{'active': showBilling}"
                                [ngStyle]="{'color': showBilling ? primaryColor : ''}">Complete order</div>
                        </div>
                        <h4 class="mb-20">Billing Details</h4>
                        <form #signInForm="ngForm">
                            <div class="d-flex form-input-control" style="flex-direction: column;">
                                <ng-container *ngIf="!showaddedCard">
                                    <div class="form-group" style="width:96.30%">
                                        <ngx-stripe-card [options]="cardOptions"
                                            [elementsOptions]="elementsOptions"></ngx-stripe-card>
                                    </div>
                                    <div class="form-group-wrapper">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Name on card"
                                                [(ngModel)]="paymentForm.name" [ngModelOptions]="{standalone: true}"
                                                name="fullName" #fullName="ngModel" required maxlength="50"
                                                pattern="^[A-Za-z\s]*$">
                                            <small class="text-danger"
                                                *ngIf="fullName.errors && (paymentFormSubmitted || fullName.touched)">
                                                <span class="text-danger" *ngIf="fullName.errors?.required">Please
                                                    enter
                                                    cardholder name.</span>
                                                <span class="text-danger" *ngIf="fullName.errors?.pattern">Please enter
                                                    valid name.</span>
                                            </small>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Cardholder email"
                                                [(ngModel)]="paymentForm.email" [ngModelOptions]="{standalone: true}"
                                                name="email" #email="ngModel" required maxlength="50"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                            <small class="text-danger"
                                                *ngIf="email.errors && (paymentFormSubmitted || email.touched)">
                                                <span class="text-danger" *ngIf="email.errors?.required"> Please
                                                    enter
                                                    cardholder email.</span>
                                                <span class="text-danger" *ngIf="email.errors?.pattern">Please enter
                                                    valid
                                                    email pattern.</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="form-group-wrapper">

                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Address line 1"
                                                [(ngModel)]="paymentForm.addressLine1"
                                                [ngModelOptions]="{standalone: true}" name="addressLine1"
                                                maxlength="255" #addressLine1="ngModel" required
                                                pattern="^[a-zA-Z0-9\s,.'-/]{3,}$">
                                            <small class="text-danger"
                                                *ngIf="addressLine1.errors && (paymentFormSubmitted || addressLine1.touched)">
                                                <span class="text-danger" *ngIf="addressLine1.errors?.required">Please
                                                    enter address.</span>
                                                <span class="text-danger" *ngIf="addressLine1.errors?.pattern">Please
                                                    enter
                                                    valid address.</span>
                                            </small>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Address line 2"
                                                [(ngModel)]="paymentForm.addressLine2"
                                                [ngModelOptions]="{standalone: true}" name="addressLine2"
                                                maxlength="255" #addressLine2="ngModel"
                                                pattern="^[a-zA-Z0-9\s,.'-]{3,}$">
                                            <small class="text-danger"
                                                *ngIf="addressLine2.errors?.pattern && (paymentFormSubmitted || addressLine2.touched)">Please
                                                enter valid address.</small>
                                        </div>
                                    </div>
                                    <div class="form-group-wrapper">
                                        <div class="form-group">
                                            <ng-container *ngIf="countryList.length;else countryInput">
                                                <span class="app-dropdown d-block m-0 input-width">
                                                    <select class="form-select" placeholder="Select Country"
                                                        [(ngModel)]="paymentForm.country" name="country" required
                                                        #country="ngModel" (change)="updateStateList($event)">
                                                        <option selected disabled value="">Select Country</option>
                                                        <option *ngFor="let ct of countryList" value="{{ct.code2}}">
                                                            {{ct.name}}
                                                        </option>
                                                    </select>
                                                    <small class="text-danger"
                                                        *ngIf="country.errors?.required && (paymentFormSubmitted || country.touched)">Please
                                                        enter country.</small>
                                                </span>
                                            </ng-container>
                                            <ng-template #countryInput>
                                                <input type="text" class="form-control" placeholder="Country"
                                                    [(ngModel)]="paymentForm.country" maxlength="25"
                                                    [ngModelOptions]="{standalone: true}" name="country"
                                                    #country="ngModel" required>
                                                <small class="text-danger"
                                                    *ngIf="country.errors?.required && (paymentFormSubmitted || country.touched)">Please
                                                    enter country.</small>
                                            </ng-template>
                                        </div>
                                        <div class="form-group">
                                            <ng-container *ngIf="stateList.length;else stateInput">
                                                <span class="app-dropdown d-block m-0 input-width">
                                                    <select class="form-select" placeholder="Select State"
                                                        [(ngModel)]="paymentForm.state" name="state" required
                                                        #state="ngModel">
                                                        <option selected disabled value="">Select State</option>
                                                        <option *ngFor="let ct of stateList" value="{{ct.code}}">
                                                            {{ct.name}}
                                                        </option>
                                                    </select>
                                                    <small class="text-danger"
                                                        *ngIf="state.errors?.required && (paymentFormSubmitted || state.touched)">Please
                                                        enter state.</small>
                                                </span>
                                            </ng-container>
                                            <ng-template #stateInput>
                                                <input type="text" class="form-control" placeholder="State"
                                                    [(ngModel)]="paymentForm.state" maxlength="15"
                                                    [ngModelOptions]="{standalone: true}" name="state" #state="ngModel"
                                                    required>
                                                <small class="text-danger"
                                                    *ngIf="state.errors?.required && (paymentFormSubmitted || state.touched)">Please
                                                    enter state.</small>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="form-group-wrapper">
                                        <div class="form-group">
                                            <ng-container *ngIf="cityList.length;else cityInput">
                                                <span class="app-dropdown d-block m-0 input-width">
                                                    <select class="form-select" placeholder="Select City"
                                                        [(ngModel)]="paymentForm.city" name="city" required
                                                        #city="ngModel">
                                                        <option selected disabled value="">Select City</option>
                                                        <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                                            {{ct.cityName}}
                                                        </option>
                                                    </select>
                                                    <small class="text-danger"
                                                        *ngIf="city.errors?.required && (paymentFormSubmitted || city.touched)">Please
                                                        enter city.</small>
                                                </span>
                                            </ng-container>
                                            <ng-template #cityInput>
                                                <input type="text" class="form-control" placeholder="City"
                                                    maxlength="15" [(ngModel)]="paymentForm.city"
                                                    [ngModelOptions]="{standalone: true}" name="city" #city="ngModel"
                                                    pattern="^[A-Za-z\s]*$" required>
                                                <small class="text-danger"
                                                    *ngIf="city.errors && (paymentFormSubmitted || city.touched)">
                                                    <span class="text-danger" *ngIf="city.errors?.required">Please
                                                        enter
                                                        city.</span>
                                                    <span class="text-danger" *ngIf="city.errors?.pattern">Please enter
                                                        valid city name.</span>
                                                </small>
                                            </ng-template>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Zipcode" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                [(ngModel)]="paymentForm.zipcode" name="zip" maxlength="12" minlength="3" required
                                                pattern="[[0-9.]+" #zip="ngModel">
                                            <small class="text-danger"
                                                *ngIf="zip.errors && (paymentFormSubmitted || zip.touched)">
                                                <span class="text-danger" *ngIf="zip.errors?.required">Please enter
                                                    zipcode.</span>
                                                <span class="text-danger" *ngIf="zip.errors?.pattern">Please enter valid
                                                    zipcode.</span>
                                            </small>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="form-group" *ngIf="showaddedCard">
                                    <div class="cardInfo">
                                        <div class="d-flex align-items-center">
                                            <div class=" d-flex justify-content-center align-items-center">
                                                <img *ngIf="cardBrand === 'MasterCard'"
                                                    src="assets/images/Mask Group 30.png" alt="">
                                                <img *ngIf="cardBrand === 'Visa'" src="assets/images/Visa debit.svg"
                                                    alt="">
                                                <img *ngIf="cardBrand === 'UnionPay'"
                                                    src="assets/images/Unionpay card.svg" alt="">
                                                <img *ngIf="cardBrand === 'JCB'" src="assets/images/JCB debit.svg"
                                                    alt="">
                                                <img *ngIf="cardBrand === 'American Express'"
                                                    src="assets/images/American Express.svg" alt="">
                                                <img *ngIf="cardBrand === 'Discover'"
                                                    src="assets/images/Discover card.svg" alt="">
                                                <img *ngIf="cardBrand === 'Diners Club'"
                                                    src="assets/images/Diners Club card.svg" alt="">
                                            </div>
                                            <div class="d-flex flex-column px-3">
                                                <span class="para-text">{{cardBrand}}</span>
                                                <span class="para-text">●●●● ●●●● ●●●● {{cardLast4Digit}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="button-wrapper d-flex justify-content-between">
                                    <div class="cancel-btn mt-10">
                                        <button (click)="cancelPayment()" class="main-btn">Cancel</button>
                                    </div>
                                    <div class="save-btn mt-10">
                                        <button *ngIf="!cardData" [disabled]="isContinueDisabled"
                                            [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                            class="main-btn" (click)="createToken( signInForm)">Complete</button>
                                        <button *ngIf="cardData" [disabled]="isContinueDisabled"
                                            [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                            class="main-btn" (click)="pay()">Complete</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="profile-form form-wrapper" style="margin-left: -12px;" *ngIf="showlogin">
                        <ng-container *ngIf="!isNoPlanSelected">
                            <div class="breadcrumbs">
                                <div class="step">Choose offer</div>
                                <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                                <div class="step" [ngClass]="{'active': !showBilling}"
                                    [ngStyle]="{'color': !showBilling ? primaryColor : ''}">Customer information</div>
                                <div class="arrow-icon"><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
                                <div class="step" [ngClass]="{'active': showBilling}"
                                    [ngStyle]="{'color': showBilling ? primaryColor : ''}">Complete order</div>
                            </div>
                        </ng-container>
                        <h4 class="mb-20">Sign in</h4>
                        <form #signInForm="ngForm" (ngSubmit)="loginUser()">
                            <div class="d-flex" style="flex-direction: column;">
                                <div class="form-group">
                                    <!-- <label>Email</label> -->
                                    <input type="text" placeholder="Email" style="width: 100% !important;"
                                        [(ngModel)]="login.username" name="email" #email="ngModel" required
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control">
                                </div>

                                <div class="form-group">
                                    <!-- <label>Password</label> -->
                                    <span class="app-password">
                                        <input placeholder="Password" style="width: 100% !important;"
                                            [type]="passwordTypeLogin ? 'text' : 'password'"
                                            [(ngModel)]="login.password" name="password" #email="ngModel" required onkeydown="if(['Space'].includes(arguments[0].code)){return false;}"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control" />
                                        <span class="password-icon">
                                            <!-- <i [class.view]="passwordTypeLogin" (click)="passwordTypeLogin = !passwordTypeLogin" class="fa fa-eye" aria-hidden="true"></i> -->
                                            <i *ngIf="passwordTypeLogin" [class.view]="passwordTypeLogin"
                                                (click)="passwordTypeLogin = !passwordTypeLogin" class="fa fa-eye"
                                                aria-hidden="true"></i>
                                            <i *ngIf="!passwordTypeLogin" [class.view]="passwordTypeLogin"
                                                (click)="passwordTypeLogin = !passwordTypeLogin" class="fa fa-eye-slash"
                                                aria-hidden="true"></i>
                                        </span>
                                    </span>
                                    <!-- <input type="password" [(ngModel)]="login.password" name="email"
                                #email="ngModel" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control"> -->
                                    <div class="forgot-password">
                                        <span [ngStyle]="{'color': primaryColor ? primaryColor : '#006aff'}"
                                            (click)="showBasicDialog()">
                                            Forgot Password?
                                        </span>
                                    </div>
                                </div>

                                <div class="button-wrapper d-flex justify-content-between">
                                    <div class="cancel-btn mt-10">
                                        <button type="reset" (click)="showBilling = false;showlogin = false"
                                            class="main-btn">Cancel</button>
                                    </div>
                                    <div class="save-btn mt-10">
                                        <button type="submit"
                                            [ngClass]="{ disable: !login.username || !login.password }"
                                            [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                            class="main-btn" (click)="loginUser()">Continue </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="session-actions d-flex items-center justify-center"
                            *ngIf="!loginUserDetail && showlogin">
                            <div class="w-100">New here? <span><a
                                        [ngStyle]="{'color': primaryColor ? primaryColor : ''}"
                                        (click)="showlogin =false">Sign up</a></span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-side col-5" [style.background]="rightWrapperColor"
                [ngStyle]="{'height': loginUserDetail ? '100vh' : showlogin ? '100vh' : ''}"
                *ngIf="!isNoPlanSelected;else noPlan">
                <div class="right-side-wrapper">
                    <div class="right-side-bgImg" [ngStyle]="{'background-image': 'url(' + subPlanImg  + ')'}">
                    </div>
                    <div class="right-side-darkBg"></div>
                    <div class="plan-card">
                        <div class="image-wrapper">
                            <img [src]="subPlanImg" onload="style.opacity = 1" style="opacity: 0;" alt=""
                                *ngIf="!vedioId">
                            <img [src]="currentVedioImg" onload="style.opacity = 1" style="opacity: 0;" alt=""
                                *ngIf="vedioId">
                        </div>
                        <div class="plan-detail" *ngIf="!vedioId">{{planDuration}} Access for ${{subPlanAmount}}
                            {{planDurationName}},</div>
                        <div class="plan-detail" *ngIf="vedioId">{{currentVedioTitle}}</div>
                        <div class="type" *ngIf="!vedioId">Subscription</div>
                        <div class="description" *ngIf="!vedioId">{{subPlanDesc}}</div>
                        <div class="description" *ngIf="vedioId">{{currentVedioDesc}}</div>
                        <div class="description" *ngIf="ItemPrice">Pay ${{ItemPrice}} for this item</div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noPlan>
            <div class="main-banner" *ngIf="scrWidth > 800">
                <div class="banner-img-wrapper">
                    <div class="left-banner-color" [style.background-color]="appBGColor"></div>
                    <img class="main-bg-image" [src]="loginImg" onload="style.opacity = 1"
                        style="opacity: 0;object-fit: cover;">
                </div>
                <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
                <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
                <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
            </div>
        </ng-template>
    </div>
</div>


<p-dialog [(visible)]="displayBasic" [modal]="true" [style]="{width: '35vw'}" [breakpoints]="{'650px': '90vw'}"
    [draggable]="false" [resizable]="false">
    <form #forgotPasswordForm="ngForm" class="forgot-password-popup">
        <div class="d-flex" style="flex-direction: column;">
            <div class="form-group">
                <label>Reset password</label>
                <input type="text" placeholder="Enter your email" [(ngModel)]="forgotEmail" name="email" 
                    #email="ngModel" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control">
            </div>


        </div>
        <div class="button-wrapper d-flex" style="margin-left: 0;">
            <div class="save-btn mt-10">
                <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" class="main-btn"
                    (click)="forgotPasswordLink()">Send Instructions</button>
            </div>
            <div class="cancel-btn mt-10">
                <button
                    [ngStyle]="{'background-color': changeToRGB ? changeToRGB : '' , 'color': primaryColor ? primaryColor : '#fff'}"
                    (click)="displayBasic = false" class="main-btn">Cancel</button>
            </div>
        </div>
    </form>
</p-dialog>


<p-dialog [(visible)]="displayPlan" [modal]="true" [style]="{width: '35vw'}" [breakpoints]="{'650px': '90vw'}"
    [closable]="false" [draggable]="false" [resizable]="false">
    <form #forgotPasswordForm="ngForm" class="forgot-password-popup">
        <div class="d-flex" style="flex-direction: column;">
            <p>Are you sure you want to change the subscription?</p>
        </div>
        <div class="button-wrapper d-flex" style="margin-left: 0;">
            <div class="save-btn mt-10" style="margin-right:10px">
                <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" class="main-btn"
                    (click)="changePlan()">Yes</button>
            </div>
            <div class="cancel-btn mt-10">
                <button
                    [ngStyle]="{'background-color': changeToRGB ? changeToRGB : '' , 'color': primaryColor ? primaryColor : '#fff'}"
                    (click)="cancelPlan()" class="main-btn">No</button>
            </div>
        </div>
    </form>
</p-dialog>



<p-dialog [(visible)]="unsubscribeModal" [modal]="true" [style]="{width: '35vw'}" [breakpoints]="{'650px': '90vw'}"
    [closable]="false" [draggable]="false" [resizable]="false">
    <form #forgotPasswordForm="ngForm" class="forgot-password-popup">
        <div class="d-flex" style="flex-direction: column;">
            <h1 style="font-size: 16px; margin-bottom: 15px;font-weight: 800;">Unsubscribe Plan</h1>
            <p>Please reach us to unsubscribe your current plan</p>
        </div>
        <div class="button-wrapper d-flex" style="margin-left: 0;">
            <div class="save-btn mt-10" style="margin-right:10px">
                <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" class="main-btn"
                    routerLink="/contact-us">Contact US</button>
            </div>
            <div class="cancel-btn mt-10">
                <button
                    [ngStyle]="{'background-color': changeToRGB ? changeToRGB : '' , 'color': primaryColor ? primaryColor : '#fff'}"
                    (click)="unsubscribeModal = false" class="main-btn">Cancel</button>
            </div>
        </div>
    </form>
</p-dialog>