<!-- <app-header></app-header> -->
<div class="main-watch-live" *ngIf="showPageAfterLoad">
    <div style="position: relative;" *ngIf="products.length>0">

        <div class="main-carousal" [id]="logoSizeRoundValue">
            <ngb-carousel style="outline: none;" [pauseOnHover]="false" [pauseOnFocus]="false" 
                [showNavigationArrows]="true" (slide)="onSlide($event)" [showNavigationIndicators]="true">
                <ng-template ngbSlide *ngFor="let slide of products;let i = index;count as c" ngbSlide [id]="slide.id">
                    <!-- <div class="wrapper">
                        <img *ngIf="slide.isVideo === false"
                            [src]="slide.mediaAccessType === 'FREE' ? slide.mediaImg : ''" alt=""
                            onload="style.opacity = 0.6" style="opacity: 0;">
                        <div plyr class="vedioplayer" *ngIf="slide.isVideo === true && slide.vedioType === false "
                            style="width: 100%;position: absolute;top: 0;" plyrTitle="Video 1" [plyrPlaysInline]="true"
                            [plyrOptions]="options" [plyrSources]="slide.currentVedio" (plyrInit)="players = $event"
                            (plyrPlay)="played($event,i)"></div>
                        <div class="vedioplayer" *ngIf="slide.isVideo === true && slide.vedioType === true"
                            id="{{'vedioPlayer'[i]}}" style="width: 100%;z-index: 2;position: absolute;top: 0;" plyr
                            [plyrPlaysInline]="true" [plyrCrossOrigin]="true" [plyrDriver]="test(i)"
                            [plyrOptions]="options" [plyrSources]="slide.currentVedio" (plyrPlay)="played($event,i)"
                            (plyrInit)="players = $event"></div>
                    </div> -->
                    <div class="wrapper">
                        <img *ngIf="slide.isVideo === false"
                            [src]="slide.mediaAccessType === 'FREE' ? slide.mediaImg : ''" alt=""
                            onload="style.opacity = 0.6" style="opacity: 0;">
                        <div class="vedioplayer" *ngIf="slide.isVideo === true && slide.vedioType === false "
                            style="width: 100%;position: absolute;top: 0;">
                            <app-video-player (playInitiation)="played($event)" 
                                [options]="{from: 'vod',autoplay: true ? 'play':false, controls: false, sources: [{ src: slide.currentVedio ? slide.currentVedio : '',type:slide.videoJsPlayerType }]}">
                            </app-video-player>
                            <!-- <video autoplay loop muted>
                                <source [src]="slide.currentVedio" />
                            </video> -->
                        </div>
                        <div class="vedioplayer" *ngIf="slide.isVideo === true && slide.vedioType === true"
                            id="{{'vedioPlayer'[i]}}" style="width: 100%;z-index: 2;position: absolute;top: 0;">
                            <app-video-player (playInitiation)="played($event)"
                                [options]="{from: 'vod',autoplay: true ? 'play':false, controls: false, sources: [{ src: slide.currentVedio ? slide.currentVedio : '',type:slide.videoJsPlayerType }]}">
                            </app-video-player>
                            <!-- <video autoplay loop muted>
                                <source [src]="slide.currentVedio" />
                            </video> -->
                        </div>
                    </div>
                    <div class="carousel-caption" [ngStyle]="{'width':screenSize}">
                        <h3>{{slide.title}}</h3>
                        <p>{{slide.subTitle}}</p>
                    </div>
                </ng-template>
            </ngb-carousel>
            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}" class="watch-now"
                (click)="watchBtnGoToView(SlideId)"> <i class="fa fa-play" aria-hidden="true"></i>Watch Now</button>
        </div>
        <div class="main-bg-shadow carousal-gradiant" [style.background-image]="bottomGradiant"></div>
    </div>

    <section class="media-container"
        [ngClass]="[(!mediaData.length && !othersMediaDataList.length && !continueData.length && !favData.length) ? 'noDataAvailable' : '']"
        [ngStyle]="{'background-color': appBGColor ? appBGColor : '#2a2a2a'}">
        <div class="container" [ngClass]="{'media-item-margin':!products.length}">


            <div class="app-search d-flex justify-content-end app-search-big mr-20 align-bar" *ngIf="products.length>0"
                [ngClass]="[mediaData.length==0 && othersMediaDataList.length==0 ? 'noData' : '']">
                <input type="text" style="width: 230px;background: #fff;border: 0;color: #000;"
                    (ngModelChange)="searchData($event)" placeholder="Search" class="form-control"
                    [(ngModel)]="searchInput">
            </div>

            <section class="nodata-container d-flex justify-content-center"
                *ngIf="!mediaData.length && !othersMediaDataList.length">
                No results found
            </section>
            <section class="vod-container"
                *ngIf="mediaData.length <= 1 && othersMediaDataList.length == 0 && showMedia">
                <div class="container paddingx-10" *ngFor="let mediaSeries of mediaData;let seriesIndex = index">
                    <div class="row">
                        <div class="com-md-12">
                            <!-- <h2 class="feature-catalog-title">All Media</h2> -->
                            <h2 class="series-text feature-catalog-title text-wrap"
                                *ngIf="mediaSeries.mediaItem.length > 0">
                                {{mediaSeries.title}}</h2>
                        </div>
                    </div>
                    <div class="justify-content-center">
                        <div class="row">
                            <div class="col-sm-6  col-md-6  col-lg-3 media-card"
                                *ngFor="let item of mediaSeries.mediaItem">
                                <a class="main_poster" (click)="goToView(item)"
                                    [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                    <span class="image-container"
                                        [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                        <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                            [src]="item.newwideimg ? item.newwideimg : ''" alt=""
                                            onload="style.opacity = 1" style="opacity: 0;" class="images">
                                        <div class="duration" *ngIf="item.duration && isDuration">{{item.duration}}
                                        </div>
                                        <div class="live-indication" *ngIf="item.liveStatus==='LIVE'">LIVE</div>
                                    </span>
                                </a>
                                <a class="card-title title">
                                    <span (click)="goToView(item)">
                                        {{item.title}}
                                    </span>
                                </a>
                                <a class="card-title card-sub-title-margin">
                                    <span (click)="goToView(item)">
                                        {{item.subTitle}}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="vod-container"
                *ngIf="othersMediaDataList.length >= 1 && showOtherData && mediaData.length == 0">
                <div class="container paddingx-10">
                    <div class="row">
                        <div class="com-md-12">
                            <!-- <h2 class="feature-catalog-title">All Media</h2> -->
                            <h2 class="series-text feature-catalog-title text-wrap"
                                *ngIf="othersMediaDataList.length > 0">Other
                                Media Items</h2>
                        </div>
                    </div>
                    <div class="justify-content-center">
                        <div class="row">
                            <div class="col-sm-6  col-md-6  col-lg-3 media-card"
                                *ngFor="let item of othersMediaDataList">
                                <a class="main_poster" (click)="goToView(item)"
                                    [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                    <span class="image-container"
                                        [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                        <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                            [src]="item.newwideimg ? item.newwideimg : ''" alt=""
                                            onload="style.opacity = 1" style="opacity: 0;" class="images">
                                        <div class="duration" *ngIf="item.duration && isDuration">{{item.duration}}
                                        </div>
                                        <div class="live-indication" *ngIf="item.liveStatus==='LIVE'">LIVE</div>
                                    </span>
                                </a>
                                <a class="card-title title">
                                    <span (click)="goToView(item)">
                                        {{item.title}}
                                    </span>
                                </a>
                                <a class="card-title card-sub-title-margin">
                                    <span (click)="goToView(item)">
                                        {{item.subTitle}}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--added new code -->
            <section class="vod-container"
                *ngIf="mediaData.length == 1 && othersMediaDataList.length   && showMedia && showOtherData">
                <div class="container paddingx-10" *ngFor="let mediaSeries of mediaData;let seriesIndex = index">
                    <div class="row">
                        <div class="com-md-12">
                            <!-- <h2 class="feature-catalog-title">All Media</h2> -->
                            <h2 class="series-text feature-catalog-title text-wrap"
                                *ngIf="mediaSeries.mediaItem.length > 0">
                                {{mediaSeries.title}}</h2>
                        </div>
                    </div>
                    <div class="justify-content-center">
                        <div class="row">
                            <div class="col-sm-6  col-md-6  col-lg-3 media-card"
                                *ngFor="let item of mediaSeries.mediaItem">
                                <a class="main_poster" (click)="goToView(item)"
                                    [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                    <span class="image-container"
                                        [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                        <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                            [src]="item.newwideimg ? item.newwideimg : ''" alt=""
                                            onload="style.opacity = 1" style="opacity: 0;" class="images">
                                        <div class="duration" *ngIf="item.duration && isDuration">{{item.duration}}
                                        </div>
                                        <div class="live-indication" *ngIf="item.liveStatus==='LIVE'">LIVE</div>

                                    </span>
                                </a>
                                <a class="card-title title">
                                    <span (click)="goToView(item)">
                                        {{item.title}}
                                    </span>
                                </a>
                                <a class="card-title card-sub-title-margin">
                                    <span (click)="goToView(item)">
                                        {{item.subTitle}}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container paddingx-10">
                    <div class="row">
                        <div class="com-md-12">
                            <!-- <h2 class="feature-catalog-title">All Media</h2> -->
                            <h2 class="series-text feature-catalog-title text-wrap"
                                *ngIf="othersMediaDataList.length > 0">Other
                                Media Items</h2>
                        </div>
                    </div>
                    <div class="justify-content-center">
                        <div class="row">
                            <div class="col-sm-6  col-md-6  col-lg-3 media-card"
                                *ngFor="let item of othersMediaDataList">
                                <a class="main_poster" (click)="goToView(item)"
                                    [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                    <span class="image-container"
                                        [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                        <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                            [src]="item.newwideimg ? item.newwideimg : ''" alt=""
                                            onload="style.opacity = 1" style="opacity: 0;" class="images">
                                        <div class="duration" *ngIf="item.duration && isDuration">{{item.duration}}
                                        </div>
                                        <div class="live-indication" *ngIf="item.liveStatus==='LIVE'">LIVE</div>
                                    </span>
                                </a>
                                <a class="card-title title">
                                    <span (click)="goToView(item)">
                                        {{item.title}}
                                    </span>
                                </a>
                                <a class="card-title card-sub-title-margin">
                                    <span (click)="goToView(item)">
                                        {{item.subTitle}}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="bottom-container media-container-wrapper" *ngIf="showMedia && mediaData.length >1">
                <!-- favroties cataory with item -->
                <div class="media-series mb-3" *ngIf="showFavMedia">
                    <p class="series-text feature-catalog-title" *ngIf="favData.length > 0">Favorites</p>
                    <p-carousel [value]="favData" [numVisible]="scroll" [numScroll]="scroll"  [responsiveOptions]="responsiveOptions" [circular]="false">

                        <ng-template let-product pTemplate="item">
                            <div class="media-item-wrapper">
                                <div class="media-card ">
                                    <a class="main_poster" (click)="goToView(product)"
                                        [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                        <span class="image-container"
                                            [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                            <img [ngStyle]="{'background-color': product ? product.color : '#e6e6e6'}"
                                                [src]="product.mediaImg ? product.mediaImg : ''" alt=""
                                                onload="style.opacity = 1" style="opacity: 0;" class="images">
                                            <div class="duration" *ngIf="product.duration && isDuration">
                                                {{product.duration ? product.duration : '0:00'}}</div>
                                            <div class="live-indication" *ngIf="product.liveStatus==='LIVE'">LIVE</div>

                                        </span>
                                    </a>
                                    <a class="card-title title" (click)="goToView(product)">
                                        {{product.title}}
                                    </a>
                                    <a class="card-title card-sub-title-margin" (click)="goToView(product)">
                                        {{product.subTitle}}
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>

                </div>


                <!-- media series catagory with item -->
                <div class="media-series mb-3" *ngFor="let mediaSeries of mediaData;let seriesIndex = index">
                    <p class="series-text feature-catalog-title text-wrap" *ngIf="mediaSeries.mediaItem.length > 0">
                        {{mediaSeries.title}}</p>
                    <p-carousel *ngIf="mediaSeries.mediaItem.length > 0" [value]="mediaSeries.mediaItem" [responsiveOptions]="responsiveOptions"
                        [numVisible]="scroll" [numScroll]="scroll" [circular]="false">

                        <ng-template let-product pTemplate="item">

                            <div class="media-item-wrapper">

                                <div class="media-card ">
                                    <a class="main_poster" (click)="goToView(product)"
                                        [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                        <span class="image-container"
                                            [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                            <img [ngStyle]="{'background-color': product ? product.color : '#e6e6e6'}"
                                                [src]="product.newwideimg ? product.newwideimg : ''" alt="" default=""
                                                onload="style.opacity = 1" style="opacity: 0;" class="images">
                                            <div class="duration" *ngIf="product.duration && isDuration">
                                                {{product.duration ? product.duration : '0:00'}}</div>
                                            <div class="live-indication" *ngIf="product.liveStatus==='LIVE'">LIVE</div>

                                        </span>
                                    </a>
                                    <a class="card-title title" (click)="goToView(product)">
                                        {{product.title}}
                                    </a>
                                    <a class="card-title card-sub-title-margin" (click)="goToView(product)">
                                        {{product.subTitle}}
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>

                </div>


                <!-- Others cataory with items -->
                <div class="media-series mb-3" *ngIf="showOtherData && othersMediaDataList.length >= 1">
                    <p class="series-text feature-catalog-title text-wrap" *ngIf="othersMediaDataList.length">Other
                        Media
                        Items</p>
                    <p-carousel [value]="othersMediaDataList" [numVisible]="scroll" [numScroll]="scroll" [responsiveOptions]="responsiveOptions"
                        [circular]="false">

                        <ng-template let-product pTemplate="item">
                            <div class="media-item-wrapper">
                                <div class="media-card ">
                                    <a class="main_poster" (click)="goToView(product)"
                                        [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                        <span class="image-container"
                                            [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                            <img [ngStyle]="{'background-color': product ? product.color : '#e6e6e6'}"
                                                [src]="product.newwideimg ? product.newwideimg : ''" alt=""
                                                onload="style.opacity = 1" style="opacity: 0;" class="images">
                                            <div class="duration" *ngIf="product.duration && isDuration">
                                                {{product.duration ? product.duration : '0:00'}}</div>
                                            <div class="live-indication" *ngIf="product.liveStatus==='LIVE'">LIVE</div>

                                        </span>
                                    </a>
                                    <a class="card-title title" (click)="goToView(product)">
                                        {{product.title}}
                                    </a>
                                    <a class="card-title card-sub-title-margin" (click)="goToView(product)">
                                        {{product.subTitle}}
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>

                </div>

                <!-- Continue play cataory with item -->
                <div class="media-series mb-3" *ngIf="showcontinueList">
                    <p class="series-text feature-catalog-title text-wrap" *ngIf="continueData.length > 0">Continue
                        Playing</p>
                    <p-carousel [value]="continueData" [numVisible]="scroll" [numScroll]="scroll" [responsiveOptions]="responsiveOptions" [circular]="false">

                        <ng-template let-product pTemplate="item">
                            <div class="media-item-wrapper">
                                <div class="media-card continuePlaying">
                                    <a class="main_poster" (click)="goToView(product)"
                                        [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                        <span class="image-container"
                                            [ngStyle]="{'background-color': product.color ? product.color : '#e6e6e6'}">
                                            <img [ngStyle]="{'background-color': product ? product.color : '#e6e6e6'}"
                                                [src]="product.mediaImg ? product.mediaImg : ''" alt=""
                                                onload="style.opacity = 1" style="opacity: 0;" class="images">
                                            <div class="duration" *ngIf="product.duration && isDuration">
                                                {{product.duration ? product.duration : '0:00'}}</div>
                                            <div class="live-indication" *ngIf="product.liveStatus==='LIVE'">LIVE</div>
                                            <div class="progressbar" [style.width]="product.totalVideoPercent+'%'">
                                            </div>
                                        </span>
                                    </a>
                                    <a class="card-title title" (click)="goToView(product)">
                                        {{product.title}}
                                    </a>
                                    <a class="card-title card-sub-title-margin" (click)="goToView(product)">
                                        {{product.subTitle}}
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>

                </div>

            </section>
        </div>
    </section>
</div>



<app-footer *ngIf="showPageAfterLoad && mediaData.length>0 && othersMediaDataList.length>0"></app-footer>

<p-dialog *ngIf="signoutModal" [closable]="false" header="" [(visible)]="signoutModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo1">
        <p>Are you sure you want to sign out ?</p>
    </div>
    <p-footer class="signout-footer">
        <button type="button" class="btn btn-white" style="margin:  10px;" (click)="logOut()">Yes</button>
        <button type="button" class="btn btn-white" (click)="signoutModal= false">Cancel</button>
    </p-footer>
</p-dialog>