import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { emptyPlayListData } from 'src/app/Models/common.model';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-m3uchannel',
  templateUrl: './m3uchannel.component.html',
  styleUrls: ['./m3uchannel.component.scss'],
})
export class M3uchannelComponent implements OnInit, OnChanges {

  allDayDefault: any[]=[]
  currentDate = new Date()
  currentDay : any
  sendDateData = {
    slotInterval: 30,
    openTime: '00:00:00',
    closeTime: '23:59:59',
    date: moment(this.currentDate).format('YYYY-MM-DD')
  };
  autoPlayChange: boolean;
  currentVedioIsPaid: any;
  loginUserDetail: any;
  paymentDone: any;
  userId: any
  subscriptionPlanID: any
  isSubscriptionAvailable: boolean;
  isUserPlanPaymentDone: boolean;
  userPlanId: any;
  loginRequired: any;
  itemPrice: any
  PlanID: any;
  orderObj: any;
  tillCurrectTimeSec: any
  sendDate: any
  isLoggedIn: boolean;
  selectedId: any;
  showUI = false
  channelData: any[]=[]
  videoJsPlayerUrl: any
  videoJsPlayerType: any
  currentSelectedChannelLogo: any
  currentPlayingItemName: any
  currentStartItemTime: any
  currentEndItemTime: any
  itemDescription: any
  selectedChannel: any
  currentSelectedChannelName: any
  currentSelectedChannelDesc: any
  checkCurrentTimeInterval: any
  currentItemCurrentTime: any;
  currentItemRemainingTime: any
  paymentModal = false
  isOneTimePurchase = false
  selectedChannelId: any
  primaryColor: any
  logoHeight: any
  @Input() m3uData : any[]
  @Output() scrolledToBottom = new EventEmitter<void>();
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;
  @ViewChild('scrollthree') scrollthree : ElementRef;
  constructor(
    private router: Router,
    private _sessionService: SessionService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private homeService: HomeService
  ) { 
    this.generateCalendar(this.sendDateData)
  }

  ngOnInit(): void {
    this.getLogoDimention()
    setInterval(() => {
      this.currentDate = new Date()
      this.currentDay = moment(this.currentDate).format('dddd').substring(0,3)

      let sendDateTime = new Date() 
      let todayDateTime = moment(sendDateTime).format('YYYY-MM-DD') + ' ' + '00:00:00'
      let currentDateTime = moment(sendDateTime).format('YYYY-MM-DD HH:mm:ss')
      var getDiff = moment(currentDateTime).diff(moment(todayDateTime))
      // let secondsDiff =  currentDateTime.diff(todayDateTime)
      let getDiffinSec = getDiff / 1000;
      // console.log(secondsDiff,'<=============secondsDiff');
      
      this.tillCurrectTimeSec = getDiffinSec
    }, 1000);
    // setInterval(()=>{
    //     if(this.scrollOne){
    //       const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    //       const scrollTo = this.tillCurrectTimeSec ? (((this.tillCurrectTimeSec * 0.13888889 - 325)) + 'px') : '0'
    //       scrollOne.scrollLeft = parseInt(scrollTo)
    //     }
    // },10000)
    this.orderObj = this.route.snapshot.params.id;
    this.selectedId = +this.route.snapshot.queryParams.videoId
    let loginData: any;
    loginData = this._sessionService.getSession('loggedInUser');
    // loginData = localStorage.getItem('loggedInUser')
    // if(!loginData){
    //   loginData = false
    // }
    this.isLoggedIn = loginData ? true : false;
    this.loginUserDetail = loginData;
    this.paymentDone = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
        : '';
      this.userId = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.id
        : null;
      this.subscriptionPlanID = this.loginUserDetail
        ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
        : '';
    this.autoPlayChange = true
    // this.getSubscribe()
    
    this.sendDate = moment(this.currentDate).format('YYYY-MM-DD')
  }

  ngOnDestroy(): void {
    if (this.checkCurrentTimeInterval) {
      clearInterval(this.checkCurrentTimeInterval);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showUI = false
    if(this.m3uData){
      if(this.m3uData.length>0){
        this.showUI = true
        setTimeout(() => {
          const scrollOne = this.scrollOne.nativeElement as HTMLElement;
          const scrollTo = this.tillCurrectTimeSec ? (((this.tillCurrectTimeSec * 0.13888889 - 325)) + 'px') : '0'
          scrollOne.scrollLeft = parseInt(scrollTo)
        }, 500);
        this.channelData = this.m3uData.map((el)=>({
            Playlist : [el]
        }))
        console.log(this.channelData)
        if(this.channelData.length>0){
          this.channelData.forEach((el: any,i: any)=>{
            if(el.Playlist.length>0){
              el.Playlist.forEach((element:any,index: any) => {
                // element['logoPath'] = element.logoId ? element.logoId : null
                element['isSelected'] = false
                if(element.playlist){
                  if(element.playlist.length>0){
                    element.playlist.forEach((item: any)=>{
                      // item.duration = 1800
                      let date = item.date
                      item.duration = this.getDiffBetweenStartEndTime(item.startTime, item.endTime,date)
                    })
                  }
                }
                // else{
                //   element.playlist = emptyPlayListData
                // }
              });
              setTimeout(() => {
                if(i === 0){
                  this.channelData[0].Playlist[0]['isSelected'] = true
                  if(this.channelData[i].Playlist[i]){
                    this.selectChannel(this.channelData[0].Playlist[0])
                  }
                }
              }, 1000);
              // this.videoJsPlayerUrl = this.channelData[0].Playlist[0].playlistUrl
              // this.currentSelectedChannelLogo = this.channelData[0].Playlist[0].logoPath
              // this.logoOnRight = this.channelData[0].Playlist[0].logoOnRight
              // this.logoOnPlayer = this.channelData[0].Playlist[0].logoOnPlayer
              // this.playlistType = this.channelData[0].Playlist[0].playlistType
              // this.videoOnLoop = this.channelData[0].Playlist[0].videoOnLoop
              // this.currentSelectedChannelName = this.channelData[0].Playlist[0].title
              // this.currentSelectedChannelDesc = this.channelData[0].Playlist[0].description
            }
          })
          // this.categoryData = this.channelData.map((el)=>({
          //   categoryName : el.Category
          // }))
          // let newCategoryData = [...new Set(this.categoryData)];
          // console.log(newCategoryData);
          
        }
      }
    }
  }
  generateCalendar(x: any) {
    //Format the time
    let start = x.date + ' ' + x.openTime
    let end = x.date + ' ' + x.closeTime
    let startTime = moment(start, "YYYY-MM-DD HH:mm");

    //Format the end time and the next day to it 
    let endTime = moment(end, "YYYY-MM-DD HH:mm").add(0, 'days');

    //Times
    let allTimes: any = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    this.allDayDefault = []
    this.allDayDefault = allTimes.map((el: any, i: any) => ({
      date: x.date,
      end: allTimes[i + 1],
      start: el,
    })
    )
  }

  updateScroll(){
    const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;
    const scrollthree = this.scrollthree.nativeElement as HTMLElement;
    // do logic and set
    scrollTwo.scrollLeft = scrollOne.scrollLeft;
    scrollthree.scrollLeft = scrollOne.scrollLeft;
    if (this.bottomReached()) {
      this.scrolledToBottom.emit();
    }
  }

  selectChannel(data: any){
    this.currentVedioIsPaid = false
    this.isSubscriptionAvailable = false
    this.paymentModal = false
    this.currentVedioIsPaid = data.currentVedioIsPaid
    this.isOneTimePurchase = data.isOneTimePurchase
    this.loginRequired = data.loginRequired
    this.isSubscriptionAvailable = data.isSubscriptionAvailable
    this.selectedChannelId = data.selectedChannelId
    this.primaryColor = data.primaryColor
    this.itemPrice = data.itemPrice
    if(data.currentVedioIsPaid){
      this.paymentModal = true
    }
    // this.itemPrice = data.price;
    // this.isOneTimePurchase = data.isOneTimePurchase
    // this.selectedChannelId = data.id
        // if(!this.isLoggedIn){
          this.PlanID = this.loginUserDetail
          ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
          : '';
        // this.currentVedioIsPaid = true;
        // }
        // else{
          // if (data.mediaAccessType === 'PAID') {
          //   this.isSubscriptionAvailable = data
          //     .subscriptionPlanIds.length
          //     ? true
          //     : false;
          //   data.subscriptionPlanIds.forEach(
          //     (element: any) => {
          //       if (element == this.PlanID && this.paymentDone) {
          //         this.isSubscriptionAvailable = false
          //         this.currentVedioIsPaid = false;
          //       }
          //       if (element == this.userPlanId && this.isUserPlanPaymentDone) {
          //         this.isSubscriptionAvailable = false
          //         this.currentVedioIsPaid = false;
          //       }
          //     }
          //   );
          // } else {
          //   this.currentVedioIsPaid = false;
          // }
          // if (data.mediaAccessType === 'FREE') {
          //   this.isOneTimePurchase = false;
          // }
          // if (data.mediaAccessType === 'PAID' && data.isOneTimePurchasePaymentDone) {
          //   this.loginRequired = false;
          //   this.isOneTimePurchase = false;
          //   this.currentVedioIsPaid = false;
          //   this.isSubscriptionAvailable = false
          //   // this.vedioIsNull = false;
          // }
          // this.accessType =
          // data.mediaAccessType === 'ACCESSREQUIRED'
          //   ? true
          //   : false;
          // if (this.accessType && !this.isLoggedIn) {
          //   this.loginRequired = true;
          // } else {
          //   this.loginRequired = false;
          // }
        // }
        
        // }
    this.videoJsPlayerUrl = ''
    this.videoJsPlayerType = ''
    this.currentSelectedChannelLogo = ''
    this.currentPlayingItemName = ''
    this.currentStartItemTime = ''
    this.currentEndItemTime = ''
    this.itemDescription = ''
    // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
    if (data.channelUri) {
      let size = data.channelUri?.split(".").length;
      let ext = data.channelUri?.split(".")[size - 1];
      let urlHasExt = false
      if (/(?:\/|^)[^.\/]+$/.test(data.channelUri)) {
          // Successful match
          urlHasExt = true
      } else {
        urlHasExt = false
          // Match attempt failed
      }
      if(!urlHasExt){
        if (ext === "m3u8" || data.channelUri.includes('.m3u8')) {
          this.videoJsPlayerType = 'application/x-mpegURL';
          this.videoJsPlayerUrl = data.channelUri;
        } 
        else {
          this.videoJsPlayerUrl = data.channelUri;
          this.videoJsPlayerType = `video/mp4`;
        }
      }
      else{
        let modifiedURL = this.addLiveAfterDomain(data.channelUri)
        this.videoJsPlayerType = 'application/x-mpegURL';
        this.videoJsPlayerUrl = modifiedURL;
      }
      this.autoPlayChange = true
    }
    if(data.currentVedioIsPaid){
      this.videoJsPlayerType = ''
      this.videoJsPlayerUrl = ''
    }
    if(this.loginRequired){
      if(!this.loginUserDetail){
        this.paymentModal = true
        this.videoJsPlayerType = ''
        this.videoJsPlayerUrl = ''
      }
    }
    this.currentSelectedChannelLogo = data.tvgLogo ? data.tvgLogo : null 
    this.currentSelectedChannelName = data.tvgName ? data.tvgName : ''
    // this.currentSelectedChannelDesc = data.description
    // this.logoOnRight = data.logoOnRight
    // this.streamId = data.streamId
    // this.logoOnPlayer = data.logoOnPlayer
    // this.playlistType = data.playlistType
    // this.videoOnLoop = data.videoOnLoop
    if(this.channelData.length>0){
      this.channelData.forEach((el: any)=>{
        if(el.Playlist.length>0){
          el.Playlist.forEach((element:any) => {
            if(element.tvgId === data.tvgId && element.groupTitle === data.groupTitle && element.tvgName === data.tvgName){
              element.isSelected = true
              this.selectedChannel = element
              let sendDate = new Date()
              this.getCurrentPlayItem(sendDate,element)
            }
            else{
              element.isSelected = false
            }
          });
        }
      })
    }
  }
  
  addLiveAfterDomain(url: any) {
    var domainPattern = /\/\/([^:/]+(:\d+)?)(\/|$)/;
    var domainMatch = url.match(domainPattern);
    if (domainMatch) {
        var domain = domainMatch[1];
        var modifiedURL = url.replace(domain, domain + '/live');
        return modifiedURL + '.m3u8';
    }
    return url;
  }

  getDiffBetweenStartEndTime(startTime: any,endTime: any,date: any){
    const startDateTime = date + ' ' + startTime;
    const endDateTime = date + ' ' + endTime;
    const startMoment = moment(startDateTime);
    const endMoment = moment(endDateTime);
    var diffMilliseconds : any;
    if (endMoment.isBefore(startMoment)) {
      let newEndTime = date + ' ' + (endTime === '00:00:00' ? '23:59:59' : endTime )
      const sendEndMoment = moment(newEndTime);
      diffMilliseconds = sendEndMoment.diff(startMoment);
    } else {
      diffMilliseconds = endMoment.diff(startMoment);
    }
    // Convert the difference to seconds
    const diffSeconds = diffMilliseconds / 1000;
    return diffSeconds
  }
 
  getCurrentPlayItem(currentDateTime: any,getData: any){
    let currectDateTime = moment(currentDateTime).format('YYYY-MM-DD HH:mm:ss')
    if(this.checkCurrentTimeInterval){
      clearInterval(this.checkCurrentTimeInterval)
    }
    if(this.channelData.length>0){
      this.channelData.forEach((el: any)=>{
        if(el.Playlist.length>0){
          el.Playlist.forEach((element:any) => {
            if(element.tvgId === getData.tvgId && element.groupTitle === getData.groupTitle && element.tvgName === getData.tvgName){
              if(element.playlist){ 
              if(element.playlist.length>0){
                element.playlist.forEach((data: any)=>{
                  data.start = data.date + ' ' + data.startTime
                  data.end = data.date + ' ' + data.endTime
                  let time : any
                  // if(this.playlistType === 'LOOP' && this.videoOnLoop){
                  //   let selectedTime =  moment(currentDateTime).format('HH:mm:ss')
                  //   let selectedDateTime = data.date + ' ' + selectedTime
                  //   time = moment(selectedDateTime,'YYYY-MM-DD HH:mm:ss')
                  // }
                  // else{
                    time = moment(currectDateTime,'YYYY-MM-DD HH:mm:ss')
                  // }
                  let beforeTime = moment(data.start, 'YYYY-MM-DD HH:mm:ss')
                  let afterTime = moment(data.end, 'YYYY-MM-DD HH:mm:ss');
                  if (time.isBetween(beforeTime, afterTime)) {
                    this.currentPlayingItemName = data.tvgName
                    this.currentStartItemTime = data.start
                    this.currentEndItemTime = data.end
                    this.itemDescription = data.groupTitle
                    if(this.currentPlayingItemName === 'No Program Available'){
                      this.currentPlayingItemName = this.currentSelectedChannelName
                      this.itemDescription = ''
                    }
                    this.checkCurrentTimeInterval = setInterval(() => {
                      let sendCurrentDateTime = new Date()
                      let sendDateData = moment(sendCurrentDateTime).format('YYYY-MM-DD HH:mm:ss')
                      let sendStartTime = data.start
                      var getDiff = moment(sendCurrentDateTime).diff(moment(sendStartTime))
                      let currDiff = moment.utc(getDiff).format('HH:mm:ss')
                      
                      // var getDiff = moment.utc(moment(sendDateData, "HH:mm:ss").diff(moment(sendStartTime, "HH:mm:ss"))).format("HH:mm:ss")
                      this.currentItemCurrentTime = currDiff;
        
                      let sendEndTime =  data.end
                      var getRemDiff = moment(sendEndTime).diff(moment(sendDateData))
                      let remainDiff = moment.utc(getRemDiff).format('HH:mm:ss')
                      // var getRemDiff = moment.utc(moment(sendEndTime, "HH:mm:ss").diff(moment(sendDateData, "HH:mm:ss"))).format("HH:mm:ss")
                      this.currentItemRemainingTime = remainDiff;
                      if(this.currentItemRemainingTime == '00:00:00'){
                        if (this.selectedChannel) {
                          if(this.checkCurrentTimeInterval){
                            clearInterval(this.checkCurrentTimeInterval)
                          }
                          this.currentPlayingItemName = ''
                          this.currentStartItemTime = ''
                          this.currentEndItemTime = ''
                          this.currentItemRemainingTime = ''
                          this.currentItemCurrentTime = ''
                          this.itemDescription = ''
                          setTimeout(() => {
                            this.selectChannel(this.selectedChannel)  
                          }, 2000);
                          // this.getMediaItemData(this.mediaItemId, 'channelData');
                          // let sendDate = moment(this.currentDate).format('YYYY-MM-DD')
                          // this.getSchedulingChannelInfoWithDate(sendDate,this.mediaItemId)
                        }
                      }
                    }, 1000);
                  }
                })
              }
            }
            }
          });
        }
      })
    }
    }

    bottomReached(): boolean {
      const element = this.scrollOne.nativeElement;
      return element.scrollTop + element.clientHeight >= element.scrollHeight;
    }

    getAccess() {
      if (this.isLoggedIn) {
        this.router.navigate(['/signup'], {
          queryParams: {
            vedioId: this.selectedChannelId,
            id: this.PlanID,
            showOffers: true,
            type: 'customNet',
            clickFrom:'customNet',
          },
        });
      } else {
        this.router.navigate(['/signup'], {
          queryParams: { vedioId: this.selectedChannelId, showOffers: true,type: 'customNet' },
        });
      }
    }
    buyItem() {
      if (this.isLoggedIn) {
        this.router.navigate(['/signup'], {
          queryParams: {
            vedioId: this.selectedChannelId,
            id: this.PlanID,
            buyItem: true,
            ItemPrice: this.itemPrice,
            type: 'customNet',
            clickFrom:'customNet'
          },
        });
      } else {
        this.router.navigate(['/signup'], {
          queryParams: {
            vedioId: this.selectedChannelId,
            ItemPrice: this.itemPrice,
            buyItem: true,
            type: 'customNet'
          },
        });
      }
    }
    signInRequired() {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          clickFrom: 'programsignInRequire',
          type: 'customNet'
        },
      });
    }
    signInOnMedia() {
      this.router.navigate(['/signin'], {
        queryParams: { vedioId: this.selectedChannelId,type: 'customNet' },
      });
    }
    getLogoDimention(){
      this.homeService.dimensions$.subscribe((dimensions) => {
        this.logoHeight = dimensions.height
      });
    }
    
}
