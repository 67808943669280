import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MediaService } from 'src/app/services/media.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import videojs from 'video.js';
// import '../../../services/ads.js';
// import './ad.js';
// import { VASTParser } from 'vast-client';

// import * as VASTParser from '@dailymotion/vast-client/lib/parser';


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    controls: boolean;
    from: any;
    sources: {
      src: string;
    }[];
  };
  @Input() pauseTime: number;
  @Input() currentVedio: any;
  @Input() playerValidation: boolean;
  @Input() poster: any;
  @Output() completeVideoAction: EventEmitter<any>;
  @Output() playedAction: EventEmitter<any>;
  @Output() languageChangedAction: EventEmitter<any>;
  @Output() resolutionChangeAction: EventEmitter<any>;
  @Output() playInitiation: EventEmitter<any>;
  @Output() pauseInitiation: EventEmitter<any>;
  @Output() dataUsageAction: EventEmitter<any>;
  @Input() isVastTag: boolean;
  @Input() vastTag: string;
  @Input() mediaId: string;
  @Input() itemType: string;
  @Input() isVideoPause: boolean;
  @Input() showAds: boolean = false;
  player: any;
  adOptions: {
    adTagUrl: string;
  };
  currentStart = 0
  currentEnd = 0
  vastTagEnabled: any;
  constructor(
    private _SharedService: SharedService,
    private _MediaService: MediaService
  ) {
    this.currentVedio = { src: '' };
    this.playerValidation = false;
    this.pauseTime = 0;
    this.completeVideoAction = new EventEmitter<any>();
    this.playedAction = new EventEmitter<any>();
    this.languageChangedAction = new EventEmitter<any>();
    this.resolutionChangeAction = new EventEmitter<any>();
    this.dataUsageAction = new EventEmitter<any>();
    this.adOptions = {
      adTagUrl:
        'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=',
    };
    this.playInitiation = new EventEmitter<any>();
    this.pauseInitiation = new EventEmitter<any>();
    this.isVastTag = false;
    this.vastTag = '';
    this.playInitiation = new EventEmitter<any>();
    this.mediaId = '';
    this.pauseInitiation = new EventEmitter<any>();
  }
  ngOnInit(): void {
    console.log('ngOnInitvidep p')
    this.player = videojs(
      this.target.nativeElement,
      {
        ...this.options,
        // techOrder: ['html5', 'vsh'],
        // html5: {
        //   hls: {
        //     withCredentials: true // Allow sending cookies with cross-origin requests
        //   }
        // },
        controlBar: {
          pictureInPictureToggle: false,
        },
      },
      () => {
        // console.log('onPlayerReady', this);
      }
    );
    this.player.aspectRatio('16:9');
    this.player.responsive(true);
    if (this.options.autoplay) {
      this.player.play();
    }
    this.player.poster(this.poster);
    this.player.controls(this.options.controls);
    this.pauseTime && this.player.currentTime(this.pauseTime);
    this.player.on('error', (error: any) => {
      this._SharedService.updateLoader(true);
      this.resolutionChangeAction.emit(error);
      this._SharedService.updateLoader(false);
      // console.log(error);
    });
    this._MediaService.getVideoPlayerStatus().subscribe((data) => {
      if (!data) {
        this.player.pause();
      }
    });

    let loginUserDetail = localStorage.getItem('loggedInUser')
    if(loginUserDetail){
      this.vastTagEnabled = loginUserDetail
       ? JSON.parse(loginUserDetail).userDetails.vastTagEnabled
       : '';
     }

   this.player.on('loadedmetadata',(data: any)=>{
    // console.log(data,'loadedmetadata')
   })
    // if (this.isVastTag && this.vastTag && this.vastTagEnabled && this.itemType !== 'LIVE_STREAMING') {
      // this.player.vast({
      //   url: this.vastTag,
      // });
    // }
    // const vastParser :any = {}
    

    // vastParser.getAndParseVAST('https://servedbyadbutler.com/vast.spark?setID=52430&ID=187439')
    //       .then((response:any) => {
    //         // console.log(response.ads, "Rewsponse")
    //         if (response.ads && response.ads.length > 0) {
    //           // setIsAdAvailable(true);
    //           const creatives = response.ads[0].creatives;
    //           creatives.forEach((item: any) => {
    //             if (item.mediaFiles && item.mediaFiles.length > 0) {
    //               // setAdType(item.type);
    //               // item.skipDelay > 0 && setSkipable(true) && setSkipTime(item.skipDelay);
    //               let allUrls = item.mediaFiles.map((i : any) => i.fileURL);
    //               console.log(allUrls, "READ VAST TAGES")
    //               // setAdAlldata(allUrls);
    //             }
    //           })
    //         } else {
    //           // setIsAdAvailable(false)
    //           // setAdAlldata(["http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4", "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"]);
    //         }
    //       })
    //       .catch((error:any) => console.log(error));

  }
  ngOnChanges(): void {
    console.log('asdfsdfction', this.player, this.options)
    if (this.player) {
      this.player.poster(this.poster);
      this.player.controls(this.options.controls);
      if( !this.showAds){
      this.player.src(this.options.sources[0]);
      }
      this.pauseTime && this.player.currentTime(this.pauseTime);
     

      if(!this.isVideoPause && !this.showAds){
        this.player.play();
      }
      if (this.options.autoplay && !this.showAds) {
        this.player.play();
      }
      if( this.showAds){
        this.player.pause();
      }
    }
  }
  handleError(event: any) {
    console.log(event);
  }
  playerAction(event: any) {
    this.playedAction.emit(event);
    if(this.player.textTracks().length>0){
      if(this.player.textTracks()[0].activeCues[0]?.value){
        let newStart = this.player.textTracks()[0].activeCues[0].value.start
        let newEnd = this.player.textTracks()[0].activeCues[0].value.end
        this.checkNewChunkLoaded(newStart, newEnd);
      } 
    }
  }
  playInitiate(event: any): void {
    if (event) {
      this.playInitiation.emit(event);
    }
  }
  pauseInitate(event: any): void{
    if(event){
      this.pauseInitiation.emit(event);
    }
  }
  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  checkNewChunkLoaded(newStart: number, newEnd: number): void {
    // Check if the new start and end values differ from the previous ones
    if (newStart !== this.currentStart || newEnd !== this.currentEnd) {
          // Update current values
          this.currentStart = newStart;
          this.currentEnd = newEnd;
          this.dataUsageAction.emit({
            byteLength : this.player.textTracks()[0].activeCues[0].value.byteLength
          })
      }
  }

}
