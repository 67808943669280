<div class="main-header top-header">
    <div class="container">
        <div class="header-container container">
            <div class="logo col-lg-3 col-sm-3 col-md-3" [ngStyle]="{'width': (logoSize - 10) + '%' }"
                *ngIf="!removeheaderData">
                <a routerLink="/main">
                    <img (load)="onImageLoad($event)" [src]="headerLogo" alt="" [ngStyle]="{'width': (logoSize - 10) + '%' }"
                        style="object-fit: contain;">
                </a>
            </div>
            <nav class="nav-bar col-sm-8">
                <ul class="menus" [ngStyle]="{'color': headerLinkColor}">
                    <li *ngIf="!userName && isHomePage" routerLink="/main" routerLinkActive="active">{{mainPageName}}
                    </li>
                    <li routerLink="/vod" routerLinkActive="active" *ngIf="isVodPage" [ngClass]="{'active':isVideoUrl}">
                        {{vodPageName}}
                    </li>
                    <li routerLink="/ebook-list" routerLinkActive="active" [ngClass]="{'active':isEbookUrl}"
                        *ngIf="showHideEbook">{{ebookPage}}</li>
                    <li routerLink="/mobileApp" *ngIf="isAppStore" routerLinkActive="active">{{appStoreName}}</li>
                    <li routerLink="/giving" *ngIf="showHideGiving" routerLinkActive="active">{{givingPageName}}</li>
                    <li routerLink="/channel" *ngIf="isChannelPage" routerLinkActive="active">{{channelName}}</li>
                    <li routerLink="/frame" *ngIf="isLivePage" routerLinkActive="active">{{livePageContent}}</li>
                    <li *ngIf="isNetworkPage" routerLinkActive="active" routerLink="/network">{{networkName}}</li>
                    <li routerLink="/contact-us" routerLinkActive="active">{{contactName}}</li>
                    <li *ngIf="userName" routerLink="/Dashboard/menu" routerLinkActive="active">Dashboard</li>
                </ul>
            </nav>
            <div class="signin-wrapper col-sm-1">
                <div class="btn-group align-items-center" style="float: right;" *ngIf="userName" dropdown>
                    <!-- <p class="signout" *ngIf="userName" (click)="signoutModal=true" style="cursor: pointer;">
                        Sign Out
                    </p> -->
                    <button class="btn signin main-btn" (click)="signoutModal=true" *ngIf="userName"
                    [style.background-color]="appBtnPrimaryColor" [ngStyle]="{'width':'100px','margin-left':'10px'}">
                    Sign Out
                </button>
                </div>
                <!-- <div class="d-flex flex-column mt-3"> -->
                <button class="btn signin main-btn" routerLink="/signin" *ngIf="!userName"
                    [style.background-color]="appBtnPrimaryColor" [ngStyle]="{'width':'85px','margin-left':'10px'}">
                    Sign In
                </button>
                <!-- <a [ngStyle]="{'color': headerLinkColor}" (click)="navigateForFreeAccount()" *ngIf="!userName"
                        class="cursor-pointer free-account">Create free
                        account</a> -->
                <!-- </div> -->
            </div>
            <div class="menu-icon" [ngClass]="{fixedIcon: removeheaderData}" (click)="openNav()">
                <span class="hamburger-icon">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </span>
            </div>
            <nav class="nav-bar-mobile">
                <ul class="menus sidenav" id="mySidenav" [ngStyle]="{'color': headerLinkColor}">
                    <div class="arrowed" (click)="closeNav()">
                        <div class="arrow-6"></div>
                    </div>
                    <!-- <span class="close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span> -->
                    <li (click)="closeNav()" *ngIf="!userName && isHomePage" routerLink="/main">{{mainPageName}}</li>
                    <li (click)="closeNav()" routerLink="/vod" *ngIf="isVodPage">{{vodPageName}}</li>
                    <li (click)="closeNav()" routerLink="/ebook-list" routerLinkActive="active" *ngIf="showHideEbook">
                        {{ebookPage}}</li>
                    <li (click)="closeNav()" *ngIf="isAppStore" routerLink="/mobileApp">{{appStoreName}}</li>
                    <li (click)="closeNav()" *ngIf="showHideGiving" routerLink="/giving">{{givingPageName}}</li>
                    <li (click)="closeNav()" *ngIf="isChannelPage" routerLink="/channel">{{channelName}}</li>
                    <li (click)="closeNav()" *ngIf="isNetworkPage" routerLink="/network">{{networkName}}</li>
                    <li (click)="closeNav()" *ngIf="isLivePage" routerLink="/frame">{{livePageContent}}</li>
                    <li (click)="closeNav()" routerLink="/contact-us">{{contactName}}</li>
                    <li (click)="closeNav()" *ngIf="userName" routerLink="/Dashboard/menu">Dashboard</li>
                    <li (click)="closeNav()" *ngIf="!userName" routerLink="/signin">Sign In</li>
                    <li (click)="closeNav()" *ngIf="userName" (click)="signoutModal=true">Sign Out</li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<p-dialog *ngIf="signoutModal" [closable]="false" header="" [(visible)]="signoutModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo1">
        <p>Are you sure you want to sign out ?</p>
    </div>
    <p-footer class="signout-footer">
        <button type="button" class="btn btn-white" style="margin: 0 10px;" (click)="logOut()">Yes</button>
        <button type="button" class="btn btn-white" (click)="signoutModal= false">Cancel</button>
    </p-footer>
</p-dialog>