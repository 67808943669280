<ng-container *ngIf="isLoaded">
    <div class="main-program dark" [ngStyle]="{'background-color': appBGColor ? appBGColor : '#2a2a2a','padding-top': logoHeight ? ((logoHeight + 60) + 'px') : ''}">
        <div class="container">
            <div class="vedio-wrapper"
                [ngClass]="{'col-8': playlistPosition === true , 'width100': playlistPosition === false}">
                <div class="player" [ngClass]="{sidebarView: playlistPosition === true}">
                    <div *ngIf="isIframe" class="container d-flex justify-content-center"
                        [innerHTML]="iframe"></div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</ng-container>