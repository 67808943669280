<section class="main-pageNotFound">
    <div class="container">
        <div class="main-wrapper">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <h3>Look like you're lost</h3>
            <p>the page you are looking for is not available!</p>
            <a [href]="sendURL">
                <button>Go to Home</button>
            </a>
        </div>
    </div>
</section>