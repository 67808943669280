<div class="position-relative">
    <div *ngIf="!disablePlus" class="magnify-plus cursor-pointer setting-icon" data-toggle="tooltip"
        data-placement="top" title="Increase font size" (click)="changeFontSize(0,'INCREMENT')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px">
            <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" />
        </svg>
    </div>
    <ng-template [ngIf]="disablePlus">
        <div class="magnify-plus cursor-pointer setting-icon disabled-icon" data-toggle="tooltip" data-placement="top"
            title="Increase font size">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px">
                <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" />
            </svg>
        </div>
    </ng-template>
    <div *ngIf="!disableMinus" class="magnify-minus cursor-pointer setting-icon" data-toggle="tooltip"
        data-placement="top" title="Decrease font size" (click)="changeFontSize(0,'DECREMENT')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px">
            <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM136 184c-13.3 0-24 10.7-24 24s10.7 24 24 24H280c13.3 0 24-10.7 24-24s-10.7-24-24-24H136z" />
        </svg>
    </div>
    <ng-template [ngIf]="disableMinus">
        <div class="magnify-minus cursor-pointer setting-icon disabled-icon" data-toggle="tooltip" data-placement="top"
            title="Decrease font size">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px">
                <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM136 184c-13.3 0-24 10.7-24 24s10.7 24 24 24H280c13.3 0 24-10.7 24-24s-10.7-24-24-24H136z" />
            </svg>
        </div>
    </ng-template>
    <div class="single-screen cursor-pointer setting-icon" data-toggle="tooltip" data-placement="top"
        title="Single Page" (click)="changeLayout('SINGLE')"
        [ngClass]="{'active-stroke':isSingleView,'not-active-stroke':isSingleView===false}">
        <svg xmlns="http://www.w3.org/2000/svg" aria-labelledby="" width="20px" height="20px">
            <path
                d="M9.772 1c.108 0 .198.084.222.196l.006.058v16.492c0 .12-.075.221-.176.247L9.772 18H1.228c-.108 0-.198-.084-.222-.196L1 17.746V1.254c0-.12.075-.221.176-.247L1.228 1z"
                stroke-width="1.5" />
        </svg>
    </div>
    <div class="double-screen cursor-pointer setting-icon" data-toggle="tooltip" data-placement="top"
        title="Double Page" (click)="changeLayout('DOUBLE')"
        [ngClass]="{'active-stroke':isSingleView===false,'not-active-stroke':isSingleView}">
        <svg xmlns="http://www.w3.org/2000/svg" aria-labelledby="" width="20px" height="20px">
            <g fill-rule="evenodd" stroke-width="1.5">
                <path
                    d="M18.544 1c.216 0 .397.084.444.196l.012.058v16.492c0 .12-.15.221-.352.247l-.104.007H1.456c-.216 0-.397-.084-.444-.196L1 17.746V1.254c0-.12.15-.221.352-.247L1.456 1z" />
                <path d="M10 2v15" stroke-linecap="square" />
            </g>
        </svg>
    </div>
</div>

<div id="area" #ebookReader class="spreads"></div>
<a id="prev" (click)="prev()" class="arrow">‹</a>
<a id="next" (click)="next()" class="arrow">›</a>