import * as moment from 'moment';
import { appConfig } from '../app.config';

export class PageCustomiseDataModel {
  pageCustomizationDTO = new PageCustomizationDTO();
  webAppSettingDTO = new WebAppSettingDTO();
  headerDTO = new HeaderDTO();
  allPlatFormsDTO = new AllPlatFormsDTO();
  mainBannerDTO = new MainBannerDTO();
  bottomCallActionDTO = new BottomCallActionDTO();

  constructor(obj?: {
    pageCustomizationDTO: any;
    webAppSettingDTO: any;
    headerDTO: any;
    allPlatFormsDTO: any;
    mainBannerDTO: any;
    bottomCallActionDTO: any;
  }) {
    this.pageCustomizationDTO = obj
      ? new PageCustomizationDTO(obj.pageCustomizationDTO)
      : new PageCustomizationDTO();
    this.webAppSettingDTO = obj
      ? new WebAppSettingDTO(obj.webAppSettingDTO)
      : new WebAppSettingDTO();
    this.headerDTO = obj ? new HeaderDTO(obj.headerDTO) : new HeaderDTO();
    this.allPlatFormsDTO = obj
      ? new AllPlatFormsDTO(obj.allPlatFormsDTO)
      : new AllPlatFormsDTO();
    this.mainBannerDTO = obj
      ? new MainBannerDTO(obj.mainBannerDTO)
      : new MainBannerDTO();
    this.bottomCallActionDTO = obj
      ? new BottomCallActionDTO(obj.bottomCallActionDTO)
      : new BottomCallActionDTO();
  }
}

export class BottomCallActionDTO {
  title: string;
  subTitle: string;
  ctaText: string;
  ctaLink: string;
  showHideBottomCallToAction: boolean;
  constructor(obj?: {
    showHideBottomCallToAction: any;
    title: any;
    subTitle: any;
    ctaText: any;
    ctaLink: any;
  }) {
    this.showHideBottomCallToAction = obj
      ? obj.showHideBottomCallToAction
      : false;
    this.title = obj ? obj.title : 'Start your 7 days free trail';
    this.subTitle = obj ? obj.subTitle : '';
    this.ctaText = obj ? obj.ctaText : 'Join Now';
    this.ctaLink = obj ? obj.ctaLink : '';
  }
}

export class MainBannerDTO {
  title: string;
  subTitle: string;
  description: string;
  ctaLink: string;
  ctaTitle: string;
  ctaText: string;
  backgroundImageId: number;
  mainHomeBgImage: any;
  constructor(obj?: {
    title: any;
    subTitle: any;
    description: any;
    ctaLink: any;
    ctaTitle: any;
    ctaText: any;
    backgroundImageId: any;
    mainHomeBgImage: any;
  }) {
    this.title = obj ? obj.title : '';
    this.subTitle = obj ? obj.subTitle : '';
    this.description = obj ? obj.description : '';
    this.ctaLink = obj ? obj.ctaLink : '';
    this.ctaTitle = obj ? obj.ctaTitle : 'Try free for 7 days';
    this.ctaText = obj ? obj.ctaText : 'START YOUR TRIAL';
    this.backgroundImageId = obj ? obj.backgroundImageId : null;
    this.mainHomeBgImage = obj
      ? `${appConfig.imageURL}` +
        obj.backgroundImageId +
        '?height=630&width=1120'
      : '';
  }
}

export class PageCustomizationDTO {
  homePage: string;
  watchDailyPage: string;
  contactUs: string;
  faviconId: number;
  favIcon: any;
  showHideEbook: boolean;
  ebookPage: string;
  constructor(obj?: {
    homePage: any;
    watchDailyPage: any;
    contactUs: any;
    faviconId: any;
    favIcon: any;
    showHideEbook: boolean;
    ebookPage: string;
  }) {
    this.homePage = obj ? obj.homePage : '';
    this.watchDailyPage = obj ? obj.watchDailyPage : '';
    this.contactUs = obj ? obj.contactUs : '';
    this.faviconId = obj ? obj.faviconId : null;
    this.favIcon = obj ? `${appConfig.imageURL}` + obj.faviconId : '';
    this.showHideEbook = obj ? obj.showHideEbook : false;
    this.ebookPage = obj ? obj.ebookPage : '';
  }
}

export class WebAppSettingDTO {
  showProgramDuration: boolean;

  constructor(obj?: { showProgramDuration: any }) {
    this.showProgramDuration = obj ? obj.showProgramDuration : false;
  }
}

export class HeaderDTO {
  headerLinksColor: string;
  logoId: number;
  headerLogo: string;
  constructor(obj?: { headerLinksColor: any; logoId: any; headerLogo: any }) {
    this.headerLinksColor = obj ? obj.headerLinksColor : null;
    this.logoId = obj ? obj.logoId : null;
    this.headerLogo = obj ? `${appConfig.imageURL}` + obj.logoId : '';
  }
}

export class AllPlatFormsDTO {
  title: string;
  subTitle: string;
  titleForDevicesLogos: any;
  showHideAndroidTV: boolean;
  showHideYoutube: boolean;
  showHideGoogleTv: boolean;
  showHideCromeCast: boolean;
  showHideAndroid: boolean;
  showHideIPhone: boolean;
  showHideRokuTV: boolean;
  showHideAppleTV: boolean;
  showHideFireTV: boolean;
  showHideAmazon: boolean;
  showHideSection: boolean;
  androidLink: string;
  androidTVLink: string;
  appleTVLink: string;
  fireTVLink: string;
  iphoneLink: string;
  rokuTVLink: string;
  youtubeLink: string;
  googleTvLink: string;
  cromeCastLink: string;
  amazonLink: any;
  constructor(obj?: {
    title: any;
    subTitle: any;
    titleForDevicesLogos: any;
    showHideAndroidTV: any;
    showHideYoutube: any;
    showHideGoogleTv: any;
    showHideCromeCast: any;
    showHideAndroid: any;
    showHideIPhone: any;
    showHideRokuTV: any;
    showHideAppleTV: any;
    showHideFireTV: any;
    showHideAmazon: any;
    showHideSection: any;
    androidLink: any;
    androidTVLink: any;
    appleTVLink: any;
    fireTVLink: any;
    iphoneLink: any;
    rokuTVLink: any;
    youtubeLink: any;
    googleTvLink: any;
    cromeCastLink: any;
    amazonLink: any;
  }) {
    this.title = obj ? obj.title : null;
    this.subTitle = obj ? obj.subTitle : null;
    this.titleForDevicesLogos = obj ? obj.titleForDevicesLogos : null;
    this.showHideAndroidTV = obj ? obj.showHideAndroidTV : false;
    this.showHideYoutube = obj ? obj.showHideYoutube : false;
    this.showHideGoogleTv = obj ? obj.showHideGoogleTv : false;
    this.showHideCromeCast = obj ? obj.showHideCromeCast : false;
    this.showHideAndroid = obj ? obj.showHideAndroid : false;
    this.showHideIPhone = obj ? obj.showHideIPhone : false;
    this.showHideRokuTV = obj ? obj.showHideRokuTV : false;
    this.showHideAppleTV = obj ? obj.showHideAppleTV : false;
    this.showHideFireTV = obj ? obj.showHideFireTV : false;
    this.showHideAmazon = obj ? obj.showHideAmazon : false;
    this.showHideSection = obj ? obj.showHideSection : false;
    this.androidLink = obj ? obj.androidLink : null;
    this.androidTVLink = obj ? obj.androidTVLink : null;
    this.appleTVLink = obj ? obj.appleTVLink : null;
    this.fireTVLink = obj ? obj.fireTVLink : null;
    this.iphoneLink = obj ? obj.iphoneLink : null;
    this.rokuTVLink = obj ? obj.rokuTVLink : null;

    this.youtubeLink = obj ? obj.youtubeLink : null;
    this.googleTvLink = obj ? obj.googleTvLink : null;
    this.cromeCastLink = obj ? obj.cromeCastLink : null;
    this.amazonLink = obj ? obj.amazonLink : null;
  }
}

const newDate = new Date();
const sendDate = moment(newDate).format('YYYY-MM-DD')
export const emptyPlayListData = [
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "03:00:00", groupTitle: "No Program Available", playlist: '',startTime: "00:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "06:00:00", groupTitle: "No Program Available", playlist: '',startTime: "03:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "09:00:00", groupTitle: "No Program Available", playlist: '',startTime: "06:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "12:00:00", groupTitle: "No Program Available", playlist: '',startTime: "09:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "15:00:00", groupTitle: "No Program Available", playlist: '',startTime: "12:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "18:00:00", groupTitle: "No Program Available", playlist: '',startTime: "15:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "21:00:00", groupTitle: "No Program Available", playlist: '',startTime: "18:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
  {channelName: "No Program Available", channelUri: 'null', duration: null, date: sendDate, endTime: "23:59:59", groupTitle: "No Program Available", playlist: '',startTime: "21:00:00", tvgId: 'null', tvgLogo: 'null', tvgName: "No Program Available"},
]

export const emptyPlayListDataForCustom = [
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "03:00:00", playlist: '',startTime: "00:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "06:00:00", playlist: '',startTime: "03:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "09:00:00", playlist: '',startTime: "06:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "12:00:00", playlist: '',startTime: "09:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "15:00:00", playlist: '',startTime: "12:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "18:00:00", playlist: '',startTime: "15:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "21:00:00", playlist: '',startTime: "18:00:00"},
  {name: "No Program Available", duration: 10800, date: sendDate, endTime: "23:59:59", playlist: '',startTime: "21:00:00"},
]