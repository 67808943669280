<div class="main-profile">
    <div class="container">
        <div class="avatar d-flex justify-content-between">
            <div class="d-flex">
                <span class="image-uploader">
                    <input type="file" accept="image/jpeg, image/png" (click)="$event.target.value = ''"
                        (change)="onFileChange($event)">
                    <span class="img-overlay">
                        <ng-container *ngIf="logoId else uploadText">Change</ng-container>
                        <ng-template #uploadText>
                            Upload
                        </ng-template>
                        <span class="remove" *ngIf="logoId"><i (click)="removeImage()" pInputText
                                pTooltip="remove image" tooltipPosition="bottom" class="fa fa-trash"
                                aria-hidden="true"></i></span>
                    </span>
                    <ng-container *ngIf="profileImg else defaultImage">
                        <img [src]="profileImg" alt="Customer" class="h-100">
                    </ng-container>
                    <ng-template #defaultImage>
                        <img src="assets/images/profile.png" alt="Customer" class="h-100">
                    </ng-template>
                </span>
                <div class="avatar-detail">
                    <div class="title">{{userData.basicInfo.fullName}}</div>
                    <div class="text">Recommended dimensions: 200×200px, maximum file size: 5MB</div>
                </div>
            </div>
        </div>

        <div class="profile-form form-wrapper">
            <form #signInForm="ngForm">
                <div class="d-flex" style="flex-direction: column;">
                    <div class="form-group">
                        <!-- <label>Email</label> -->
                        <input type="text" name="email" placeholder="Email" readonly
                            [(ngModel)]="userData.basicInfo.email" class="form-control">
                    </div>
                    <div class="form-group">
                        <!-- <label>Full Name</label> -->
                        <input type="text" name="text" #fullName="ngModel" placeholder="Full Name"
                            [(ngModel)]="userData.basicInfo.fullName" class="form-control" required>
                        <ng-container *ngIf="fullName.errors && (fullName.touched || fullName.dirty)">
                            <p class="error text-danger" [hidden]="!fullName.errors.required">Full Name is required
                            </p>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <!-- <label>Mobile</label> -->
                        <input type="number" name="number" placeholder="Mobile" #mobile="ngModel"
                            [(ngModel)]="userData.basicInfo.mobileNumber" class="form-control" required>
                        <ng-container *ngIf="mobile.errors && (mobile.touched || mobile.dirty)">
                            <p class="error text-danger" [hidden]="!mobile.errors.required">Mobile is required
                            </p>
                        </ng-container>
                    </div>

                    <div class="form-group">
                        <!-- <label>State</label> -->
                        <span class="app-dropdown d-block m-0">
                            <select class="form-select" placeholder="Select State"
                                [(ngModel)]="userData.mailingAddress.state" name="state" #state="ngModel" required>
                                <option selected disabled value="">Select State</option>
                                <option *ngFor="let st of stateList" value="{{st.code}}">
                                    {{st.name}}
                                </option>
                            </select>
                        </span>
                    </div>
                    <div class="form-group">
                        <!-- <label>City</label> -->
                        <ng-container *ngIf="cityDropdown else cityInput">
                            <span class="app-dropdown d-block m-0">
                                <select class="form-select" placeholder="Select City"
                                    [(ngModel)]="userData.mailingAddress.city" name="city" required #city="ngModel">
                                    <option selected disabled value="">Select City</option>
                                    <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                        {{ct.cityName}}
                                    </option>
                                </select>
                            </span>
                            <ng-container *ngIf="city.errors && (city.touched || city.dirty)">
                                <p class="error text-danger" [hidden]="!city.errors.required">City is required
                                </p>
                            </ng-container>
                        </ng-container>
                        <ng-template #cityInput>
                            <input type="text" class="form-control" placeholder="City" maxlength="25"
                                [(ngModel)]="userData.mailingAddress.city" name="city" required #city="ngModel"
                                pattern="^[a-zA-Z ]*$">
                            <ng-container *ngIf="city.errors && (city.touched || city.dirty)">
                                <p class="error text-danger" [hidden]="!city.errors.pattern">Please enter a valid city
                                </p>
                                <p class="error text-danger" [hidden]="!city.errors.required">City
                                    is required</p>
                            </ng-container>
                        </ng-template>
                    </div>
                    <div class="form-group">
                        <!-- <label>Zip Code</label> -->
                        <input type="text" placeholder="Zip Code" [(ngModel)]="userData.mailingAddress.postalCode"
                            name="postalCode" #postalCode="ngModel" required pattern="^[0-9]{5}(?:-[0-9]{4})?$"
                            class="form-control">
                        <ng-container *ngIf="postalCode.errors && (postalCode.touched || postalCode.dirty)">
                            <p class="error text-danger" [hidden]="!postalCode.errors.pattern">Please enter a valid zip
                                code
                            </p>
                            <p class="error text-danger" [hidden]="!postalCode.errors.required">Zip Code
                                is required</p>
                        </ng-container>
                    </div>
                    <div class="save-btn mt-10">
                        <button [ngStyle]="{'background-color': primaryColor ? primaryColor : ''}" class="main-btn"
                            type="submit" (click)="updateContact()">Save Changes</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<p-dialog *ngIf="cropperModal" class="cropper-modal" header="Upload Logo" [(visible)]="cropperModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
            format="png" (imageCropped)="imageCropped($event)" roundCropper="true">
        </image-cropper>
    </div>
    <p-footer>
        <button type="button" class="btn btn-white" (click)="cropImage()">Crop</button>
        <button type="button" class="btn btn-white"
            (click)="croppedImage = ''; imageChangedEvent = null; cropperModal = false">Cancel</button>
    </p-footer>
</p-dialog>