import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { AngularFaviconService } from 'angular-favicon';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { PageCustomiseDataModel } from 'src/app/Models/common.model';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-giving',
  templateUrl: './giving.component.html',
  styleUrls: ['./giving.component.scss'],
})
export class GivingComponent implements OnInit {
  pageCustomeData: PageCustomiseDataModel;
  appBtnPrimaryColor: any;
  isLoggedIn: any;
  mainBGBlur: any;
  leftGradiant: any;
  bottomGradiant: any;
  footerBlur: any;
  blurOpacity: any;
  changeToRGB: any;
  footerblurOpacity: any;
  footerchangeToRGB: any;
  appBGColor: any;
  getUserInfo = false;
  primaryColor: any;
  DonationAmount: any = 0;
  showCard = false;
  showCard2 = false;
  cardData: any;
  cardBrand: any;
  cardLast4Digit: any;
  showaddedCard = false;
  userFirstName: any;
  userLastName: any;
  userEmail: any;
  userMobile: any;
  invoiceId: any;
  isZipValidate: boolean;
  stripeTokenId: string;
  disableButton = false;
  showPopup = false;
  @ViewChild(StripeCardComponent)
  card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#6d6d6d',
        color: '#000',
        fontWeight: '500',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '20px',
        '::placeholder': {
          color: '#6d6d6d',
        },
      },
    },
  };
  cardOptionsMobile: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#6d6d6d',
        color: '#000',
        fontWeight: '500',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#6d6d6d',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  paymentForm: {
    name: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    zipcode: number | null;
  };
  paymentFormSubmitted: boolean;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  paymentFormSubmitting: boolean;
  givingPageName: any;
  scrWidth: any;
  scrHeight: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  domain: string;
  constructor(
    public router: Router,
    public messageService: MessageService,
    public loginService: LoginService,
    public homeService: HomeService,
    private elementRef: ElementRef,
    private ngxFavicon: AngularFaviconService,
    private _sessionService: SessionService,
    private stripeService: StripeService,
    private sanitizer: DomSanitizer,
    private _SharedService: SharedService,
    private _Title: Title,
    private _Meta: Meta,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.isZipValidate = false;
    this.pageCustomeData = new PageCustomiseDataModel();
    this.paymentForm = {
      name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: 'US',
      zipcode: null,
    };
    this.paymentFormSubmitted = false;
    this.countryList = [];
    this.stateList = [];
    this.cityList = [];
    this.paymentFormSubmitting = false;
    this.givingPageName = '';
    this.domain = '';
  }

  ngOnInit(): void {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    let loginData: any;
    loginData = this._sessionService.getSession('loggedInUser');
    // loginData = localStorage.getItem('loggedInUser')
    this.isLoggedIn = loginData ? true : false;
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this._ActivatedRoute.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.getSubscribe();
    this.getAllCountryList();
    if (!this.isLoggedIn) {
      this.getSecretKeyWithoutLogin();
    } else {
      this.userEmail = loginData ? JSON.parse(loginData).userDetails.email : '';
      console.log(this.userEmail);

      this.getcardDetails();
      this.getSecretApiKey();
    }
  }

  getAllCountryList() {
    this.homeService.getAllCountryList().subscribe(
      (response: any) => {
        this.countryList = response.data.countryList;
        this.stateList = response.data.countryList[0]
          ? response.data.countryList[0].states
          : [];
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this._SharedService.updateLoader(false);
      }
    );
  }

  updateStateList(event: any) {
    const selectedCountryList = this.countryList.filter(
      (value) => value.code2 === event.target.value
    );
    this.stateList = []
    this.paymentForm.state = ''
    if (selectedCountryList.length && selectedCountryList[0].states.length) {
      this.stateList = selectedCountryList[0].states;
    }
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.pageCustomeData = new PageCustomiseDataModel(response.data);
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        // webappSetting -----------------
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.givingPageName = response.data.pageCustomizationDTO.givingPage;
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.footerblurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.footerShadowsFade / 10
          : '0.5';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        this.addAlphaFooter(this.appBGColor, this.footerblurOpacity);
        //  radial-gradient(farthest-corner at 55% 45%, rgba(42, 42, 42, 0.1), rgb(42, 42, 42))
        this.mainBGBlur = this.sanitizer.bypassSecurityTrustStyle(
          `radial-gradient(farthest-corner at 55% 40%,${this.changeToRGB},${this.appBGColor})`
        );
        this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to top, ${this.footerchangeToRGB},${this.appBGColor})`
        );
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );

        // if (this.scrWidth < 500) {
        //   this.logoSize = response.data.headerDTO.mobileLogoSize;
        // } else if (this.scrWidth > 500 && this.scrWidth < 900) {
        //   this.logoSize = response.data.headerDTO.tabletLogoSize;
        // } else {
        //   this.logoSize = response.data.headerDTO.webLogoSize;
        // }
        // this.theme = response.data.pageCustomizationDTO.colorScheme
        //   ? (response.data.pageCustomizationDTO.colorScheme = 'light')
        //   : (response.data.pageCustomizationDTO.colorScheme = 'dark');
        // this.headerLogo = response.data.headerDTO.logoId
        //   ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
        //   : '';

        // this.favIcon = response.data.pageCustomizationDTO.faviconId
        //   ? `${appConfig.imageURL}` +
        //     response.data.pageCustomizationDTO.faviconId
        //   : '';
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.givingPage}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
              ? `${response.data.pageCustomizationDTO.orgName}-`
              : ''
            }${response.data.pageCustomizationDTO.givingPage}`,
        });
        this._Meta.updateTag({
          property: 'og:image',
          content: `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`,
        });
        this.ngxFavicon.setFavicon(
          this.pageCustomeData.pageCustomizationDTO.favIcon
        );
      }
    });
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.givingPage}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }${response.data.givingPage}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  addAlphaFooter(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = data + _opacity.toString(16).toUpperCase();
    }
  }

  getSecretKeyWithoutLogin() {
    this.homeService.getSecretKeyWithoutLogin().subscribe((response: any) => {
      if (response.data.givingStripeApiKey) {
        this.stripeService.setKey(response.data.givingStripeApiKey);
        setTimeout(() => {
          if (this.router.url.includes('giving') && this.scrWidth < 500) {
            this.showCard2 = true;
          } else {
            this.showCard = true;
          }
        }, 1000);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Something went wrong, Please try after some time or raise a query from contact us page',
        });
      }
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  getSecretApiKey() {
    this.homeService.getSecretKey().subscribe((response: any) => {
      if (response.data.givingStripeApiKey) {
        this.stripeService.setKey(response.data.givingStripeApiKey);
        setTimeout(() => {
          if (this.router.url.includes('giving') && this.scrWidth < 500) {
            this.showCard2 = true;
          } else {
            this.showCard = true;
          }
        }, 1000);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Something went wrong, Please try after some time or raise a query from contact us page',
        });
      }
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  createToken(): void {
    const name = this.userEmail;
    this.paymentFormSubmitted = true;
    this.paymentForm.name = this.userFirstName
      ? this.userFirstName + ' ' + (this.userLastName ? this.userLastName : '')
      : '';
    this.paymentForm.email = this.userEmail ? this.userEmail : null;
    if (
      !this.userFirstName.trim() ||
      !this.userEmail.trim() ||
      !(
        // this.paymentForm.name.trim() ||
        !(
          // this.paymentForm.email.trim() ||
          !this.paymentForm.addressLine1.trim()
        )
      ) ||
      !this.paymentForm.city.trim() ||
      !this.userMobile.trim() ||
      !this.paymentForm.country.trim() ||
      !this.paymentForm.zipcode ||
      !this.paymentForm.state.trim()
    ) {
      this.paymentFormSubmitting = true;
    } else {
      this.paymentFormSubmitting = false;
      this.disableButton = true;
      this._SharedService.updateLoader(true);
      this.stripeService
        .createToken(this.card.element, {
          name: this.paymentForm.name.trim(),
          address_line1: this.paymentForm.addressLine1.trim(),
          address_line2: this.paymentForm.addressLine2.trim(),
          address_city: this.paymentForm.city.trim(),
          address_country: this.paymentForm.country.trim(),
          address_state: this.paymentForm.state.trim(),
          address_zip: String(this.paymentForm.zipcode),
          currency: 'usd',
        })
        .subscribe(
          (result) => {
            this.paymentFormSubmitted = false;
            if (result.error) {
              this.paymentFormSubmitting = false;
              this._SharedService.updateLoader(false);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: result.error.message
                  ? result.error.message
                  : 'Something went wrong.',
              });
              this.disableButton = false;
            } else if (result.token) {
              this.stripeTokenId = result.token.id;
              this.addCardDetails(result);
              this.paymentFormSubmitting = false;
            }
          },
          (error) => {
            this.disableButton = false;
            this.paymentFormSubmitting = false;
            setTimeout(() => {
              this._SharedService.updateLoader(false);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error.error
                  ? error.error.message
                  : 'Something went wrong.',
              });
            }, 1000);
          }
        );
    }
  }

  addCardDetails(result: any) {
    const data = JSON.parse(JSON.stringify(result));
    let tokenID = data.token.id;
    data['card'] = data.token;
    data['card']['addressCity'] = this.paymentForm.city
      ? this.paymentForm.city
      : null;
    data['card']['addressCountry'] = this.paymentForm.country
      ? this.paymentForm.country
      : null;
    data['card']['addressLine1'] = this.paymentForm.addressLine1
      ? this.paymentForm.addressLine1
      : null;
    data['card']['addressLine1Check'] = data.token.card.address_line1_check
      ? data.token.card.address_line1_check
      : null;
    data['card']['addressLine2'] = this.paymentForm.addressLine2
      ? this.paymentForm.addressLine2
      : null;
    data['card']['addressState'] = this.paymentForm.state
      ? this.paymentForm.state
      : null;
    data['card']['addressZip'] = this.paymentForm.zipcode
      ? this.paymentForm.zipcode
      : null;
    data['card']['addressZipCheck'] = data.token.card.address_zip_check
      ? data.token.card.address_zip_check
      : null;
    data['card']['autoPayment'] = true;
    data['card']['brand'] = data.token.card.brand
      ? data.token.card.brand
      : null;
    data['card']['country'] = this.paymentForm.country
      ? this.paymentForm.country
      : null;
    data['card']['cvcCheck'] = data.token.card.cvc_check
      ? data.token.card.cvc_check
      : null;
    data['card']['dynamicLast4'] = data.token.card.dynamic_last4
      ? data.token.card.dynamic_last4
      : null;
    data['card']['expMonth'] = data.token.card.exp_month
      ? data.token.card.exp_month
      : null;
    data['card']['expYear'] = data.token.card.exp_year
      ? data.token.card.exp_year
      : null;
    data['card']['funding'] = data.token.card.funding
      ? data.token.card.funding
      : null;
    data['card']['id'] = data.token.card.id ? data.token.card.id : null;
    data['card']['last4'] = data.token.card.last4
      ? data.token.card.last4
      : null;
    data['card']['name'] = data.token.card.name ? data.token.card.name : null;
    data['card']['object'] = data.token.card.object
      ? data.token.card.object
      : null;
    data['card']['selectForPayment'] = false;
    data['card']['tokenId'] = tokenID ? tokenID : null;
    data['card']['tokenizationMethod'] = data.token.card.tokenization_method
      ? data.token.card.tokenization_method
      : null;
    data['card']['forGiving'] = true;
    (data['email'] = this.userEmail.trim()),
      (data['firstName'] = this.userFirstName.trim()),
      (data['lastName'] = this.userLastName && this.userLastName.trim());
    (data['contactSource'] = 'WEBSITE_GIVING'),
      (data['mobile'] = this.userMobile),
      delete data['token'];
    delete data['card']['card'];
    delete data['card']['client_ip'];
    delete data['card']['created'];
    delete data['card']['type'];
    delete data['card']['used'];
    delete data['card']['livemode'];

    if (this.isLoggedIn) {
      this.homeService.addCard(data).subscribe(
        (response: any) => {
          this.makePayment();
          this.stateList = [];
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error ? error.error.message : 'Something went wrong.',
          });
          this.paymentFormSubmitting = false;
          this.disableButton = false;
        }
      );
    } else {
      this.homeService.addCardWithoutLogin(data).subscribe(
        (response: any) => {
          this.makePayment();
          this.stateList = [];
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error ? error.error.message : 'Something went wrong.',
          });
          this.paymentFormSubmitting = false;
          this.disableButton = false;
        }
      );
    }
  }

  donateNow() {
    this.stripeTokenId = this.cardData.tokenId;
    this.userEmail = this.cardData.updatedBy;
    this.makePayment();
  }

  makePayment() {
    let newdata = {};

    newdata = {
      card: { tokenId: this.stripeTokenId },
      email: this.userEmail,
      amount: this.DonationAmount,
      isGiving: true,
      invoiceId: this.invoiceId ? this.invoiceId : null,
      subdomain: null,
      mobile: this.userMobile,
      firstName: this.userFirstName ? this.userFirstName : '',
      lastName: this.userLastName ? this.userLastName : '',
      paymentMethod: 'card',
      frequency: 'ONETIME',
    };
    if (this.isLoggedIn) {
      this.homeService.makePayment(newdata).subscribe(
        (response: any) => {
          this.disableButton = false;
          if (response.body.data.paymentStatus === 'Payment Failed') {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: response.body.data.reason,
            });
            this.getAllCountryList();
            this._SharedService.updateLoader(false);
          } else if (response.body.data.paymentStatus === 'Payment Succeed') {
            this.userFirstName = null;
            this.userLastName = null;
            // this.userEmail = null;
            this.userMobile = null;
            this.DonationAmount = 0;
            this.paymentFormSubmitted = false;
            this.showPopup = true;
            this.showCard = false;
            this.showCard2 = false;
            if (this.cardBrand) {
              this.showaddedCard = true;
            }
            setTimeout(() => {
              this.showCard = true;
              this.showCard2 = true;
            }, 1000);
            this.getAllCountryList();
            this.getcardDetails();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Payment Done successfully`,
            });
            this.paymentFormSubmitting = false;
            // this.getcardDetails();
            // this.getCardDetailsList();
            // this.showAddCard = false
            this._SharedService.updateLoader(false);
            this.paymentForm = {
              name: '',
              email: '',
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              country: 'US',
              zipcode: null,
            };
          }
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error ? error.error.message : 'Something went wrong.',
          });
          this.paymentFormSubmitting = false;
          this.disableButton = false;
        }
      );
    } else {
      this.homeService.makePaymentWithoutLogin(newdata).subscribe(
        (response: any) => {
          this.disableButton = false;
          if (response.body.data.paymentStatus === 'Payment Failed') {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: response.body.data.reason,
            });
            this.getAllCountryList();
            this._SharedService.updateLoader(false);
          } else if (response.body.data.paymentStatus === 'Payment Succeed') {
            this.userFirstName = null;
            this.userLastName = null;
            this.userEmail = null;
            this.userMobile = null;
            this.DonationAmount = 0;
            this.paymentFormSubmitted = false;
            this.showPopup = true;
            this.showCard = false;
            this.showCard2 = false;
            setTimeout(() => {
              this.showCard = true;
              this.showCard2 = true;
            }, 1000);
            this.getAllCountryList();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Payment Done successfully`,
            });
            // this.getcardDetails();
            // this.getCardDetailsList();
            // this.showAddCard = false
            this._SharedService.updateLoader(false);
            this.paymentForm = {
              name: '',
              email: '',
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              country: 'US',
              zipcode: null,
            };
          }
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error ? error.error.message : 'Something went wrong.',
          });
          this.paymentFormSubmitting = false;
          this.disableButton = false;
        }
      );
    }
  }

  getcardDetails() {
    this.homeService.cardDetailsForGiving(true).subscribe((response: any) => {
      let allCardData = [];
      const sortedCard =
        response.data.cardDetails.cards.length &&
        response.data.cardDetails.cards.sort(
          (currentValue: any, nextValue: any) => {
            const currentDate: any = new Date(currentValue.createdDate);
            const nextDate: any = new Date(nextValue.createdDate);
            return nextDate - currentDate;
          }
        );
      allCardData = sortedCard.length ? sortedCard : [];
      this.cardData = sortedCard.length ? sortedCard[0] : [];
      this.cardBrand = this.cardData ? this.cardData.brand : null;
      this.cardLast4Digit = this.cardData ? this.cardData.last4 : null;
      if (allCardData.length) {
        this.showaddedCard = true;
      }
      // SubscriptionComponent.apiKey = response.data.stripeApiKey
    });
  }

  isNumberOnly(evt: any) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  zipValidation(event: any) {
    const regex = new RegExp('[0-9]{5}(?:-[0-9]{4})?$');
    this.disableButton = regex.test(event) ? false : true;
  }
}
