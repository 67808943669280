import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { WatchdailyComponent } from './components/watchdaily/watchdaily.component';
import { HeaderComponent } from './components/header/header.component';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { DialogModule } from 'primeng/dialog';
import { SigninPageComponent } from './components/signin-page/signin-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'primeng/carousel';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgramsComponent } from './components/programs/programs.component';
import { PlyrModule } from 'ngx-plyr';
import { ProfileComponent } from './components/dashboard/profile/profile.component';
import { SecurityComponent } from './components/dashboard/security/security.component';
import { MenuComponent } from './components/dashboard/menu/menu.component';
import { BillingComponent } from './components/dashboard/billing/billing.component';
import { AppInterceptor } from './../app/interceptors/interceptor';
import { SignupPageComponent } from './components/signup-page/signup-page.component';
import { JoinComponent } from './components/join/join.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule } from 'primeng/tooltip';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
// import { NgxStripeModule } from 'ngx-stripe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgramsResolver } from './components/programs/programs.resolver';
import { WatchDailyResolver } from './components/watchdaily/watchdaily.resolver';
import { SitecantreachComponent } from './sitecantreach/sitecantreach.component';
import { VideoPlayerComponent } from './components/programs/video-player/video-player.component';
import { EbookComponent } from './components/ebook/ebook.component';
import { EbookDetailComponent } from './components/ebook-detail/ebook-detail.component';
import { EbookReaderComponent } from './components/ebook-detail/ebook-reader/ebook-reader.component';
import { AuthGuard } from './guards/auth.guard';
import { ImageDirective } from './directives/image.directive';
import { EbookDetailResolver } from './components/ebook-detail/ebook-detail.resolver';
import { EbookGuard } from './guards/ebook.guard';
import { MobileAppComponent } from './components/mobile-app/mobile-app.component';
import { HomeGuard } from './guards/home.guard';
import { GivingComponent } from './components/giving/giving.component';
import { MobileAppGuard } from './guards/mobile-app.guard';
import { JoinGuard } from './guards/join.guard';
import { VodGuard } from './guards/vod.guard';
import { GivingGuard } from './guards/giving.guard';
import { PrebootModule } from 'preboot';
import { DOCUMENT, isPlatformBrowser, ɵgetDOM } from '@angular/common';

import { InputNumberModule } from 'primeng/inputnumber';
import { ChannelComponent } from './components/channel/channel.component';
import { NetworkComponent } from './components/network/network.component';
import { IframeLiveComponent } from './components/iframe-live/iframe-live.component';
import { LiveGuard } from './guards/live.guard';
import { IframePlayerComponent } from './components/iframe-player/iframe-player.component';
import { IframePlayerResolver } from './components/iframe-player/iframe-player.resolver';
import { ProgramsM3uComponent } from './components/programs-m3u/programs-m3u.component';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { SocketService } from './services/socket.service';
import { M3uchannelComponent } from './components/m3uchannel/m3uchannel.component';
import { M3uDetailPageComponent } from './components/m3u-detail-page/m3u-detail-page.component';
import { M3uDetailPageResolver } from './components/m3u-detail-page/m3u-detail-page.resolver';
import { ProgramsM3uResolver } from './components/programs-m3u/programs-m3u.resolver';
import { ChannelGuard } from './guards/channel.guard';
import { NetworkGuard } from './guards/network.guard';
import { EbookResolver } from './components/ebook/ebook.resolver';
import { HomeResolver } from './components/home/home.resolver';
import { PaymentPageComponent } from './components/payment-page/payment-page.component';
import { VideoAdsPlayerComponent } from './components/programs/video-ads-player/video-player.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WatchdailyComponent,
    HeaderComponent,
    SigninPageComponent,
    FooterComponent,
    DashboardComponent,
    ProgramsComponent,
    ProfileComponent,
    SecurityComponent,
    MenuComponent,
    BillingComponent,
    SignupPageComponent,
    JoinComponent,
    ContactUsComponent,
    PagenotfoundComponent,
    SitecantreachComponent,
    VideoPlayerComponent,
VideoAdsPlayerComponent,
    EbookComponent,
    EbookDetailComponent,
    EbookReaderComponent,
    ImageDirective,
    MobileAppComponent,
    GivingComponent,
    ChannelComponent,
    NetworkComponent,
    IframeLiveComponent,
    IframePlayerComponent,
    ProgramsM3uComponent,
    M3uchannelComponent,
    M3uDetailPageComponent,
    PaymentPageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    PrebootModule.withConfig({ appRoot: 'app-root', replay: false }),
    AppRoutingModule,
    HttpClientModule,
    DialogModule,
    NgbModule,
    CarouselModule,
    FormsModule,
    ToastModule,
    BsDropdownModule,
    PlyrModule,
    NgxStripeModule.forRoot(),
    ImageCropperModule,
    TooltipModule,
    MatProgressSpinnerModule,
    InputNumberModule,
    NoopAnimationsModule,
    DropdownModule,
    PaginatorModule,
    NgxYoutubePlayerModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (platformId: object) => () => {
        if (isPlatformBrowser(platformId)) {
          const dom = ɵgetDOM();
          const styles = Array.prototype.slice.apply(
            dom.getDefaultDocument().querySelectorAll('style[ng-transition]')
          );
          styles.forEach((el) => {
            // Remove ng-transition attribute to prevent Angular appInitializerFactory
            // to remove server styles before preboot complete
            el.removeAttribute('ng-transition');
          });
          dom.getDefaultDocument().addEventListener('PrebootComplete', () => {
            // After preboot complete, remove the server scripts
            styles.forEach((el) => dom.remove(el));
          });
        }
      },
      deps: [PLATFORM_ID],
      multi: true,
    },
    MessageService,
    ProgramsResolver,
    WatchDailyResolver,
    EbookDetailResolver,
    EbookResolver,
    HomeResolver,
    M3uDetailPageResolver,
    ProgramsM3uResolver,
    AuthGuard,
    EbookGuard,
    HomeGuard,
    MobileAppGuard,
    JoinGuard,
    VodGuard,
    ChannelGuard,
    NetworkGuard,
    GivingGuard,
    LiveGuard,
    IframePlayerResolver,
    SocketService,
  ],
  bootstrap: [AppComponent],
  exports: [ImageDirective],
})
export class AppModule { }
