import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-ebook-detail',
  templateUrl: './ebook-detail.component.html',
  styleUrls: ['./ebook-detail.component.scss'],
})
export class EbookDetailComponent implements OnInit {
  playlistPosition: boolean;
  appBGColor: any;
  isContentNotAvailable: boolean;
  isContentPaid: boolean;
  isOneTimePurchase: boolean;
  primaryColor: any;
  loginUserDetail: any;
  loginRequired: boolean;
  isLoggedIn: boolean;
  isFavourites: boolean;
  nextVedioTitle: boolean;
  showAboutSection: boolean;
  showAbout: boolean;
  isReadMore: boolean;
  currentBookDescription: string;
  mediadata: any[];
  bookId: string;
  isReaderDisabled: boolean;
  poster: string;
  loginData: any;
  srcWidth: number;
  title: string;
  ebookDetails: any;
  isEbookPaid: boolean;
  isOneTimePurchaseDone: boolean;
  planId: number;
  userPlanId: number;
  userId: number;
  currentEbookData: any;
  eBookUrl: string;
  accessRequired: boolean;
  isEbookAvailable: boolean;
  isSubscriptionIdsChecked: boolean;
  isDataLoaded: boolean;
  isPlanPaymentDone: boolean;
  isUserPlanPaymentDone: boolean;
  isSubscriptionAvailable: boolean;
  description: string;
  accessType: any;
  logoHeight: any
  constructor(
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _HomeService: HomeService,
    private _ElementRef: ElementRef,
    private _MessageService: MessageService,
    private _SessionService: SessionService,
    private _SharedService: SharedService
  ) {
    this.srcWidth = window.innerWidth;
    this.playlistPosition = false;
    this.isContentNotAvailable = false;
    this.isContentPaid = false;
    this.isOneTimePurchase = false;
    this.loginRequired = false;
    this.isLoggedIn = false;
    this.isFavourites = false;
    this.nextVedioTitle = false;
    this.showAboutSection = false;
    this.showAbout = false;
    this.isReadMore = true;
    this.currentBookDescription = '';
    this.mediadata = [];
    this.isReaderDisabled = false;
    this.poster = '';
    this.title = '';
    this.isEbookPaid = false;
    this.isOneTimePurchaseDone = false;
    this.planId = 0;
    this.userPlanId = 0;
    this.userId = 0;
    this.eBookUrl = '';
    this.accessRequired = false;
    this.isEbookAvailable = true;
    this.isSubscriptionIdsChecked = false;
    this.isDataLoaded = false;
    this.isPlanPaymentDone = false;
    this.isUserPlanPaymentDone = false;
    this.isSubscriptionAvailable = false;
    this.description = '';
  }
  ngOnInit(): void {
    this.getLogoDimention()
    this.loginData = this._SessionService.getSession('loggedInUser');
    this.isLoggedIn = this.loginData ? true : false;
    this.planId = this.loginData
      ? JSON.parse(this.loginData).userDetails.userSubscriptionPlanId
      : '';
    this.userId = this.loginData
      ? JSON.parse(this.loginData).userDetails.id
      : null;
    this.isPlanPaymentDone = this.loginData
      ? JSON.parse(this.loginData).userDetails.isPaymentDone
      : false;
    this._ActivatedRoute.params.subscribe((params) => {
      this.bookId = params.id;
      this.getSubscribe();
      if (this.isLoggedIn) {
        this.userPlanDetails(this.userId);
        this.getMediaItemWithLogin(params.id);
        this.getRelatedMediaItemWith(1, 4, params.id);
      } else {
        this.getMediaItemWithoutLogin(params.id);
        this.getRelatedMediaItemWithout(1, 4, params.id);
      }
    });
  }
  nextVedio(nextBookId: any): void {
    this._Router.navigate([`ebook/${nextBookId}`]);
    if (this.isLoggedIn) {
      this.userPlanDetails(this.userId);
      this.getMediaItemWithLogin(nextBookId);
      this.getRelatedMediaItemWith(1, 4, nextBookId);
    } else {
      this.getMediaItemWithoutLogin(nextBookId);
      this.getRelatedMediaItemWithout(1, 4, nextBookId);
    }
  }
  signInRequired() {
    this._Router.navigate(['/signup'], {
      queryParams: {
        vedioId: this.bookId,
        clickFrom: 'programsignInRequire',
        type: 'EBOOK',
        loginRequired:true
      },
    });
  }

  signInOnMedia() {
    this._Router.navigate(['/signin'], {
      queryParams: { vedioId: this.bookId, type: 'EBOOK' },
    });
  }
  getSubscribe() {
    this._HomeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this._ElementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.playlistPosition =
          response.data.pageCustomizationDTO.playlistPosition;
      }
    });
  }
  userPlanDetails(id: any) {
    this._SharedService.updateLoader(false);
    this._HomeService.userPlanDetails(id).subscribe(
      (response: any) => {
        this.userPlanId = response.data.id;
        this.isUserPlanPaymentDone = response.data.isPaymentDone;
      },
      (error) => {
        this._SharedService.updateLoader(false);
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
    this._SharedService.updateLoader(false);
  }
  getMediaItem(): void {
    this._HomeService
      .getEbookMediaItem(Number(this.bookId))
      .then((data) => {
        console.log(data.data);
        this.ebookDetails = data.data;
        if (this.srcWidth < 500) {
          this.poster =
            data.data.ebookArtwork &&
            `${appConfig.imageURL}${data.data.ebookArtwork.id}?height=306&width=198`;
        } else {
          this.poster =
            data.data.ebookArtwork &&
            `${appConfig.imageURL}${data.data.ebookArtwork.id}?height=748&width=484`;
        }
        this.title = data.data.title;
        if (data.data.mediaAccessType !== 'Free') {
          this.isEbookPaid = true;
        }
        this.isOneTimePurchase = data.data.isOneTimePurchase;
        this.isOneTimePurchaseDone = data.data.isOneTimePurchasePaymentDone;
        if (data.data.mediaAccessType === 'PAID') {
          data.data.subscriptionPlanIds.forEach((element: any) => {
            if (element == this.planId) {
              this.isEbookPaid = false;
              this.isReaderDisabled = false;
            }
            if (element == this.userPlanId) {
              this.isEbookPaid = false;
              this.isReaderDisabled = false;
            }
          });
          if (this.isOneTimePurchase && this.isOneTimePurchaseDone) {
            this.isReaderDisabled = false;
            this.isEbookPaid = false;
          } else {
            this.isEbookPaid = true;
            this.isReaderDisabled = true;
          }
        } else {
          this.isEbookPaid = false;
          this.isReaderDisabled = false;
        }
        if (data.data.mediaAccessType === 'FREE') {
          this.isEbookPaid = false;
          this.isReaderDisabled = false;
        }
      })
      .catch((error) =>
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
        })
      );
  }
  getAccess() {
    if (this.isLoggedIn) {
      this._Router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.bookId,
          id: this.planId,
          showOffers: true,
          clickFrom: 'program',
          type: 'EBOOK',
        },
      });
    } else {
      this._Router.navigate(['/signup'], {
        queryParams: { vedioId: this.bookId, showOffers: true, type: 'EBOOK' },
      });
    }
  }
  buyItem() {
    if (this.isLoggedIn) {
      this._Router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.bookId,
          id: this.planId,
          buyItem: true,
          ItemPrice: this.ebookDetails.price,
          clickFrom: 'program',
          type: 'EBOOK',
        },
      });
    } else {
      this._Router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.bookId,
          ItemPrice: this.ebookDetails.price,
          buyItem: true,
          type: 'EBOOK',
        },
      });
    }
  }
  showText() {
    this.isReadMore = !this.isReadMore;
  }
  getMediaItemWithLogin(id: any) {
    this._SharedService.updateLoader(true);
    this._HomeService.getEbookMediaDataByIdWith(id).subscribe(
      (data: any) => {
        if (data.data.currentMediaItem) {
          this.ebookDetails = data.data.currentMediaItem;
          this.eBookUrl =
            data.data.currentMediaItem.document &&
            data.data.currentMediaItem.document.path;
          this.description =
            this.ebookDetails && this.ebookDetails.description
              ? this.ebookDetails.description.trim()
              : '';
          if (this.srcWidth < 500) {
            this.poster =
              data.data.currentMediaItem.ebookArtworkId &&
              `${appConfig.imageURL}${data.data.currentMediaItem.ebookArtworkId}?height=1001&width=637`;
          } else {
            this.poster =
              data.data.currentMediaItem.ebookArtworkId &&
              `${appConfig.imageURL}${data.data.currentMediaItem.ebookArtworkId}?height=1408&width=868`;
          }
          this.title = data.data.currentMediaItem.title;
          this.isFavourites = data.data.currentMediaItem.isFavourites
            ? data.data.currentMediaItem.isFavourites
            : false;
          if (data.data.currentMediaItem.mediaAccessType !== 'Free') {
            this.isEbookPaid = true;
            this.isReaderDisabled = true;
          }
          this.isOneTimePurchase = data.data.currentMediaItem.isOneTimePurchase;
          this.isOneTimePurchaseDone =
            data.data.currentMediaItem.isOneTimePurchasePaymentDone;
          this.isSubscriptionAvailable = data.data.currentMediaItem
            .subscriptionPlanIds.length
            ? true
            : false;
            this.accessType =
             data.data.currentMediaItem.mediaAccessType === 'ACCESSREQUIRED'
            ? true
            : false;
          if (data.data.currentMediaItem.mediaAccessType === 'PAID') {
            if (data.data.currentMediaItem.subscriptionPlanIds.length) {
              this.isSubscriptionIdsChecked = true;
              data.data.currentMediaItem.subscriptionPlanIds.forEach(
                (element: any) => {
                  if (element == this.planId && this.isPlanPaymentDone) {
                    this.isEbookPaid = false;
                    this.isReaderDisabled = false;
                  }
                  if (
                    element == this.userPlanId &&
                    this.isUserPlanPaymentDone
                  ) {
                    this.isEbookPaid = false;
                    this.isReaderDisabled = false;
                  }
                }
              );
            } else {
              this.isEbookPaid = true;
              this.isReaderDisabled = true;
            }
            if (!this.isSubscriptionIdsChecked || this.isReaderDisabled) {
              if (this.isOneTimePurchase && this.isOneTimePurchaseDone) {
                this.isReaderDisabled = false;
                this.isEbookPaid = false;
              } else {
                this.isEbookPaid = true;
                this.isReaderDisabled = true;
              }
            }
          } else {
            this.isEbookPaid = false;
            this.isReaderDisabled = false;
          }
          if (data.data.currentMediaItem.mediaAccessType === 'FREE') {
            if (!data.data.currentMediaItem.document) {
              this.isEbookAvailable = false;
              this.isReaderDisabled = true;
            } else {
              this.isEbookPaid = false;
              this.isReaderDisabled = false;
            }
          }
          if (data.data.currentMediaItem.mediaAccessType === 'ACCESSREQUIRED') {
            this.accessRequired = true;
          }
          if (this.accessType && !this.isLoggedIn) {
          this.isReaderDisabled = true;
          this.loginRequired = true;
        } else {
            this.isReaderDisabled = false;
            this.loginRequired = false;
          }
          this._SharedService.updateLoader(false);
          this.isDataLoaded = true;
          this.isReadMore = true;
        }
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  getMediaItemWithoutLogin(id: any) {
    this._SharedService.updateLoader(true);
    this._HomeService.getMediaDataByIdWithout(id).subscribe(
      (data: any) => {
        if (data.data.currentMediaItem) {
          this.ebookDetails = data.data.currentMediaItem;
          this.eBookUrl =
            data.data.currentMediaItem.document &&
            data.data.currentMediaItem.document.path;
          this.description =
            this.ebookDetails && this.ebookDetails.description
              ? this.ebookDetails.description.trim()
              : '';

          if (this.srcWidth < 500) {
            this.poster =
              data.data.currentMediaItem.ebookArtworkId &&
              `${appConfig.imageURL}${data.data.currentMediaItem.ebookArtworkId}?height=1001&width=637`;
          } else {
            this.poster =
              data.data.currentMediaItem.ebookArtworkId &&
              `${appConfig.imageURL}${data.data.currentMediaItem.ebookArtworkId}?height=1408&width=868`;
          }
          this.title = data.data.currentMediaItem.title;
          this.isSubscriptionAvailable = data.data.currentMediaItem
            .subscriptionPlanIds.length
            ? true
            : false;
          if (data.data.currentMediaItem.mediaAccessType !== 'Free') {
            this.isEbookPaid = true;
          }
          this.isOneTimePurchase = data.data.currentMediaItem.isOneTimePurchase;
          this.isOneTimePurchaseDone =
            data.data.currentMediaItem.isOneTimePurchasePaymentDone;
           this.accessType =
            data.data.currentMediaItem.mediaAccessType === 'ACCESSREQUIRED'
            ? true
            : false;
          if (data.data.currentMediaItem.mediaAccessType === 'PAID') {
            if (this.isOneTimePurchase && this.isOneTimePurchaseDone) {
              this.isReaderDisabled = false;
              this.isEbookPaid = false;
            } else {
              this.isEbookPaid = true;
              this.isReaderDisabled = true;
            }
          } else {
            this.isEbookPaid = false;
            this.isReaderDisabled = false;
          }
          if (data.data.currentMediaItem.mediaAccessType === 'FREE') {
            if (!data.data.currentMediaItem.document) {
              this.isEbookAvailable = false;
              this.isReaderDisabled = true;
            } else {
              this.isEbookPaid = false;
              this.isReaderDisabled = false;
            }
          }
          if (data.data.currentMediaItem.mediaAccessType === 'ACCESSREQUIRED') {
            this.accessRequired = true;
            this.isReaderDisabled = true;
          }
          if (this.accessType && !this.isLoggedIn) {
            this.loginRequired = true;
          } else {
            this.loginRequired = false;
          }
        }
        this._SharedService.updateLoader(false);
        this.isDataLoaded = true;
        this.isReadMore = true;
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
        });
      }
    );
  }

  getRelatedMediaItemWithout(page: any, size: any, id: any) {
    this._SharedService.updateLoader(true);
    this._HomeService.getEbookRelatedMediaItemWithout(page, size, id).subscribe(
      (response: any) => {
        this.mediadata = response.data.content;
        this.mediadata.forEach((el) => {
          if (el.ebookArtworkId) {
            var width = 245;
            var height = 385;
            let id = el.ebookArtwork.document.id;
            el.ebookArtwork.document['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
        });

        this.mediadata = this.mediadata.map((el) => ({
          mediaImg: el.ebookArtworkId ? el.ebookArtwork.document.newimg : '',
          title: el.title,
          color: el.ebookArtworkId ? el.ebookArtwork.document.imageColur : '',
          id: el.id,
          subTitle: el.subTitle,
        }));
        this._SharedService.updateLoader(false);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  getRelatedMediaItemWith(page: any, size: any, id: any) {
    this._SharedService.updateLoader(true);
    this._HomeService.getEbookRelatedMediaItemWith(page, size, id).subscribe(
      (response: any) => {
        this.mediadata = response.data.content;
        this.mediadata.forEach((el) => {
          if (el.ebookArtworkId) {
            var width = 245;
            var height = 385;
            let id = el.ebookArtwork.document.id;
            el.ebookArtwork.document['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
        });

        this.mediadata = this.mediadata.map((el) => ({
          mediaImg: el.ebookArtworkId ? el.ebookArtwork.document.newimg : '',
          title: el.title,
          color: el.ebookArtworkId ? el.ebookArtwork.document.imageColur : '',
          id: el.id,
          subTitle: el.subTitle,
        }));
        this._SharedService.updateLoader(false);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  favButtonClick() {
    this.isFavourites
      ? this.removeFavourites(this.bookId)
      : this.addToFavourites(this.bookId);
    this.isFavourites = !this.isFavourites;
  }

  addToFavourites(id: any) {
    this._HomeService.addToFavourites(id).subscribe(
      (response: any) => {
        this._MessageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Added to favourites.',
        });
        this._SharedService.updateLoader(false);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this._SharedService.updateLoader(false);
      }
    );
  }

  removeFavourites(id: any) {
    this._HomeService.removeFavourites(id).subscribe(
      (response: any) => {
        this._MessageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Removed from favourites.',
        });
        this._SharedService.updateLoader(false);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
        this._SharedService.updateLoader(false);
      }
    );
  }
  getLogoDimention(){
    this._HomeService.dimensions$.subscribe((dimensions) => {
      this.logoHeight = dimensions.height
    });
  }
}
