<section class="top-header">
    <section class="main-banner-wrapper">
        <div class="banner-img-wrapper">
            <div class="left-banner-color" [style.background-color]="appBGColor"></div>
            <img class="main-bg-image" [src]="pageCustomeData.mainBannerDTO.mainHomeBgImage" onload="style.opacity = 1"
                style="opacity: 0;object-fit: cover;">
        </div>
        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
        <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
        <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>

        <section class="cards-wrapper">
            <section class="bigger-card">
                <div class="container d-flex justify-content-center">
                    <div class="app-info-wrapper">
                        <div class="d-flex">
                            <div class="left-side">
                                <div class="left-side-wrapper">
                                    <img class="logo" [src]="bannerInfo.fullIconPath" alt="">
                                    <div class="introduction-text">
                                        <!-- <h2 style="font-size: 20px;">Introducing</h2> -->
                                        <h2>{{bannerInfo.shortAppTitle}}</h2>
                                        <h1>MOBILE APP</h1>
                                    </div>
                                    <h2 class="grey-text">
                                        Available in App Store
                                    </h2>
                                    <div class="appstoreIcons">
                                        <img src="assets/logos/svg/amazon.svg" alt="">
                                        <img src="assets/logos/svg/appstore.svg" alt="">
                                        <img src="assets/logos/svg/googlepay.svg" alt="">
                                        <img src="assets/logos/svg/appletv.svg" alt="">
                                        <img src="assets/logos/svg/roku.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="right-side">
                                <section class="main-platform">
                                    <div class="platform-devices">
                                        <div class="platform-tv">
                                            <img class="platform-inner" [src]="androidTVImage"
                                                onload="style.opacity = 1" style="opacity: 0;object-fit: cover;">

                                            <img src="assets/device/4K-TV-MOCKUP.png" alt="">
                                            <!-- <img src="assets/logos/appstore_Assets/asset16.png" alt=""> -->
                                        </div>
                                        <div class="platform-ipad">
                                            <img class="platform-inner" [src]="amazonTabImage"
                                                onload="style.opacity = 1" style="opacity: 0;object-fit: cover;">

                                            <img src="assets/device/iPad.png" alt="">
                                            <!-- <img src="assets/logos/appstore_Assets/asset11.png" alt=""> -->
                                        </div>
                                        <div class="platform-iphone">
                                            <img class="platform-inner" [src]="iphoneImage" onload="style.opacity = 1"
                                                style="opacity: 0;object-fit: cover;">

                                            <img src="assets/device/iPhone-12.png" alt="">
                                            <!-- <img src="assets/logos/appstore_Assets/asset22.png" alt=""> -->
                                        </div>
                                        <div class="platform-android">
                                            <img class="platform-inner" [src]="androidImage" onload="style.opacity = 1"
                                                style="opacity: 0;object-fit: cover;">

                                            <img src="assets/device/Android-Mobile-Mockup.png" alt="">
                                            <!-- <img src="assets/logos/appstore_Assets/asset-android.png" alt=""> -->
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="small-cards-container">
                <div class="container">
                    <div class="card-wrapper">
                        <ng-template ngFor let-item [ngForOf]="storeCards">
                            <div class="cards" *ngIf="item.isShow" (click)="goToView(item)"
                                [ngClass]="[item.name === 'Android App On'? 'androidCard':'',item.name === 'Apple App on'? 'appleCard':'',item.name === 'Amazon App On'? 'amazonCard':'',item.name === 'Roku Channel'? 'rokuCard':'', item.name === 'Apple TV'? 'appleTvCard':'']"
                                [ngStyle]="{opacity:item.name === 'Anywhere'? '0': ''}"
                                style="background-image: url({{item.bgcardImg}})">
                                <div class="d-flex" [ngClass]="{'cursor-pointer':item.isLink}">
                                    <div class="left-side">
                                        <div class="left-side-wrapper">
                                            <img class="logo" [src]="bannerInfo.fullIconPath" alt=""
                                                *ngIf="item.name !== 'Anywhere'">
                                            <div class="description" *ngIf="item.name !== 'Anywhere'">
                                                <img *ngIf="item.name === 'Roku Channel' || item.name === 'Apple TV'"
                                                    style="margin-bottom: 5px;margin-top: -25px;width: 215px;"
                                                    [src]="item.appStoreImg" alt="">
                                                <p>Click here to Install</p>
                                                <h2>{{item.name}}</h2>
                                            </div>
                                            <img *ngIf="item.name !== 'Anywhere' && item.name !== 'Roku Channel' && item.name !== 'Apple TV'"
                                                [ngClass]="[item.deviceImgType === 'androidMobile' || item.deviceImgType === 'appleMobile' ? 'bgBlackWithBorder' : '',item.name==='Amazon App On' ? 'amazonIcon':'']"
                                                [src]="item.appStoreImg" alt="">
                                        </div>
                                    </div>
                                    <div class="right-side">
                                        <img class="platform-inner" [src]="item.posterImage" onload="style.opacity = 1"
                                            *ngIf="item.deviceImgType === 'tv'"
                                            [ngClass]="[item.deviceImgType==='tv' ? 'tv-deviceImg' : '']"
                                            style="opacity: 0;object-fit: cover;">
                                        <img class="platform-inner"
                                            [ngClass]="[item.deviceImgType==='androidMobile' ? 'androidMobile-deviceImg' : '', item.deviceImgType==='appleMobile' ? 'appleMobile-deviceImg' : '', item.deviceImgType==='tab' ? 'tab-deviceImg' : '']"
                                            *ngIf="item.deviceImgType === 'androidMobile' || item.deviceImgType === 'appleMobile' || item.deviceImgType === 'tab'"
                                            [src]="item.posterImage" onload="style.opacity = 1"
                                            style="opacity: 0;object-fit: cover;">
                                        <img class="platform-img"
                                            [ngClass]="[item.deviceImgType==='androidMobile' ? 'androidMobile-device' : '', item.deviceImgType==='appleMobile' ? 'appleMobile-device' : '', item.deviceImgType==='tab' ? 'tab-device' : '', item.deviceImgType==='tv' ? 'tv-device' : '']"
                                            [src]="item.deviceImg" alt="">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </section>

            <div class="main-footer" *ngIf="showfooter">
                <div class="footer-background" [ngStyle]="{'background-image': 'url(' + footerBgImage + ')'}"></div>
                <div class="footer-shadow" [style.background-image]="footerBlur"></div>
                <div class="container footer-bottom">
                    <div class="footer-wrapper col-lg-12 align-items-center">
                        <div class="copyright col-lg-5">
                            &copy; {{footerDetail ? footerDetail.copyright : ''}}
                        </div>
                        <div class="social-wrapper col-lg-5" style="padding-left: 15px;">
                            <div class="social-media">
                                <div class="social-icons">
                                    <!-- <img [src]="item.socialImg" (click)="goToSocialLink(item)" alt=""  onload="style.opacity = 1" style="opacity: 0;"> -->
                                    <a class="ml-29" [href]="instagramLink" *ngIf="instagramLink" target="_blank"><i
                                            class="fa fa-instagram"></i></a>
                                    <a class="ml-29" [href]="facebookLink" *ngIf="facebookLink" target="_blank"><i
                                            class="fa fa-facebook-square"></i></a>
                                    <a class="ml-29" [href]="youtubeLink" *ngIf="youtubeLink" target="_blank"><i
                                            class="fa fa-youtube-play"></i></a>
                                    <a class="ml-29" [href]="twitterLink" *ngIf="twitterLink" target="_blank"><i
                                            class="fa fa-twitter-square"></i></a>
                                </div>
                            </div>

                        </div>
                        <div class="poweredBy col-lg-2">
                            <p class="d-flex align-items-center">Powered By
                                <span style="max-width:85px;">
                                    <a *ngIf="!isWhiteLabel" href="https://www.nowcast.cc/" target="_blank">
                                        <img style="width: 85px;filter: brightness(1.3);"
                                            src="assets/images/Nowcast bottom logo.svg" alt="">
                                    </a>
                                    <a *ngIf="isWhiteLabel" href="" target="_blank">
                                        <img style="width: 85px;filter: brightness(1.3);margin-left: 7px;"
                                            [src]="headerLogo" alt="">
                                    </a>
                                </span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </section>
</section>



<p-dialog *ngIf="signoutModal" [closable]="false" header="" [(visible)]="signoutModal" [modal]="true"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="showInfo1">
        <p>Are you sure you want to sign out ?</p>
    </div>
    <p-footer class="signout-footer">
        <button type="button" class="btn btn-white" style="margin:  10px;" (click)="logOut()">Yes</button>
        <button type="button" class="btn btn-white" (click)="signoutModal= false">Cancel</button>
    </p-footer>
</p-dialog>