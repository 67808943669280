<div>
    <p-toast position="top-right"></p-toast>
    <ng-container *ngIf="isHeader">
        <div [ngClass]="{'position-relative':isRelativePosition}">
            <app-header [ngClass]="{'top-header':isAbsolutePosition, 'hide-logo' : isGiving,'show-logo':!isGiving}"
                class="logo-position"></app-header>
        </div>
    </ng-container>
    <router-outlet *ngIf="showView"></router-outlet>
</div>

<div class="app-loader" *ngIf="showLoader">
    <!-- <mat-spinner></mat-spinner> -->
    <div class="nb-spinner"></div>
</div>