<div class="main-signinPage">
    <div class="col-12" style="position: relative;">
      <div class="d-flex">
        <div class="col-12 left-panel">
          <!-- <div class="left-content">
            <div class="container form-wrapper">
  
            </div>
          </div> -->
          <div class="logo mb-20">
            <a routerLink="/main">
              <img [src]="logo" alt="">
            </a>
          </div>
          <div class="detail-container">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div class="backBtn" (click)="gotToView()"><i class="fa fa-chevron-circle-left" aria-hidden="true"></i><span>Back</span></div>
              
            </div>
            <div class="d-flex">
              <div class="image-section" [ngClass]="{'noImg': !itemDetail.tvgLogo}">
                <img *ngIf="itemDetail.tvgLogo" [src]="itemDetail ? itemDetail.tvgLogo :  loginImg" onload="style.opacity = 1"
                style="opacity: 0;" alt="">
                <img *ngIf="!itemDetail.tvgLogo" style="width: 100px;min-width: 100px;" src="assets/images/no-image-icon-23494.png" alt="">
              </div>
              <div class="detail-section">
                <div class="d-flex justify-content-between align-items-center">
                  <h2 *ngIf="!itemDetail.titleLogo">{{itemDetail ? itemDetail.tvgName: ''}}</h2>
                  <img *ngIf="itemDetail.titleLogo" class="titleLogo" [src]="itemDetail ? itemDetail.titleLogo : ''" alt="">
                  <div>
                    <i *ngIf="!isFav && isLoggedIn" (click)="addToFav()" class="fa fa-heart-o fav-icon" aria-hidden="true"></i>
                    <i *ngIf="isFav && isLoggedIn" (click)="removeFromFav()" class="fa fa-heart fav-icon" aria-hidden="true"></i>
                  </div>
                </div>
                <h3 class="item-description">{{itemDetail ? itemDetail.groupTitle : ''}}</h3>
                <table>
                  <tr *ngIf="itemDetail ? itemDetail.credits : ''">
                    <td class="titles">Directed By:</td>
                    <td class="item-info">{{itemDetail ? itemDetail.credits : ''}}</td>
                  </tr>
                  <tr *ngIf="itemDetail ? itemDetail.movieDate : ''">
                    <td class="titles">Release Date:</td>
                    <td class="item-info">{{itemDetail ? itemDetail.movieDate : ''}}</td>
                  </tr>
                  <tr *ngIf="m3uType === 'M3U_MOVIES' && duration ? duration : ''">
                    <td class="titles">Duration:</td>
                    <td class="item-info">{{duration ? duration : '00:00:00'}}</td>
                  </tr>
                  <tr *ngIf="itemDetail ? itemDetail.subTitle: ''">
                    <td class="titles">Genre:</td>
                    <td class="item-info">{{itemDetail ? itemDetail.subTitle: ''}}</td>
                  </tr>
                  <tr *ngIf="itemDetail ? itemDetail.actor : ''">
                    <td class="titles">Cast:</td>
                    <td class="item-info cast">
                      <!-- {{itemDetail ? itemDetail.actor : ''}} -->
                      <p *ngIf="itemDetail ? itemDetail.actor : ''">
                        {{(showMore) ? itemDetail.actor : itemDetail.actor | slice:0:150}} <span *ngIf="!showMore && itemDetail.actor.length > 150">...</span> 
                        <span class="showMorebtn" *ngIf="!showMore && itemDetail.actor.length > 150" (click)="showMore=true">[Show More]</span>
                        <span class="showMorebtn" *ngIf="showMore && itemDetail.actor.length > 150" (click)="showMore=false">[Show Less]</span>
                        </p>
                    </td>
                  </tr>
                </table>
                <div class="buttons">
                  <button *ngIf="m3uType === 'M3U_MOVIES'" (click)="goToItemView('','')" class="btn"><i class="fa fa-play" aria-hidden="true"></i>Play</button>
                  <!-- <button (click)="goToItemView()" class="btn"><i class="fa fa-play" aria-hidden="true"></i>Watch trailer</button> -->
                  <!-- <button *ngIf="!isFav && isLoggedIn" (click)="addToFav()" class="btn"><i class="fa fa-heart" aria-hidden="true"></i>Add to favorites</button>
                  <button *ngIf="isFav && isLoggedIn" (click)="removeFromFav()" class="btn"><i style="color: red;" class="fa fa-heart" aria-hidden="true"></i>Favourite</button> -->
                </div>
              </div>
            </div>
          </div>
          <section class="series-details" *ngIf="m3uType === 'M3U_SERIES'">
            <div>
              <div class="col-12 left-panel">
                <h1 class="heading d-flex justify-content-between align-items-center">
                  <span>Episodes</span>
                  <div class="dropdown">
                    <select name="seasons" class="select-dropdown" id="seasons" [(ngModel)]="seasons" (ngModelChange)="getDetailOfSeason($event,seasons)">
                      <option [value]="item" *ngFor="let item of itemDetail.seasons;index as i">{{item}}</option>
                    </select>
                  </div>
                </h1>
                <div class="all-episodes">
                  <div class="detail-wrapper d-flex justify-content-between" (click)="goToItemView(data,i)" *ngFor="let data of seasonData;index as i">
                    <div class="d-flex ">
                      <div *ngIf="data.tvgName" class="eps-no">{{i + 1}}</div>
                      <div class="img">
                        <img *ngIf="data.tvgLogo" [src]="data ? data.tvgLogo : ''" alt="">
                        <img *ngIf="!data.tvgLogo" style="width: 70px;min-width: 70px;" src="assets/images/no-image-icon-23494.png" alt="">
                      </div>
                      <div class="details">
                        <p class="title">{{data ? data.tvgName: ''}}</p>
                        <p class="descr">{{data ? data.groupTitle: ''}}</p>
                      </div>
                    </div>
                    <div class="duration" *ngIf="data.showDuration">
                      <p>{{data.showDuration ? data.showDuration : ''}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- <div class="col-7">
      
          </div> -->
      
      <div class="main-banner" *ngIf="scrWidth > 800">
        <div class="banner-img-wrapper">
          <div class="left-banner-color" [style]="appBGColor"></div>
          <img class="main-bg-image" [src]="itemDetail ? itemDetail.icon : loginImg" onload="style.opacity = 1" style="opacity: 0;object-fit: cover;">
        </div>
        <div class="main-bg-shadow left-gradiant" [style.background-image]="leftGradiant"></div>
        <!-- <div class="main-bg-shadow" [style.background-image]="mainBGBlur"></div> -->
        <div class="main-bg-shadow" [style.background-image]="bottomGradiant"></div>
      </div>

    </div>
  </div>