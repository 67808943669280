import { Component, ElementRef, OnInit } from '@angular/core';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { ContactModel } from 'src/app/Models/profile.model';
import { HomeService } from 'src/app/services/home/home.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  loginUserDetail: any;
  userMail: any;
  userName: any;
  userFirstName: any;
  userLastName: any;
  appBGColor: any;
  logo: any;
  logoId: any;
  primaryColor: any;
  favIcon: any;
  stateList: any[] = [];
  cityDropdown = false;
  cityList: any[] = [];
  userData: ContactModel;
  userId: any;
  profileImg: any;
  imageName = '';
  imageChangedEvent: any = '';
  cropperModal = false;
  croppedImage: any = '';
  orgImage = '';
  subscriptionPlanID: any;
  planName: any;
  changeToRGB: any;
  domain: string;
  constructor(
    public homeService: HomeService,
    private elementRef: ElementRef,
    public messageService: MessageService,
    private ngxFavicon: AngularFaviconService,
    private router: Router,
    private _Title: Title,
    private _Meta: Meta,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.domain = ''
    this.userData = new ContactModel();
  }

  ngOnInit(): void {
    this.loginUserDetail = localStorage.getItem('loggedInUser');
    this.userMail = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.email
      : '';
    this.userFirstName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.firstName
      : '';
    this.userLastName = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.lastName
      : '';
    this.userId = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.id
      : '';
    this.userName = this.userFirstName + ' ' + this.userLastName;
    // this.pageCustomization();
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    //   this._ActivatedRoute.queryParams.subscribe((params) => {
    //     this.domain = params.domain;
    //   });
    this.getPageCustomizationByDomain();
    this.getSubscribe();
    this.getAllCountryList();
    this.getContactById(this.userId);
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        // this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.addAlpha(this.primaryColor, 0.25);
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
      }
    });
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {
  //     this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //     this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     // this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
  //     this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //     this.addAlpha(this.primaryColor, 0.25)
  //     this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //     this.ngxFavicon.setFavicon(this.favIcon);
  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  getAllCountryList() {
    this.homeService.getAllCountryList().subscribe(
      (response: any) => {
        this.stateList = response.data.countryList[0]
          ? response.data.countryList[0].states
          : [];
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  updateCityList(event: any) {
    const selStateObj = this.stateList.filter(
      (el) => el.code === event.target.value
    );
    if (selStateObj.length && selStateObj[0].cities.length) {
      this.cityDropdown = true;
      this.cityList = selStateObj[0].cities;
      this.userData.mailingAddress.city = this.cityList[0].cityName;
    } else {
      this.cityDropdown = false;
      this.userData.mailingAddress.city = '';
    }
  }

  getContactById(id: any) {
    this.homeService.getContactById(id).subscribe((response: any) => {
      this.userData = new ContactModel(response.data);
      this.userData.basicInfo.fullName = `${response.data.basicInfo.firstName
        } ${response.data.basicInfo.lastName ? response.data.basicInfo.lastName : ''
        }`;
      this.profileImg = response.data.logo
        ? `${appConfig.imageURL}` + response.data.logo.id
        : '';
      this.logoId = response.data.logoId;
    });
  }

  removeImage() {
    this.logoId = false;
    this.updateContact();
  }

  updateContact() {
    const data = JSON.parse(JSON.stringify(this.userData));
    const regex = /[a-zA-Z]/;

    if (!data.basicInfo.mobileNumber) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter mobile number.',
      });
    }
    if (
      !data.basicInfo.fullName &&
      !data.basicInfo.firstName &&
      !regex.test(data.basicInfo.fullName.split(' ')[0])
    ) {
      if (
        data.basicInfo.fullName.split(' ')[1] &&
        !regex.test(data.basicInfo.fullName.split(' ')[1])
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please enter name.',
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please enter name.',
        });
      }
    }
    if (!data.mailingAddress.city.trim()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter city.',
      });
    }
    if (!data.mailingAddress.postalCode) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter zip code.',
      });
    }
    if (!data.mailingAddress.state) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select state.',
      });
    }
    if (
      data.basicInfo.fullName &&
      data.basicInfo.firstName &&
      data.basicInfo.mobileNumber &&
      data.mailingAddress.city.trim() &&
      data.mailingAddress.postalCode &&
      data.mailingAddress.state
    ) {
      let [first, ...last] = data.basicInfo.fullName.split(' ');
      last = last.join(' ');
      data.basicInfo.firstName = first;
      data.basicInfo.lastName = last ? last : null;
      data.logoId = this.logoId ? this.logoId : null;

      this.homeService.updateContact(this.userId, data).subscribe(
        (response: any) => {
          const storedData = localStorage.getItem('loggedInUser');
          const sharedData = JSON.parse(storedData ? storedData : '')
          sharedData.userDetails.firstName = data.basicInfo.firstName
          sharedData.userDetails.lastName = data.basicInfo.lastName
          sharedData.userDetails.mobileNumber = data.basicInfo.mobileNumber
          // this.m3uData = JSON.parse(this.sharedData.allData)
          // if(this.m3uType === 'M3U_SERIES'){
          //   this.m3uData.tvgName = data.tvgName
          // }
          // const senddata = {allData: JSON.stringify(this.m3uData)}
          localStorage.setItem('loggedInUser', JSON.stringify(sharedData));
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Profile updated successfully',
          });
          if (!this.logoId) {
            this.profileImg = null;
          }
          this.getContactById(this.userId);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error ? error.error.message : 'Something went wrong.',
          });
        }
      );
    }
  }

  onFileChange(event: any) {
    if (event.target.files[0].type === 'image/png') {
      this.imageName = event.target.files[0].name;
      if (event.target.files[0].size > 5300000) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Maximum file size allowed in 50 mb',
        });
      } else {
        this.imageChangedEvent = event;
        this.cropperModal = true;
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Supported image format is PNG',
      });
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropImage() {
    const imgData = {
      name: this.imageName,
      isLogo: true,
      imageString: this.croppedImage.split(';base64,')[1],
    };
    this.homeService.uploadBase64(imgData).subscribe(
      (response: any) => {
        this.profileImg = `${appConfig.imageURL}` + response.body.data.id;
        this.imageChangedEvent = null;
        this.croppedImage = '';
        this.logoId = response.body.data.id;
        this.cropperModal = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logo uploaded successfully',
        });
      },
      (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error ? error.error.message : 'Something went wrong.',
        });
      }
    );
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }profile`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }profile`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
}
