import { Component, ElementRef, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  appBGColor: any;
  logo: any;
  primaryColor: any;
  favIcon: any;
  web: any;
  domain: string;
  constructor(
    public homeService: HomeService,
    private elementRef: ElementRef,
    public messageService: MessageService,
    private ngxFavicon: AngularFaviconService,
    private router: Router,
    private _Title: Title,
    private _Meta: Meta,
    private _ActivatedRoute: ActivatedRoute
  ) { 
    this.domain = '';
  }

  ngOnInit(): void {

    this.web = `${window.location.protocol}//${window.location.hostname}/`
    // this.pageCustomization()
    this.getSubscribe()
  }

  getSubscribe(){
    this.homeService.sendPageData$.subscribe((response: any) => {
      if(response.data){
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
      this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
      this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
      this.ngxFavicon.setFavicon(this.favIcon);
    }
    })
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {

  //   this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //   this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
  //   this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //   this.favIcon = response.data.pageCustomizationDTO.faviconId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.faviconId : ''
  //   this.ngxFavicon.setFavicon(this.favIcon);
  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }
}
