import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  socialdata: any;
  footerDetail: any;
  facebookLink: any;
  footerBgImage: any;
  twitterLink: any;
  instagramLink: any;
  youtubeLink: any;

  appBGColor: any;
  appBtnPrimaryColor: any;
  mainBGBlur: any;
  footerBlur: any;
  blurOpacity: any;
  changeToRGB: any;
  showfooter = false

  footerblurOpacity: any
  footerchangeToRGB: any
  isWhiteLabel: boolean;
  headerLogo: any
  domain: string;
  constructor(
    public homeService: HomeService,
    public messageService: MessageService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    public router: Router,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.socialdata = [
      { socialName: 'instagram', iconsClass: 'fa fa-instagram' },
      { socialName: 'facebook', iconsClass: 'fa fa-facebook-square' },
      { socialName: 'youtube', iconsClass: 'fa fa-youtube-play' },
      { socialName: 'twitter', iconsClass: 'fa fa-twitter-square' },
    ]
  }

  ngOnInit(): void {
    // this.pageCustomization()
    this.getSubscribe()
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this._ActivatedRoute.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.footerDetail = response.data.footerDTO;
        this.footerBgImage = response.data.footerDTO.backgroundImageId ? `${appConfig.imageURL}` + response.data.footerDTO.backgroundImageId + "?height=630&width=1120" : ''
        this.facebookLink = response.data.footerDTO.facebookLink ? response.data.footerDTO.facebookLink : '';
        this.twitterLink = response.data.footerDTO.twitterLink ? response.data.footerDTO.twitterLink : '';
        this.instagramLink = response.data.footerDTO.instagramLink ? response.data.footerDTO.instagramLink : '';
        this.youtubeLink = response.data.footerDTO.youtubeLink ? response.data.footerDTO.youtubeLink : ''
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
        // webappSetting -----------------
        this.blurOpacity = response.data.webAppSettingDTO ? response.data.webAppSettingDTO.shadowsFade / 10 : '0.5'
        this.footerblurOpacity = response.data.webAppSettingDTO ? response.data.webAppSettingDTO.footerShadowsFade / 10 : '0.5'
        this.addAlpha(this.appBGColor, this.blurOpacity)
        this.addAlphaFooter(this.appBGColor, this.footerblurOpacity)
        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(`${response.data.pageCustomizationDTO.primaryColor}`);
        // this.elementRef.nativeElement.ownerDocument.button.style.backgroundColor = this.appBtnPrimaryColor;
        this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to top, ${this.changeToRGB},${this.appBGColor})`);
        // this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to top, ${this.appBGColor}),${this.appBGColo
        // this.blurOpacity = response.data.webAppSettingDTO.shadowsFade? response.data.webAppSettingDTO.shadowsFade / 10  : '0.5'
        setTimeout(() => {
          this.showfooter = true
        }, 1000);
      }
    })
  }

  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this.isWhiteLabel = response.data.organizationDTO.isWhiteLabel
        }
      });
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {

  //     this.footerDetail = response.data.footerDTO;
  //     this.footerBgImage = response.data.footerDTO.backgroundImageId ? `${appConfig.imageURL}` + response.data.footerDTO.backgroundImageId + "?height=630&width=1120" : ''
  //     this.facebookLink = response.data.footerDTO.facebookLink ? response.data.footerDTO.facebookLink : '';
  //   this.twitterLink = response.data.footerDTO.twitterLink ? response.data.footerDTO.twitterLink : '';
  //   this.instagramLink = response.data.footerDTO.instagramLink ? response.data.footerDTO.instagramLink : '';
  //   this.youtubeLink = response.data.footerDTO.youtubeLink ? response.data.footerDTO.youtubeLink : ''

  //   this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //   this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //   // webappSetting -----------------
  //   this.blurOpacity = response.data.webAppSettingDTO ? response.data.webAppSettingDTO.shadowsFade / 10  : '0.5'
  //   this.footerblurOpacity = response.data.webAppSettingDTO ? response.data.webAppSettingDTO.footerShadowsFade / 10 : '0.5'
  //   this.addAlpha(this.appBGColor,this.blurOpacity)
  //   this.addAlphaFooter(this.appBGColor,this.footerblurOpacity)
  //   this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(`${response.data.pageCustomizationDTO.primaryColor}`);
  //   // this.elementRef.nativeElement.ownerDocument.button.style.backgroundColor = this.appBtnPrimaryColor;
  //   this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to top, ${this.changeToRGB},${this.appBGColor})`);
  //   // this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to top, ${this.appBGColor}),${this.appBGColo
  //   // this.blurOpacity = response.data.webAppSettingDTO.shadowsFade? response.data.webAppSettingDTO.shadowsFade / 10  : '0.5'
  //   setTimeout(() => {
  //     this.showfooter = true
  //   }, 1000);
  //   }, (error) => {
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }


  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  addAlphaFooter(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = data + _opacity.toString(16).toUpperCase();
    }
  }

  goToSocialLink(item: any) {
    if (item.socialName === 'facebook') {
      window.open(this.facebookLink, '_blank');
    }
    else if (item.socialName === 'twitter') {
      window.open(this.twitterLink, '_blank');
    }
  }

}
