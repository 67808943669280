import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-iframe-live',
  templateUrl: './iframe-live.component.html',
  styleUrls: ['./iframe-live.component.scss'],
})
export class IframeLiveComponent implements OnInit {
  isIframe: boolean;
  iframe: string | any;
  appBGColor: string;
  primaryColor: string;
  playlistPosition: boolean;
  isLoaded: boolean;
  domain: string;
  logoHeight: any;
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _HomeService: HomeService,
    private _DomSanitizer: DomSanitizer,
    private _ElementRef: ElementRef,
    private _Router: Router,
    private _AngularFaviconService: AngularFaviconService,
    private _Title: Title,
    private _Meta: Meta
  ) {
    this.isIframe = false;
    this.iframe = '';
    this.appBGColor = '';
    this.primaryColor = '';
    this.playlistPosition = false;
    this.isLoaded = false;
    this.domain = '';
  }
  ngOnInit(): void {
    this.getLogoDimention()
    this.domain =
      this._HomeService.sendURL &&
      this._HomeService.sendURL.replace('https://', '').replace('/', '');
    // this._Router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this._ActivatedRoute.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.getSubscribe();
  }

  getSubscribe() {
    this._HomeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this._ElementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.playlistPosition =
          response.data.pageCustomizationDTO.playlistPosition;
        this.isIframe = response.data.pageCustomizationDTO.isIFrame;
        this.iframe = this._DomSanitizer.bypassSecurityTrustHtml(
          response.data.pageCustomizationDTO.iframe.trim()
        );
        this.isLoaded = true;
      }
    });
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this._HomeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.liveStreamTabName}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this._AngularFaviconService.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-${response.data.liveStreamTabName}`
                : ''
              }`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  getLogoDimention() {
    this._HomeService.dimensions$.subscribe((dimensions) => {
      this.logoHeight = dimensions.height
    });
  }
}
