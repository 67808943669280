import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// import * as io from 'socket.io-client';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  
  private socket: Socket;
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (this.isBrowser) {
      this.socket = io('wss://socket.nowcast.cc'); 
      this.socket.on('connect', () => {
        console.log('Connected to WebSocket');
      });
      
      this.socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket');
      });
    }

   }

  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        subscriber.next(data);
      });
    });
  }

  emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }
}
