<div class="main-joinPage mt-20">
    <div class="container container-margin">
        <div class="logo mb-20">
            <a routerLink="/main">
                <img [src]="logo" alt="">
            </a>
        </div>
        <div class="plans-container">
            <ng-container *ngIf="subscriptionPlanList.length;else noDataFound">
                <div class="plan-block mb-20" *ngFor="let item of subscriptionPlanList">
                    <div class="plans">
                        <div class="plan-img" [ngStyle]="{'background-color': changeToRGB ? changeToRGB : ''}">
                            <img [src]="item.planImg" alt="">
                        </div>
                        <div class="plan-content">
                            <div class="plan-info">
                                <div class="plan-title">
                                    {{item.name}}
                                </div>
                                <div class="plan-desc">
                                    ${{item.price}}/{{item.planDurationName}}
                                </div>
                                <ng-template [ngIf]="item.trialDays!=='0'">
                                    <div class="plan-desc">
                                        Trial: {{item.trialDays}} Days
                                    </div>
                                </ng-template>
                            </div>
                            <div class="plan-action">
                                <a *ngIf="showbtn"
                                    [ngStyle]="{'background-color': changeToRGB ? changeToRGB : '' , 'color': primaryColor ? primaryColor : '#fff'}"
                                    (click)="choosePlan(item)" class="choose-plan">Choose Plan</a>
                            </div>
                        </div>

                    </div>
                </div>
                <ng-container *ngIf="skipSubscriptionPlan">
                    <div class="d-flex justify-content-end">
                        <button [ngStyle]="{'background-color': primaryColor ? primaryColor: '#006aff'}"
                            class="main-btn" type="button" (click)="navigateWithoutPlan()">
                            Skip Plan
                        </button>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #noDataFound>
                <div class="plan-block mb-20">
                    <div class="plans">
                        <div class="plan-content">
                            <div class="plan-info" style="width: 100%;">
                                <div class="plan-title" style="text-align: center;">
                                    No plans are available
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>