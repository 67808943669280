<ng-container *ngIf="isDataLoaded">

    <div class="main-program dark" [ngStyle]="{'background-color': appBGColor ? appBGColor : '#2a2a2a', 'padding-top': logoHeight ? ((logoHeight + 60) + 'px') : ''}">
        <div class="container d-flex justify-content-between">
            <div class="vedio-wrapper"
                [ngClass]="{'col-8': playlistPosition === true , 'width100': playlistPosition === false}">
                <div class="player" [ngClass]="{sidebarView: playlistPosition === true}">
                    <div class="container mt-5">
                        <ng-container *ngIf="isReaderDisabled;else reader">
                            <div class="card ">
                                <img class="card-img-top" [src]="poster" alt="Card image cap">
                            </div>
                        </ng-container>
                        <ng-template #reader>
                            <app-ebook-reader [eBookUrl]="eBookUrl"></app-ebook-reader>
                        </ng-template>
                        <div class="paid-info" *ngIf="!isEbookAvailable">
                            <p class="vedio-name mb-20">{{title}}</p>
                            <p class="vedio-name mb-20">EBook is not available for this media</p>
                        </div>
                        <div class="paid-info" *ngIf="isEbookPaid">
                            <p class="vedio-name mb-20">{{title}}</p>
                            <button *ngIf="isEbookPaid && isSubscriptionAvailable"
                                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                (click)="getAccess()" class="watch-now mb-20">Get Access Now</button>
                            <button *ngIf="isOneTimePurchase"
                                [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                (click)="buyItem()" class="watch-now mb-20" style="margin-left: 10px;">Buy Item
                                ${{ ebookDetails && ebookDetails.price && ebookDetails.price.toFixed(2)}}</button>
                            <p *ngIf="!isLoggedIn" class="vedio-name">or <span style="cursor: pointer;"
                                    (click)="signInOnMedia()">sign up
                                </span>to continue</p>
                        </div>

                        <div class="paid-info" *ngIf="loginRequired">
                            <p class="vedio-name mb-20">Please create a free account to read this ebook</p>
                            <button [ngStyle]="{'background-color': primaryColor ? primaryColor : '#006aff'}"
                                (click)="signInRequired()" class="watch-now mb-20">Sign Up</button>
                            <p class="vedio-name">or <span style="cursor: pointer;text-decoration: underline;" (click)="signInOnMedia()">Sign in
                                </span>to
                                continue</p>
                        </div>
                    </div>
                </div>
                <div class="player-detail-wrapper">
                    <div class="row ">
                        <div [ngClass]="{'col-lg-8 col-sm-12': playlistPosition === true , 'col-lg-9 col-sm-12': playlistPosition === false}"
                            class="box-padding">
                            <form #updatefavorite="ngForm">
                                <div class="video-detail ">
                                    <h2 class="m-0">{{title}}</h2>
                                    <h3>{{ebookDetails && ebookDetails.subTitle}}</h3>
                                    <!-- <div class="favAndshare-btn">
                                        <button *ngIf="isLoggedIn" (click)="favButtonClick()" name="favorite"><i
                                                class="fa fa-heart" aria-hidden="true"
                                                [ngStyle]="{'color':isFavourites === true ? 'red' : '#fff' }"></i>
                                            <span *ngIf="!isFavourites">{{' Add to Favorites'}}</span> <span
                                                *ngIf="isFavourites">{{'In Favorites'}}</span></button>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-side-vedio" [ngClass]="{'col-4': playlistPosition === true}" *ngIf="playlistPosition">

                <div class="related-video-wrapper">
                    <div class="" *ngIf="mediadata.length">
                        <div class="related-title">Related Ebooks</div>
                        <div class="related-video">
                            <div class="col-12 media-card" *ngFor="let item of mediadata">
                                <a class="main_poster">
                                    <span class="image-container" (click)="nextVedio(item.id)"
                                        [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                        <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                            [src]="item.mediaImg ? item.mediaImg : ''" alt="" onload="style.opacity = 1"
                                            style="opacity: 0;" class="images">
                                    </span>
                                </a>
                                <a class="card-title mt-2 subTitle">
                                    {{item.title}}
                                </a>
                                <a class="card-title mt-0">{{item.subTitle}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="about-comment-wrapper" [ngStyle]="{'background-color': appBGColor ? appBGColor : '#2a2a2a'}"
            *ngIf="description.length">
            <div class="about-comment-container">
                <div class="col-12 d-flex container">
                    <div class="col-sm-12 col-lg-8">
                        <section class="about-section detail-section" style="margin-left: 12px;">
                            <div class="title">About</div>
                            <div class="detail" [ngClass]="{'limitTextHeight': isReadMore}">{{ebookDetails &&
                                ebookDetails.description}}</div>
                            <a class="readmore" (click)="showText()" *ngIf="description.length > 170">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                            </a>
                        </section>
                    </div>
                </div>
            </div>
        </section>

        <section class="related-video-wrapper" *ngIf="!playlistPosition">
            <div class="container catalog" *ngIf="mediadata.length">
                <div class="related-title">Related Ebooks</div>
                <div class="related-video below-veiw">
                    <div class="media-card" *ngFor="let item of mediadata">
                        <a class="main_poster">
                            <span class="image-container" (click)="nextVedio(item.id)"
                                [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}">
                                <img [ngStyle]="{'background-color': item.color ? item.color : '#e6e6e6'}"
                                    [src]="item.mediaImg ? item.mediaImg : ''" alt="" onload="style.opacity = 1"
                                    style="opacity: 0;" class="images">
                            </span>
                        </a>
                        <a class="card-title mt-2 subTitle">
                            {{item.title}}
                        </a>
                        <a class="card-title mt-0">{{item.subTitle}}</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</ng-container>