import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { LoginModel } from '../../Models/login.model';

@Component({
  selector: 'app-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss'],
  // providers: [MessageService]
})
export class SigninPageComponent implements OnInit {
  login: LoginModel;
  homeLogo = 'assets/images/logo.png';
  primaryColor: any;
  logo: any;
  appBGColor: any;
  loginImg: any;
  showForgot = false;
  web: any;
  passwordType = false;
  bottomGradiant: any;
  leftGradiant: any;
  blurOpacity: any;
  changeToRGB: any;
  scrHeight: any;
  scrWidth: any;
  vedioId: any;
  isEbook: boolean;
  isChannel: boolean;
  isNetwork: boolean;
  m3uType: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(
    public signService: LoginService,
    public router: Router,
    private messageService: MessageService,
    public homeService: HomeService,
    private elementRef: ElementRef,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.getScreenSize();
    this.login = new LoginModel();
    this.isEbook = false;
    this.isChannel = false;
    this.isNetwork = false;
  }

  ngOnInit(): void {
    // this.pageCustomization()
    this.getSubscribe();
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
    this.route.queryParamMap.subscribe((params) => {
      this.vedioId = params.get('vedioId');
      this.isChannel = params.get('type') && params.get('type') === 'chanel' ? true : false;
      this.isNetwork = params.get('type') && params.get('type') === 'customNet' ? true : false;
      this.m3uType = params.get('m3uType')
      this.isEbook =
        params.get('type') && params.get('type') === 'EBOOK' ? true : false;
    });
  }

  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
          this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
        this.logo = response.data.pageCustomizationDTO.logoId
          ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId
          : '';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.loginImg = response.data.mainBannerDTO.backgroundImageId
          ? `${appConfig.imageURL}` +
            response.data.mainBannerDTO.backgroundImageId +
            '?height=630&width=1120'
          : '';
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
      }
    });
  }

  // pageCustomization() {
  //   this.homeService.pageCustomizationData().subscribe((response: any) => {
  //     this.appBGColor = response.data.pageCustomizationDTO.backgroundColor
  //     this.blurOpacity = response.data.webAppSettingDTO ? response.data.webAppSettingDTO.shadowsFade / 10  : '0.5'
  //     this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.appBGColor;
  //     this.logo = response.data.pageCustomizationDTO.logoId ? `${appConfig.imageURL}` + response.data.pageCustomizationDTO.logoId: ''
  //     this.addAlpha(this.appBGColor,this.blurOpacity)
  //   this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
  //   this.loginImg = response.data.mainBannerDTO.backgroundImageId ? `${appConfig.imageURL}` + response.data.mainBannerDTO.backgroundImageId + "?height=630&width=1120" : ''
  //   this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`);
  //   this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`);

  //   }, (error) => {
  //     let errMsg = error.error.message
  //     if(errMsg.includes('Organization not found with subdomain')){
  //       this.router.navigate(['/site-error'])
  //     }
  //     // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
  //   });
  // }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);
  
    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }
  
    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;
  
    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    
    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');
  
    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if(color.includes('#')){
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else{
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  /**
   * To login user
   */
  loginUser() {
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var courseCode = this.login.username;
    if (this.login.username !== undefined && this.login.username) {
      if (courseCode.match(regex)) {
        this.signService.loginUser(this.login).subscribe(
          (response: any) => {
            localStorage.setItem(
              'loggedInUserName',
              response.body.data.userDetails.username
            );
            localStorage.setItem(
              'loggedInUser',
              JSON.stringify(response.body.data)
            );
            if (this.vedioId) {
              if (this.isEbook) {
                this.router.navigate([`/ebook/${this.vedioId}`]);
              } 
              else if(this.isChannel){
                this.router.navigate([`channel`])
              } 
              else if(this.isNetwork){
                if(this.m3uType){
                  this.router.navigate([`network`], {queryParams: {m3uType:this.m3uType}})
                }
                else{
                  this.router.navigate([`network`])
                }
              }
              else {
                this.router.navigate(['/video/' + this.vedioId]);
              }
            } else {
              this.router.navigate(['/vod']);
            }
          },
          (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error.message,
            });
          }
        );
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Enter a Valid Email',
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Enter an Email',
      });
    }
  }

  forgotPasswordLink() {
    const dataToSend: any = {};
    dataToSend['username'] = this.login.username;
    let validateEmail = this.homeService.ValidateEmail(dataToSend.username);
    if (validateEmail) {
      this.signService.forgotPassword(dataToSend).subscribe(
        (response: any) => {
          this.showForgot = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Password reset link successfully send to your mail`,
          });
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.message,
          });
        }
      );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid email',
      });
    }
  }

  gotoSignup() {
    this.router.navigate(['/join']);
  }
}
