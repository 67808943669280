import { environment } from '../environments/environment';

export const appConfig = {
  logIn: environment.authService + 'user/loginUser',
  logOut: environment.authService + 'user/logout',
  signUp: environment.authService + 'signUp',

  refreshToken: environment.authService + 'refreshToken',
  // tokenValidate: 'tokenValidate',
  // switch: 'user/switchUser',
  // reset: 'user/forgetPassword',
  // forgetPassword: 'user/forgetPasswordLink',

  imageURL: environment.imageService,
  displayMediaItemInfo: environment.webService + 'displayMediaItemInfo',
  pageCustomization: environment.webService + 'pageCustomization',
  favourites: environment.webService + 'favourites',
  subscription: environment.adminService + 'subscription',
  organization: environment.webService + 'organization',
  contact: environment.webService + 'contact',
  usersubScription: environment.adminService + 'usersubScription',
  user: environment.webService + 'user',
  userAdmin: environment.adminService + 'user',
  upload: environment.image_service + 'upload',
  // giving
  giving: environment.adminService + 'giving',
  mediaPlay: environment.adminService + 'mediaPlay',
  contactUs: environment.webService + 'contactUs',
  mediaStatus: `${environment.adminService}liveStreaming/broadCastStatus`,
  ebookList: `${environment.webService}displayMediaItemInfo`,
  EbookMediaItem: `${environment.adminService}mediaItem`,
  branding: `${environment.adminService}branding`,
  channel: `${environment.webService}channel`,
  schedulingChannelInfo: `${environment.adminService}schedulingChannelInfo`,
  mediaseries: `${environment.adminService}mediaseries`,
  device: `${environment.adminService}device`,
  addInFavourites: `${environment.webService}addInFavourites`,
};
