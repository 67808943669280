<div class="main-footer" *ngIf="showfooter">
    <div class="footer-background" [ngStyle]="{'background-image': 'url(' + footerBgImage + ')'}"></div>
        <div class="footer-shadow" [style.background-image]="footerBlur"></div>
    <div class="container footer-bottom">
        <div class="footer-wrapper col-lg-12 align-items-center">
            <div class="copyright col-lg-5">
                &copy; {{footerDetail ? footerDetail.copyright : ''}}
            </div>
            <div class="social-wrapper col-lg-5" style="padding-left: 15px;">
                <div class="social-media">
                    <div class="social-icons">
                        <!-- <img [src]="item.socialImg" (click)="goToSocialLink(item)" alt=""  onload="style.opacity = 1" style="opacity: 0;"> -->
                        <a class="ml-29" [href]="instagramLink" *ngIf="instagramLink" target="_blank"><i class="fa fa-instagram"></i></a>
                        <a class="ml-29" [href]="facebookLink" *ngIf="facebookLink" target="_blank"><i class="fa fa-facebook-square"></i></a>
                        <a class="ml-29" [href]="youtubeLink" *ngIf="youtubeLink" target="_blank"><i class="fa fa-youtube-play"></i></a>
                        <a class="ml-29" [href]="twitterLink" *ngIf="twitterLink" target="_blank"><i class="fa fa-twitter-square"></i></a>
                    </div>
                </div>
               
            </div>
            <div class="poweredBy col-lg-2">
                <p class="d-flex align-items-center">Powered By
                    <span style="max-width:85px;">
                        <a *ngIf="!isWhiteLabel" href="https://www.nowcast.cc/" target="_blank">
                            <img style="width: 'auto';height: 45px;filter: brightness(1.3);" src="assets/images/Nowcast bottom logo.svg" alt="">
                        </a>
                        <a *ngIf="isWhiteLabel" href="" target="_blank">
                            <img style="width: 'auto';height: 45px;filter: brightness(1.3);margin-left: 7px;"
                                [src]="headerLogo" alt="">
                        </a>
                    </span>
                </p>
            </div>
           
        </div>
    </div>
</div>