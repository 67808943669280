import { Component, OnInit } from '@angular/core';
import { ssrWindow } from 'ssr-window';

@Component({
  selector: 'app-sitecantreach',
  templateUrl: './sitecantreach.component.html',
  styleUrls: ['./sitecantreach.component.scss']
})
export class SitecantreachComponent implements OnInit {
  // sendURL: any
  constructor() { }

  ngOnInit(): void {
    // this.sendURL = `${ssrWindow.location.origin}/`;
  }

}
