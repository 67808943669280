// import { SharedService } from './../services/shared/shared.service';

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { SharedService } from '../services/shared/shared.service';
import { getWindow } from 'ssr-window';
import * as moment from 'moment';
import { SessionService } from '../services/shared/session.service';
import { MessageService } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentPageComponent } from '../components/payment-page/payment-page.component';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  totalRequest = 0;
  constructor(
    public router: Router,
    public sharedService: SharedService,
    private _SessionService: SessionService,
    private _MessageService: MessageService,
    private _NgbModal: NgbModal
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.totalRequest = 0;
      }
    });
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.totalRequest++;
    let newData: any;

    newData = getWindow().localStorage
      ? getWindow().localStorage.getItem('loggedInUser')
      : null;
    const authToken = newData ? JSON.parse(newData).token : '';
    const timeZone = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const requestToHandle = authToken
      ? request.clone({
        headers: request.headers.set('Authorization', `Bearer ${authToken}`),
        setHeaders: { deviceType: 'WEBSITE', timeZone },
      })
      : request.clone({
        setHeaders: { deviceType: 'WEBSITE', timeZone },
      });
    return next.handle(requestToHandle).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status && (event.status === 200 || 201)) {
              if (this.totalRequest > 0) {
                this.totalRequest--;
              }
              if (this.totalRequest === 0) {
                this.sharedService.updateLoader(false);
              }
            } else if (event.status === 401) {
              getWindow().localStorage.clear();
            }
          }
        },
        (error) => {
          if (error.status === 401 || error.status === 500) {
            if (error.url.split('/').reverse()[0] === 'loginUser') {
              getWindow().localStorage.clear();
              this.sharedService.updateLoader(false);
            } else if (
              error &&
              error.error &&
              error.error.message.includes('deactivated')
            ) {
              this.router.navigate(['/site-error']);
              this._MessageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error.error.message,
              });
            } else {
              if (!this._SessionService.getSession('hitCount')) {
                this._SessionService.setSession({ hitCount: '0' });
              }
              const hitCount = this._SessionService.getSession('hitCount')
                ? Number(this._SessionService.getSession('hitCount'))
                : 0;
              if (hitCount < 3) {
                getWindow().localStorage.clear();
                this.router.navigate(['/main']);
                this.sharedService.updateLoader(false);
                this._SessionService.setSession({
                  hitCount: `${hitCount + 1}`,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              } else {
                this._SessionService.setSession({
                  hitCount: `0`,
                });
                this.router.navigate(['/site-error']);
              }
            }
          }
          if (error.status === 400 && error.error.message.includes('Please complete the payment and get started')) {
            this._NgbModal.dismissAll();
            this._NgbModal.open(PaymentPageComponent, {
              backdrop: false,
              centered: true,
              size: 'lg',
              ariaLabelledBy: 'Payment Page'
            });
          }
          this.sharedService.updateLoader(false);
        }
      )
    );
  }
}
