import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { HomeService } from 'src/app/services/home/home.service';
import { AngularFaviconService } from 'angular-favicon';
import { SharedService } from 'src/app/services/shared/shared.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { PageCustomiseDataModel } from 'src/app/Models/common.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  pageCustomeData: PageCustomiseDataModel;
  mediadata: any;
  medias: any[] = [];
  headerLinkColor: any;
  logoSize: any;
  bottomLogoSize: any;
  bottomWebLogoSize: any;
  bottomTabletLogoSize: any;
  bottomMobileLogoSize: any;
  theme: any;
  headerLogo: any;
  favIcon: any;
  mainHomeBgImage: any;
  footerBgImage: any;
  homeBannerDetails: any;
  bannerFontSize: any;
  bottomCallAction: any;
  footerDetail: any;
  socialdata: any[] = [];
  facebookLink: any;
  twitterLink: any;
  youtubeLink: any;
  instagramLink: any;
  showMenu = false;

  allPlatFormImage: any;
  allPlatFormImagePhone: any;
  allPlatFormTitle: any;
  allPlatFormSubTitle: any;
  allPlatFormTitleForDeviceLogos: any;
  showHideAndroidTV: any;
  showHideAndroid: any;
  showHideIPhone: any;
  showHideRokuTV: any;
  showHideAppleTV: any;
  showHideFireTV: any;
  showHideAmazon: any;
  showHideYoutube: any;
  showHideGoogleTv: any;
  showHideChromeCast: any;
  showHideAllPlatformSection: any;
  androidctaLink: any;
  androidTVctaLink: any;
  appleTVctaLink: any;
  fireTVctaLink: any;
  iphonectaLink: any;
  rokuTVctaLink: any;

  browseProgramsTitle: any;
  showOrHideBrowseProgramSection: any;
  programsLimit: any;
  hover: any;
  platFormTitle: any;
  platFormId: any;
  platformImg =
    'https://alpha.uscreencdn.com/1200xnull/6029/uploads/7777a012-6e16-4e7c-8926-6171ca71acd4.jpg';

  appBGColor: any;
  appBtnPrimaryColor: any;
  mainBGBlur: any;
  leftGradiant: any;
  bottomGradiant: any;
  footerBlur: any;
  blurOpacity: any;
  changeToRGB: any;
  bottomCallActionctaLink: any;
  bannerButtonctaLink: any;
  showBottomCallToAction: boolean;
  showProgramDuration: boolean;
  routeTo: any;
  userDetail: any;
  web: any;
  mainPageName: any;
  vodPageName: any;
  contactName: any;
  showPage = false;
  scrHeight: any;
  scrWidth: any;
  footerblurOpacity: any;
  footerchangeToRGB: any;
  BottomlogoSize: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  organizationUuid: string;
  isFreeAccountActive: boolean;
  domain: string;
  isWhiteLabel: boolean;
  constructor(
    public homeService: HomeService,
    public sharedService: SharedService,
    public router: Router,
    public messageService: MessageService,
    private ngxFavicon: AngularFaviconService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private _Title: Title,
    private _Meta: Meta,
    private _ActivatedRoute: ActivatedRoute
  ) {
    this.organizationUuid = '';
    this.isFreeAccountActive = false;
    this.pageCustomeData = new PageCustomiseDataModel();
    this.getScreenSize();
    this.socialdata = [
      { socialName: 'instagram', iconsClass: 'fa fa-instagram' },
      { socialName: 'facebook', iconsClass: 'fa fa-facebook-square' },
      { socialName: 'youtube', iconsClass: 'fa fa-youtube-play' },
      { socialName: 'twitter', iconsClass: 'fa fa-twitter-square' },
      // {socialName: 'twitter', socialImg: 'assets/images/twitter-color.png'}
    ];
    this.domain = '';
  }

  ngOnInit(): void {
    this.userDetail = localStorage.getItem('loggedInUser');
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this._ActivatedRoute.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    // if (this.userDetail) {
    //   this.router.navigate(['/vod']);
    // } else {
    //   this.router.navigate(['/main']);
    // }
    // this.pageCustomization();
    this._ActivatedRoute.data.subscribe(data => {
      const mediaItems = data.data._value;
      console.log(mediaItems)
      this.getSubscribe(mediaItems);
      // this.getMediaItemsByResolver(mediaItems);
      // this.mediaArray = mediaRes.data;

    });
    this.getIPAddress();
    // this.gethhmmss(3900)
  }

  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this.organizationUuid = response.data.organizationDTO.uuid;
          this.isWhiteLabel = response.data.organizationDTO.isWhiteLabel
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.homePage}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}`
                : ''}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }

  getSubscribe(response: any) {
    // this.homeService.sendPageData$.subscribe((response: any) => {
    if (response.data) {
      this.pageCustomeData = new PageCustomiseDataModel(response.data);
      this.organizationUuid = response.data.pageCustomizationDTO.uuid;
      this.headerLinkColor = response.data.headerDTO.headerLinksColor;
      this.isFreeAccountActive =
        response.data.pageCustomizationDTO.showHideFreeAccount;
      this.headerLogo = response.data.headerDTO.logoId
        ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
        : '';
      if (this.scrWidth < 500) {
        this.logoSize = response.data.headerDTO.mobileLogoSize;
        this.bottomLogoSize =
          response.data.bottomCallActionDTO.mobileLogoSize;
        this.bannerFontSize =
          response.data.mainBannerDTO.mobileMainBannerFontSize;
      } else if (this.scrWidth > 500 && this.scrWidth < 900) {
        this.logoSize = response.data.headerDTO.tabletLogoSize;
        this.bottomLogoSize =
          response.data.bottomCallActionDTO.tabletLogoSize;
        this.bannerFontSize =
          response.data.mainBannerDTO.tabletMainBannerFontSize;
      } else {
        this.logoSize = response.data.headerDTO.webLogoSize;
        this.bottomLogoSize = response.data.bottomCallActionDTO.webLogoSize;
        this.bannerFontSize =
          response.data.mainBannerDTO.webMainBannerFontSize;
      }
      this._Meta.updateTag({
        property: 'og:title',
        content: `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.homePage}`,
      });
      this._Title.setTitle(
        `${response.data.pageCustomizationDTO.orgName
          ? `${response.data.pageCustomizationDTO.orgName}-`
          : ''
        }${this.pageCustomeData.pageCustomizationDTO.homePage}`
      );
      this.ngxFavicon.setFavicon(
        this.pageCustomeData.pageCustomizationDTO.favIcon
      );
      this._Meta.updateTag({
        property: 'og:image',
        content: `${this.pageCustomeData.headerDTO.headerLogo}?height=100&width=100`,
      });
      this.theme = response.data.pageCustomizationDTO.colorScheme
        ? (response.data.pageCustomizationDTO.colorScheme = 'light')
        : (response.data.pageCustomizationDTO.colorScheme = 'dark');

      this.footerBgImage = response.data.footerDTO.backgroundImageId
        ? `${appConfig.imageURL}` +
        response.data.footerDTO.backgroundImageId +
        '?height=630&width=1120'
        : '';
      this.footerDetail = response.data.footerDTO;
      this.facebookLink = response.data.footerDTO.facebookLink
        ? response.data.footerDTO.facebookLink
        : '';
      this.twitterLink = response.data.footerDTO.twitterLinkx
        ? response.data.footerDTO.twitterLink
        : '';
      this.instagramLink = response.data.footerDTO.instagramLink
        ? response.data.footerDTO.instagramLink
        : '';
      this.youtubeLink = response.data.footerDTO.youtubeLink
        ? response.data.footerDTO.youtubeLink
        : '';

      this.allPlatFormImage = response.data.allPlatFormsDTO
        .devicesScreenImageId
        ? `${appConfig.imageURL}` +
        response.data.allPlatFormsDTO.devicesScreenImageId +
        '?height=360&width=640'
        : '';
      this.allPlatFormImagePhone = response.data.allPlatFormsDTO
        .devicesScreenImageId
        ? `${appConfig.imageURL}` +
        response.data.allPlatFormsDTO.devicesScreenImageId +
        '?height=270&width=480'
        : '';

      // browseProgramsDTO -------------
      this.browseProgramsTitle = response.data.browseProgramsDTO.title;
      this.showOrHideBrowseProgramSection =
        response.data.browseProgramsDTO.showOrHideSection;
      this.programsLimit = response.data.browseProgramsDTO.programsLimit
        ? response.data.browseProgramsDTO.programsLimit
        : '9';
      this.GetMediaItemWithout(1, this.programsLimit, 'free');

      // webappSetting -----------------
      this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
        this.appBGColor;
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = ''
      this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
        `${response.data.pageCustomizationDTO.primaryColor}`
      );
      // this.elementRef.nativeElement.ownerDocument.button.style.backgroundColor = this.appBtnPrimaryColor;
      this.blurOpacity = response.data.webAppSettingDTO
        ? response.data.webAppSettingDTO.shadowsFade / 10
        : '0.5';
      this.footerblurOpacity = response.data.webAppSettingDTO
        ? response.data.webAppSettingDTO.footerShadowsFade / 10
        : '0.5';
      this.addAlpha(this.appBGColor, this.blurOpacity);
      this.addAlphaFooter(this.appBGColor, this.footerblurOpacity);
      //  radial-gradient(farthest-corner at 55% 45%, rgba(42, 42, 42, 0.1), rgb(42, 42, 42))
      this.mainBGBlur = this.sanitizer.bypassSecurityTrustStyle(
        `radial-gradient(farthest-corner at 55% 40%,${this.changeToRGB},${this.appBGColor})`
      );
      this.footerBlur = this.sanitizer.bypassSecurityTrustStyle(
        `linear-gradient(to top, ${this.footerchangeToRGB},${this.appBGColor})`
      );
      this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
        `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
      );
      this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
        `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
      );
    }
    // });
  }

  platform(data: any) {
    this.platFormTitle = data.title;
    this.platFormId = data.id;
  }

  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error("Invalid RGBA color format.");
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  addAlphaFooter(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = color + _opacity.toString(16).toUpperCase();
    }
    else {
      let data = this.rgbaToHex(color)
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.footerchangeToRGB = data + _opacity.toString(16).toUpperCase();
    }
  }

  buttonRoute(type: any) {
    if (type === 'Join page') {
      this.router.navigate(['/join']);
    } else if (type === 'Home page') {
      this.router.navigate(['/main']);
    } else if (type === 'Login page') {
      this.router.navigate(['/signin']);
    } else if (type === 'Catalog page') {
      this.router.navigate(['/vod']);
    } else if (type === 'Signup page') {
      this.router.navigate(['/signup'], {
        queryParams: { category: 'NOPLAN' },
      });
    }
  }

  GetMediaItemWithout(page: any, size: any, type: any) {
    this.homeService.getMediaItemWithout(page, size, type).subscribe(
      (response: any) => {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Logged out successful.' });
        this.medias = response.data.content;
        this.medias.forEach((el, i) => {
          if (el.wideArtworkId) {
            var width = 480;
            var height = 270;
            let id = el.wideArtworkId;
            el['newimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }
          if (el.videoDTO) {
            el.videoDTO['vedioTime'] = this.sharedService.timeConvert(
              el.videoDTO.duration
            );
          } else if (el.videoDuration) {
            el['vedioTime'] = this.sharedService.timeConvert(el.videoDuration);
          }
        });

        this.mediadata = this.medias.map((el) => ({
          mediaImg: el.newimg ? el.newimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          subTitle: el.subTitle,
          color: el.wideArtworkImageColor ? el.wideArtworkImageColor : '',
          id: el.id,
        }));
        this.showPage = true;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  goToView(item: any) {
    this.router.navigate([`/video/${item.id}`]);
  }
  navigateForFreeAccount(): void {
    this.router.navigate(['/signup'], { queryParams: { category: 'NOPLAN' } });
  }

  async getIPAddress() {
    const res =  await fetch('https://api.ipify.org?format=json')
    .then(async response => {
      let ip = await response.json();
      localStorage.setItem('ip', JSON.stringify(ip));
    } )
    .catch(err => err.message);
  }
}
