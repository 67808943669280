import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title, Meta, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFaviconService } from 'angular-favicon';
import { json, response } from 'express';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { appConfig } from 'src/app/app.config';
import { emptyPlayListData, emptyPlayListDataForCustom, PageCustomiseDataModel } from 'src/app/Models/common.model';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
  pageCustomeData: PageCustomiseDataModel;
  primaryColor: any;
  appBGColor: any;
  bottomGradiant: any;
  leftGradiant: any;
  blurOpacity: any;
  changeToRGB: any;
  appBtnPrimaryColor: any;
  headerLinkColor: any;
  headerLogo: any;
  mainPageName: any;
  vodPageName: any;
  contactName: any;
  showHideEbook: boolean;
  ebookPage: string;
  logo: any;
  loginImg: any;
  isLoggedIn: any;
  logoSize: any;
  favIcon: any;
  logoSizeRoundValue: number;
  domain: string;

  poster: string;
  autoPlayChange: boolean;
  currentVideoResolution: string;
  allResolutionsName: string[];
  allVideoResolutionList: any[];
  videoJsPlayerUrl: string;
  videoJsPlayerType: string;
  currentMediaItemValues: any;
  pauseTime: number;
  channelData: any[] = []
  allDayDefault: any[] = []
  categoryData: any[] = []
  streamId: string
  videoUrl: SafeResourceUrl;
  showUI = false
  showIframe = false
  showSpinner = false
  accessType: any
  showViewAll = false
  isOneTimePurchase: any;
  selectNetworkId: number
  selectedChannelId: number
  m3uType = 'M3U_CHANNEL'
  viewAllSelected = false
  showm3uContainer = false
  totalElements: any
  searchInputData: any;
  showErrorMessage = false
  data: any
  page = 1
  size = 50
  pageForChannelM3u = 1
  rowCount: number = 50;
  interval: any
  channelName: string;
  isM3u: boolean
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;
  @ViewChild('scrollthree') scrollthree: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  scrHeight: any;
  scrWidth: any;
  currentDate = new Date()
  currentDay: any
  sendDateData = {
    slotInterval: 30,
    openTime: '00:00:00',
    closeTime: '23:59:59',
    date: moment(this.currentDate).format('YYYY-MM-DD')
  };
  currentSelectedChannelLogo: any
  logoOnRight: any
  itemDescription: any
  logoOnPlayer: any
  playlistType: any
  videoOnLoop: any;
  currentSelectedChannelName: any
  currentSelectedChannelDesc: any
  currentPlayingItemName: any
  currentStartItemTime: any
  currentEndItemTime: any
  checkCurrentTimeInterval: any
  currentItemCurrentTime: any;
  currentItemRemainingTime: any
  selectedChannel: any;
  tillCurrectTimeSec: any
  sendDate: any
  isManual = true
  m3uData: any[] = []
  viewAllm3uData: any[] = []
  selectedM3uCategoryNameFromDropdown: any
  allDataFromCategory: any[] = []
  showNotFound = false
  allM3uData: any[] = []
  m3uCategory: any
  selectedM3uCategory: any
  selectedM3uCategoryName: any
  showLogo = false;
  currentVedioIsPaid: any;
  loginUserDetail: any;
  paymentDone: any;
  userId: any
  subscriptionPlanID: any
  isSubscriptionAvailable: boolean;
  isUserPlanPaymentDone: boolean;
  userPlanId: any;
  loginRequired: any;
  itemPrice: any
  PlanID: any;
  orderObj: any;
  selectedId: any
  paymentModal = false
  logoHeight: any
  isM3u8 = false;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  constructor(
    private router: Router,
    private messageService: MessageService,
    private homeService: HomeService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private ngxFavicon: AngularFaviconService,
    private loginService: LoginService,
    private _sessionService: SessionService,
    private _Title: Title,
    private _Meta: Meta,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.pageCustomeData = new PageCustomiseDataModel();
    this.getScreenSize();
    this.showHideEbook = false;
    this.currentVedioIsPaid = false
    this.isSubscriptionAvailable = false
    this.isUserPlanPaymentDone = false
    this.ebookPage = '';
    this.logoSizeRoundValue = 0;
    this.domain = '';
    this.currentVideoResolution = '720';
    this.tillCurrectTimeSec = 0
    this.allResolutionsName = ['720', '360', 'original', '1080'];
    this.allVideoResolutionList = [];
    this.videoJsPlayerUrl = ''
    this.videoJsPlayerType = '';
    this.generateCalendar(this.sendDateData)
  }

  ngOnInit(): void {
    this.getLogoDimention()
    setInterval(() => {
      this.currentDate = new Date()
      this.currentDay = moment(this.currentDate).format('dddd').substring(0, 3)

      let sendDateTime = new Date()
      let todayDateTime = moment(sendDateTime).format('YYYY-MM-DD') + ' ' + '00:00:00'
      let currentDateTime = moment(sendDateTime).format('YYYY-MM-DD HH:mm:ss')
      var getDiff = moment(currentDateTime).diff(moment(todayDateTime))
      // let secondsDiff =  currentDateTime.diff(todayDateTime)
      let getDiffinSec = getDiff / 1000;
      // console.log(secondsDiff,'<=============secondsDiff');

      this.tillCurrectTimeSec = getDiffinSec
    }, 1000);
    this.orderObj = this.route.snapshot.params.id;
    this.m3uType = this.route.snapshot.queryParams.channelType
    if (this.route.snapshot.queryParams.m3uType) {
      this.m3uType = this.route.snapshot.queryParams.m3uType
    }
    this.m3uType = this.m3uType ? this.m3uType : 'M3U_CHANNEL'
    this.selectedId = +this.route.snapshot.queryParams.videoId
    let loginData: any;
    loginData = this._sessionService.getSession('loggedInUser');
    // loginData = localStorage.getItem('loggedInUser')
    this.isLoggedIn = loginData ? true : false;
    this.loginUserDetail = loginData;
    this.domain =
      this.homeService.sendURL &&
      this.homeService.sendURL.replace('https://', '').replace('/', '');
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this.route.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.paymentDone = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.isPaymentDone
      : '';
    this.userId = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.id
      : null;
    this.subscriptionPlanID = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
      : '';
    this.autoPlayChange = true
    this.getSubscribe()

    this.sendDate = moment(this.currentDate).format('YYYY-MM-DD')
    this.generateVideoUrl('')
    // if(this.isLoggedIn){
    //   this.getChannelData(this.sendDate)
    // }
    // else{
    //   this.getChannelDataWithout(this.sendDate)
    // }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // if (this.currentTimeSubscribe) {
    //   this.currentTimeSubscribe.unsubscribe();
    // }
    if (this.checkCurrentTimeInterval) {
      clearInterval(this.checkCurrentTimeInterval);
    }
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  updateScroll() {
    const scrollOne = this.scrollOne.nativeElement as HTMLElement;
    const scrollTwo = this.scrollTwo.nativeElement as HTMLElement;
    const scrollthree = this.scrollthree.nativeElement as HTMLElement;
    // do logic and set
    scrollTwo.scrollLeft = scrollOne.scrollLeft;
    scrollthree.scrollLeft = scrollOne.scrollLeft;
  }

  modifyVideoUrl(): string {
    if (this.allVideoResolutionList.length) {
      const flavourList = this.allVideoResolutionList.filter(
        (value: any) => value.size === this.currentVideoResolution && value.src
      );
      return flavourList.length ? flavourList[0].src : '';
    }
    return '';
  }
  resolutionChange(event: any) {
    // if (event) {
    //   this.currentVideoResolution = '360';
    //   this.videoJsPlayerUrl = this.modifyVideoUrl()
    //     ? this.modifyVideoUrl()
    //     : this.currentMediaItemValues.videoDTO
    //     ? this.currentMediaItemValues.videoDTO.path
    //     : this.currentMediaItemValues.videoUrl
    //     ? this.currentMediaItemValues.videoUrl
    //     : 'https://cdn.plyr.io/static/blank.mp4';
    //   this.currentVideoResolution = '720';
    // }
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this.homeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.givingPage}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this.ngxFavicon.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }${response.data.givingPage}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  getSubscribe() {
    this.homeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.pageCustomeData = new PageCustomiseDataModel(response.data);
        this.mainPageName = response.data.pageCustomizationDTO.homePage;
        this.vodPageName = response.data.pageCustomizationDTO.watchDailyPage;
        this.contactName = response.data.pageCustomizationDTO.contactUs;
        this.showHideEbook = response.data.pageCustomizationDTO.showHideEbook;
        this.ebookPage = response.data.pageCustomizationDTO.ebookPage;
        this.selectNetworkId = response.data.pageCustomizationDTO.selectedNetwork
        setTimeout(() => {
          this.getNetworkData(this.sendDate, response.data.pageCustomizationDTO.selectedNetwork, 10, this.isLoggedIn)
        }, 500);
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.contactUs}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
              ? `${response.data.pageCustomizationDTO.orgName}-`
              : ''
            }${response.data.pageCustomizationDTO.contactUs}`,
        });
        if (this.router.url === '/vod') {
          this.router.navigate(['/vod'], {
            queryParams: { vod: this.vodPageName },
          });
        }
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this.ngxFavicon.setFavicon(this.favIcon);
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          '#080B2A';
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = 'linear-gradient(to bottom left, #080b2b, #010204)'
        // 'linear-gradient(to bottom left, #080b2b, #010204);'
        this.logo = response.data.pageCustomizationDTO.logoId
          ? `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`
          : '';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        this._Meta.updateTag({
          property: 'og:image',
          content: this.logo,
        });
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.logoSizeRoundValue = Math.round(this.logoSize / 10) * 10;

        this.appBtnPrimaryColor = this.sanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );
        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';

        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
        this.loginImg = response.data.mainBannerDTO.backgroundImageId
          ? `${appConfig.imageURL}` +
          response.data.mainBannerDTO.backgroundImageId +
          '?height=630&width=1120'
          : '';
        this.bottomGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
        this.leftGradiant = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to left, ${this.changeToRGB} 70%,${this.appBGColor})`
        );
      }
    });
  }

  addAlpha(color: string, opacity: number): any {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    // return color + _opacity.toString(16).toUpperCase();
    this.changeToRGB = color + _opacity.toString(16).toUpperCase();
  }

  goBackView() {
    if (this.route.snapshot.queryParams.selectedM3uCategory) {
      this.router.navigate(['network'], { queryParams: { channelType: this.m3uType } })
    }
    this.getNetworkData(this.sendDate, this.selectNetworkId, 10, this.isLoggedIn)
    this.showSpinner = false
    this.showViewAll = false;
    this.showSpinner = true
  }

  loadMediaItem(event: any) {
    this.page = (event.first / event.rows) + 1;
    this.size = event.rows;
    this.getM3uDataByCatagory(this.selectedM3uCategoryName)
  }

  getM3uDataByCatagory(catagoryName: string) {
    this.m3uData = []
    // this.viewAllm3uData = []
    this.showSpinner = true
    this.viewAllSelected = true
    this.selectedM3uCategoryName = catagoryName
    this.homeService.getM3uDataByCatagory(this.selectedChannelId, catagoryName, this.m3uType, this.isLoggedIn, this.page, this.size).subscribe((response: any) => {
      this.showViewAll = true
      this.showSpinner = false
      this.selectedM3uCategoryNameFromDropdown = false
      // let catagoryName = response.data.Category
      let newData = response.data.Playlist.content
      this.totalElements = response.data.Playlist.totalElements
      // const dataArray: any[] = response.data.Playlist.content;
      // this.viewAllm3uData.push(...dataArray)
      let newM3uData = {
        Category: catagoryName,
        Playlist: newData
      }
      // console.log(newM3uData,'newM3uData')
      this.m3uData.push(newM3uData)
      this.viewAllm3uData.push(newM3uData)
    });
  }

  getNetworkData(date: any, id: number, noOfItems: number, isLoggedIn: boolean) {
    this.homeService
      .getNetworkData(date, id, noOfItems, isLoggedIn)
      .subscribe((response: any) => {
        setTimeout(() => {
          if (this.scrollOne) {
            const scrollOne = this.scrollOne.nativeElement as HTMLElement;
            const scrollTo = this.tillCurrectTimeSec ? (((this.tillCurrectTimeSec * 0.13888889 - 385)) + 'px') : '0'
            scrollOne.scrollLeft = parseInt(scrollTo)
          }
        }, 500);
        this.channelData = response.data.manualChannelList
        this.isM3u = response.data.isM3U
        this.isM3u8 = response.data.type === 'M3U8'
        // this.isM3u = true
        if (this.isM3u) {
          this.isManual = false
          this.selectedChannelId = response.data.manualChannelList[0].Playlist[0].id
          this.getCategoryList(this.selectedChannelId, this.m3uType)
          let m3uStatus = response.data.manualChannelList[0].Playlist[0].processURL
          this.currentVedioIsPaid = false
          this.isSubscriptionAvailable = false
          this.itemPrice = response.data.manualChannelList[0].Playlist[0].price;
          this.isOneTimePurchase = response.data.manualChannelList[0].Playlist[0].isOneTimePurchase
          this.selectedChannelId = response.data.manualChannelList[0].Playlist[0].id
          // if(!this.isLoggedIn){
          this.PlanID = this.loginUserDetail
            ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
            : '';
          this.currentVedioIsPaid = true;
          // }
          // else{
          if (response.data.manualChannelList[0].Playlist[0].mediaAccessType === 'PAID') {
            this.isSubscriptionAvailable = response.data.manualChannelList[0].Playlist[0]
              .subscriptionPlanIds.length
              ? true
              : false;
            response.data.manualChannelList[0].Playlist[0].subscriptionPlanIds.forEach(
              (element: any) => {
                if (element == this.PlanID && this.paymentDone) {
                  this.isSubscriptionAvailable = false
                  this.currentVedioIsPaid = false;
                }
                if (element == this.userPlanId && this.isUserPlanPaymentDone) {
                  this.isSubscriptionAvailable = false
                  this.currentVedioIsPaid = false;
                }
              }
            );
          } else {
            this.currentVedioIsPaid = false;
          }
          if (response.data.manualChannelList[0].Playlist[0].mediaAccessType === 'FREE') {
            this.isOneTimePurchase = false;
          }
          if (response.data.manualChannelList[0].Playlist[0].mediaAccessType === 'PAID' && response.data.manualChannelList[0].Playlist[0].isOneTimePurchasePaymentDone) {
            this.loginRequired = false;
            this.isOneTimePurchase = false;
            this.currentVedioIsPaid = false;
            this.isSubscriptionAvailable = false
            // this.vedioIsNull = false;
          }
          this.accessType =
            response.data.manualChannelList[0].Playlist[0].mediaAccessType === 'ACCESSREQUIRED'
              ? true
              : false;
          if (this.accessType && !this.isLoggedIn) {
            this.loginRequired = true;
          } else {
            this.loginRequired = false;
          }
          if (m3uStatus === 'IN_PROGRESS') {
            this.showSpinner = true
            this.showm3uContainer = false
            this.interval = setInterval(() => {
              this.getNetworkData(this.sendDate, this.selectNetworkId, 10, this.isLoggedIn)
              // this.getChannelItemById(this.selectedChannelId,this.isLoggedIn);
              // this.getMediaSeriesData(this.mediaSeriesId)
            }, 10000);
          }
          else if (m3uStatus === 'ERROR') {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.showSpinner = false
            this.showm3uContainer = false
            this.showErrorMessage = true
          }
          else {
            if (this.interval) {
              clearInterval(this.interval)
            }
            this.showSpinner = false
            this.showm3uContainer = true
            this.selectM3uType(this.m3uType)
            // this.m3uData = response.data.channelInfoDTO.m3uCategoryList
            // this.allM3uData = response.data.channelInfoDTO.m3uCategoryList
            // this.m3uCategory = this.m3uData.map((el)=>({
            //   categoryName : el.Category
            // }))
          }
          // this.m3uData = response.data.m3uChannelList
          // this.allM3uData = response.data.m3uChannelList
          // this.m3uCategory = this.m3uData.map((el)=>({
          //   categoryName : el.Category
          // }))
          // this.showSpinner = false
        }
        else {
          this.isManual = true
          this.videoJsPlayerUrl = ''
          this.videoJsPlayerType = ''
          // this.videoJsPlayerType = 'application/x-mpegURL'
          if (this.channelData.length > 0) {
            if (this.selectedId) {
              this.channelData.forEach((el: any, i: any) => {
                if (el.Playlist.length > 0) {
                  el.Playlist.forEach((element: any, index: any) => {
                    element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                    element['isSelected'] = false
                  });
                  if (el.Playlist.length > 0) {
                    el.Playlist.forEach((element: any, index: any) => {
                      if (!element.schedulingChannelInfoDTO || element.schedulingChannelInfoDTO.length === 0) {
                        element.schedulingChannelInfoDTO = emptyPlayListDataForCustom
                      }
                      if (element.id === this.selectedId) {
                        el.Playlist[index]['isSelected'] = true
                        setTimeout(() => {
                          if (i === 0) {
                            if (el.Playlist[i]) {
                              this.selectChannel(el.Playlist[0])
                            }
                          }
                          else {
                            this.selectChannel(el.Playlist[index])
                          }
                          // this.streamId = el.Playlist[0].streamId
                        }, 1000);

                        // this.videoJsPlayerUrl = el.Playlist[index].playlistUrl
                        // this.currentSelectedChannelLogo = el.Playlist[index].logoPath
                        // this.logoOnRight = el.Playlist[index].logoOnRight
                        // this.logoOnPlayer = el.Playlist[index].logoOnPlayer
                        // this.playlistType = el.Playlist[index].playlistType
                        // this.videoOnLoop = el.Playlist[index].videoOnLoop
                        // this.currentSelectedChannelName = el.Playlist[index].title
                        // this.currentSelectedChannelDesc = el.Playlist[index].description
                      }
                    })
                  }
                }
              })
            }
            else {
              this.channelData.forEach((el: any, i: any) => {
                if (el.Playlist.length > 0) {
                  el.Playlist.forEach((element: any, index: any) => {
                    if (!element.schedulingChannelInfoDTO || element.schedulingChannelInfoDTO.length === 0) {
                      element.schedulingChannelInfoDTO = emptyPlayListDataForCustom
                    }
                    element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                    element['isSelected'] = false
                  });
                  this.channelData[0].Playlist[0]['isSelected'] = true
                  setTimeout(() => {
                    if (i === 0) {
                      if (this.channelData[i].Playlist[i]) {
                        this.selectChannel(this.channelData[0].Playlist[0])
                      }
                    }
                    // this.streamId = this.channelData[0].Playlist[0].streamId
                  }, 1000);
                  // this.videoJsPlayerType = ''
                  // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
                  this.videoJsPlayerUrl = this.channelData[0].Playlist[0].playlistUrl
                  if(this.isM3u8){
                    this.videoJsPlayerType = 'application/x-mpegURL'
                  }
                  this.currentSelectedChannelLogo = this.channelData[0].Playlist[0].logoPath
                  this.logoOnRight = this.channelData[0].Playlist[0].logoOnRight
                  this.logoOnPlayer = this.channelData[0].Playlist[0].logoOnPlayer
                  this.playlistType = this.channelData[0].Playlist[0].playlistType
                  this.videoOnLoop = this.channelData[0].Playlist[0].videoOnLoop
                  this.currentSelectedChannelName = this.channelData[0].Playlist[0].title
                  this.currentSelectedChannelDesc = this.channelData[0].Playlist[0].description
                }
              })
            }
            this.categoryData = this.channelData.map((el) => ({
              categoryName: el.Category
            }))
            // let newCategoryData = [...new Set(this.categoryData)];
            // console.log(newCategoryData);

          }
        }
        setTimeout(() => {
          this.showUI = true
        }, 100);
      });
  }

  selectCategory(event: any) {
    if (event.value === null) {
      if (this.route.snapshot.queryParams.selectedM3uCategory) {
        this.router.navigate(['network'], { queryParams: { channelType: this.m3uType } })
      }
      if (this.searchInputData) {
        this.searchM3uData(this.selectedChannelId, this.searchInputData, null)
      } else {
        this.m3uData = this.allM3uData
      }
      this.selectedM3uCategoryName = null
      this.showViewAll = false
    }
    else {
      this.allDataFromCategory = []
      this.selectedM3uCategoryName = event.value.Category
      this.selectedM3uCategoryNameFromDropdown = true
      this.showViewAll = true
      this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
      // this.m3uData = this.allM3uData.filter(el=>{
      //   if(el.Category === event.value.categoryName){
      //     this.selectedM3uCategoryNameFromDropdown = event.value.categoryName
      //     this.selectedM3uCategoryName = event.value.categoryName
      //     return el
      //   }
      // })
      // this.allDataFromCategory = this.m3uData
    }
  }

  searchM3uData(id: number, search: any, categoryName: any) {
    this.homeService.searchM3uData(id, search, categoryName, this.m3uType, this.isLoggedIn).subscribe((response: any) => {
      this.m3uData = response.data
      if (!response.data.length || (!response.data[0].Playlist.length && response.data.length === 1)) {
        this.showNotFound = true
      }
      else {
        this.showNotFound = false
      }
    });
  }
  searchData(event: any) {
    if (event.target.value.length > 2) {
      const debounce = setTimeout(() => {
        if (this.selectedM3uCategoryName && event.target.value) {
          this.searchM3uData(this.selectedChannelId, event.target.value, this.selectedM3uCategoryName)
        }
        else if (this.selectedM3uCategoryName) {
          this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
        }
        else {
          this.searchM3uData(this.selectedChannelId, event.target.value, null)
        }
        // this.getMediaItems(1, event.target.value ? (this.totalMediaItems>0 ? this.totalMediaItems : 5) : 5,event.target.value);
      }, 1000);
    }
    else if (event.target.value.length === 0) {
      setTimeout(() => {
        if (this.selectedM3uCategoryName && event.target.value) {
          this.searchM3uData(this.selectedChannelId, event.target.value, this.selectedM3uCategoryName)
        }
        else if (this.selectedM3uCategoryName) {
          // this.m3uData = this.allDataFromCategory
          this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
        }
        else {
          this.m3uData = this.allM3uData
        }
        this.showNotFound = false
      }, 1000);
    }
  }

  getChannelDataWithout(date: any): void {
    this.homeService
      .getChannelListWithout(date)
      .subscribe((response: any) => {
        this.channelData = response.data
        this.videoJsPlayerUrl = ''
        this.videoJsPlayerType = ''
        // this.videoJsPlayerType = 'application/x-mpegURL'
        if (this.channelData.length > 0) {
          this.channelData.forEach((el: any, i: any) => {
            if (el.Playlist.length > 0) {
              el.Playlist.forEach((element: any, index: any) => {
                element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                element['isSelected'] = false
              });
              this.channelData[0].Playlist[0]['isSelected'] = true
              setTimeout(() => {
                if (i === 0) {
                  if (this.channelData[i].Playlist[i]) {
                    this.selectChannel(this.channelData[0].Playlist[0])
                  }
                }
                // this.streamId = this.channelData[0].Playlist[0].streamId
              }, 1000);
              // this.videoJsPlayerType = ''
              // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
              this.videoJsPlayerUrl = this.channelData[0].Playlist[0].playlistUrl
              this.currentSelectedChannelLogo = this.channelData[0].Playlist[0].logoPath
              this.logoOnRight = this.channelData[0].Playlist[0].logoOnRight
              this.logoOnPlayer = this.channelData[0].Playlist[0].logoOnPlayer
              this.playlistType = this.channelData[0].Playlist[0].playlistType
              this.videoOnLoop = this.channelData[0].Playlist[0].videoOnLoop
              this.currentSelectedChannelName = this.channelData[0].Playlist[0].title
              this.currentSelectedChannelDesc = this.channelData[0].Playlist[0].description
            }
          })
          this.categoryData = this.channelData.map((el) => ({
            categoryName: el.Category
          }))
          // let newCategoryData = [...new Set(this.categoryData)];
          // console.log(newCategoryData);

        }
      });
  }

  getChannelData(date: any): void {
    this.homeService
      .getChannelList(date)
      .subscribe((response: any) => {
        this.channelData = response.data
        // this.videoJsPlayerType = 'application/x-mpegURL'
        if (this.channelData.length > 0) {
          this.channelData.forEach((el: any) => {
            if (el.Playlist.length > 0) {
              el.Playlist.forEach((element: any) => {
                element['logoPath'] = element.logoId ? `${appConfig.imageURL}` + element.logoId : null
                element['isSelected'] = false
              });
              this.channelData[0].Playlist[0]['isSelected'] = true
              // this.videoJsPlayerType = ''
              // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
              // this.videoJsPlayerUrl = this.channelData[0].Playlist[0].playlistUrl
              // this.currentSelectedChannelLogo = this.channelData[0].Playlist[0].logoPath
              // this.currentSelectedChannelName = this.channelData[0].Playlist[0].title
              // this.currentSelectedChannelDesc = this.channelData[0].Playlist[0].description
            }
          })
          this.categoryData = this.channelData.map((el) => ({
            categoryName: el.Category
          }))
        }
      });
  }

  updateVedioPlay(event?: any) {

  }
  played(event: any) {
  }

  generateVideoUrl(videoId: string): void {
    // const url = `https://media.nowcast.cc:5443/LiveApp/play.html?name=${videoId}`;
    const url = `${environment.antMediaServerUrlChannel}/ChannelApp/play.html?name=${videoId}`;
    this.videoUrl = videoId ? this.sanitizer.bypassSecurityTrustResourceUrl(url): '';
  }

  selectChannel(data: any) {
    this.currentVedioIsPaid = false
    this.isSubscriptionAvailable = false
    this.itemPrice = data.price;
    this.isOneTimePurchase = data.isOneTimePurchase
    this.selectedChannelId = data.id
    // if(!this.isLoggedIn){
    this.PlanID = this.loginUserDetail
      ? JSON.parse(this.loginUserDetail).userDetails.userSubscriptionPlanId
      : '';
    this.currentVedioIsPaid = true;
    // }
    // else{
    if (data.mediaAccessType === 'PAID') {
      this.isSubscriptionAvailable = data
        .subscriptionPlanIds.length
        ? true
        : false;
      data.subscriptionPlanIds.forEach(
        (element: any) => {
          if (element == this.PlanID && this.paymentDone) {
            this.isSubscriptionAvailable = false
            this.currentVedioIsPaid = false;
          }
          if (element == this.userPlanId && this.isUserPlanPaymentDone) {
            this.isSubscriptionAvailable = false
            this.currentVedioIsPaid = false;
          }
        }
      );
    } else {
      this.currentVedioIsPaid = false;
    }
    if (data.mediaAccessType === 'FREE') {
      this.isOneTimePurchase = false;
    }
    if (data.mediaAccessType === 'PAID' && data.isOneTimePurchasePaymentDone) {
      this.loginRequired = false;
      this.isOneTimePurchase = false;
      this.currentVedioIsPaid = false;
      this.isSubscriptionAvailable = false
      // this.vedioIsNull = false;
    }
    this.accessType =
      data.mediaAccessType === 'ACCESSREQUIRED'
        ? true
        : false;
    if (this.accessType && !this.isLoggedIn) {
      this.loginRequired = true;
    } else {
      this.loginRequired = false;
    }
    // }

    // }
    this.videoJsPlayerUrl = ''
    this.videoJsPlayerType = ''
    this.currentSelectedChannelLogo = ''
    this.currentPlayingItemName = ''
    this.currentStartItemTime = ''
    this.currentEndItemTime = ''
    this.itemDescription = ''
    // this.videoJsPlayerUrl = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'
    this.currentSelectedChannelLogo = data.logoId ? `${appConfig.imageURL}` + data.logoId : null
    this.currentSelectedChannelName = data.title
    this.currentSelectedChannelDesc = data.description
    this.logoOnRight = data.logoOnRight
    if(!this.isM3u8){
      this.streamId = data.streamId
      this.generateVideoUrl(this.streamId)
    }  
    this.logoOnPlayer = data.logoOnPlayer
    this.playlistType = data.playlistType
    this.videoOnLoop = data.videoOnLoop
    if (this.channelData.length > 0) {
      this.channelData.forEach((el: any) => {
        if (el.Playlist.length > 0) {
          el.Playlist.forEach((element: any) => {
            if (element.id === data.id) {
              if (element.schedulingChannelInfoDTO.length > 0){
                if (element.schedulingChannelInfoDTO[0].name === "No Program Available") {
                  this.videoJsPlayerUrl = ''
                  this.videoJsPlayerType = ''
                  if(!this.isM3u8)
                  this.generateVideoUrl('')
                }
                else{
                  if(this.isM3u8)
                    this.videoJsPlayerUrl = data.playlistUrl
                    this.videoJsPlayerType = 'application/x-mpegURL'
                }
              }
              element.isSelected = true
              this.selectedChannel = element
              let sendDate = new Date()
              this.getCurrentPlayItem(sendDate, element)
            }
            else {
              element.isSelected = false
            }
          });
        }
      })
    }
  }

  generateCalendar(x: any) {
    //Format the time
    let start = x.date + ' ' + x.openTime
    let end = x.date + ' ' + x.closeTime
    let startTime = moment(start, "YYYY-MM-DD HH:mm");

    //Format the end time and the next day to it 
    let endTime = moment(end, "YYYY-MM-DD HH:mm").add(0, 'days');

    //Times
    let allTimes: any = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    this.allDayDefault = []
    this.allDayDefault = allTimes.map((el: any, i: any) => ({
      date: x.date,
      end: allTimes[i + 1],
      start: el,
    })
    )
  }

  handleMissingImage(event: any) {
    event.target.src = 'assets/images/no-image-icon-23494.png';
    (event.target as HTMLImageElement).style.width = "75px";
  }

  getCurrentPlayItem(currentDateTime: any, getData: any) {
    let currectDateTime = moment(currentDateTime).format('YYYY-MM-DD HH:mm:ss')
    if (this.checkCurrentTimeInterval) {
      clearInterval(this.checkCurrentTimeInterval)
    }
    if (this.channelData.length > 0) {
      this.channelData.forEach((el: any) => {
        if (el.Playlist.length > 0) {
          el.Playlist.forEach((element: any) => {
            if (element.id === getData.id) {
              if (element.schedulingChannelInfoDTO) {
                if (element.schedulingChannelInfoDTO.length > 0) {
                  element.schedulingChannelInfoDTO.forEach((data: any) => {
                    data.start = data.date + ' ' + data.startTime
                    data.end = data.date + ' ' + data.endTime
                    let time: any
                    if (this.playlistType === 'LOOP' && this.videoOnLoop) {
                      let selectedTime = moment(currentDateTime).format('HH:mm:ss')
                      let selectedDateTime = data.date + ' ' + selectedTime
                      time = moment(selectedDateTime, 'YYYY-MM-DD HH:mm:ss')
                    }
                    else {
                      time = moment(currectDateTime, 'YYYY-MM-DD HH:mm:ss')
                    }
                    let beforeTime = moment(data.start, 'YYYY-MM-DD HH:mm:ss')
                    let afterTime = moment(data.end, 'YYYY-MM-DD HH:mm:ss');
                    if (time.isBetween(beforeTime, afterTime)) {
                      this.currentPlayingItemName = data.name
                      this.currentStartItemTime = data.start
                      this.currentEndItemTime = data.end
                      this.itemDescription = data.itemDescription
                      this.checkCurrentTimeInterval = setInterval(() => {
                        let sendCurrentDateTime = new Date()
                        let sendDateData = moment(sendCurrentDateTime).format('YYYY-MM-DD HH:mm:ss')
                        let sendStartTime = data.start
                        var getDiff = moment(sendCurrentDateTime).diff(moment(sendStartTime))
                        let currDiff = moment.utc(getDiff).format('HH:mm:ss')

                        // var getDiff = moment.utc(moment(sendDateData, "HH:mm:ss").diff(moment(sendStartTime, "HH:mm:ss"))).format("HH:mm:ss")
                        this.currentItemCurrentTime = currDiff;

                        let sendEndTime = data.end
                        var getRemDiff = moment(sendEndTime).diff(moment(sendDateData))
                        let remainDiff = moment.utc(getRemDiff).format('HH:mm:ss')
                        // var getRemDiff = moment.utc(moment(sendEndTime, "HH:mm:ss").diff(moment(sendDateData, "HH:mm:ss"))).format("HH:mm:ss")
                        this.currentItemRemainingTime = remainDiff;
                        if (this.currentItemRemainingTime == '00:00:00') {
                          if (this.selectedChannel) {
                            if (this.checkCurrentTimeInterval) {
                              clearInterval(this.checkCurrentTimeInterval)
                            }
                            this.currentPlayingItemName = ''
                            this.currentStartItemTime = ''
                            this.currentEndItemTime = ''
                            this.currentItemRemainingTime = ''
                            this.currentItemCurrentTime = ''
                            this.itemDescription = ''
                            setTimeout(() => {
                              this.selectChannel(this.selectedChannel)
                            }, 2000);
                            // this.getMediaItemData(this.mediaItemId, 'channelData');
                            // let sendDate = moment(this.currentDate).format('YYYY-MM-DD')
                            // this.getSchedulingChannelInfoWithDate(sendDate,this.mediaItemId)
                          }
                        }
                      }, 1000);
                    }
                  })
                }
              }
            }
          });
        }
      })
    }
  }

  userPlanDetails(id: any) {
    this.sharedService.updateLoader(false);
    this.homeService.userPlanDetails(id).subscribe(
      (response: any) => {
        this.userPlanId = response.data.id;
        this.isUserPlanPaymentDone = response.data.isPaymentDone;
      },
      (error) => {
        this.sharedService.updateLoader(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
    this.sharedService.updateLoader(false);
  }

  getAccess() {
    if (this.isLoggedIn) {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          id: this.PlanID,
          showOffers: true,
          type: 'customNet',
          clickFrom: 'customNet',
          m3uType: this.m3uType ? this.m3uType : ''
        },
      });
    } else {
      this.router.navigate(['/signup'], {
        queryParams: { vedioId: this.selectedChannelId, showOffers: true, type: 'customNet', m3uType: this.m3uType ? this.m3uType : '' },
      });
    }
  }
  buyItem() {
    if (this.isLoggedIn) {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          id: this.PlanID,
          buyItem: true,
          ItemPrice: this.itemPrice,
          type: 'customNet',
          clickFrom: 'customNet',
          m3uType: this.m3uType ? this.m3uType : ''
        },
      });
    } else {
      this.router.navigate(['/signup'], {
        queryParams: {
          vedioId: this.selectedChannelId,
          ItemPrice: this.itemPrice,
          buyItem: true,
          type: 'customNet',
          m3uType: this.m3uType ? this.m3uType : ''
        },
      });
    }
  }
  signInRequired() {
    this.router.navigate(['/signup'], {
      queryParams: {
        vedioId: this.selectedChannelId,
        clickFrom: 'programsignInRequire',
        type: 'customNet',
        m3uType: this.m3uType ? this.m3uType : '',
        loginRequired: true
      },
    });
  }
  signInOnMedia() {
    this.router.navigate(['/signin'], {
      queryParams: { vedioId: this.selectedChannelId, type: 'customNet', m3uType: this.m3uType ? this.m3uType : '' },
    });
  }

  selectM3uType(type: any) {
    this.m3uType = type
    this.m3uData = []
    this.showViewAll = false;
    this.showSpinner = true;
    this.selectedM3uCategory = null;
    this.selectedM3uCategoryNameFromDropdown = true;
    this.viewAllSelected = false;
    this.selectedM3uCategoryName = null
    this.pageForChannelM3u = 1
    this.getCategoryList(this.selectedChannelId, this.m3uType)
    if (this.searchInput) {
      this.searchInput.nativeElement.value = ''
    }
    if (this.m3uType === 'M3U_CHANNEL') {
      this.getM3uData(this.m3uType, this.pageForChannelM3u, 100, false)
    } else {
      this.getM3uData(this.m3uType, '', '', false)
    }
  }

  getM3uData(m3uType: any, page: any, size: any, isOnScroll: boolean) {
    this.sharedService.updateLoader(true);
    this.homeService.getM3uData(this.selectedChannelId, m3uType, this.sendDate, page, size, 10, this.isLoggedIn).subscribe((response: any) => {
      // console.log(response.data)
      this.showm3uContainer = true

      if (response.data.content.length > 0) {
        response.data.content.forEach((el: any) => {
          if (!el.playlist) {
            el.playlist = emptyPlayListData
          }
          el.currentVedioIsPaid = this.currentVedioIsPaid
          el.isOneTimePurchase = this.isOneTimePurchase
          el.loginRequired = this.loginRequired
          el.isSubscriptionAvailable = this.isSubscriptionAvailable
          el.selectedChannelId = this.selectedChannelId
          el.primaryColor = this.primaryColor
          el.itemPrice = this.itemPrice
        })
        // response.data.content.forEach((el:any)=>{
        //   el.playlist = this.manageArrayWithTime(el.playlist)
        // })
      }


      if (isOnScroll) {
        this.m3uData = this.m3uData.concat(response.data.content);
      }
      else {
        this.m3uData = response.data.content
      }
      this.allM3uData = response.data.content
      this.data = this.m3uData
      this.sharedService.updateLoader(false);
      if (this.m3uData.length) {
        this.showSpinner = false
        this.showNotFound = false
        // this.m3uCategory = this.m3uData.map((el)=>({
        //   categoryName : el.Category
        // }))
      }
      else {
        this.showSpinner = false
        this.showNotFound = true
      }
      this.cdr.detectChanges();
    });
    this.sharedService.updateLoader(true);
  }

  onScrollEnd() {
    this.pageForChannelM3u++
    if (this.m3uType === 'M3U_CHANNEL') {
      this.getM3uData(this.m3uType, this.pageForChannelM3u, 100, true)
    } else {
      this.getM3uData(this.m3uType, '', '', false)
    }
  }

  manageArrayWithTime(inputArray: any) {
    const resultArray = [];

    for (let i = 0; i < inputArray.length; i++) {
      const currentItem = inputArray[i];
      const nextItem = inputArray[i + 1];

      resultArray.push(currentItem);

      if (nextItem && currentItem.endTime !== nextItem.startTime) {
        // If endTime of the current item is not equal to startTime of the next item,
        // create a new array with the missing time slot.
        const missingTimeEntry = {
          date: currentItem.date,
          startTime: currentItem.endTime,
          endTime: nextItem.startTime,
          channelName: "No Program Available",
          channelType: null,
          channelUri: null,
          credits: null,
          duration: null,
          groupTitle: "No Program Available",
          icon: null,
          movieDate: null,
          playlist: null,
          radio: null,
          rating: "TV14",
          subTitle: "",
          tvgId: null,
          tvgLogo: null,
          tvgName: "No Program Available"
        };

        resultArray.push(missingTimeEntry);
      }
    }

    return resultArray;
  }

  goToItemView(data: any) {
    // if(this.currentVedioIsPaid || this.loginRequired){
    //   this.paymentModal = true
    // }
    // else{
    // const senddata = {allData: JSON.stringify(data)}
    // localStorage.setItem('m3uData', JSON.stringify(senddata));
    if (this.isLoggedIn) {
      this.router.navigate(['/m3u-detail'], { queryParams: { channelType: this.m3uType, mediaItemId: this.selectedChannelId, channelName: data.channelName, isFav: data.isFavourite ? data.isFavourite : '', selectedM3uCategory: this.selectedM3uCategoryName } })
    }
    else {
      this.router.navigate(['/m3u-detail'], { queryParams: { channelType: this.m3uType, mediaItemId: this.selectedChannelId, channelName: data.channelName, selectedM3uCategory: this.selectedM3uCategoryName } })
    }
    // , {queryParams: {allData: JSON.stringify(data),URL: data.channelUri}}
    // query params for filter 
    // filterData: JSON.stringify({selectedM3uCategoryName: this.selectedM3uCategoryName,inputSearch:this.searchInputData,showViewAll: this.showViewAll,selectedChannelId : this.selectedChannelId,viewAllSelected: this.viewAllSelected})

  }
  getCategoryList(id: any, channelType: any) {
    this.homeService.getCategoryList(id, channelType, this.isLoggedIn).subscribe((response) => {
      this.m3uCategory = response.data
      this.m3uCategory.forEach((el: any) => {
        if (this.route.snapshot.queryParams.selectedM3uCategory) {
          if (el.Category === this.route.snapshot.queryParams.selectedM3uCategory) {
            this.selectedM3uCategory = el
            this.selectedM3uCategoryName = el.Category
            this.selectedM3uCategoryNameFromDropdown = true
            this.showViewAll = true
            this.searchM3uData(this.selectedChannelId, '', this.selectedM3uCategoryName)
          }
        }
      })
    })
  }
  getLogoDimention() {
    this.homeService.dimensions$.subscribe((dimensions) => {
      this.logoHeight = dimensions.height
    });
  }
}

