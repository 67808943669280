import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  isPlayVideoPlayer: Subject<any>;
  constructor() {
    this.isPlayVideoPlayer = new BehaviorSubject(true);
  }
  setVideoPlayerStatus(value: boolean) {
    this.isPlayVideoPlayer.next(value);
  }
  getVideoPlayerStatus() {
    return this.isPlayVideoPlayer.asObservable();
  }
}
