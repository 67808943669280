import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { HomeService } from '../services/home/home.service';

@Injectable()
export class EbookGuard implements CanActivate {
  constructor(private _HomeService: HomeService, private _Router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this._HomeService
      .pageCustomizationData()
      .toPromise()
      .then((response: any) => {
        if (response.data && response.data.pageCustomizationDTO.showHideEbook) {
          return true;
        } else if (
          response.data &&
          response.data.pageCustomizationDTO.showHideHome
        ) {
          return true;
        } else if (response.data.pageCustomizationDTO.showHideVOD) {
          this._Router.navigate(['/vod'], {
            queryParams: {
              vod: response.data.pageCustomizationDTO.watchDailyPage,
            },
          });
          return false;
        } else if (response.data.pageCustomizationDTO.showHideAppstore) {
          this._Router.navigate(['/mobileApp']);
          return false;
        } else if (response.data.pageCustomizationDTO.showHideGiving) {
          this._Router.navigate(['/giving']);
          return false;
        } else if (response.data.pageCustomizationDTO.isIFrame) {
          this._Router.navigate(['/frame']);
          return false;
        } else if (response.data.pageCustomizationDTO.showHideTvChannel) {
          this._Router.navigate(['/channel']);
          return false;
        } else if (response.data.pageCustomizationDTO.showHideNetwork) {
          this._Router.navigate(['/network']);
          return false;
        } else {
          this._Router.navigate(['/contact-us']);
          return false;
        }
      });
  }
}
