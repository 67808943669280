import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AngularFaviconService } from 'angular-favicon';
import * as Plyr from 'plyr';
import { MessageService } from 'primeng/api';
import { Carousel } from 'primeng/carousel';
import { appConfig } from 'src/app/app.config';
import { HlsjsPlyrDriver } from 'src/app/hlsjs-plyr-driver/hlsjs-plyr-driver';
import { HomeService } from 'src/app/services/home/home.service';
import { LoginService } from 'src/app/services/login/login.service';
import { SessionService } from 'src/app/services/shared/session.service';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'app-ebook',
  templateUrl: './ebook.component.html',
  styleUrls: ['./ebook.component.scss'],
  providers: [NgbCarouselConfig],
})
export class EbookComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;
  SlideId: any;
  products: any[];
  changeToRGB: any;
  loginData: any;
  isLoggedIn: boolean;
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onSlide(event: any) {
    this.SlideId = event.current;
  }
  pageRoutes: {
    mainPageName: string;
    vodPageName: string;
    contactName: string;
    showHideEbook: boolean;
    ebookPage: string;
  };
  web: string;
  options: Plyr.Options = {
    // muted: true,
    volume: 0,
    autoplay: true,
    invertTime: false,
    disableContextMenu: true,
    displayDuration: true,
    settings: ['quality', 'speed', 'loop'],
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'settings',
      'fullscreen',
    ],
    captions: { active: true, update: true, language: 'en' },
    loop: { active: true },
  };
  showMenu: boolean = false;
  primaryColor: string;
  appBGColor: string;
  logoSize: number;
  appBtnPrimaryColor: any;
  headerLinkColor: string;
  headerLogo: string;
  bottomGradiant: any;
  blurOpacity: any;
  favIcon: string;
  scroll: number;
  medias: any[];
  showMedia: boolean;
  currentVedio: any;
  freeData: any[];
  showPage: boolean;
  mediadata: any[];
  players: Plyr;
  allMediaData: any[];
  othersMediaDataList: any[];
  OthermediaData: any[];
  showOtherData: boolean;
  mediaData: any[];
  showPageAfterLoad: boolean;
  // hlsjsDriver1: HlsjsPlyrDriver;
  // hlsjsDriver2: HlsjsPlyrDriver;
  // hlsjsDriver3: HlsjsPlyrDriver;
  // hlsjsDriver4: HlsjsPlyrDriver;
  // hlsjsDriver5: HlsjsPlyrDriver;
  // hlsjsDriver6: HlsjsPlyrDriver;
  // hlsjsDriver7: HlsjsPlyrDriver;
  // hlsjsDriver8: HlsjsPlyrDriver;
  // hlsjsDriver9: HlsjsPlyrDriver;
  // hlsjsDriver10: HlsjsPlyrDriver;
  // hlsjsDriver11: HlsjsPlyrDriver;
  // hlsjsDriver12: HlsjsPlyrDriver;
  signoutModal: boolean;
  searchValue: string;
  logoSizeRoundValue: number;
  screenSize: string;
  domain: string;
  constructor(
    private _SessionService: SessionService,
    private _Title: Title,
    private _Meta: Meta,
    private _HomeService: HomeService,
    private _Router: Router,
    private _AngularFaviconService: AngularFaviconService,
    private _DomSanitizer: DomSanitizer,
    private _ElementRef: ElementRef,
    private _SharedService: SharedService,
    private _MessageService: MessageService,
    private _LoginService: LoginService,
    private _ActivatedRoute: ActivatedRoute,
    config: NgbCarouselConfig
  ) {
    Carousel.prototype.onTouchMove = () => { };
    this.getScreenSize();
    if (this.scrWidth < 500) {
      this.scroll = 1;
    } else if (this.scrWidth < 800 && this.scrWidth > 500) {
      this.scroll = 3;
    } else if (this.scrWidth < 1100 && this.scrWidth > 800) {
      this.scroll = 3;
    } else {
      this.scroll = 4;
    }
    this.screenSize = `${Math.round(this.scrWidth / 2)}px`;
    config.interval = 10000;
    config.animation = true;
    config.wrap = true;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.keyboard = true;
    config.pauseOnHover = false;
    this.products = [];
    this.isLoggedIn = false;
    this.pageRoutes = {
      mainPageName: '',
      vodPageName: '',
      contactName: '',
      showHideEbook: false,
      ebookPage: '',
    };
    this.web = '';
    this.primaryColor = '';
    this.appBGColor = '';
    this.logoSize = 0;
    this.appBtnPrimaryColor = '';
    this.headerLinkColor = '';
    this.headerLogo = '';
    this.bottomGradiant = '';
    this.blurOpacity = '';
    this.favIcon = '';
    this.medias = [];
    this.showMedia = false;
    this.freeData = [];
    this.showPage = false;
    this.mediadata = [];

    this.allMediaData = [];
    this.othersMediaDataList = [];
    this.OthermediaData = [];
    this.showOtherData = false;
    this.mediaData = [];
    this.showPageAfterLoad = false;

    // this.hlsjsDriver1 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver2 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver3 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver4 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver5 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver6 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver7 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver8 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver9 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver10 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver11 = new HlsjsPlyrDriver(false);
    // this.hlsjsDriver12 = new HlsjsPlyrDriver(false);
    this.signoutModal = false;
    this.searchValue = '';
    this.logoSizeRoundValue = 0;
    this.domain = '';
  }
  ngOnInit(): void {
    this.loginData = this._SessionService.getSession('loggedInUser');
    this.isLoggedIn = this.loginData ? true : false;
    this.web = `${window.location.protocol}//${window.location.hostname}/`;
    this.domain =
      this._HomeService.sendURL &&
      this._HomeService.sendURL.replace('https://', '').replace('/', '');
    // this._Router.navigate([], {
    //   relativeTo: this._ActivatedRoute,
    //   queryParams: { domain: this.domain },
    //   queryParamsHandling: 'merge',
    // });
    // this._ActivatedRoute.queryParams.subscribe((params) => {
    //   this.domain = params.domain;
    // });
    this.getPageCustomizationByDomain();
    this.getSubscribe();
    this._ActivatedRoute.data.subscribe(data => {
      const mediaItems = data.data.dataFromAPI1.data;
      const responseGetEbookItems = data.data.dataFromAPI2.data;
      this.responseGetMediaData(mediaItems)
      this.responseGetEbookItems(responseGetEbookItems)
      // this.getMediaItemsByResolver(mediaItems);
      // this.mediaArray = mediaRes.data;

    });
    // if (this.isLoggedIn) {
    //   this.GetMediaItemWith(1, 20);
    //   // this.GetMediaDataWith('');
    //   this.getEbookItemsWithLogin('');
    // } else {
    //   this.GetMediaItemWithout(1, 20, 'all');
    //   // this.GetMediaDataWithout('');
    //   this.getEbookItemsWithoutLogin('');
    // }
  }
  rgbaToHex(rgbaColor: any) {
    // Check if the input is a valid RGBA color string
    const rgbaRegex =
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d(?:\.\d+)?))?\)$/;
    const match = rgbaRegex.exec(rgbaColor);

    if (!match) {
      console.error('Invalid RGBA color format.');
      return null;
    }

    // Extract RGBA components
    const red = parseInt(match[1]);
    const green = parseInt(match[2]);
    const blue = parseInt(match[3]);
    const alpha = match[4] ? parseFloat(match[4]) : 1.0;

    // Convert to HEX
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Alpha is usually not represented in HEX, but you can append it to the end if you want
    // const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

    // Concatenate the HEX components (without alpha) and return the result
    return `#${redHex}${greenHex}${blueHex}`;
  }

  addAlpha(color: string, opacity: number): any {
    if (color.includes('#')) {
      // coerce values so ti is between 0 and 1.
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = color + _opacity.toString(16).toUpperCase();
    } else {
      // rgba(1,1,22,0.99)
      let data = this.rgbaToHex(color);
      const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
      // return color + _opacity.toString(16).toUpperCase();
      this.changeToRGB = data + _opacity.toString(16).toUpperCase();
      // this.changeToRGB = color
    }
  }

  logOut() {
    this._LoginService.logOutUser().subscribe(
      () => {
        this._MessageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logged out successful.',
        });
        setTimeout(() => {
          localStorage.clear();
          this._Router.navigate(['/main']);
        }, 100);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }
  getSubscribe() {
    this._HomeService.sendPageData$.subscribe((response: any) => {
      if (response.data) {
        this.pageRoutes = {
          mainPageName: response.data.pageCustomizationDTO.homePage,
          vodPageName: response.data.pageCustomizationDTO.watchDailyPage,
          contactName: response.data.pageCustomizationDTO.contactUs,
          showHideEbook: response.data.pageCustomizationDTO.showHideEbook,
          ebookPage: response.data.pageCustomizationDTO.ebookPage,
        };
        this._Title.setTitle(
          `${response.data.pageCustomizationDTO.orgName
            ? `${response.data.pageCustomizationDTO.orgName}-`
            : ''
          }${response.data.pageCustomizationDTO.ebookPage}`
        );
        this._Meta.updateTag({
          property: 'og:title',
          content: `${response.data.pageCustomizationDTO.orgName
              ? `${response.data.pageCustomizationDTO.orgName}-`
              : ''
            }${response.data.pageCustomizationDTO.ebookPage}`,
        });
        this._Meta.updateTag({
          property: 'og:image',
          content: `${appConfig.imageURL}${response.data.pageCustomizationDTO.logoId}?height=100&width=100`,
        });
        if (this._Router.url === '/vod') {
          this._Router.navigate(['/vod'], {
            queryParams: { vod: this.pageRoutes.vodPageName },
          });
        }
        // webappSetting -----------------
        this.appBGColor = response.data.pageCustomizationDTO.backgroundColor;
        this.favIcon = response.data.pageCustomizationDTO.faviconId
          ? `${appConfig.imageURL}` +
          response.data.pageCustomizationDTO.faviconId
          : '';
        this._AngularFaviconService.setFavicon(this.favIcon);
        this.blurOpacity = response.data.webAppSettingDTO
          ? response.data.webAppSettingDTO.shadowsFade / 10
          : '0.5';
        this.addAlpha(this.appBGColor, this.blurOpacity);
        if (this.scrWidth < 500) {
          this.logoSize = response.data.headerDTO.mobileLogoSize;
        } else if (this.scrWidth > 500 && this.scrWidth < 900) {
          this.logoSize = response.data.headerDTO.tabletLogoSize;
        } else {
          this.logoSize = response.data.headerDTO.webLogoSize;
        }
        this.logoSizeRoundValue = Math.round(this.logoSize / 10) * 10;

        this.appBtnPrimaryColor = this._DomSanitizer.bypassSecurityTrustStyle(
          `${response.data.pageCustomizationDTO.primaryColor}`
        );
        this.bottomGradiant = this._DomSanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom, ${this.changeToRGB} 65%,${this.appBGColor})`
        );
        this.headerLinkColor = response.data.headerDTO.headerLinksColor;
        this.headerLogo = response.data.headerDTO.logoId
          ? `${appConfig.imageURL}` + response.data.headerDTO.logoId
          : '';
        this._ElementRef.nativeElement.ownerDocument.body.style.backgroundColor =
          this.appBGColor;
        this.primaryColor = response.data.pageCustomizationDTO.primaryColor;
      }
    });
  }
  getPageCustomizationByDomain(): void {
    if (!this.domain) {
      return;
    }
    this._HomeService
      .getPageCustomizationByDomain(this.domain)
      .subscribe((response: any) => {
        if (response.data) {
          this._Title.setTitle(
            `${response.data.organizationDTO.name
              ? `${response.data.organizationDTO.name}-`
              : ''
            }${response.data.homePage}`
          );
          const favIcon = response.data.faviconId
            ? `${appConfig.imageURL}` + response.data.faviconId
            : '';
          this._AngularFaviconService.setFavicon(favIcon);
          this._Meta.updateTag({
            property: 'og:title',
            content: `${response.data.organizationDTO.name
                ? `${response.data.organizationDTO.name}-`
                : ''
              }${response.data.homePage}`,
          });
          this._Meta.updateTag({
            property: 'og:image',
            content: `${response.data.organizationDTO.documentResponseDTO.path}`,
          });
        }
      });
  }
  navigateToVideo() {
    this._Router.navigate([`/video/${this.SlideId}`]);
  }
  getEbookItemsWithLogin(searchKey: string) {
    this._HomeService
      .getEbookSeriesList(searchKey)
      .subscribe((response) => {
        this._SharedService.updateLoader(true);
        this.allMediaData = response.data.mediaSeriesResponseDTOS;
        this.othersMediaDataList = response.data.otherMediaItemsDTO;
        this.showOtherData =
          response.data.otherMediaItemsDTO.length > 0 ? true : false;
        if (this.allMediaData.length > 0) {
          this.showMedia = true;
        }
        this.allMediaData.forEach((el, i) => {
          el.mediaItemDTO.forEach((element: any, index: any) => {
            element['index'] = index;
            if (element.ebookArtworkId) {
              var width = 245;
              var height = 385;
              let id = element.ebookArtworkId;
              element['newwideimg'] =
                `${appConfig.imageURL}` +
                id +
                '?height=' +
                height +
                '&width=' +
                width;
              element['newwideimgBanner'] =
                `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              element['color'] = element.ebookArtworkImageColor
                ? element.ebookArtworkImageColor
                : '';
            }
          });
        });
        this.mediaData = this.allMediaData
          .map((el, i) => ({
            title: el.title,
            mediaItem: el.mediaItemDTO,
            subTitle: el.subTitle,
          }))
          .filter((value: any) => value.mediaItem.length);

        this.othersMediaDataList.forEach((element) => {
          if (element.ebookArtworkId) {
            var width = 245;
            var height = 385;
            let id = element.ebookArtworkId;
            element['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
            element['newwideimgBanner'] =
              `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
            element['color'] = element.ebookArtworkImageColor
              ? element.ebookArtworkImageColor
              : '';
          }
        });
        setTimeout(() => {
          this.showPageAfterLoad = true;
          this._SharedService.updateLoader(false);
        }, 200);
      }), ((err: any) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error.message,
        })
      })

  }

  getEbookItemsWithoutLogin(searchKey: string) {
    this._HomeService
      .getEbookSeriesListWithout(searchKey)
      .subscribe((response) => {
        this._SharedService.updateLoader(true);
        this.allMediaData = response.data.mediaSeriesResponseDTOS;
        this.othersMediaDataList = response.data.otherMediaItemsDTO;
        this.showOtherData =
          response.data.otherMediaItemsDTO.length > 0 ? true : false;
        if (this.allMediaData.length > 0) {
          this.showMedia = true;
        }
        this.allMediaData.forEach((el, i) => {
          el.mediaItemDTO.forEach((element: any, index: any) => {
            element['index'] = index;
            if (element.ebookArtworkId) {
              var width = 245;
              var height = 385;
              let id = element.ebookArtworkId;
              element['newwideimg'] =
                `${appConfig.imageURL}` +
                id +
                '?height=' +
                height +
                '&width=' +
                width;
              element['newwideimgBanner'] =
                `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              element['color'] = element.ebookArtworkImageColor
                ? element.ebookArtworkImageColor
                : '';
            }
          });
        });
        this.mediaData = this.allMediaData
          .map((el, i) => ({
            title: el.title,
            mediaItem: el.mediaItemDTO,
            subTitle: el.subTitle,
          }))
          .filter((value: any) => value.mediaItem.length);

        this.othersMediaDataList.forEach((element) => {
          if (element.ebookArtworkId) {
            var width = 245;
            var height = 385;
            let id = element.ebookArtworkId;
            element['newwideimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
            element['newwideimgBanner'] =
              `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
            element['color'] = element.ebookArtworkImageColor
              ? element.ebookArtworkImageColor
              : '';
          }
        });
        setTimeout(() => {
          this.showPageAfterLoad = true;
          this._SharedService.updateLoader(false);
        }, 200);
      }), ((err: any) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: err.error.message,
        })
      })
  }
  searchEbookItems(searchKey: string) {
    if (searchKey.length >= 3) {
      if (this.isLoggedIn) {
        this.getEbookItemsWithLogin(searchKey);
      }
      this.getEbookItemsWithoutLogin(searchKey);
    } else if (searchKey.length === 0) {
      if (this.isLoggedIn) {
        this.getEbookItemsWithLogin('');
      }
      this.getEbookItemsWithoutLogin('');
    }
  }

  responseGetEbookItems(data: any) {
    this.allMediaData = data.mediaSeriesResponseDTOS;
    this.othersMediaDataList = data.otherMediaItemsDTO;
    this.showOtherData =
      data.otherMediaItemsDTO.length > 0 ? true : false;
    if (this.allMediaData.length > 0) {
      this.showMedia = true;
    }
    this.allMediaData.forEach((el, i) => {
      el.mediaItemDTO.forEach((element: any, index: any) => {
        element['index'] = index;
        if (element.ebookArtworkId) {
          var width = 245;
          var height = 385;
          let id = element.ebookArtworkId;
          element['newwideimg'] =
            `${appConfig.imageURL}` +
            id +
            '?height=' +
            height +
            '&width=' +
            width;
          element['newwideimgBanner'] =
            `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
          element['color'] = element.ebookArtworkImageColor
            ? element.ebookArtworkImageColor
            : '';
        }
      });
    });
    this.mediaData = this.allMediaData
      .map((el, i) => ({
        title: el.title,
        mediaItem: el.mediaItemDTO,
        subTitle: el.subTitle,
      }))
      .filter((value: any) => value.mediaItem.length);

    this.othersMediaDataList.forEach((element) => {
      if (element.ebookArtworkId) {
        var width = 245;
        var height = 385;
        let id = element.ebookArtworkId;
        element['newwideimg'] =
          `${appConfig.imageURL}` +
          id +
          '?height=' +
          height +
          '&width=' +
          width;
        element['newwideimgBanner'] =
          `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
        element['color'] = element.ebookArtworkImageColor
          ? element.ebookArtworkImageColor
          : '';
      }
    });
    setTimeout(() => {
      this.showPageAfterLoad = true;
      this._SharedService.updateLoader(false);
    }, 200);
  }

  responseGetMediaData(data: any) {
    this.medias = data.content;
    if (this.medias.length > 0) {
      this.showMedia = true;
    }
    this.medias.forEach((el, i) => {
      if (el.bannerArtworkId) {
        var width = 1920;
        var height = 692;
        let id = el.bannerArtworkId;
        el['newbannerimg'] =
          `${appConfig.imageURL}` +
          id +
          '?height=' +
          height +
          '&width=' +
          width;
      }

      let vedioSrc: any;
      if (el.videoDTO) {
        vedioSrc = el.videoDTO.bannerVideoUrl
          ? el.videoDTO.bannerVideoUrl
          : null;
      }
      var url = vedioSrc;
      var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : '';
      if (urlExt === 'm3u8') {
        el['vedioType'] = true;
        this.currentVedio = [];
        let newObj = {
          src: '',
        };
        let newarray: any[] = [];
        this.currentVedio.push(newObj);
        el['currentVedio'] = this.currentVedio;
        el.currentVedio[0].src = vedioSrc;
      } else {
        el['vedioType'] = false;
        el['currentVedio'] = vedioSrc;
      }
      if (el.videoDTO) {
        el.videoDTO['vedioTime'] = this._SharedService.timeConvert(
          el.videoDTO.duration
        );
      }
      // }
      else if (el.videoDuration) {
        el['vedioTime'] = this._SharedService.timeConvert(el.videoDuration);
      }
    });

    this.freeData = this.medias.filter((el) => {
      if (el.mediaAccessType === 'FREE') {
        return el;
      }
    });

    if (this.freeData.length > 8) {
      this.freeData = this.freeData.slice(0, 9);
    }

    this.products = this.freeData.map((el) => ({
      mediaImg: el.newbannerimg ? el.newbannerimg : '',
      duration: el.videoDTO
        ? el.videoDTO.vedioTime
        : el.vedioTime
          ? el.vedioTime
          : '0:00',
      title: el.title,
      subTitle: el.subTitle,
      color: el.bannerArtworkImageColor ? el.bannerArtworkImageColor : '',
      mediaAccessType: el.mediaAccessType,
      id: el.id,
      isVideo: el.isVideo,
      currentVedio: el.currentVedio ? el.currentVedio : null,
      vedioType: el.vedioType ? el.vedioType : false,
    }));
    setTimeout(() => {
      this.play();
    }, 1000);

    this.SlideId = this.products.length > 0 ? this.products[0].id : null;

    // this.mediadata = this.medias.map((el) => ({
    //   duration: el.videoDTO
    //     ? el.videoDTO.vedioTime
    //     : el.vedioTime
    //     ? el.vedioTime
    //     : '0:00',
    //   title: el.title,
    //   color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
    //   id: el.id,
    // }));
    this.showPage = true;
    this._SharedService.updateLoader(false);
  }
  GetMediaItemWith(page: any, size: any) {
    this._HomeService.getMediaItemWith(page, size, '').subscribe(
      (response: any) => {
        this.medias = response.data.content;
        if (this.medias.length > 0) {
          this.showMedia = true;
        }
        this.medias.forEach((el) => {
          if (el.bannerArtworkId) {
            var width = 1920;
            var height = 692;
            let id = el.bannerArtworkId;
            el['newbannerimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }

          let vedioSrc: any;
          if (el.videoDTO) {
            vedioSrc = el.videoDTO.bannerVideoUrl
              ? el.videoDTO.bannerVideoUrl
              : null;
          }
          var url = vedioSrc;
          var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : '';
          if (urlExt === 'm3u8') {
            el['vedioType'] = true;
            this.currentVedio = [];
            let newObj = {
              src: '',
            };
            let newarray: any[] = [];
            this.currentVedio.push(newObj);
            el['currentVedio'] = this.currentVedio;
            el.currentVedio[0].src = vedioSrc;
          } else {
            el['vedioType'] = false;
            el['currentVedio'] = vedioSrc;
          }
          if (el.videoDTO) {
            // el['currentVedio'] = el.videoDTO.originalPath;
            el.videoDTO['vedioTime'] = this._SharedService.timeConvert(
              el.videoDTO.duration
            );
          } else if (el.videoDuration) {
            el['vedioTime'] = this._SharedService.timeConvert(el.videoDuration);
          }
        });

        this.freeData = this.medias.filter((el) => {
          if (el.mediaAccessType === 'FREE') {
            return el;
          }
        });

        if (this.freeData.length > 8) {
          this.freeData = this.freeData.slice(0, 9);
        }

        this.products = this.freeData.map((el) => ({
          mediaImg: el.newbannerimg ? el.newbannerimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          subTitle: el.subTitle,
          color: el.bannerArtworkImageColor ? el.bannerArtworkImageColor : '',
          mediaAccessType: el.mediaAccessType,
          id: el.id,
          isVideo: el.isVideo,
          currentVedio: el.currentVedio ? el.currentVedio : null,
          vedioType: el.vedioType ? el.vedioType : false,
        }));
        setTimeout(() => {
          this.play();
        }, 1000);
        this.SlideId = this.products.length > 0 ? this.products[0].id : null;
        // this.mediadata = this.medias.map((el) => ({
        //   duration: el.videoDTO
        //     ? el.videoDTO.vedioTime
        //     : el.vedioTime
        //     ? el.vedioTime
        //     : '0:00',
        //   title: el.title,
        //   color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
        //   id: el.id,
        // }));
        this.showPage = true;
      },
      (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  GetMediaItemWithout(page: any, size: any, type: any) {
    this._SharedService.updateLoader(true);
    this._HomeService.getMediaItemWithout(page, size, type).subscribe(
      (response: any) => {
        this.medias = response.data.content;
        if (this.medias.length > 0) {
          this.showMedia = true;
        }
        this.medias.forEach((el, i) => {
          if (el.bannerArtworkId) {
            var width = 1920;
            var height = 692;
            let id = el.bannerArtworkId;
            el['newbannerimg'] =
              `${appConfig.imageURL}` +
              id +
              '?height=' +
              height +
              '&width=' +
              width;
          }

          let vedioSrc: any;
          if (el.videoDTO) {
            vedioSrc = el.videoDTO.bannerVideoUrl
              ? el.videoDTO.bannerVideoUrl
              : null;
          }
          var url = vedioSrc;
          var urlExt = url ? url.substr(url.lastIndexOf('.') + 1) : '';
          if (urlExt === 'm3u8') {
            el['vedioType'] = true;
            this.currentVedio = [];
            let newObj = {
              src: '',
            };
            let newarray: any[] = [];
            this.currentVedio.push(newObj);
            el['currentVedio'] = this.currentVedio;
            el.currentVedio[0].src = vedioSrc;
          } else {
            el['vedioType'] = false;
            el['currentVedio'] = vedioSrc;
          }
          if (el.videoDTO) {
            el.videoDTO['vedioTime'] = this._SharedService.timeConvert(
              el.videoDTO.duration
            );
          }
          // }
          else if (el.videoDuration) {
            el['vedioTime'] = this._SharedService.timeConvert(el.videoDuration);
          }
        });

        this.freeData = this.medias.filter((el) => {
          if (el.mediaAccessType === 'FREE') {
            return el;
          }
        });

        if (this.freeData.length > 8) {
          this.freeData = this.freeData.slice(0, 9);
        }

        this.products = this.freeData.map((el) => ({
          mediaImg: el.newbannerimg ? el.newbannerimg : '',
          duration: el.videoDTO
            ? el.videoDTO.vedioTime
            : el.vedioTime
              ? el.vedioTime
              : '0:00',
          title: el.title,
          subTitle: el.subTitle,
          color: el.bannerArtworkImageColor ? el.bannerArtworkImageColor : '',
          mediaAccessType: el.mediaAccessType,
          id: el.id,
          isVideo: el.isVideo,
          currentVedio: el.currentVedio ? el.currentVedio : null,
          vedioType: el.vedioType ? el.vedioType : false,
        }));
        setTimeout(() => {
          this.play();
        }, 1000);

        this.SlideId = this.products.length > 0 ? this.products[0].id : null;

        // this.mediadata = this.medias.map((el) => ({
        //   duration: el.videoDTO
        //     ? el.videoDTO.vedioTime
        //     : el.vedioTime
        //     ? el.vedioTime
        //     : '0:00',
        //   title: el.title,
        //   color: el.wideArtwork ? el.wideArtwork.document.imageColur : '',
        //   id: el.id,
        // }));
        this.showPage = true;
        this._SharedService.updateLoader(false);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  goToView(item: any) {
    this._Router.navigate([`ebook/${item.id}`]);
  }

  play(): void {
    if (this.players) {
      this.players.play();
      this.players.muted = true;
    }
  }

  GetMediaDataWithout(search: string) {
    this._SharedService.updateLoader(true);
    this._HomeService.getMediaDataWithout(search).subscribe(
      (response: any) => {
        this._SharedService.updateLoader(true);
        this.allMediaData = response.data.mediaSeriesResponseDTOS;
        this.othersMediaDataList = response.data.otherMediaItemsDTO;
        this.showOtherData =
          response.data.otherMediaItemsDTO.length > 0 ? true : false;
        if (this.allMediaData.length > 0) {
          this.showMedia = true;
        }
        this.allMediaData.forEach((el, i) => {
          el.mediaItemDTO.forEach((element: any, index: any) => {
            element['index'] = index;
            if (element.wideArtworkId) {
              var width = 480;
              var height = 270;
              let id = element.wideArtworkId;
              element['newwideimg'] =
                `${appConfig.imageURL}` +
                id +
                '?height=' +
                height +
                '&width=' +
                width;
              element['newwideimgBanner'] =
                `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              element['color'] = element.wideArtworkImageColor
                ? element.wideArtworkImageColor
                : '';
            }
            if (element.videoDTO) {
              element['duration'] = this._SharedService.timeConvert(
                element.videoDTO.duration
              );
            } else if (element.videoDuration) {
              element['duration'] = this._SharedService.timeConvert(
                element.videoDuration
              );
            }
          });
        });
        // this.mediaData = this.allMediaData.map((el, i) => ({
        //   title: el.title,
        //   mediaItem: el.mediaItemDTO,
        // }));

        // this.othersMediaDataList.forEach((element) => {
        //   if (element.wideArtworkId) {
        //     var width = 320;
        //     var height = 180;
        //     let id = element.wideArtworkId;
        //     element['newwideimg'] =
        //       `${appConfig.imageURL}` +
        //       id +
        //       '?height=' +
        //       height +
        //       '&width=' +
        //       width;
        //     element['newwideimgBanner'] =
        //       `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
        //     element['color'] = element.wideArtworkImageColor
        //       ? element.wideArtworkImageColor
        //       : '';
        //   }
        //   if (element.videoDTO) {
        //     element['duration'] = this._SharedService.timeConvert(
        //       element.videoDTO.duration
        //     );
        //   } else if (element.videoDuration) {
        //     element['duration'] = this._SharedService.timeConvert(
        //       element.videoDuration
        //     );
        //   }
        // });

        setTimeout(() => {
          this.showPageAfterLoad = true;
          this._SharedService.updateLoader(false);
        }, 200);
      },
      (error) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
      }
    );
  }

  GetMediaDataWith(search: string) {
    this._SharedService.updateLoader(true);
    this._HomeService.getMediaDataWith(search).subscribe(
      (response: any) => {
        this._SharedService.updateLoader(true);
        this.allMediaData = response.data.mediaSeriesResponseDTOS;
        this.othersMediaDataList = response.data.otherMediaItemsDTO;
        this.showOtherData =
          response.data.otherMediaItemsDTO.length > 0 ? true : false;
        if (this.allMediaData.length > 0) {
          this.showMedia = true;
        }
        this.allMediaData.forEach((el, i) => {
          el.mediaItemDTO.forEach((element: any, index: any) => {
            element['index'] = index;
            if (element.wideArtworkId) {
              var width = 320;
              var height = 180;
              let id = element.wideArtworkId;
              element['newwideimg'] =
                `${appConfig.imageURL}` +
                id +
                '?height=' +
                height +
                '&width=' +
                width;
              element['newwideimgBanner'] =
                `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
              element['color'] = element.wideArtworkImageColor
                ? element.wideArtworkImageColor
                : '';
            }
            if (element.videoDTO) {
              element['duration'] = this._SharedService.timeConvert(
                element.videoDTO.duration
              );
            } else if (element.videoDuration) {
              element['duration'] = this._SharedService.timeConvert(
                element.videoDuration
              );
            }
          });
        });
        // this.mediaData = this.allMediaData.map((el, i) => ({
        //   title: el.title,
        //   mediaItem: el.mediaItemDTO,
        // }));

        // this.othersMediaDataList.forEach((element) => {
        //   if (element.wideArtworkId) {
        //     var width = 320;
        //     var height = 180;
        //     let id = element.wideArtworkId;
        //     element['newwideimg'] =
        //       `${appConfig.imageURL}` +
        //       id +
        //       '?height=' +
        //       height +
        //       '&width=' +
        //       width;
        //     element['newwideimgBanner'] =
        //       `${appConfig.imageURL}` + id + '?height=270' + '&width=480';
        //     element['color'] = element.wideArtworkImageColor
        //       ? element.wideArtworkImageColor
        //       : '';
        //   }
        //   if (element.videoDTO) {
        //     element['duration'] = this._SharedService.timeConvert(
        //       element.videoDTO.duration
        //     );
        //   } else if (element.videoDuration) {
        //     element['duration'] = this._SharedService.timeConvert(
        //       element.videoDuration
        //     );
        //   }
        // });
        setTimeout(() => {
          this.showPageAfterLoad = true;
          this._SharedService.updateLoader(false);
        }, 200);
      },
      (error) => {
        this._MessageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.message,
        });
      }
    );
  }

  // played(event: Plyr.PlyrEvent, index: any) {
  //   this.products.forEach((el: any, i: any) => {
  //     if (el.vedioType === true) {
  //       if (i === 0) {
  //         setTimeout(() => {
  //           this.hlsjsDriver1.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 1) {
  //         setTimeout(() => {
  //           this.hlsjsDriver2.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 2) {
  //         setTimeout(() => {
  //           this.hlsjsDriver3.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 3) {
  //         setTimeout(() => {
  //           this.hlsjsDriver4.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 4) {
  //         setTimeout(() => {
  //           this.hlsjsDriver5.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 5) {
  //         setTimeout(() => {
  //           this.hlsjsDriver6.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 6) {
  //         setTimeout(() => {
  //           this.hlsjsDriver7.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 7) {
  //         setTimeout(() => {
  //           this.hlsjsDriver8.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 8) {
  //         setTimeout(() => {
  //           this.hlsjsDriver9.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 9) {
  //         setTimeout(() => {
  //           this.hlsjsDriver10.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 10) {
  //         setTimeout(() => {
  //           this.hlsjsDriver11.load(el.currentVedio[0].src);
  //         }, 500);
  //       } else if (i === 11) {
  //         setTimeout(() => {
  //           this.hlsjsDriver12.load(el.currentVedio[0].src);
  //         }, 500);
  //       }
  //     }
  //   });
  //   setTimeout(() => {}, 1000);
  // }

  // test(i: any) {
  //   if (i === 0) {
  //     return this.hlsjsDriver1;
  //   } else if (i === 1) {
  //     return this.hlsjsDriver2;
  //   } else if (i === 2) {
  //     return this.hlsjsDriver3;
  //   } else if (i === 3) {
  //     return this.hlsjsDriver4;
  //   } else if (i === 4) {
  //     return this.hlsjsDriver5;
  //   } else if (i === 5) {
  //     return this.hlsjsDriver6;
  //   } else if (i === 6) {
  //     return this.hlsjsDriver7;
  //   } else if (i === 7) {
  //     return this.hlsjsDriver8;
  //   } else if (i === 8) {
  //     return this.hlsjsDriver9;
  //   } else if (i === 9) {
  //     return this.hlsjsDriver10;
  //   } else if (i === 10) {
  //     return this.hlsjsDriver11;
  //   } else {
  //     return this.hlsjsDriver12;
  //   }
  // }
}
